import React from "react";
import CustomMetricsAdmin from "views/SuperAdmin/CustomMetricsAdmin";
import CustomMetricsCustomer from "views/CustomerAdmin/CustomMetricsCustomer";
import jwtDecode from "jwt-decode";

const CustomMetrics = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const loggedinRole = decodedToken.role;

  return (
    <>
      {loggedinRole === "SuperAdmin" ? (
        <CustomMetricsAdmin />
      ) : (
        <CustomMetricsCustomer />
      )}
    </>
  );
};

export default CustomMetrics;
