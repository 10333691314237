/* eslint-disable no-constant-condition */
import React from "react";
import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/views/findingStyle";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { viewInsightsStore } from "store";
import AreaSquare from "components/ViewInsights/AreaSquare";
import jwtDecode from "jwt-decode";

const useStyles = makeStyles(styles);

const FindingResuable = ({ title, data, Color }) => {
  const classes = useStyles();
  const inquiryId = viewInsightsStore((state) => state.inquiryId);
  const setCurrentAreaRef = viewInsightsStore(
    (state) => state.setCurrentAreaRef
  );
  const history = useHistory();

  const onClick = (value) => {
    setCurrentAreaRef(value);
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    let loggedInRole = "";
    if (decodedToken.role.toLowerCase() === "superadmin") {
      loggedInRole = "superAdmin";
    } else if (decodedToken.role.toLowerCase() === "customeradmin") {
      loggedInRole = "customerAdmin";
    }
    history.push(
      `/${loggedInRole}/dashboard/view-insights/${inquiryId}/inputs`
    );
  };

  return (
    <GridContainer className={classes.cont}>
      <GridItem xs={12} sm={12} md={3} style={{ margin: "10px 0px" }}>
        <span
          onClick={() => onClick(title.toLowerCase())}
          className={classes.cursor}
        >
          <AreaSquare areaName={title} color={Color} />
        </span>
      </GridItem>
      <GridItem xs={12} sm={12} md={9}>
        {data &&
          data.map((item, key) => {
            return (
              <ul key={key} className={classes.mar_A}>
                <li xs={12} sm={12} md={12} className={classes.li}>
                  {item.FindingsDesc}
                </li>
              </ul>
            );
          })}
      </GridItem>
    </GridContainer>
  );
};

export default FindingResuable;

FindingResuable.propTypes = {
  title: PropTypes.string,
  Color: PropTypes.string,
  data: PropTypes.array,
};
