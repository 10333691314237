import React from "react";
import { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useHistory } from "react-router-dom";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import useAxios from "axios/httpService";
import { GetInputResultForInquiryUrl } from "axios/commonAPIsUrls";
import Loading from "components/Loading/Loading";
import { viewInsightsStore } from "store";
import InputsResultsGraph from "./InputsResultsGraph";
import { makeStyles } from "@material-ui/core";

const styles = {
  inputResults: {
    margin: "20px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

const useStyles = makeStyles(styles);

const Inputs = () => {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");
  const currentAreaRef = viewInsightsStore((state) => state.currentAreaRef);
  const setCurrentAreaRef = viewInsightsStore(
    (state) => state.setCurrentAreaRef
  );

  const inquiryId = viewInsightsStore((state) => state.inquiryId);

  // Getting Structure Information from Store
  const structureColor = viewInsightsStore((state) => state.structureColor);
  const structureDataset = viewInsightsStore((state) => state.structureDataset);
  const setStructureDataset = viewInsightsStore(
    (state) => state.setStructureDataset
  );
  const structureYAxisLabels = viewInsightsStore(
    (state) => state.structureYAxisLabels
  );

  const setStructureYAxisLabels = viewInsightsStore(
    (state) => state.setStructureYAxisLabels
  );

  // Getting Process Information from Store
  const processColor = viewInsightsStore((state) => state.processColor);
  const processDataset = viewInsightsStore((state) => state.processDataset);
  const setProcessDataset = viewInsightsStore(
    (state) => state.setProcessDataset
  );
  const processYAxisLabels = viewInsightsStore(
    (state) => state.processYAxisLabels
  );
  const setProcessYAxisLabels = viewInsightsStore(
    (state) => state.setProcessYAxisLabels
  );

  // Getting People Information from Store
  const peopleColor = viewInsightsStore((state) => state.peopleColor);
  const peopleDataset = viewInsightsStore((state) => state.peopleDataset);
  const setPeopleDataset = viewInsightsStore((state) => state.setPeopleDataset);
  const peopleYAxisLabels = viewInsightsStore(
    (state) => state.peopleYAxisLabels
  );
  const setPeopleYAxisLabels = viewInsightsStore(
    (state) => state.setPeopleYAxisLabels
  );

  // Getting Culture Information from Store
  const cultureColor = viewInsightsStore((state) => state.cultureColor);
  const cultureDataset = viewInsightsStore((state) => state.cultureDataset);
  const setCultureDataset = viewInsightsStore(
    (state) => state.setCultureDataset
  );
  const cultureYAxisLabels = viewInsightsStore(
    (state) => state.cultureYAxisLabels
  );
  const setCultureYAxisLabels = viewInsightsStore(
    (state) => state.setCultureYAxisLabels
  );

  // Getting Inputs Results from API
  const [{ data, loading, error }, executeGetInputsUrl] = useAxios(
    GetInputResultForInquiryUrl(inquiryId, token)
  );
  useEffect(async () => {
    await executeGetInputsUrl();
  }, []);
  const getAreaInputsResults = (data, setYAxisLabels, dataset, setDataset) => {
    const newDataset = [...dataset];
    // Removing older values from data array
    newDataset[0].data = [];
    newDataset[1].data = [];
    const newYAxisLabels = [];
    data &&
      data.map((item) => {
        // setting values in yAxisLabels

        // truncating y axis values upto 25 characters
        /*let truncatedValue = item.Questiondescription.substring(0, 25) + "...";
        newYAxisLabels.push(truncatedValue);
        still have to work down here */

        newYAxisLabels.push(item.Questiondescription);

        // setting values in dataset
        newDataset[0].data.push(item.AverageRating);
        newDataset[1].data.push(item.AverageValue);
      });
    newDataset[0].data.push(5);
    newDataset[1].data.push(5);

    setDataset(newDataset);
    setYAxisLabels(newYAxisLabels);
  };

  // This useEffect is redirecting the user to particular area upon clicking in OverViews and Findings
  useEffect(() => {
    if (currentAreaRef) {
      document.querySelector(`.${currentAreaRef}`)?.scrollIntoView({
        block: "center",
        scrollBehavior: "smooth",
      });
    }
    return () => setCurrentAreaRef("culture");
  }, [currentAreaRef, data]);

  React.useEffect(() => {
    // setting data for structureGraph
    data &&
      getAreaInputsResults(
        data.StructureArray,
        setStructureYAxisLabels,
        structureDataset,
        setStructureDataset
      );

    // setting data for processGraph
    data &&
      getAreaInputsResults(
        data.ProcessArray,
        setProcessYAxisLabels,
        processDataset,
        setProcessDataset
      );

    // setting data for peopleGraph
    data &&
      getAreaInputsResults(
        data.PeopleArray,
        setPeopleYAxisLabels,
        peopleDataset,
        setPeopleDataset
      );

    // setting data for cultureGraph
    data &&
      getAreaInputsResults(
        data.CultureArray,
        setCultureYAxisLabels,
        cultureDataset,
        setCultureDataset
      );
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }

  // error in fetching API response of input details
  if (error) {
    console.log("Error in Getting Input Results", error);
    apiErrorHandler(error, history);
  }

  if (
    data &&
    data.StructureArray.length === 0 &&
    data.CultureArray.length === 0 &&
    data.ProcessArray.length === 0 &&
    data.PeopleArray.length === 0
  ) {
    return (
      <GridContainer className={classes.centered}>
        <GridItem xs={12} sm={12} md={12} className={classes.inputResults}>
          <h1>No Data Available Currently</h1>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      {/* Culture Inputs Results */}
      {data && data.CultureArray && data.CultureArray.length > 0 && (
        <GridItem
          xs={12}
          sm={12}
          md={12}
          className={`${classes.inputResults} culture`}
        >
          <InputsResultsGraph
            className={"culture"}
            areaName="Culture"
            areaColor={cultureColor}
            yAxisValues={cultureYAxisLabels}
            dataset={cultureDataset}
          />
        </GridItem>
      )}
      {/* People Inputs Results */}
      {data && data.PeopleArray && data.PeopleArray.length > 0 && (
        <GridItem
          xs={12}
          sm={12}
          md={12}
          className={`${classes.inputResults} people`}
        >
          <InputsResultsGraph
            className={"people"}
            areaName="People"
            areaColor={peopleColor}
            yAxisValues={peopleYAxisLabels}
            dataset={peopleDataset}
          />
          {/* <div className="People"></div> */}
        </GridItem>
      )}
      {/* Process Inputs Results */}
      {data && data.ProcessArray && data.ProcessArray.length > 0 && (
        <GridItem
          xs={12}
          sm={12}
          md={12}
          className={`${classes.inputResults} process`}
        >
          <InputsResultsGraph
            className={"process"}
            areaName="Process"
            areaColor={processColor}
            yAxisValues={processYAxisLabels}
            dataset={processDataset}
          />
          {/* <div className="Process"></div> */}
        </GridItem>
      )}
      {/* Structure Inputs Results */}
      {data && data.StructureArray && data.StructureArray.length > 0 && (
        <GridItem
          xs={12}
          sm={12}
          md={12}
          className={`${classes.inputResults} structure`}
        >
          <InputsResultsGraph
            className={"structure"}
            areaName="Structure"
            areaColor={structureColor}
            yAxisValues={structureYAxisLabels}
            dataset={structureDataset}
          />
          {/* <div className="Structure-page"></div> */}
        </GridItem>
      )}
    </GridContainer>
  );
};

export default Inputs;
