import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import style from "assets/jss/material-dashboard-pro-react/views/Auth/signupStyle";

const styles = {
  ...style,
  centered: {
    textAlign: "center",
    alignSelf: "center",
  },
  btnMargin: {
    display: "inline-block",
    margin: "0px 10px",
  },
  title: {
    fontWeight: "600",
  },
  bold: {
    fontWeight: "400",
  },
};

const useStyles = makeStyles(styles);

const NotFoundPage = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <GridContainer className={classes.fullSize + " " + classes.whiteBg}>
      <GridItem xs={12} sm={12} md={6} className={classes.centered}>
        <GridItem xs={12} sm={12} md={12}>
          <h1 className={classes.title}>Page 404 not found</h1>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <h3 className={classes.bold}>The page you requested is not found.</h3>
        </GridItem>
        <GridItem xs={12} md={12} sm={12}>
          <Button
            color="primary"
            round
            size="lg"
            className={classes.floatRight}
            muiClasses={{
              label: "",
            }}
            onClick={() => history.goBack()}
          >
            Go Back
          </Button>
          <div className={classes.btnMargin}></div>
          <Button
            color="primary"
            round
            size="lg"
            className={classes.floatRight}
            muiClasses={{
              label: "",
            }}
            onClick={() => history.push("/auth/login")}
          >
            Go to Login
          </Button>
        </GridItem>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={6}
        className={classes.removePadding + " " + classes.coverImage}
      >
        <h1 className={classes.imgTitle}>
          Welcome to{" "}
          <b>
            <span className={classes.primary}>ACT</span>ivate Advance
          </b>
        </h1>
        <h3 className={classes.imgText}>
          Activating Advanced Strategies in Real Time
        </h3>
      </GridItem>
    </GridContainer>
  );
};

export default NotFoundPage;
