import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ActivateLogo from "assets/img/activateImages/activate-logo.png";
import Box from "@material-ui/core/Box";
import styles from "assets/jss/material-dashboard-pro-react/views/Auth/authStyle.js";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Icon, makeStyles, Typography } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { authStore } from "store";
// import useAxios from "axios-hooks";
import { UserResetPasswordUrl } from "axios/authAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import classNames from "classnames";
import ButtonLoading from "components/Loading/ButtonLoading";
import CustomInput from "components/Inputs/CustomInput";
import { validatePassword } from "helpers/validations";
import { validateConfPassword } from "helpers/validations";
import ResetSvg from "assets/img/svgs/reset.svg";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
const ResetPasswordPage = ({ history }) => {
  const classes = useStyles();
  const userId = authStore((state) => state.userId);
  const emailZustand = authStore((state) => state.emailZustand);
  const emailCode = authStore((state) => state.emailCode);

  const [values, setValues] = React.useState({
    newPassword: "",
    confirmPassword: "",
  });
  // checkingLoader

  const [validateFields, setValidateFields] = React.useState(false);

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const [{ loading }, ExecuteUserResetPasswordUrl] = useAxios(
    UserResetPasswordUrl(userId),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (validatePassword(values.newPassword)) {
      return false;
    }
    if (validateConfPassword(values.newPassword, values.confirmPassword)) {
      return false;
    }

    return true;
  };
  const resetPasswordHandler = async () => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      try {
        const res = await ExecuteUserResetPasswordUrl({
          data: {
            Email: emailZustand,
            Password: values.newPassword,
            token: emailCode,
          },
        });
        NotificationManager.success("Reset Password Successfully", 5000);
        // eslint-disable-next-line react/prop-types
        history.push("/auth/login");
      } catch (error) {
        console.log("API Error", error);
        apiErrorHandler(error, history);
      }
    }
  };

  return (
    <div>
      <GridContainer noDefaultSetting className={classes.container}>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            // this style line will be remove later
            className={classes.login_form}
          >
            {/* image */}
            <Box mb={8}>
              <img src={ActivateLogo} alt="activate_logo" height="140" />
            </Box>
            <Box p={1}>
              <Typography variant="caption" display="block" gutterBottom>
                Please enter your new password and confirm that password
              </Typography>
            </Box>
            {/* Custom input form */}
            <Box className={classes.width_80}>
              <Box mt={2}>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    <CustomInput
                      inputType="password"
                      required
                      label="New Password"
                      leftIcon={<Icon>lock</Icon>}
                      value={values.newPassword}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          newPassword: event.target.value,
                        })
                      }
                      errorText={
                        validateFields && validatePassword(values.newPassword)
                      }
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    <CustomInput
                      inputType="password"
                      required
                      label="Confirm Password"
                      leftIcon={<Icon>lock</Icon>}
                      value={values.confirmPassword}
                      onChange={(event) =>
                        setValues({
                          ...values,
                          confirmPassword: event.target.value,
                        })
                      }
                      errorText={
                        validateFields &&
                        validateConfPassword(
                          values.newPassword,
                          values.confirmPassword
                        )
                      }
                    />
                  </GridItem>
                </GridContainer>
              </Box>
            </Box>
            {/* litte content */}
            <Box mb={3}>
              <Box display="flex">
                <Typography variant="caption" display="block" gutterBottom>
                  Already have an account ?{" "}
                  <span
                    className={classNames(
                      classes.theme_color,
                      classes.cursor_pointer
                    )}
                    // eslint-disable-next-line react/prop-types
                    onClick={() => history.push("/auth/login")}
                  >
                    {" "}
                    Login
                  </span>
                </Typography>
              </Box>
            </Box>
            {/* button */}
            <Box p={1} mt={4} mb={10} display="flex">
              <Button
                round
                disabled={loading}
                className={classes.theme_color_background}
                onClick={resetPasswordHandler}
              >
                <Box display="inline" mr={2}>
                  <img src={ResetSvg} alt="Box Logo" height="15" />
                </Box>
                {loading ? <ButtonLoading /> : "Reset Password"}
              </Button>
            </Box>
          </Box>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item2}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_content}
            pb={4}
          >
            {/* Content One */}
            <Box p={1}>
              <h1 className={classes.login_form_content1}>
                Welcome to{" "}
                <b>
                  <span className={classes.theme_color}>ACT</span>ivate Advance
                </b>
              </h1>
            </Box>
            {/* Content Two */}
            <Box>
              <h3 className={classes.login_form_content2}>
                Activating Advanced Strategies in Real Time
              </h3>
            </Box>
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ResetPasswordPage;
