import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useParams } from "react-router-dom";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { GetRespondantsDocuments } from "axios/adminAPIsUrls";
import Loading from "components/Loading/Loading";
import SurveyFormStatus from "components/SurveyForms/SurveyFormStatus";
import PrintIcon from "@material-ui/icons/Print";
import jwtDecode from "jwt-decode";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
  longtooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.5rem",
    marginInlineStart: "-32px",

    "&::before": {
      left: "60%",
      marginLeft: "-36px",
      //top: "41px",
    },
  },
};

const useStyles = makeStyles(styles);
const ViewUploadedDocuments = () => {
  const history = useHistory();
  const { inquiryId, customerUserId } = useParams();
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  const dateConverter = (date) => {
    const dt = new Date(date);
    const str = dt.toLocaleString();
    let splitedStr = str.split(",");
    let dayDate = new Date(splitedStr[0]).toDateString();
    let dateAndTimes = { time: splitedStr[1], date: dayDate };

    return dateAndTimes;
  };

  const [
    { data: dataRespondents, error, loading },
    ExecuteGetRespondantsDocuments,
  ] = useAxios(
    GetRespondantsDocuments(
      inquiryId,
      customerUserId,
      currentPage,
      pageSize,
      token
    ),
    {
      manual: true,
    }
  );

  useEffect(() => {
    ExecuteGetRespondantsDocuments(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataRespondents) {
      setData(
        dataRespondents.data.map((prop, key) => {
          return {
            id: prop.Id,
            DocumentName: prop.DocumentName,
            UploadDate: `${dateConverter(prop.UploadDate).date}`,
            path: prop.path,
            actions: (
              <div className="actions-center">
                <Button
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  action
                  tooltipClassName={classes.longtooltip}
                  tooltipText="Download Document"
                  onClick={() => window.open(prop.path)}
                >
                  <i className="fa fa-sm fa-download" />
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataRespondents]);

  const classes = useStyles();

  const columns = [
    {
      Header: "Document Name",
      accessor: "DocumentName",
      alignment: "center",
    },
    {
      Header: "Uploaded Date",
      accessor: "UploadDate",
      alignment: "center",
    },
    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }
  const goBackRoute = () =>
    `/superAdmin/monitor-activity/view-respondents/${inquiryId}`;

  return (
    <GridContainer className={classes.center}>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Documents"
              showBackButton
              goBackRoute={goBackRoute()}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataRespondents && dataRespondents.currentPage}
              defaultPageSize={dataRespondents && dataRespondents.pageSize}
              totalItems={dataRespondents && dataRespondents.totalItem}
              totalPages={dataRespondents && dataRespondents.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewUploadedDocuments;
