/* eslint-disable no-constant-condition */
import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import DashboardCard from "components/common/DashboardCards";
import useDashboardInquiries from "hooks/useDashboardInquiries";
import { useHistory } from "react-router";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import authStore from "store/authStore";
import ProfileSetup from "views/CustomerAdmin/ProfileSetup";
import { makeStyles } from "@material-ui/core";
import jwtDecode from "jwt-decode";
import { grayColor } from "assets/jss/material-dashboard-pro-react";

const styles = {
  emptyDashboard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  emptyDashboardText: {
    color: `${grayColor[1]}`,
  },
};

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  const { loading, error, inquiries } = useDashboardInquiries();
  const history = useHistory();

  if (error) {
    apiErrorHandler(error, history);
  }

  const isFirstLogin = authStore((state) => state.isFirstLogin);
  if (isFirstLogin) {
    return <ProfileSetup />;
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <TableHeader title="Dashboard" />
          </CardHeader>
          <CardBody>
            {!inquiries ? (
              <Loading loading />
            ) : (
              <GridContainer xs={12} sm={12} md={12}>
                {inquiries &&
                  inquiries.map((inquiry) => {
                    return (
                      <GridItem key={inquiry.Id} lg={4} md={6} sm={12} xs={12}>
                        <DashboardCard
                          inquiryName={inquiry.Description}
                          inquiryTypeName={inquiry.InquiryTypeName}
                          numberOfParticipants={inquiry.NoOfParticipants}
                          numberOfDocuments={inquiry.Documents}
                          numberOfAdvancedInsights={inquiry.AdvanceInsights}
                          progressPercentage={inquiry.Progress}
                          onClick={() => {
                            const token = localStorage.getItem("token");
                            const decodedToken = jwtDecode(token);
                            let loggedInRole = null;
                            if (
                              decodedToken.role.toLowerCase() === "superadmin"
                            ) {
                              loggedInRole = "superAdmin";
                            } else if (
                              decodedToken.role.toLowerCase() ===
                              "customeradmin"
                            ) {
                              loggedInRole = "customerAdmin";
                            }

                            history.push(
                              `/${loggedInRole}/dashboard/view-insights/${inquiry.Id}/overview`
                            );
                          }}
                        />
                      </GridItem>
                    );
                  })}
                {inquiries && inquiries.length === 0 && (
                  <GridItem
                    xs={12}
                    md={12}
                    sm={12}
                    className={classes.emptyDashboard}
                  >
                    <h3 className={classes.emptyDashboardText}>
                      No Inquiries to show on dashboard.
                    </h3>
                  </GridItem>
                )}
              </GridContainer>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Dashboard;
