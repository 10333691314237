import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { IntakeQuestionByIdUrl } from "axios/adminAPIsUrls";
import { UpdateIntakeQuestionUrl } from "axios/adminAPIsUrls";
import { isEmpty } from "helpers/validations";
import { isNumber } from "helpers/validations";
import IntakeQuestionFields from "views/Common/intakeQuestion/IntakeQuestionFields";

const styles = {
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyle = makeStyles(styles);

const EditIntakeQuestion = () => {
  const classes = useStyle();
  const history = useHistory();
  let { inquiryParamId, intakeQuestionId } = useParams();
  const [areFieldsValidated, setFieldsValidated] = useState(false);
  const [isRequired, setRequired] = useState(false);
  const [fieldsValidation, validateFields] = useState(false);
  const [fieldsValidationSelect, validateFieldsSelect] = useState(false);
  const [fieldsValidationMultiline, validateFieldsSelectMultiline] = useState(
    false
  );
  const [fieldsValidationMax, validateFieldsSelectMax] = useState(false);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const handleSimple = (event) => {
    setFields({ ...fields, Type: event.target.value });
  };

  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    MaxLength: 200,
  });

  const token = localStorage.getItem("token");
  const [
    {
      data: dataIntakeQuestion,
      loading: loadingIntakeQuestion,
      error: errorIntakeQuestion,
    },
    ExecuteIntakeQuestionByIdUrl,
  ] = useAxios(IntakeQuestionByIdUrl(intakeQuestionId, token));

  useEffect(() => {
    ExecuteIntakeQuestionByIdUrl();
  }, []);

  useEffect(() => {
    if (dataIntakeQuestion) {
      setFields({
        Title: dataIntakeQuestion.Title,
        Type: dataIntakeQuestion.Type,
        Description: dataIntakeQuestion.Description,
        MaxLength:
          dataIntakeQuestion.MaxLength === 0
            ? fields.MaxLength
            : dataIntakeQuestion.MaxLength,
      });
    }
  }, [dataIntakeQuestion]);
  const [{ loading }, ExecuteUpdateIntakeQuestionUrl] = useAxios(
    UpdateIntakeQuestionUrl(inquiryParamId, intakeQuestionId, token),
    {
      manual: true,
    }
  );
  const allFieldsValidated = () => {
    if (isEmpty(fields.Type)) {
      return false;
    }
    if (isEmpty(fields.Title)) {
      return false;
    }
    if (isEmpty(fields.Description)) {
      return false;
    }
    if (fields.Type === "Textbox") {
      if (isNumber(fields.MaxLength)) {
        return false;
      }
    }

    return true;
  };

  const upDate = async (data = fields) => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      if (fields.Type === "Checkbox") {
        delete data.MaxLength;
      }
      try {
        const res = await ExecuteUpdateIntakeQuestionUrl({
          data,
        });
        NotificationManager.success("Intake Question Edited", 5000);
        history.push(
          `/superAdmin/inquiry-type-management/${inquiryParamId}/intake-questions`
        );
      } catch (exception) {
        console.log("Error in Creating New Pathway", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (loading || loadingIntakeQuestion) {
    return <Loading loading />;
  }

  if (errorIntakeQuestion) {
    apiErrorHandler(errorIntakeQuestion, history);
  }

  const prerequisiteValue = {
    title: "Edit Intake Questions",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/intake-questions`,
    btnText: "Save", // Btn One
    // disabled: true,
    onClick: function () {
      upDate();
    },
  };

  return (
    <IntakeQuestionFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
    />
  );
};

export default EditIntakeQuestion;
