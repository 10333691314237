/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import FormContainer from "components/Forms/FormContainer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";

const styles = {
  themeColorPointer: {
    color: "#D65746",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const useStyles = makeStyles(styles);

const ManageCustomerUserFields = ({
  fields,
  handleChange,
  setFields,
  AreFieldsValidated,
  prerequisiteValue,
}) => {
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <TableHeader
                title={prerequisiteValue.title}
                btnText={prerequisiteValue.btnText}
                showBackButton
                goBackRoute={prerequisiteValue.goBackLink}
                onClick={() => prerequisiteValue.onClick()}
              />
            </CardHeader>
            <CardBody>
              <FormContainer height>
                <GridContainer style={{ marginTop: "50px" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="First Name"
                      value={fields.FirstName}
                      onChange={handleChange("FirstName")}
                      errorText={
                        AreFieldsValidated && isEmpty(fields.FirstName)
                      }
                      disabled={prerequisiteValue.disabled}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="Last Name"
                      value={fields.LastName}
                      onChange={handleChange("LastName")}
                      disabled={prerequisiteValue.disabled}
                      errorText={AreFieldsValidated && isEmpty(fields.LastName)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      disabled
                      label="Email Address"
                      value={fields.Email}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      label="Job Title"
                      value={fields.Designation}
                      disabled={prerequisiteValue.disabled}
                      onChange={handleChange("Designation")}
                    />
                  </GridItem>

                  <GridItem xs={12} noDefaultPadding>
                    <CustomSwitchLabel
                      disabled={prerequisiteValue.disabled}
                      checked={fields.IsVerified}
                      onChange={(event) => {
                        if (!prerequisiteValue.disabled) {
                          setFields({
                            ...fields,
                            IsVerified: event.target.checked,
                          });
                        }
                      }}
                      themeColorLabel
                      label="Verify"
                    />
                  </GridItem>
                  <GridItem xs={12} noDefaultPadding>
                    <CustomSwitchLabel
                      //disabled={prerequisiteValue.disabled}
                      disabled
                      checked={fields.IsAdmin}
                      onChange={(event) => {
                        if (!prerequisiteValue.disabled) {
                          setFields({
                            ...fields,
                            IsAdmin: event.target.checked,
                          });
                        }
                      }}
                      themeColorLabel
                      label="Admin"
                    />
                  </GridItem>
                  <GridItem xs={12} noDefaultPadding>
                    <CustomSwitchLabel
                      disabled={prerequisiteValue.disabled}
                      checked={fields.Active}
                      onChange={(event) => {
                        if (!prerequisiteValue.disabled) {
                          setFields({
                            ...fields,
                            Active: event.target.checked,
                          });
                        }
                      }}
                      themeColorLabel
                      label="Active"
                    />
                  </GridItem>
                </GridContainer>
              </FormContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default ManageCustomerUserFields;
