/* eslint-disable react/display-name */
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import PropTypes from "prop-types";
import {
  validateMandatoryEmail,
  validateConfPassword,
} from "helpers/validations";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";
import { Icon } from "@material-ui/core";
import { validatePassword } from "helpers/validations";
import { signupStore } from "store";

const Step2 = React.forwardRef((props, ref) => {
  const fields = signupStore((state) => state.fields);
  const setFields = signupStore((state) => state.setFields);
  const areFieldsLocked = signupStore((state) => state.areFieldsLocked);

  const { validateFields } = props;

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));
  const isValidated = () => {
    if (
      fields.step2.firstName.length === 0 ||
      fields.step2.lastName.length === 0 ||
      validateMandatoryEmail(fields.step2.email) !== null ||
      fields.step2.password.length === 0 ||
      fields.step2.confPassword.length === 0 ||
      validatePassword(fields.step2.password) !== null ||
      validateConfPassword(fields.step2.password, fields.step2.confPassword) !==
        null
    ) {
      return false;
    }
    return true;
  };

  const handleValueChange = (event, fieldName) => {
    const newFields = { ...fields };
    newFields.step2[`${fieldName}`] = event.target.value;
    setFields(newFields);
  };
  return (
    <GridContainer>
      <GridItem xs={12} md={12} sm={12}>
        {/*First Name */}
        <CustomInput
          inputType="text"
          required
          component="form"
          label="First Name"
          leftIcon={<Icon>person</Icon>}
          value={fields.step2.firstName}
          onChange={(event) => handleValueChange(event, "firstName")}
          errorText={validateFields && isEmpty(fields.step2.firstName)}
        />
        {/* Last Name */}
        <CustomInput
          inputType="text"
          required
          label="Last Name"
          component="form"
          leftIcon={<Icon>person</Icon>}
          value={fields.step2.lastName}
          onChange={(event) => handleValueChange(event, "lastName")}
          errorText={validateFields && isEmpty(fields.step2.lastName)}
        />
        {/* Email Address */}
        <CustomInput
          inputType="text"
          required
          disabled={areFieldsLocked}
          label="Email Address"
          leftIcon={<Icon>email</Icon>}
          value={fields.step2.email}
          onChange={(event) => handleValueChange(event, "email")}
          errorText={
            validateFields && validateMandatoryEmail(fields.step2.email)
          }
        />
        {/* Job Title */}
        <CustomInput
          inputType="text"
          label="Job Title"
          component="form"
          leftIcon={<Icon>business_center</Icon>}
          value={fields.step2.designation}
          onChange={(event) => handleValueChange(event, "designation")}
        />
        {/* Password */}
        <CustomInput
          inputType="password"
          required
          label="Password"
          value={fields.step2.password}
          leftIcon={<Icon>lock</Icon>}
          onChange={(event) => handleValueChange(event, "password")}
          errorText={validateFields && validatePassword(fields.step2.password)}
        />
        {/* Confirm Password */}
        <CustomInput
          inputType="password"
          required
          label="Confirm Password"
          leftIcon={<Icon>lock</Icon>}
          value={fields.step2.confPassword}
          onChange={(event) => handleValueChange(event, "confPassword")}
          errorText={
            validateFields &&
            validateConfPassword(
              fields.step2.password,
              fields.step2.confPassword
            )
          }
        />
      </GridItem>
    </GridContainer>
  );
});

export default Step2;

Step2.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func,
  validateFields: PropTypes.object,
  setValidateFields: PropTypes.func,
};
