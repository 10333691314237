import React from "react";
import useAxios from "axios/httpService";
import { GetAllInquiriesByAdminUrl } from "axios/adminAPIsUrls";
import { GetAllInquiriesUrl } from "axios/customerAdminAPIsUrls";
import jwtDecode from "jwt-decode";
const useDashboardInquiries = () => {
  //   SENDING -1 CURRENT PAGE TO GET ALL INQUIRIES PRESENT IN DATABASE
  const currentPage = -1;
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const loggedInRole = decodedToken.role;
  const pageSize = 10;

  const [response, setResponse] = React.useState({});

  // GET ALL INQUIRIES BY CUSTOMER ADMIN
  const [
    {
      loading: loadingInInquiriesByCustomerAdmin,
      error: errorInInquiriesByCustomerAdmin,
    },
    executeGetAllInquiriesForCustomerAdmin,
  ] = useAxios(GetAllInquiriesUrl(currentPage, pageSize, token), {
    manual: true,
  });

  // GET ALL INQUIRIES BY A SUPER ADMIN
  const [
    {
      loading: loadingInInquiriesBySuperAdmin,
      error: errorInInquiriesBySuperAdmin,
    },
    executeGetAllInquiriesForSuperAdmin,
  ] = useAxios(GetAllInquiriesByAdminUrl(currentPage, pageSize, token), {
    manual: true,
  });

  React.useEffect(async () => {
    if (loggedInRole && loggedInRole === "CustomerAdmin") {
      const res = await executeGetAllInquiriesForCustomerAdmin();
      setResponse({
        ...response,
        inquiries: res.data.data,
        loading: loadingInInquiriesByCustomerAdmin,
        error: errorInInquiriesByCustomerAdmin,
      });
    } else if (loggedInRole && loggedInRole === "SuperAdmin") {
      const res = await executeGetAllInquiriesForSuperAdmin();
      setResponse({
        ...response,
        inquiries: res.data.data,
        loading: loadingInInquiriesBySuperAdmin,
        error: errorInInquiriesBySuperAdmin,
      });
    }
  }, [loggedInRole]);

  if (response) {
    return response;
  }
};

export default useDashboardInquiries;
