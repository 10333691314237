import create from "zustand";

const surveyFormStore = create((set) => ({
  structureArray: null,
  setStructureArray: (structureArray) => {
    set(() => ({ structureArray }));
  },

  peopleArray: null,
  setPeopleArray: (peopleArray) => {
    set(() => ({ peopleArray }));
  },

  processArray: null,
  setProcessArray: (processArray) => {
    set(() => ({ processArray }));
  },

  cultureArray: null,
  setCultureArray: (cultureArray) => {
    set(() => ({ cultureArray }));
  },

  othersArray: null,
  setOthersArray: (othersArray) => {
    set(() => ({ othersArray }));
  },

  isDocNameDuplicated: null,
  setDocNameDuplication: (isDocNameDuplicated) => {
    set(() => ({ isDocNameDuplicated }));
  },
  // this to check if the files being entered not already in data base
  isDocNameDuplicatedObject: [],
  setDocNameDuplicationObject: (value) => {
    set((state) => ({
      isDocNameDuplicatedObject: [...state.isDocNameDuplicatedObject, value],
    }));
  },
  setDocNameTrue: (value) => {
    set((state) => ({
      isDocNameDuplicatedObject: state.isDocNameDuplicatedObject.filter(
        (val) => {
          return val !== value;
        }
      ),
    }));
  },

  ifExcelFormat: false,
  setNotExcelFormat: (ifExcelFormat) => {
    set(() => ({ ifExcelFormat }));
  },

  disabledFields: null,
  setDisabledFields: (disabledFields) => {
    set(() => ({ disabledFields }));
  },

  isPrompt: false,
  setIsPrompt: (isPrompt) => {
    set(() => ({ isPrompt }));
  },
}));

export default surveyFormStore;
