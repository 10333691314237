import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { GetInquiryTypeUrl } from "axios/adminAPIsUrls";
import { useHistory, useParams } from "react-router-dom";
import { EditInquiryTypeUrl } from "axios/adminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { NotificationManager } from "components/common/ReactNotifications";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";

const EditInquiryType = () => {
  const history = useHistory();
  const { id } = useParams();
  const recordId = id;

  // THIS STATE WILL PERFORM VALIDATION ON SUBMIT BUTTON CLICK
  const [areFieldsValidated, setValidateFields] = useState(false);
  const [fields, setFields] = useState({
    InqType: "Type",
    Description: "Details",
    IsActive: false,
  });

  const token = localStorage.getItem("token");

  // VIEW BY ID
  const [
    { data: inquiryDetails, loading: detailsLoading, error: errorInDetails },
    ExecuteGetInquiryTypeDetail,
  ] = useAxios(GetInquiryTypeUrl(recordId, token));

  // EDIT INQUIRY
  const [
    { loading: editLoading, error: errorInEdit },
    ExecuteEditInquiryType,
  ] = useAxios(EditInquiryTypeUrl(recordId, token), { manual: true });

  // CALLING VIEW API ON COMPONENT MOUNT
  useEffect(() => {
    ExecuteGetInquiryTypeDetail();
  }, []);

  useEffect(() => {
    if (inquiryDetails) {
      setFields({
        InqType: inquiryDetails.InqType,
        Description: inquiryDetails.Description,
        IsActive: inquiryDetails.IsActive,
      });
    }
  }, [inquiryDetails]);

  const onEditInquiryType = async () => {
    if (fields.InqType) {
      try {
        const response = await ExecuteEditInquiryType({ data: fields });
        if (response.status === 200) {
          NotificationManager.success("Inquiry Type Edited", 5000);
          history.push("/superAdmin/inquiry-type-management");
        }
      } catch (exception) {
        console.log("Error in Editing Inquiry Type", exception);
        apiErrorHandler(exception, history);
      }
    } else {
      setValidateFields(true);
    }
  };

  if (detailsLoading) {
    return <Loading loading={detailsLoading} />;
  }
  if (editLoading) {
    return <Loading loading={editLoading} />;
  }

  // ERROR HANDLING
  if (errorInDetails) {
    apiErrorHandler(errorInDetails, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Edit Inquiry Type"
              btnText="Save"
              showBackButton
              goBackRoute="/superAdmin/inquiry-type-management"
              onClick={() => onEditInquiryType()}
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={6} sm={6} md={6}>
                {/* FOR INQUIRY TYPES */}
                <CustomInput
                  inputType="text"
                  label="Inquiry Type"
                  value={fields.InqType}
                  required
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      InqType: event.target.value,
                    });
                  }}
                  errorText={areFieldsValidated && isEmpty(fields.InqType)}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                {/* FOR DETAILS */}
                <CustomInput
                  inputType="text"
                  label="Details"
                  value={fields.Description}
                  multiline
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Description: event.target.value,
                    });
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomSwitchLabel
                  insideForm
                  checked={fields.IsActive}
                  onChange={(event) =>
                    setFields({ ...fields, IsActive: event.target.checked })
                  }
                  label="Active"
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditInquiryType;
