import authStore from "store/authStore";
import definePathwayStore from "store/definePathwayStore";
import signupStore from "store/signupStore";
import surveyFormStore from "store/surveyFormStore";
import viewInsightsStore from "./viewInsightsStore";

const initialAuthStoreState = authStore.getState();
const initializeInquiryStoreState = definePathwayStore.getState();
const initialSignupStoreState = signupStore.getState();
const initialSurveyFormStore = surveyFormStore.getState();
const initialViewInsightsStore = viewInsightsStore.getState();

export const resetStores = () => {
  authStore.setState(initialAuthStoreState);
  definePathwayStore.setState(initializeInquiryStoreState);
  signupStore.setState(initialSignupStoreState);
  surveyFormStore.setState(initialSurveyFormStore);
  viewInsightsStore.setState(initialViewInsightsStore);
};

export const resetSurveyFormStore = () => {
  surveyFormStore.setState(initialSurveyFormStore);
};

export const resetViewInsightsStore = () => {
  viewInsightsStore.setState(initialViewInsightsStore);
};

export {
  authStore,
  definePathwayStore,
  signupStore,
  surveyFormStore,
  viewInsightsStore,
};
