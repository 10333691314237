import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CreatePathwaysUrl } from "axios/adminAPIsUrls";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetInquiryTypeUrl } from "axios/adminAPIsUrls";
import ManagePathwayFields from "views/Common/ManagePathway/ManagePathwayFields";
import { isEmpty } from "helpers/validations";

const CreatePathway = () => {
  const history = useHistory();
  const [areFieldsValidated, setFieldsValidated] = useState(false);
  let { inquiryParamId } = useParams();

  const [fields, setFields] = useState({
    inquiryName: "",
    name: "",
  });

  const token = localStorage.getItem("token");
  const [
    { data: inquiryDetailData, loading: inquiryLoading, error: inquiryError },
    ExecuteGetInquiryTypeDetails,
  ] = useAxios(GetInquiryTypeUrl(inquiryParamId, token), { manual: true });

  const [{ loading }, ExecuteCreatePathwaysUrl] = useAxios(
    CreatePathwaysUrl(inquiryParamId, token),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (isEmpty(fields.name)) {
      return false;
    }
    return true;
  };

  const create = async () => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      try {
        const res = await ExecuteCreatePathwaysUrl({
          data: {
            InquiryTypeId: inquiryParamId,
            Name: fields.name,
          },
        });
        NotificationManager.success("New Pathway Created", 5000);
        history.push(
          `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways`
        );
      } catch (exception) {
        console.log("Error in Creating New Pathway", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  useEffect(() => {
    if (inquiryDetailData) {
      setFields({
        ...fields,
        inquiryName: inquiryDetailData.InqType,
      });
    }
  }, [inquiryDetailData]);

  // getting inquiry type name from getInquiryTypeById API
  useEffect(() => {
    ExecuteGetInquiryTypeDetails();
  }, []);

  if (loading || inquiryLoading) {
    return <Loading loading />;
  }

  const prerequisiteValue = {
    title: "Create Pathways",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways`,
    btnText: "Save", // Btn One
    // disabled: true,
    onClick: function () {
      create();
    },
  };

  return (
    <ManagePathwayFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
    />
  );
};

export default CreatePathway;
