import { FormControl, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import PropTypes from "prop-types";
import { Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles(styles);

const CustomSearchableSelect = ({
  customClassName,
  value,
  onChange,
  errorText,
  label,
  disabled,
  loading,
  inputValidation,
  showCustomValues,
  menu,
  required,
  multiple,
}) => {
  const classes = useStyles();

  const [cursorPosition, setCursorPosition] = React.useState(0);

  const selectedValues = value.map((item) => item.value);
  const [filteredMenu, setFilteredMenu] = React.useState([]);

  // USE EFFECT IS CALLED BECAUSE VALUES ARE BEING FETCHED FROM API
  React.useEffect(() => {
    if (menu) {
      setFilteredMenu(
        menu.filter((item) => !selectedValues.includes(item.value))
      );
    }
  }, [menu]);

  // THIS FUNCTION WILL ADD USER TYPED VALUES INSIDE THE DROPDOWN IF THEY ARE NOT ALREADY PRESENT IN IT (THIS IS THE CLIENT DEMAND)
  const addCustomValuesToDropDown = (event) => {
    if (
      inputValidation(event.target.value) &&
      event.key === "Enter" &&
      !value.find((element) => element.value === event.target.value)
    ) {
      setFilteredMenu((prevState) => [
        ...prevState,
        {
          key: filteredMenu.length,
          value: event.target.value,
          label: event.target.value,
        },
      ]);
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl + " " + customClassName}
    >
      {errorText && (
        <div
          className="invalid-feedback"
          style={{ left: `${cursorPosition}%` }}
        >
          {errorText}
        </div>
      )}

      <Autocomplete
        multiple={multiple}
        size="small"
        value={value}
        fullWidth
        disabled={disabled}
        classes={{
          root: classes.autocompleteRoot,
          inputRoot: classes.autocompleteInputRoot,
          tagSizeSmall: classes.autocompleteTagSizeSmall,
          option: classes.autocompleteOption,
        }}
        onChange={(event, newValue) => {
          setCursorPosition(event.currentTarget.selectionStart);
          onChange(newValue);
        }}
        noOptionsText="No Search Found"
        loading={loading}
        options={filteredMenu}
        filterSelectedOptions
        renderTags={(value, getTagProps) =>
          value &&
          value.map((menuItem, index) => (
            <Chip
              variant="filled"
              size="small"
              color="primary"
              label={menuItem.label}
              key={index}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            size="small"
            variant="outlined"
            label={label}
            onKeyDown={(event) => {
              // CUSTOM VALUES WILL BE ADDED BASED ON showCustomValues bool
              if (showCustomValues) {
                addCustomValuesToDropDown(event);
              }
            }}
          />
        )}
      />
    </FormControl>
  );
};

export default CustomSearchableSelect;

CustomSearchableSelect.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  customClassName: PropTypes.className,
  multiple: PropTypes.bool,
  menu: PropTypes.array,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  inputValidation: PropTypes.func,
  showCustomValues: PropTypes.bool,
};
