import React from "react";
import useAxios from "axios/httpService";
import { GetOrganizationDetailsUrl } from "axios/customerUserAPIsUrls";

// THIS HOOK IS BEING CREATED FOR STAFF MEMBERS TO GET THEIR ORGANIZATION DETAILS ON SIGNUP

const useOrganizationDetails = (orgName) => {
  const token = localStorage.getItem("token");

  const [response, setResponse] = React.useState("");

  // GETTING ORGANIZATION DETAILS IF THE STAFF MEMBER TRIES TOS SIGNUP
  const [{ loading, error }, executeGetOrganizationDetails] = useAxios(
    GetOrganizationDetailsUrl(orgName, token),
    {
      manual: true,
    }
  );

  React.useEffect(async () => {
    if (orgName) {
      setResponse(await executeGetOrganizationDetails());
    }
  }, [orgName]);

  if (response) {
    const organizationDetails = {};
    organizationDetails.orgName = response.data.CustomerName;
    organizationDetails.customerId = response.data.CustomerId;
    organizationDetails.cityName = response.data.City;
    organizationDetails.countryName = response.data.Country;
    organizationDetails.industryType = response.data.Industry;
    organizationDetails.stateName = response.data.State;
    organizationDetails.website = response.data.Website;
    organizationDetails.streetAddress = response.data.Address;

    return { loading, organizationDetails, error };
  }

  return { loading };
};

export default useOrganizationDetails;
