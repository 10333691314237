/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// importing core modules
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core";

// importing useHistory from react-router-dom
import { useHistory } from "react-router";

// importing styles from jss file
import styles from "assets/jss/material-dashboard-pro-react/components/topNavbarLinksStyle.js";
import { handleClickAway, handleCloseProfile } from "helpers/authHelpers";
import authStore from "store/authStore";
import { getRole } from "helpers/authHelpers";
import PersonRound from "assets/img/activateImagesComponents/PersonRound";
import { LogoutRound } from "assets/img/activateImagesComponents";
import surveyFormStore from "store/surveyFormStore";
import { useEffect } from "react";
// logoutRound

const useStyles = makeStyles(styles);

const ProfileMenu = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const role = getRole();

  const { openProfile, setOpenProfile } = props;
  const dropdownItem = classNames(
    classes.dropdownItem,
    classes.primaryHover,
    {}
  );

  const setAuthenticated = authStore((state) => state.setAuthenticated);
  const setIsPrompt = surveyFormStore((state) => state.setIsPrompt);
  useEffect(() => {
    if (openProfile) {
      setIsPrompt(false);
    }
  }, [openProfile]);
  return (
    <Popper
      open={Boolean(openProfile)}
      anchorEl={openProfile}
      transition
      disableSuperAdmin
      placement="bottom"
      className={classNames({
        [classes.popperClose]: !openProfile,
        [classes.popperResponsive]: true,
        [classes.popperNav]: true,
      })}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          id="profile-menu-list"
          style={{ transformOrigin: "0 0 0" }}
        >
          <Paper className={classes.dropdown}>
            <ClickAwayListener
              onClickAway={() => handleClickAway(setOpenProfile)}
            >
              <MenuList role="menu">
                {role === "CustomerAdmin" ? (
                  <MenuItem
                    style={{ display: "flex" }}
                    onClick={() => {
                      setOpenProfile(null);
                      history.push("/customerAdmin/manage-profile");
                    }}
                    className={dropdownItem}
                  >
                    <span className={classes.menuItem}>
                      <PersonRound style={{ fontSize: "20px" }} />
                    </span>
                    <p>Manage Profile</p>
                  </MenuItem>
                ) : null}
                {role === "CustomerUser" ? (
                  <MenuItem
                    style={{ display: "flex" }}
                    onClick={() => {
                      setOpenProfile(null);
                      history.push("/customerUser/manage-profile");
                    }}
                    className={dropdownItem}
                  >
                    <span className={classes.menuItem}>
                      <PersonRound style={{ fontSize: "20px" }} />
                    </span>
                    <p>Manage Profile</p>
                  </MenuItem>
                ) : null}
                <MenuItem
                  style={{ display: "flex" }}
                  onClick={() => {
                    handleCloseProfile(
                      history,
                      setOpenProfile,
                      setAuthenticated
                    );
                  }}
                  className={dropdownItem}
                >
                  <span className={classes.menuItem}>
                    <LogoutRound style={{ fontSize: "20px" }} />
                  </span>
                  <p>Log out</p>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default ProfileMenu;

ProfileMenu.propTypes = {
  openProfle: PropTypes.bool,
  setOpenProfile: PropTypes.bool,
};
