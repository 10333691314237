/* eslint-disable react/prop-types */
import React from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";
import { isNumber } from "helpers/validations";
import CustomInputDropdown from "components/Inputs/CustomInputDropdown";

// import constants
import { TIME_DURATION } from "constants/defaultValues";

const ManageCustomerFields = ({
  prerequisiteValue,
  fields,
  setFields,
  AreFieldsValidated,
}) => {
  const handleYearsDropdown = (value) => {
    setFields({
      ...fields,
      duration: value,
    });
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title={prerequisiteValue.title}
              btnText={prerequisiteValue.btnText}
              showBackButton
              goBackRoute={prerequisiteValue.goBackLink}
              onClick={() => prerequisiteValue.onClick()}
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Organization Name"
                    value={fields.CustomerName}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        CustomerName: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isEmpty(fields.CustomerName)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Industry Type"
                    value={fields.Industry}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        Industry: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.Industry)}
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Website"
                    value={fields.Website}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        Website: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.Website)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    label="Street Address"
                    value={fields.Address}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        Address: event.target.value,
                      });
                    }}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="State"
                    value={fields.State}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        State: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.State)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="City"
                    value={fields.City}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        City: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.City)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {/* YEARS IN BUSINESS */}
                  <CustomInputDropdown
                    inputType="number"
                    required
                    label="How Long in business"
                    value={fields.YearsInBusiness}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        YearsInBusiness: event.target.value,
                      });
                    }}
                    dropdownMenu={TIME_DURATION}
                    dropdownValue={fields.Duration}
                    handleDropdown={(event) =>
                      handleYearsDropdown(event.target.value)
                    }
                    errorText={
                      AreFieldsValidated && isEmpty(fields.yearsInBusiness)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="For Profit Or Non-Profit"
                    value={fields.ProfitOrNon}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        ProfitOrNon: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isEmpty(fields.ProfitOrNon)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Ownership Structure"
                    value={fields.OwnershipStructure}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        OwnershipStructure: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isEmpty(fields.OwnershipStructure)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Annual Revenue"
                    value={fields.AnnualRevenue}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        AnnualRevenue: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isNumber(fields.AnnualRevenue)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Average FTE's Over Past Three Years"
                    value={fields.AverageFTE}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        AverageFTE: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isNumber(fields.AverageFTE)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Number Of Facilities/Locations"
                    value={fields.NumberOfLocations}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        NumberOfLocations: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isNumber(fields.NumberOfLocations)
                    }
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Headquater's Location"
                    value={fields.HeadquarterAddress}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        HeadquarterAddress: event.target.value,
                      });
                    }}
                    errorText={
                      AreFieldsValidated && isEmpty(fields.HeadquarterAddress)
                    }
                    disabled
                  />
                </GridItem>

                <GridItem noDefaultPadding xs={12}>
                  <CustomSwitchLabel
                    checked={fields.IsActive}
                    disabled={prerequisiteValue.disabled}
                    onChange={(event) => {
                      if (!prerequisiteValue.disabled) {
                        setFields({
                          ...fields,
                          IsActive: event.target.checked,
                        });
                      }
                    }}
                    label="Active"
                  />
                </GridItem>
              </GridContainer>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ManageCustomerFields;
