import { GetCustomerUserUrl } from "axios/adminAPIsUrls";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import useAxios from "axios/httpService";
import ManageCustomerUserFields from "views/Common/profileManageCustomers/ManageCustomerUserFields";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";

const ViewCustomerUser = () => {
  let { customersUserParamId, CustomerUserParamId } = useParams();
  const [fields, setFields] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Designation: "",
    IsAdmin: false,
    Active: false,
    IsVerified: false,
  });

  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
  };

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteGetCustomerUserUrl] = useAxios(
    GetCustomerUserUrl(CustomerUserParamId, token),
    {
      manual: true,
    }
  );

  useEffect(() => {
    ExecuteGetCustomerUserUrl();
  }, []);
  useEffect(() => {
    if (data) {
      setFields({
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: data.Email,
        Designation: data.Designation,
        IsAdmin: data.IsAdmin,
        Active: data.Active,
        IsVerified: data.IsVerified,
      });
    }
  }, [data]);

  if (error) {
    apiErrorHandler(error, history);
  }
  if (loading) {
    return <Loading loading />;
  }

  const prerequisiteValue = {
    title: "View User",
    goBackLink: `/superAdmin/manage-customers-profile/customers-users/${customersUserParamId}`,
    disabled: true,
  };

  return (
    <ManageCustomerUserFields
      fields={fields}
      prerequisiteValue={prerequisiteValue}
      handleChange={handleChange}
    />
  );
};

export default ViewCustomerUser;
