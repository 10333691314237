import React from "react";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import { makeStyles } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import { useParams, useHistory } from "react-router";
import useSurveyFormDetails from "hooks/SurveyForms/useSurveyFormDetails";
import { resetSurveyFormStore } from "store";
import jwtDecode from "jwt-decode";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import DynamicSurveyFormAgainstOthers from "views/Common/SurveyForms/DynamicSurveyFormAgainstOthers";
import CustomInput from "components/Inputs/CustomInput";
import useAxios from "axios/httpService";
import { useEffect } from "react";
import { GetIntakeResponse } from "axios/adminAPIsUrls";
import { Checkbox } from "@mui/material";

const useStyles = makeStyles(styles);

const ViewIntakeResponse = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  const classes = useStyles();

  const history = useHistory();

  const { inquiryId } = useParams();
  let count = 0;

  const [{ data, loading, error }, ExecuteGetIntakeResponse] = useAxios(
    GetIntakeResponse(inquiryId && inquiryId, token),
    {
      manual: true,
    }
  );

  useEffect(() => {
    ExecuteGetIntakeResponse();
  }, []);

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Intake Response"
              showBackButton
              goBackRoute={`/superAdmin/monitor-activity`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              {data && data.length > 0 ? (
                data.map((data, key) => {
                  return (
                    <GridItem key={key} xs={6} sm={6} md={6}>
                      <div>
                        {/* FOR INQUIRY TYPES */}
                        <h4 className={classes.questionStyle}>
                          {key + 1}. {data.Question}
                          <br />
                        </h4>
                        {data.QuestionType == "Textbox" ? (
                          <CustomInput
                            inputType="text"
                            disabled
                            multiline
                            label="Answer"
                            value={data && data.Answer}
                            required
                          />
                        ) : (
                          <Checkbox
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checkbox,
                            }}
                            checked={data?.Answer == "yes" ? true : false}
                            defaultChecked={false}
                            disabled={true}
                            //value={value}
                          />
                        )}
                      </div>
                    </GridItem>
                  );
                })
              ) : (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h1 style={{ textAlign: "center" }}>
                      No Data Available Currently
                    </h1>
                  </GridItem>
                </GridContainer>
              )}
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewIntakeResponse;
