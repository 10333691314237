import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetAllInquirySuperAdmin } from "axios/adminAPIsUrls";
import SurveyFormStatus from "components/SurveyForms/SurveyFormStatus";
import FileUploadModal from "views/SuperAdmin/MonitorActivity/FileUploadModal";
import MultiFileUploadModal from "./MultiFileUploadModal";
import SvgCalculateAverageIcon from "assets/img/svgsComponents/CalculateAverageIcon";
import SvgReviewIntakeQuestionsIcon from "assets/img/svgsComponents/ReviewIntakeQuestionsIcon";
import SvgUploadHelpDoc from "assets/img/svgsComponents/UploadHelpDoc";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
  longtooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-23px",

    "&::before": {
      left: "60%",
      marginLeft: "-36px",
      //top: "41px",
    },
  },
  ViewResponselongtooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-25px",

    "&::before": {
      left: "60%",
      marginLeft: "-50px",
      //top: "41px",
    },
  },
};

const useStyles = makeStyles(styles);
const MonitorActivity = () => {
  const history = useHistory();
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  // isModal State
  const [isModalOpen, setModalOpen] = useState(false);

  // isMultiModal state
  const [isMultiModalOpen, setMultiModalOpen] = useState(false);
  const [inquiryId, setInquiryId] = useState(null);

  const token = localStorage.getItem("token");
  const [
    { data: dataInquiry, error, loading },
    ExecuteGetAllInquirySuperAdmin,
  ] = useAxios(GetAllInquirySuperAdmin(currentPage, pageSize, token), {
    manual: true,
  });

  useEffect(() => {
    ExecuteGetAllInquirySuperAdmin(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataInquiry) {
      setData(
        dataInquiry.data.map((prop, key) => {
          return {
            id: prop.Id,
            inquiry: prop.Description,
            process: `${prop.Progress}%`,
            createdDate: new Date(`${prop.CreatedDate}`).toDateString(),
            status: (
              <div className="actions-center">
                <SurveyFormStatus status={prop.Status} />
              </div>
            ),
            actions: (
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Respondents"
                  justIcon
                  disabled={prop.Participants == null}
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view-respondents/${prop.Id}`
                    )
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Upload File"
                  justIcon
                  round
                  simple
                  //disabled={prop.Progress !== 100}
                  size="sm"
                  color="primary"
                  onClick={() => {
                    setInquiryId(prop.Id);
                    setModalOpen(true);
                  }}
                >
                  <CloudUploadOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Upload Help Document"
                  justIcon
                  round
                  simple
                  size="exmd"
                  color="primary"
                  onClick={() => {
                    setInquiryId(prop.Id);
                    setMultiModalOpen(true);
                  }}
                >
                  <SvgUploadHelpDoc />
                </Button>

                <Button
                  action
                  tooltipText="View Intake Responses"
                  tooltipClassName={classes.ViewResponselongtooltip}
                  justIcon
                  round
                  simple
                  //disabled={prop.Status.toLowerCase() !== "pending"}
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view-intake-response/${prop.Id}`
                    )
                  }
                >
                  <div
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    <SvgReviewIntakeQuestionsIcon
                      style={{
                        width: "14px !important",
                        height: "16px",
                      }}
                    />
                  </div>
                </Button>
                <Button
                  action
                  tooltipClassName={classes.longtooltip}
                  align="right"
                  tooltipText="Inputs Calculation"
                  justIcon
                  round
                  simple
                  //disabled={prop.Status.toLowerCase() == "pending"}
                  //disabled={prop.Progress !== 100}
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/inputs/${prop.Id}`
                    )
                  }
                >
                  <div>
                    <SvgCalculateAverageIcon
                      style={{
                        width: "14px !important",
                        height: "12px",
                        marginBottom: "2px",
                      }}
                    />
                  </div>
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataInquiry]);

  const classes = useStyles();

  const columns = [
    {
      Header: "Inquiry",
      accessor: "inquiry",
      alignment: "center",
    },
    {
      Header: "Process (%)",
      accessor: "process",
      alignment: "center",
    },
    {
      Header: "Created Date",
      accessor: "createdDate",
      alignment: "center",
    },
    {
      Header: "Status",
      accessor: "status",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }

  return (
    <GridContainer className={classes.center}>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader title="Monitor Activity" />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataInquiry && dataInquiry.currentPage}
              defaultPageSize={dataInquiry && dataInquiry.pageSize}
              totalItems={dataInquiry && dataInquiry.totalItem}
              totalPages={dataInquiry && dataInquiry.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
          {isModalOpen ? (
            <FileUploadModal
              setModalOpen={setModalOpen}
              isModalOpen={isModalOpen}
              inquiryId={inquiryId}
              data={dataInquiry && dataInquiry.data}
              executeGetAllInquiries={ExecuteGetAllInquirySuperAdmin}
            />
          ) : null}
          {isMultiModalOpen ? (
            <MultiFileUploadModal
              setMultiModalOpen={setMultiModalOpen}
              isMultiModalOpen={isMultiModalOpen}
              inquiryId={inquiryId}
              data={dataInquiry && dataInquiry.data}
              executeGetAllInquiries={ExecuteGetAllInquirySuperAdmin}
            />
          ) : null}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default MonitorActivity;
