import create from "zustand";

const definePathwayStore = create((set) => ({
  inquiryName: "",

  setInquiryName: (inquiryName) => {
    set(() => ({ inquiryName }));
  },
}));

export default definePathwayStore;
