import {
  FormControl,
  Icon,
  makeStyles,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

const CustomInputDropdown = ({
  required,
  isError,
  label,
  errorText,
  labelWidth,
  value,
  leftIcon,
  rightIcon,
  inputType,
  onChange,
  multiline,
  dropdownMenu,
  dropdownValue,
  handleDropdown,
  disabled,
  rows,
  customClassName,
  customInput,
}) => {
  const classes = useStyles();
  const defaultLabelWidth = 170;

  const [cursorPosition, setCursorPosition] = React.useState(0);
  return (
    <FormControl
      variant="outlined"
      className={classes.formControl + " " + customClassName}
    >
      {errorText && (
        <div
          className="invalid-feedback"
          style={{ left: `${cursorPosition}%` }}
        >
          {errorText}
        </div>
      )}
      <TextField
        type="number"
        variant="outlined"
        fullWidth
        disabled={disabled}
        multiline={multiline}
        rows={rows ? rows : 5}
        error={isError}
        value={value}
        size="small"
        onChange={(event) => {
          setCursorPosition(event.currentTarget.selectionStart);
          onChange(event);
        }}
        InputLabelProps={{
          FormLabelClasses: {
            asterisk: classes.required,
          },
        }}
        InputProps={{
          endAdornment:
            inputType === "number" ? (
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={dropdownValue}
                className={classes.dropdown}
                onChange={handleDropdown}
              >
                {dropdownMenu &&
                  dropdownMenu.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select>
            ) : (
              rightIcon
            ),
          startAdornment: leftIcon,
        }}
        classes={{
          root: classes.input + " " + customInput + " " + classes.paddingright,
        }}
        className={
          disabled
            ? classes.disabled + " " + customInput + " " + classes.paddingright
            : classes.input + " " + customInput + " " + classes.paddingright
        }
        autoComplete="Off"
        // helperText={helperText}
        label={label}
        required={required}
        labelWidth={labelWidth ? labelWidth : defaultLabelWidth}
      />
    </FormControl>
  );
};

export default CustomInputDropdown;

CustomInputDropdown.propTypes = {
  required: PropTypes.bool,
  isError: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.string,
  labelWidth: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  leftIcon: PropTypes.func,
  rightIcon: PropTypes.func,
  inputType: PropTypes.string,
  customClassName: PropTypes.string,
  customInput: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  dropdownMenu: PropTypes.array,
  dropdownValue: PropTypes.string,
  handleDropdown: PropTypes.func,
};
