import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { PathwayByIdUrl } from "axios/adminAPIsUrls";
import ManagePathwayFields from "views/Common/ManagePathway/ManagePathwayFields";
import { filterInquiryTypeName } from "helpers/authHelpers";
import authStore from "store/authStore";
import jwtDecode from "jwt-decode";

const ViewPathway = () => {
  let { inquiryParamId, pathwayId } = useParams();
  const [fields, setFields] = useState({
    inquiryName: "",
    name: "",
  });
  const userToken = authStore((state) => state.userToken);
  const token = localStorage.getItem("token");

  const [
    { data, loading, error },
    ExecutePathwayByIdUrl,
  ] = useAxios(PathwayByIdUrl(pathwayId, token), { manual: true });

  useEffect(() => {
    ExecutePathwayByIdUrl();
  }, []);

  useEffect(() => {
    if (data) {
      setFields({
        name: data.Name,
        inquiryName: filterInquiryTypeName(data, inquiryParamId),
      });
    }
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }
  const prerequisiteValue = {
    title: "View Pathway",
    goBackLink:
      userToken && jwtDecode(userToken).role === "CustomerAdmin"
        ? `/customerAdmin/inquiry-type-management/${inquiryParamId}/pathways`
        : `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways`,
    disabled: true,
  };

  return (
    <ManagePathwayFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
    />
  );
};

export default ViewPathway;
