import React from "react";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import { makeStyles } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import { useParams, useHistory } from "react-router";
import useSurveyFormDetails from "hooks/SurveyForms/useSurveyFormDetails";
import { resetSurveyFormStore } from "store";
import jwtDecode from "jwt-decode";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import DynamicSurveyFormAgainstOthers from "views/Common/SurveyForms/DynamicSurveyFormAgainstOthers";
import CustomInput from "components/Inputs/CustomInput";
import useAxios from "axios/httpService";
import { useEffect } from "react";
import { GetOpenEndedAnswersUrl } from "axios/adminAPIsUrls";
const useStyles = makeStyles(styles);

const OpenEndedAnswers = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  const classes = useStyles();

  const history = useHistory();

  const { inquiryId, customerUserId } = useParams();
  console.log(inquiryId, customerUserId);
  let count = 0;

  const [{ data, loading, error }, ExecuteGetOpenEndedAnswersUrl] = useAxios(
    GetOpenEndedAnswersUrl(
      inquiryId && inquiryId,
      customerUserId && customerUserId,
      token
    ),
    {
      manual: true,
    }
  );

  useEffect(() => {
    ExecuteGetOpenEndedAnswersUrl();
  }, []);

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Open Ended Answers"
              showBackButton
              goBackRoute={`/superAdmin/monitor-activity/view-respondents/${inquiryId}`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              {data && data.length > 0 ? (
                data.map((data, key) => {
                  return (
                    <GridItem key={key} xs={6} sm={6} md={6}>
                      {/* FOR INQUIRY TYPES */}
                      <h4 className={classes.questionStyle}>
                        {key + 1}. {data.Question}
                        <br />
                      </h4>
                      <CustomInput
                        inputType="text"
                        disabled
                        multiline
                        label="Answer"
                        value={data && data.Answer}
                        required
                      />
                    </GridItem>
                  );
                })
              ) : (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <h1 style={{ textAlign: "center" }}>
                      No Data Available Currently
                    </h1>
                  </GridItem>
                </GridContainer>
              )}
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OpenEndedAnswers;
