import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import AlertButton from "@mui/material/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = {
  pdR: {
    paddingRight: "100px",
  },
  Strong: {
    display: "block",
  },
  Flex: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

const useStyles = makeStyles(styles);

const ConfirmationAlert = ({ setModalOpen, setShowAlert, showAlert }) => {
  const classes = useStyles();
  return (
    <div>
      <Alert
        severity="warning"
        style={{ paddingRight: "100px", marginBottom: "30px" }}
      >
        <AlertTitle>Warning</AlertTitle>
        <strong style={{ display: "block" }}>
          The inquiry is not completed yet
        </strong>
        Do you still wants to proceed?
      </Alert>
    </div>
  );
};
export default ConfirmationAlert;

ConfirmationAlert.propTypes = {
  setModalOpen: PropTypes.func,
  setShowAlert: PropTypes.func,
  showAlert: PropTypes.bool,
};
