import React, { useState } from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/Auth/authStyle.js";

// import activate logo
import ActivateLogo from "assets/img/activateImages/activate-logo.png";

import Box from "@material-ui/core/Box";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { Icon, makeStyles, Typography } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { authStore } from "store";
import useAxios from "axios/httpService";
import { UserVerifyTokenUrl } from "axios/authAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import classNames from "classnames";
import ButtonLoading from "components/Loading/ButtonLoading";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
const VerifyTokenPage = ({ history }) => {
  const classes = useStyles();
  const emailZustand = authStore((state) => state.emailZustand);
  const setEmailCode = authStore((state) => state.setEmailCode);
  const [validateFields, setValidateFields] = useState(false);

  const [values, setValues] = useState({
    Token: "",
  });

  const [{ loading }, ExecuteUserVerifyTokenUrl] = useAxios(
    UserVerifyTokenUrl(),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (isEmpty(values.Token)) {
      return false;
    }
    return true;
  };

  const VerifyCodeUserHandler = async () => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      setEmailCode(values.Token);
      try {
        const res = await ExecuteUserVerifyTokenUrl({
          data: {
            Email: emailZustand,
            Token: values.Token,
          },
        });

        // eslint-disable-next-line react/prop-types
        history.push("/auth/reset-password");
      } catch (error) {
        console.log("API Error", error);
        apiErrorHandler(error, history);
      }
    }
  };

  return (
    <div>
      <GridContainer noDefaultSetting className={classes.container}>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_form}
          >
            {/* image */}
            <Box mb={8} mt={8}>
              <img src={ActivateLogo} alt="activate_logo" height="140" />
            </Box>
            <Box p={1}>
              <Typography variant="caption" display="block" gutterBottom>
                Please enter your verification code that we sent to your email
                address
              </Typography>
            </Box>
            {/* Custom input form */}
            <Box className={classes.width_80}>
              <Box mt={2}>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    {/* Email Address */}
                    <CustomInput
                      inputType="email"
                      required
                      label="Email Address"
                      leftIcon={<Icon>email</Icon>}
                      value={emailZustand}
                      disabled
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    <CustomInput
                      inputType="text"
                      required
                      label="Email Code"
                      leftIcon={<Icon>lock</Icon>}
                      value={values.Token}
                      onChange={(event) =>
                        setValues({ ...values, Token: event.target.value })
                      }
                      errorText={validateFields && isEmpty(values.Token)}
                    />
                  </GridItem>
                </GridContainer>
              </Box>
            </Box>
            {/* litte content */}
            <Box mb={3}>
              <Box
                display="flex"
                // eslint-disable-next-line react/prop-types
                onClick={() => history.push("/auth/login")}
              >
                <Typography variant="caption" display="block" gutterBottom>
                  Already have an account ?{" "}
                  <span
                    className={classNames(
                      classes.theme_color,
                      classes.cursor_pointer
                    )}
                  >
                    {" "}
                    Login
                  </span>
                </Typography>
              </Box>
            </Box>
            {/* button */}
            <Box p={1} mt={4} mb={10} display="flex">
              <Button
                round
                disabled={loading}
                className={classes.theme_color_background}
                onClick={VerifyCodeUserHandler}
              >
                <CheckCircleOutlineIcon />
                {loading ? <ButtonLoading /> : "Verify"}
              </Button>
            </Box>
          </Box>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item2}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_content}
            pb={4}
          >
            {/* Content One */}
            <Box p={1}>
              <h1 className={classes.login_form_content1}>
                Welcome to{" "}
                <b>
                  <span className={classes.theme_color}>ACT</span>ivate Advance
                </b>
              </h1>
            </Box>
            {/* Content Two */}
            <Box>
              <h3 className={classes.login_form_content2}>
                Activating Advanced Strategies in Real Time
              </h3>
            </Box>
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default VerifyTokenPage;
