import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useParams } from "react-router-dom";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { GetAllRespondentsUrl } from "axios/commonAPIsUrls";
import Loading from "components/Loading/Loading";
import SurveyFormStatus from "components/SurveyForms/SurveyFormStatus";
import jwtDecode from "jwt-decode";
import DescriptionIcon from "@material-ui/icons/Description";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SvgReviewIntakeQuestionsIcon from "assets/img/svgsComponents/ReviewIntakeQuestionsIcon";
import { ViewDocumentIcon } from "assets/img/svgsComponents";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
  longtooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-49px",

    "&::before": {
      left: "60%",
      marginLeft: "-36px",
      //top: "41px",
    },
  },
  ViewDocumentTooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-49px",

    "&::before": {
      left: "90%",
      marginLeft: "-36px",
      //top: "41px",
    },
  },
};

const useStyles = makeStyles(styles);
const SurveyFormRespondents = () => {
  const history = useHistory();
  const { inquiryId, customerUserId } = useParams();
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  const [
    { data: dataRespondents, error, loading },
    ExecuteGetAllRespondentsUrl,
  ] = useAxios(GetAllRespondentsUrl(inquiryId, currentPage, pageSize, token), {
    manual: true,
  });

  useEffect(() => {
    ExecuteGetAllRespondentsUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataRespondents) {
      setData(
        dataRespondents.data.map((prop, key) => {
          return {
            id: prop.Id,
            firstName: prop.FirstName,
            lastName: prop.LastName,
            email: prop.Email,
            status: (
              <div className="actions-center">
                <SurveyFormStatus status={prop.status} />
              </div>
            ),
            actions: (
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Survey Form"
                  justIcon
                  disabled={prop.status.toLowerCase() === "pending"}
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view-survey-form/${prop.Id}`
                    )
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipClassName={classes.longtooltip}
                  tooltipText="View Open Ended Answers"
                  justIcon
                  disabled={prop.status.toLowerCase() === "pending"}
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view-open-ended/${prop.Id}`
                    )
                  }
                >
                  <DescriptionIcon />
                </Button>
                <Button
                  action
                  tooltipClassName={classes.ViewDocumentTooltip}
                  tooltipText="View Documents"
                  justIcon
                  disabled={prop.status.toLowerCase() === "pending"}
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/uploaded-answers/${prop.Id}`
                    )
                  }
                >
                  <div
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    <ViewDocumentIcon
                      style={{
                        width: "14px !important",
                        height: "14px",
                      }}
                    />
                  </div>
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataRespondents]);

  const classes = useStyles();

  const columns =
    decodedToken.role === "SuperAdmin"
      ? [
          {
            Header: "First Name",
            accessor: "firstName",
            alignment: "center",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            alignment: "center",
          },
          {
            Header: "Email",
            accessor: "email",
            alignment: "center",
          },
          {
            Header: "Status",
            accessor: "status",
            alignment: "center",
          },
          {
            Header: (
              <div
                style={{
                  textAlign: "center",
                }}
              >
                Actions
              </div>
            ),
            accessor: "actions",
            alignment: "center",
          },
        ]
      : [
          {
            Header: "First Name",
            accessor: "firstName",
            alignment: "center",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            alignment: "center",
          },
          {
            Header: "Email",
            accessor: "email",
            alignment: "center",
          },
          {
            Header: "Status",
            accessor: "status",
            alignment: "center",
          },
        ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }
  const goBackRoute = () => {
    if (decodedToken.role === "SuperAdmin") {
      return `/superAdmin/monitor-activity`;
    } else if (decodedToken.role === "CustomerAdmin") {
      return "/customerAdmin/inquiry-logs";
    }
  };

  return (
    <GridContainer className={classes.center}>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title={
                decodedToken.role === "SuperAdmin"
                  ? "Monitor Activity"
                  : "Respondents List"
              }
              subTitle={
                decodedToken.role === "SuperAdmin" ? "Respondents List" : null
              }
              showBackButton
              goBackRoute={goBackRoute()}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataRespondents && dataRespondents.currentPage}
              defaultPageSize={dataRespondents && dataRespondents.pageSize}
              totalItems={dataRespondents && dataRespondents.totalItem}
              totalPages={dataRespondents && dataRespondents.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default SurveyFormRespondents;
