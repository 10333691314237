import * as React from "react";

const SvgCalculateAverageIcon = (props) => (
  <svg
    width="0.5em"
    height="0.5em"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 2v39h39"
      stroke="#D65746"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.852 35.15S13.032 2 24.427 2s16.575 33.15 16.575 33.15"
      stroke="#D65746"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.852 21.5h33.15"
      stroke="#D65746"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 6"
    />
  </svg>
);

export default SvgCalculateAverageIcon;
