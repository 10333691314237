import { getLoggedInName } from "helpers/authHelpers";
import create from "zustand";

const authStore = create((set) => ({
  isAuthenticated: JSON.parse(localStorage.getItem("isAuthenticated")),

  setAuthenticated: (isAuthenticated) => {
    set(() => ({ isAuthenticated }));
  },

  loggedInName: localStorage.getItem("loggedInName"),
  setLoggedInName: (loggedInName) => {
    set(() => ({ loggedInName }));
  },

  emailZustand: "",
  setEmail: (emailZustand) => {
    set(() => ({ emailZustand }));
  },

  emailCode: "",
  setEmailCode: (emailCode) => {
    set(() => ({ emailCode }));
  },

  userId: "",
  setUserId: (userId) => {
    set(() => ({ userId }));
  },
  isFirstLogin: JSON.parse(localStorage.getItem("isFirstLogin")),
  setFirstLogin: (isFirstLogin) => {
    set(() => ({ isFirstLogin }));
  },

  userToken: localStorage.getItem("token"),
  setUserToken: (userToken) => {
    set(() => ({ userToken }));
  },
}));

export default authStore;
