import * as React from "react";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "0px",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem", color: "white" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ReactCollapse = ({ data }) => {
  return (
    <div>
      {data &&
        data.map((item, key) => {
          const splitedDescription = item.detail?.split(`\n`);
          return (
            <Accordion
              key={key}
              style={{
                border: `2px solid ${item.color}`,
                marginBottom: "10px",
              }}
            >
              <div
                aria-controls={`${item.id}d-content`}
                id={`${item.id}d-header`}
              >
                <AccordionSummary
                  style={{
                    backgroundColor: item.color,
                    color: "white",
                  }}
                >
                  <Typography
                    style={{ fontWeight: "600", padding: "0px", margin: "0px" }}
                  >
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <Typography style={{ padding: "15px" }}>
                  {item.description}
                </Typography>
              </div>
              <AccordionDetails>
                {splitedDescription?.map((val, index) => {
                  return (
                    <Typography key={index} sx={{ marginBottom: 1.3 }}>
                      {val}
                    </Typography>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};

export default ReactCollapse;

ReactCollapse.propTypes = {
  data: PropTypes.array,
};
