import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { CreateRecommendationUrl } from "axios/adminAPIsUrls";
import FormContainer from "components/Forms/FormContainer";
import CustomFormSelect from "components/Selects/CustomSelect";
import CustomInput from "components/Inputs/CustomInput";
import useInquiryTypes from "hooks/useInquiryTypes";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "helpers/validations";
import { NotificationManager } from "components/common/ReactNotifications";
import { EditRecommendationUrl } from "axios/adminAPIsUrls";
import { GetRecommendationUrl } from "axios/adminAPIsUrls";

const useStyles = makeStyles(styles);
const EditRecommendation = () => {
  const history = useHistory();
  const classes = useStyles();
  let { RecommendationsParamId } = useParams();

  const [areFieldsValidated, setValidateFields] = useState(false);
  const [fields, setFields] = useState({
    InquiryTypeId: "",
    RecommendationDetail: "",
    InquiryTypeName: "",
  });

  const token = localStorage.getItem("token");

  const [
    {
      data: dataRecommendation,
      loading: loadingRecommendation,
      error: errorRecommendation,
    },
    ExecuteGetRecommendationUrl,
  ] = useAxios(GetRecommendationUrl(RecommendationsParamId, token));

  const { loading, inquiryTypes, error } = useInquiryTypes();
  const [
    { loading: loadingUpdateRecommendation },
    ExecuteEditRecommendationUrl,
  ] = useAxios(EditRecommendationUrl(RecommendationsParamId, token), {
    manual: true,
  });

  useEffect(() => {
    ExecuteGetRecommendationUrl();
  }, []);
  useEffect(() => {
    if (dataRecommendation) {
      setFields({
        InquiryTypeId: dataRecommendation.InquiryTypeId,
        RecommendationDetail: dataRecommendation.RecommendationDetail,
      });
    }
  }, [dataRecommendation]);

  const allFieldsValidated = () => {
    if (isEmpty(fields.InquiryTypeId)) {
      return false;
    }
    if (isEmpty(fields.RecommendationDetail)) {
      return false;
    }
    return true;
  };

  const onUpdate = async (data = fields) => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      try {
        const response = await ExecuteEditRecommendationUrl({ data });
        if (response.status === 200) {
          NotificationManager.success("Recommendated Edited", 5000);
          history.push("/superAdmin/manage-recommendations");
        }
      } catch (exception) {
        console.log("Error in updating New Recommendated ", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (loading || loadingUpdateRecommendation) {
    return <Loading loading />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Edit Recommendation"
              btnText="Save"
              onClick={() => onUpdate()}
              showBackButton
              goBackRoute={`/superAdmin/manage-recommendations`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer height>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Inquiry Type"
                  value={fields.InquiryTypeId}
                  menu={inquiryTypes && inquiryTypes}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      InquiryTypeId: parseInt(event.target.value),
                    });
                  }}
                  errorText={
                    areFieldsValidated && isEmpty(fields.InquiryTypeId)
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Recommendation"
                  multiline
                  value={fields.RecommendationDetail}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      RecommendationDetail: event.target.value,
                    });
                  }}
                  errorText={
                    areFieldsValidated && isEmpty(fields.RecommendationDetail)
                  }
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditRecommendation;
