import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import {
  GetDiagnosticQuestionUrl,
  UpdateDiagnosticQuestionUrl,
} from "axios/adminAPIsUrls";
import DiagnosticsQuestionFields from "views/Common/diagnosticsQuestion/DiagnosticsQuestionFields";
import { isEmpty } from "helpers/validations";
import { isNumber } from "helpers/validations";

const EditDiagnosticQuestion = () => {
  const history = useHistory();
  let { inquiryParamId, pathwayParamId, diagnosticParamId } = useParams();
  const [areFieldsValidated, setFieldsValidated] = useState(false);

  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    Area: "",
    MaxLength: 200,
    ValidationRegex: "",
  });

  const token = localStorage.getItem("token");
  const [
    {
      data: dataDiagnostic,
      loading: loadingDiagnostic,
      error: errorDiagnostic,
    },
    ExecuteGetDiagnosticQuestionUrl,
  ] = useAxios(GetDiagnosticQuestionUrl(diagnosticParamId, token));

  useEffect(() => {
    ExecuteGetDiagnosticQuestionUrl();
  }, []);

  useEffect(() => {
    if (dataDiagnostic) {
      setFields({
        Title: dataDiagnostic.Title,
        Type: dataDiagnostic.Type,
        Description: dataDiagnostic.Description,
        Area: dataDiagnostic.Area,
        MaxLength:
          dataDiagnostic.MaxLength === 0
            ? fields.MaxLength
            : dataDiagnostic.MaxLength,
        RadioButtonOptions:
          dataDiagnostic.RadioButtonOptions !== null
            ? dataDiagnostic.RadioButtonOptions
            : [],
        CheckBoxOptions:
          dataDiagnostic.CheckBoxOptions !== null
            ? dataDiagnostic.CheckBoxOptions
            : [],
        ValidationRegex: dataDiagnostic.ValidationRegex,
      });
    }
  }, [dataDiagnostic]);
  const [{ loading, error }, ExecuteUpdateDiagnosticQuestionUrl] = useAxios(
    UpdateDiagnosticQuestionUrl(pathwayParamId, diagnosticParamId, token),
    {
      manual: true,
    }
  );
  const allFieldsValidated = () => {
    if (isEmpty(fields.Area)) {
      return false;
    }
    if (isEmpty(fields.Type)) {
      return false;
    }
    if (isEmpty(fields.Title)) {
      return false;
    }
    if (isEmpty(fields.Description)) {
      return false;
    }
    if (fields.Type === "Textbox") {
      if (isNumber(fields.MaxLength)) {
        return false;
      }
      if (isEmpty(fields.ValidationRegex)) {
        return false;
      }
    }
    if (fields.Type.toLowerCase() === "rating") {
      if (
        isEmpty(fields.RadioButtonOptions) ||
        fields.RadioButtonOptions.indexOf("") > -1
      ) {
        return false;
      }
    }
    if (fields.Type.toLowerCase() === "checkbox") {
      if (
        isEmpty(fields.CheckBoxOptions) ||
        fields.CheckBoxOptions.indexOf("") > -1
      ) {
        return false;
      }
    }

    return true;
  };

  const onUpdate = async (data = fields) => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      if (fields.Type !== "Textbox") {
        delete data.MaxLength;
        delete data.ValidationRegex;
      }
      if (fields.Type !== "Rating") {
        data.RadioButtonOptions = [];
      }
      if (fields.Type !== "Checkbox") {
        data.CheckBoxOptions = [];
      }
      const value = data.RadioButtonOptions.filter((element) => {
        return element === "d";
      });
      if (fields.Type == "Rating" && value.length > 0) {
        NotificationManager.error(
          "Failure",
          "Remove Invalid rating option d to proceed",
          5000
        );
        setFieldsValidated(false);
      } else {
        try {
          const res = await ExecuteUpdateDiagnosticQuestionUrl({
            data,
          });
          NotificationManager.success("Diagnostic Question Edited", 5000);
          history.push(
            `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways/${pathwayParamId}/diagnostic-questions`
          );
        } catch (exception) {
          console.log("Error in Creating New Pathway", exception);
          apiErrorHandler(exception, history);
        }
      }
    }
  };

  if (loading || loadingDiagnostic) {
    return <Loading loading />;
  }

  if (errorDiagnostic) {
    apiErrorHandler(errorDiagnostic, history);
  }

  const prerequisiteValue = {
    title: "Edit Diagnostic Questions",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways/${pathwayParamId}/diagnostic-questions`,
    btnText: "Save", // Btn One
    // disabled: true,
    onClick: function () {
      onUpdate();
    },
  };

  return (
    <DiagnosticsQuestionFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
    />
  );
};

export default EditDiagnosticQuestion;
