import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { CreateDiagnosticQuestionUrl } from "axios/adminAPIsUrls";
import DiagnosticsQuestionFields from "views/Common/diagnosticsQuestion/DiagnosticsQuestionFields";
import { isEmpty } from "helpers/validations";
import { isNumber } from "helpers/validations";

const CreateDiagnosticQuestion = () => {
  const history = useHistory();
  let { inquiryParamId, pathwayParamId } = useParams();

  const [areFieldsValidated, setFieldsValidated] = useState(false);

  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    Area: "",
    MaxLength: 200,
    ValidationRegex: "",
    RadioButtonOptions: [],
    CheckBoxOptions: [],
  });

  const token = localStorage.getItem("token");
  const [{ loading }, ExecuteCreateDiagnosticQuestionUrl] = useAxios(
    CreateDiagnosticQuestionUrl(pathwayParamId, token),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (isEmpty(fields.Area)) {
      return false;
    }
    if (isEmpty(fields.Type)) {
      return false;
    }
    if (isEmpty(fields.Title)) {
      return false;
    }
    if (isEmpty(fields.Description)) {
      return false;
    }
    if (fields.Type === "Textbox") {
      if (isNumber(fields.MaxLength)) {
        return false;
      }
      if (isEmpty(fields.ValidationRegex)) {
        return false;
      }
    }
    if (fields.Type.toLowerCase() === "rating") {
      if (
        isEmpty(fields.RadioButtonOptions) ||
        fields.RadioButtonOptions.indexOf("") > -1
      ) {
        return false;
      }
    }
    if (fields.Type.toLowerCase() === "checkbox") {
      if (
        isEmpty(fields.CheckBoxOptions) ||
        fields.CheckBoxOptions.indexOf("") > -1
      ) {
        return false;
      }
    }

    return true;
  };

  const onCreate = async (data = fields) => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      if (fields.Type !== "Textbox") {
        delete data.MaxLength;
        delete data.ValidationRegex;
      }
      if (fields.Type !== "Rating") {
        data.RadioButtonOptions = [];
      }
      if (fields.Type !== "Checkbox") {
        data.CheckBoxOptions = [];
      }
      const value = data.RadioButtonOptions.filter((element) => {
        return element === "d";
      });
      if (fields.Type == "Rating" && value.length > 0) {
        NotificationManager.error(
          "Failure",
          "Remove Invalid rating option d to proceed",
          5000
        );
        setFieldsValidated(false);
      } else {
        try {
          const res = await ExecuteCreateDiagnosticQuestionUrl({
            data,
          });
          NotificationManager.success("New Diagnostic Question Created", 5000);
          history.push(
            `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways/${pathwayParamId}/diagnostic-questions`
          );
        } catch (exception) {
          console.log("Error in Creating New Pathway", exception);
          apiErrorHandler(exception, history);
        }
      }
    }
  };

  if (loading) {
    return <Loading loading />;
  }

  const prerequisiteValue = {
    title: "Add Diagnostic Questions",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways/${pathwayParamId}/diagnostic-questions`,
    btnText: "Save", // Btn One
    // disabled: true,
    onClick: function () {
      onCreate();
    },
  };

  return (
    <DiagnosticsQuestionFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
    />
  );
};

export default CreateDiagnosticQuestion;
