import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { PathwayByIdUrl } from "axios/adminAPIsUrls";
import { UpdatePathwayUrl } from "axios/adminAPIsUrls";
import ManagePathwayFields from "views/Common/ManagePathway/ManagePathwayFields";
import { isEmpty } from "helpers/validations";
import { filterInquiryTypeName } from "helpers/authHelpers";

const EditPathway = () => {
  const history = useHistory();
  let { inquiryParamId, pathwayId } = useParams();
  const [areFieldsValidated, setFieldsValidated] = useState(false);

  const [fields, setFields] = useState({
    inquiryName: "",
    name: "",
  });

  const token = localStorage.getItem("token");

  const [
    { data, loading, error },
    ExecutePathwayByIdUrl,
  ] = useAxios(PathwayByIdUrl(pathwayId, token), { manual: true });

  const [{ loading: updateLoader }, ExecuteUpdatePathwayUrl] = useAxios(
    UpdatePathwayUrl(inquiryParamId, pathwayId, token),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (isEmpty(fields.name)) {
      return false;
    }
    return true;
  };

  const update = async () => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      try {
        const res = await ExecuteUpdatePathwayUrl({
          data: {
            InquiryTypeId: inquiryParamId,
            Name: fields.name,
          },
        });
        NotificationManager.success("Pathway Updated", 5000);
        history.push(
          `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways`
        );
      } catch (exception) {
        console.log("Error in Updating Pathway", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  useEffect(() => {
    ExecutePathwayByIdUrl();
  }, []);

  useEffect(() => {
    if (data) {
      setFields({
        name: data.Name,
        inquiryName: filterInquiryTypeName(data, inquiryParamId),
      });
    }
  }, [data]);

  if (updateLoader) {
    return <Loading loading={updateLoader} />;
  }
  if (loading) {
    return <Loading loading />;
  }
  const prerequisiteValue = {
    title: "Edit Pathways",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways`,
    btnText: "Save", // Btn One
    // disabled: true,
    onClick: function () {
      update();
    },
  };

  return (
    <ManagePathwayFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
    />
  );
};

export default EditPathway;
