import React, { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core";
import ReactCollapse from "components/Collapse/ReactCollapse";
import { blackColor } from "assets/jss/material-dashboard-pro-react";
import { AREA_COLORS } from "constants/defaultValues";
import useAxios from "axios/httpService";
import { GetRoadmapForInquiryUrl } from "axios/commonAPIsUrls";
import { viewInsightsStore } from "store";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { ROADMAP_COLORS } from "constants/defaultValues";

const styles = {
  title: {
    fontSize: "1rem",
    color: blackColor,
    fontWeight: "600",
    textAlign: "center",
    margin: "20px 0px 50px 0px",
  },
  cont: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  mb: {
    marginBottom: "20px",
  },
};

const useStyles = makeStyles(styles);
const Roadmap = () => {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");

  const inquiryId = viewInsightsStore((state) => state.inquiryId);

  const [{ data, loading, error }, executeGetRoadmapUrl] = useAxios(
    GetRoadmapForInquiryUrl(inquiryId, token)
  );
  useEffect(async () => {
    await executeGetRoadmapUrl();
  }, []);
  const [roadmap, setRoadmap] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      const objToArray = [];
      for (let i = 0; i < 3; i++) {
        objToArray.push({
          title: data.data[0][`Step${i + 1}_RoadmapTitle`],
          description: data.data[0][`Step${i + 1}_BriefDescription`],
          detail: data.data[0][`Step${i + 1}_RoadmapDetail`],
          id: i + 1,
          color: ROADMAP_COLORS[i],
        });
      }
      setRoadmap(objToArray);
    }
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }

  if (error) {
    console.log("Error in Getting Roadmap Results", error);
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer className={classes.cont}>
      <GridItem xs={2} sm={2} md={2}></GridItem>
      <GridItem xs={8} md={8} sm={8}>
        <ReactCollapse data={roadmap} />
      </GridItem>
      <GridItem xs={2} sm={2} md={2}></GridItem>
      <GridItem xs={12} md={12} sm={12} className={classes.mb}>
        <h1 className={classes.title}>&nbsp;</h1>
      </GridItem>
    </GridContainer>
  );
};

export default Roadmap;
