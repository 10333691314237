import { GetProfileDetails } from "axios/commonAPIsUrls";
import Loading from "components/Loading/Loading";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import ManageProfile from "views/Common/Manage-Profile";
import useAxios from "axios/httpService";
import { EditProfileDetailsUrl } from "axios/commonAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";
import { authStore } from "store";
import { ChangePasswordUrl } from "axios/commonAPIsUrls";
import { validatePassword } from "helpers/validations";
import { validateConfPassword } from "helpers/validations";
import { useHistory } from "react-router";
import { isEmpty } from "helpers/validations";
import ManageProfileStaff from "views/Common/Manage-Profile/ManageProfileStaff";

const ProfileManageStaff = () => {
  const history = useHistory();
  const setLoggedInName = authStore((state) => state.setLoggedInName);
  const [fieldsPass, setFieldsPass] = useState({
    CurrentPassword: "",
    NewPassword: "",
    ConfirmNewPassword: "",
  });
  const [AreFieldsValidatedPass, setValidateFieldsPass] = useState(false);
  const [fieldValidation, setFieldValidation] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [fields, setFields] = useState({
    CustomerName: "",
    FirstName: "",
    LastName: "",
    Email: "",
    Designation: "",
    IsAdmin: "",
    Active: "",
  });

  const token = localStorage.getItem("token");
  let email = "";
  let userId = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    userId = decodedToken.userid;
    email = decodedToken.email;
  }
  const [{ data, loading, error }, ExecuteGetProfileDetails] = useAxios(
    GetProfileDetails(userId, token)
  );
  const [
    { loading: loadingEditProfile },
    ExecuteEditProfileDetailsUrl,
  ] = useAxios(EditProfileDetailsUrl(userId, token), { manual: true });

  useEffect(() => {
    ExecuteGetProfileDetails();
  }, []);
  useEffect(() => {
    if (data) {
      setFields({
        ...fields,
        CustomerName: data.CustomerName,
        FirstName: data.FirstName,
        LastName: data.LastName,
        Designation: data.Designation,
        IsAdmin: data.IsAdmin,
        Active: data.Active,
        Email: email,
      });
    }
  }, [data]);

  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
  };
  const handleChangeCheck = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.checked });
  };

  const allFieldsValidated = () => {
    if (isEmpty(fields.FirstName)) {
      return false;
    }
    if (isEmpty(fields.LastName)) {
      return false;
    }

    return true;
  };

  const onUpdateProfile = async (data = fields) => {
    setFieldValidation(true);
    if (allFieldsValidated()) {
      try {
        const response = await ExecuteEditProfileDetailsUrl({ data });
        NotificationManager.success("Profile Edited", 5000);
        setLoggedInName(fields.FirstName + " " + fields.LastName);
        localStorage.setItem(
          "loggedInName",
          fields.FirstName + " " + fields.LastName
        );
        history.push("/customerUser/dashboard");
      } catch (exception) {
        console.log("Error in Updating Profile", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  // **********************************
  // **Change Password APi's & State's*
  // **********************************
  const allFieldsValidatedPass = () => {
    if (isEmpty(fieldsPass.CurrentPassword)) {
      return false;
    }
    if (validatePassword(fieldsPass.NewPassword)) {
      return false;
    }
    if (
      validateConfPassword(
        fieldsPass.NewPassword,
        fieldsPass.ConfirmNewPassword
      )
    ) {
      return false;
    }
    return true;
  };

  const [
    { loading: loadingChangePass },
    ExecuteChangePasswordUrl,
  ] = useAxios(ChangePasswordUrl(userId, token), { manual: true });

  const changePasswordAPi = async (data = fieldsPass) => {
    setValidateFieldsPass(true);
    if (allFieldsValidatedPass()) {
      delete data.ConfirmNewPassword;
      try {
        const response = await ExecuteChangePasswordUrl({ data });
        NotificationManager.success("Password Change Successfully", 5000);
        setValidateFieldsPass(false);
        setIsModelOpen(false);
      } catch (exception) {
        console.log("Error in Changing Password", exception);
        apiErrorHandler(exception, history);
        setIsModelOpen(false);
      } finally {
        setFieldsPass({
          CurrentPassword: "",
          NewPassword: "",
          ConfirmNewPassword: "",
        });
      }
    }
  };

  if (error) {
    apiErrorHandler(error, history);
  }
  if (loading || loadingEditProfile || loadingChangePass) {
    return <Loading loading />;
  }

  return (
    <ManageProfileStaff
      fields={fields}
      AreFieldsValidated={fieldValidation}
      handleChange={handleChange}
      handleChangeCheck={handleChangeCheck}
      onUpdate={onUpdateProfile}
      // Change Password APi's And State
      fieldsPass={fieldsPass}
      setFieldsPass={setFieldsPass}
      AreFieldsValidatedPass={AreFieldsValidatedPass}
      setValidateFieldsPass={setValidateFieldsPass}
      isModelOpen={isModelOpen}
      setIsModelOpen={setIsModelOpen}
      changePasswordAPi={changePasswordAPi}
    />
  );
};

export default ProfileManageStaff;
