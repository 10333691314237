import React from "react";
// core components
import Button from "components/CustomButtons/Button.js";
import { PropTypes } from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// importing SVGs
import EditSvg from "assets/img/svgsComponents/Edit";
import EyeSvg from "assets/img/svgsComponents/Eye";

import { useHistory } from "react-router";
import jwtDecode from "jwt-decode";

const styles = {
  marginbt: {
    marginBottom: "10px",
  },
  expiredbt: {
    marginBottom: "10px",
    opacity: "0.65",
  },
};

const useStyles = makeStyles(styles);

const StaffDashboardActions = (props) => {
  const {
    inquiryTypeId,
    IsExpired,
    inquiryId,
    status,
    loggedInUserId,
    createdByEmail,
  } = props;
  const history = useHistory();
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const classes = useStyles();
  return (
    // we've added some custom button actions
    <div className="actions-center">
      <Button
        justIcon
        round
        simple
        size="sm"
        color="primary"
        action
        tooltipText="View Survey Form"
        disabled={status && status.toLowerCase() === "pending"}
        onClick={() => {
          if (decodedToken.role === "CustomerAdmin") {
            history.push(
              `/customerAdmin/survey-forms/${inquiryTypeId}/view-survey-form/${inquiryId}/${loggedInUserId}`
            );
          } else if (decodedToken.role === "CustomerUser") {
            history.push(
              `/customerUser/dashboard/survey-form/${inquiryTypeId}/view-survey-form/${inquiryId}/${loggedInUserId}`
            );
          }
        }}
      >
        <div>
          <EyeSvg />
        </div>
      </Button>
      {IsExpired ? (
        <Button
          className={classes.expiredbt}
          action
          tooltipText="Expired"
          disabled={status && status.toLowerCase() === "completed"}
          justIcon
          round
          simple
          size="sm"
          color="primary"
          onClick={() => {}}
        >
          <div>
            <EditSvg />
          </div>
        </Button>
      ) : (
        <Button
          className={classes.marginbt}
          action
          tooltipText="Complete Survey Form"
          disabled={status && status.toLowerCase() === "completed"}
          justIcon
          round
          simple
          size="sm"
          color="primary"
          onClick={() => {
            //if (createdByEmail === decodedToken.email) {
            if (decodedToken.role === "CustomerAdmin") {
              history.push(
                `/customerAdmin/survey-forms/${inquiryTypeId}/edit-survey-form/${inquiryId}`
              );
            } else if (decodedToken.role === "CustomerUser") {
              history.push(
                `/customerUser/dashboard/survey-form/${inquiryTypeId}/edit-survey-form/${inquiryId}`
              );
            }
          }}
        >
          <div>
            <EditSvg />
          </div>
        </Button>
      )}
    </div>
  );
};

export default StaffDashboardActions;

StaffDashboardActions.propTypes = {
  inquiryTypeId: PropTypes.number,
  inquiryId: PropTypes.number,
  status: PropTypes.string,
  loggedInUserId: PropTypes.string,
  createdByEmail: PropTypes.string,
  IsExpired: PropTypes.bool,
};
