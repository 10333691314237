import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetAllInquiriesStaffUrl } from "axios/customerUserAPIsUrls";
import jwtDecode from "jwt-decode";
import StaffDashboardActions from "views/CustomerStaff/Dashboard/StaffDashboardActions";
import SurveyFormStatus from "components/SurveyForms/SurveyFormStatus";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
  parah4: {
    textAlign: "justify",
    margin: "15px 0px",
    fontSize: "1rem",
  },
  parastrong: { color: primaryColor[0] },
};

const useStyles = makeStyles(styles);

const DashboardStaff = () => {
  const history = useHistory();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const loggedInUserId = decodedToken.userid;
  let email;
  if (token) {
    const decodedToken = jwtDecode(token);
    email = decodedToken.email;
  }

  const [
    { data: dataSurvey, error, loading },
    ExecuteGetAllInquiriesStaffUrl,
  ] = useAxios(GetAllInquiriesStaffUrl(email, currentPage, pageSize, token));

  useEffect(() => {
    ExecuteGetAllInquiriesStaffUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataSurvey) {
      setData(
        dataSurvey.data.map((prop, key) => {
          return {
            id: prop.Id,
            inquiry: prop.Description,
            status: (
              <div className="actions-center">
                <SurveyFormStatus status={prop.Status} />
              </div>
            ),
            actions: (
              <div className="actions-center">
                <StaffDashboardActions
                  inquiryTypeId={prop.InquiryTypeId}
                  inquiryId={prop.InquiryId}
                  status={prop.Status}
                  loggedInUserId={loggedInUserId}
                  createdByEmail={prop.CreatedBy}
                  IsExpired={prop.IsExpired}
                />
              </div>
            ),
          };
        })
      );
    }
  }, [dataSurvey]);

  const classes = useStyles();

  const columns = [
    {
      Header: "Inquiry",
      accessor: "inquiry",
      alignment: "center",
    },
    {
      Header: "Status",
      accessor: "status",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }

  return (
    <GridContainer className={classes.center}>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader title="Survey Forms" />
            {/* <div
              style={{
                // margin: "41px 63px",
                // mar
                display: "flex",
              }}
            > */}

            {/* </div> */}
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataSurvey?.currentPage}
              defaultPageSize={dataSurvey?.pageSize}
              totalItems={dataSurvey?.totalItem}
              totalPages={dataSurvey?.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default DashboardStaff;
