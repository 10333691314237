import React from "react";
import CustomSearchableSelect from "components/Selects/CustomSearchableSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { useParams, useHistory } from "react-router";
import CustomDatePicker from "components/CustomDates/CustomDatePicker";
import useStaffUsers from "hooks/useStaffUsers";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import useAxios from "axios/httpService";
import { AddInquiryParticipantsUrl } from "axios/customerAdminAPIsUrls";
import { GetRespondentsbyId } from "axios/customerAdminAPIsUrls";
import { validateEmail, validateDate, isEmpty } from "helpers/validations";
import { NotificationManager } from "components/common/ReactNotifications";
import Loading from "components/Loading/Loading";
import jwtDecode from "jwt-decode";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";

const SelectAndManageRespondents = () => {
  const min = new Date();
  console.log(min);
  const current = new Date();

  // it adds 2 days to a current date
  current.setDate(current.getDate() + 28);
  let tempdate = current.toDateString();
  const DefaultDate = new Date(tempdate + " " + min.toTimeString());

  // it adds 2 days to a current date
  min.setDate(min.getDate() + 1);
  let tempdate2 = min.toDateString();
  const minDate = new Date(tempdate2 + " " + min.toTimeString());

  const [fields, setFields] = React.useState({
    emails: [],
    numberOfParticipants: 0,
    expiryDate: DefaultDate,
    isCustomerAdminRespondent: false,
  });

  const [inquiryRespondents, setInquiryRespondents] = React.useState([]);

  const history = useHistory();

  const { inquiryId } = useParams();

  const [shouldValidateFields, setValidateFields] = React.useState(false);

  const token = localStorage.getItem("token");

  const decodedToken = jwtDecode(token);

  const { loading, error, customerUsers } = useStaffUsers(
    decodedToken.customerid
  );

  if (error) {
    console.log("Error in Getting Customer Users", error);
    apiErrorHandler(error, history);
  }

  const [customerUsersMenu, setCustomerUsersMenu] = React.useState([]);

  // Getting all Customer Users against the Company
  React.useEffect(() => {
    if (customerUsers) {
      setCustomerUsersMenu(
        customerUsers.map((item) => {
          return {
            key: item.Id,
            value: item.Email,
            label: item.Email,
          };
        })
      );
    }
  }, [customerUsers]);

  const areFieldsValidated = () => {
    if (
      isEmpty(fields.emails) !== null ||
      validateDate(fields.expiryDate, minDate, DefaultDate) !== null ||
      fields.numberOfParticipants === 0
    ) {
      return false;
    }
    return true;
  };

  //an api for getting emails
  const [
    {
      data: getRespondentsData,
      loading: respondentsLoading,
      error: errorInRespondents,
    },
    ExecuteGetRespondentsById,
  ] = useAxios(GetRespondentsbyId(inquiryId, token));

  // Getting Respondents Emails on Component Mounting
  React.useEffect(() => {
    ExecuteGetRespondentsById();
  }, []);

  // Getting Respondents data from API
  React.useEffect(() => {
    if (getRespondentsData) {
      const getRespondentsEmails = getRespondentsData.data.map((item) => {
        return {
          key: item.Id,
          value: item.Email,
          label: item.Email,
        };
      });
      if (getRespondentsEmails.length > 0) {
        setInquiryRespondents(getRespondentsEmails);
      } else {
        setInquiryRespondents([
          {
            key: 0,
            value: "No Respondents Added",
            label: "No Respondents Added",
          },
        ]);
      }
    }
  }, [getRespondentsData]);
  // CALLING AN API FOR ADDING INQUIRY PARTICIPANTS
  const [
    { loading: isAddParticipantsLoading },
    executeAddInquiryParticipants,
  ] = useAxios(AddInquiryParticipantsUrl(inquiryId, token), { manual: true });

  const onSubmit = async () => {
    setValidateFields(true);
    if (areFieldsValidated()) {
      try {
        const data = {
          Participants: fields.emails.map((email) => email.value),
          NoOfParticipants: fields.numberOfParticipants,
          ExpiryDate: fields.expiryDate.toISOString().slice(0, -1),
          IsCustomerAdminRespondent: fields.isCustomerAdminRespondent,
        };
        console.log("data", data);
        await executeAddInquiryParticipants({ data: data });
        NotificationManager.success("Successfully Added Participants", 5000);
        history.push(`/customerAdmin/inquiry-logs`);
      } catch (exception) {
        console.log("Error in Adding Inquiry Participants", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (isAddParticipantsLoading) {
    return <Loading loading={isAddParticipantsLoading} />;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Manage Respondents"
              btnText="Submit"
              showBackButton
              onClick={() => onSubmit()}
              goBackRoute="/customerAdmin/inquiry-logs"
            />
          </CardHeader>
          <CardBody>
            {/* RESPONDENT EMAILS*/}
            <GridItem md={6} sm={12} xs={12}>
              <CustomSearchableSelect
                label="Respondents Emails"
                required
                multiple
                value={inquiryRespondents}
                disabled
              />
            </GridItem>

            {/* RESPONDENT EMAILS SEARCHABLE DROPDOWN */}
            <GridItem md={6} sm={12} xs={12}>
              <CustomSearchableSelect
                label="Enter Respondents Emails"
                required
                multiple
                value={fields.emails}
                loading={loading}
                menu={customerUsersMenu && customerUsersMenu}
                inputValidation={validateEmail}
                showCustomValues
                onChange={(newValue) => {
                  setFields({
                    ...fields,
                    emails: newValue,
                    numberOfParticipants: newValue.length,
                  });
                }}
                errorText={shouldValidateFields && isEmpty(fields.emails)}
              />
            </GridItem>

            {/* EXPIRY DATE SELECTION CALENDAR */}
            <GridItem md={6} sm={12} xs={12}>
              <CustomDatePicker
                label="Expiration Date"
                required
                value={fields.expiryDate}
                minDate={minDate}
                onChange={(event) => {
                  setFields({ ...fields, expiryDate: event });
                }}
                errorText={
                  shouldValidateFields &&
                  validateDate(fields.expiryDate, minDate)
                }
              />
            </GridItem>
            {/* CUSTOMER ADMIN CHECKBOX */}
            <CustomSwitchLabel
              checked={fields.isCustomerAdminRespondent}
              onChange={(event) =>
                setFields({
                  ...fields,
                  isCustomerAdminRespondent: event.target.checked,
                })
              }
              label="Should customer admin receive survey form for this inquiry?"
              insideForm
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default SelectAndManageRespondents;

SelectAndManageRespondents.propTypes = {};
// isCustomerAdminRespondent;
