import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import React, { useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { CreateFindingUrl } from "axios/adminAPIsUrls";
import FormContainer from "components/Forms/FormContainer";
import CustomFormSelect from "components/Selects/CustomSelect";
import CustomInput from "components/Inputs/CustomInput";
import useInquiryTypes from "hooks/useInquiryTypes";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "helpers/validations";
import { NotificationManager } from "components/common/ReactNotifications";
import { AREA_TYPES } from "constants/defaultValues";

const useStyles = makeStyles(styles);
const CreateFindings = () => {
  const history = useHistory();
  const classes = useStyles();

  const [areFieldsValidated, setValidateFields] = useState(false);
  const [fields, setFields] = useState({
    InquiryTypeId: "",
    Area: "",
    FindingDetail: "",
  });

  const token = localStorage.getItem("token");
  const { loading, inquiryTypes, error } = useInquiryTypes();
  const [
    { loading: loadingCreateFinding },
    ExecuteCreateFindingUrl,
  ] = useAxios(CreateFindingUrl(token), { manual: true });

  const allFieldsValidated = () => {
    if (isEmpty(fields.InquiryTypeId)) {
      return false;
    }
    if (isEmpty(fields.Area)) {
      return false;
    }
    if (isEmpty(fields.FindingDetail)) {
      return false;
    }
    return true;
  };

  const onCreate = async (data = fields) => {
    setValidateFields(true);

    if (allFieldsValidated()) {
      try {
        const response = await ExecuteCreateFindingUrl({ data });
        if (response.status === 200) {
          NotificationManager.success("New Findings Created", 5000);
          history.push("/superAdmin/findings");
        }
      } catch (exception) {
        console.log("Error in Creating New Findings ", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (loading || loadingCreateFinding) {
    return <Loading loading />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Add Findings"
              btnText="Save"
              onClick={() => onCreate()}
              showBackButton
              goBackRoute={`/superAdmin/findings`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer height>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Inquiry Type"
                  value={fields.InquiryTypeId}
                  menu={inquiryTypes && inquiryTypes}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      InquiryTypeId: parseInt(event.target.value),
                    });
                  }}
                  errorText={
                    areFieldsValidated && isEmpty(fields.InquiryTypeId)
                  }
                />
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Area"
                  value={fields.Area}
                  menu={AREA_TYPES}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Area: event.target.value,
                    });
                  }}
                  errorText={areFieldsValidated && isEmpty(fields.Area)}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Finding Detail"
                  multiline
                  value={fields.FindingDetail}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      FindingDetail: event.target.value,
                    });
                  }}
                  errorText={
                    areFieldsValidated && isEmpty(fields.FindingDetail)
                  }
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateFindings;
