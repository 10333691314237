import React from "react";
// core components
import Button from "components/CustomButtons/Button.js";

// node js proptypes
import PropTypes from "prop-types";

// MUI icons
import SwapCallsOutlinedIcon from "@material-ui/icons/SwapCallsOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { useHistory } from "react-router-dom";
import { definePathwayStore } from "store";
import authStore from "store/authStore";
import jwtDecode from "jwt-decode";

const InquiryTypesActions = (props) => {
  const history = useHistory();
  const userToken = authStore((state) => state.userToken);
  const { alert, setAlert, recordId, recordName, toggleDelete } = props;
  return (
    <div className="actions-center">
      {userToken && jwtDecode(userToken).role === "CustomerAdmin" ? (
        <>
          <Button
            justIcon
            action
            tooltipText="View Pathways"
            round
            simple
            size="sm"
            color="primary"
            onClick={() => {
              history.push(`${window.location.pathname}/${recordId}/pathways`);
            }}
          >
            <SwapCallsOutlinedIcon />
          </Button>
        </>
      ) : (
        <>
          {" "}
          <Button
            action
            tooltipText="Manage Intake Questions"
            justIcon
            round
            simple
            size="sm"
            color="primary"
            onClick={() => {
              history.push(
                `${window.location.pathname}/${recordId}/intake-questions`
              );
            }}
          >
            <LiveHelpOutlinedIcon />
          </Button>
          <Button
            justIcon
            action
            tooltipText="Manage Pathways"
            round
            simple
            size="sm"
            color="primary"
            onClick={() => {
              history.push(`${window.location.pathname}/${recordId}/pathways`);
            }}
          >
            <SwapCallsOutlinedIcon />
          </Button>
          <Button
            justIcon
            round
            action
            tooltipText="View Inquiry Type"
            simple
            size="sm"
            color="primary"
            onClick={() =>
              history.push(`${window.location.pathname}/view/${recordId}`)
            }
          >
            <VisibilityOutlinedIcon />
          </Button>
          <Button
            justIcon
            round
            simple
            size="sm"
            color="primary"
            action
            tooltipText="Edit Inquiry Type"
            onClick={() =>
              history.push(`${window.location.pathname}/edit/${recordId}`)
            }
          >
            <CreateOutlinedIcon />
          </Button>
          <Button
            justIcon
            round
            simple
            size="sm"
            action
            tooltipText="Delete Inquiry Type"
            color="primary"
            onClick={() => toggleDelete(recordId)}
          >
            <DeleteForeverOutlinedIcon />
          </Button>
        </>
      )}
    </div>
  );
};

export default InquiryTypesActions;

InquiryTypesActions.propTypes = {
  alert: PropTypes.bool,
  setAlert: PropTypes.func,
  recordName: PropTypes.string,
  recordId: PropTypes.string,
  toggleDelete: PropTypes.func,
};
