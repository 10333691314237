import React from "react";
import { PropTypes } from "prop-types";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import { makeStyles } from "@material-ui/core";

// this component is being mapped inside the data table in which we need to show form status along with icons

const styles = {
  statusIcon: {
    color: primaryColor[0],
    marginRight: "5px",
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  status: {
    margin: "0px",
  },
};

const useStyles = makeStyles(styles);

const SurveyFormStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <i
        className={`fa fa-md ${classes.statusIcon} ${
          status.toLowerCase() === "completed" ? "fa-check-circle" : "fa-clock"
        }`}
      />
      <p className={classes.status}>{status}</p>
    </div>
  );
};

export default SurveyFormStatus;

SurveyFormStatus.propTypes = {
  status: PropTypes.string,
};
