import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { CreateIntakeQuestionUrl } from "axios/adminAPIsUrls";
import IntakeQuestionFields from "views/Common/intakeQuestion/IntakeQuestionFields";
import { isEmpty } from "helpers/validations";
import { isNumber } from "helpers/validations";

const CreateIntakeQuestion = () => {
  const history = useHistory();
  let { inquiryParamId } = useParams();
  const [areFieldsValidated, setFieldsValidated] = useState(false);

  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    MaxLength: 200,
  });

  const token = localStorage.getItem("token");
  const [{ loading }, ExecuteCreateIntakeQuestionUrl] = useAxios(
    CreateIntakeQuestionUrl(inquiryParamId, token),
    {
      manual: true,
    }
  );
  const allFieldsValidated = () => {
    if (isEmpty(fields.Type)) {
      return false;
    }
    if (isEmpty(fields.Title)) {
      return false;
    }
    if (isEmpty(fields.Description)) {
      return false;
    }
    if (fields.Type === "Textbox") {
      if (isNumber(fields.MaxLength)) {
        return false;
      }
    }

    return true;
  };

  const create = async (data = fields) => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      if (fields.Type !== "Textbox") {
        delete data.MaxLength;
      }
      try {
        const res = await ExecuteCreateIntakeQuestionUrl({
          data,
        });
        NotificationManager.success("New Intake Question Created", 5000);
        history.push(
          `/superAdmin/inquiry-type-management/${inquiryParamId}/intake-questions`
        );
      } catch (exception) {
        console.log("Error in Creating New Pathway", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (loading) {
    return <Loading loading />;
  }

  const prerequisiteValue = {
    title: "Create Intake Questions",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/intake-questions`,
    btnText: "Create", // Btn One
    // disabled: true,
    onClick: function () {
      create();
    },
  };

  return (
    <IntakeQuestionFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
      multiline
    />
  );
};

export default CreateIntakeQuestion;
