import * as React from "react";

const SvgEye = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 4.375c-.492.055-1.258.164-1.75.273.219.383.383 1.094.438 1.477 0 1.695-1.422 3.063-3.063 3.063-.438 0-1.148-.165-1.477-.383a6.1 6.1 0 0 0-.273 1.695A6.115 6.115 0 0 0 16 16.625a6.115 6.115 0 0 0 6.125-6.125c0-3.336-2.734-6.125-6.125-6.125Zm15.531 5.36C28.578 3.937 22.671 0 16 0 9.273 0 3.367 3.938.414 9.734c-.11.22-.164.547-.164.82 0 .22.055.548.164.766C3.367 17.117 9.274 21 16 21c6.672 0 12.578-3.883 15.531-9.68.11-.218.164-.547.164-.82a1.93 1.93 0 0 0-.164-.766ZM16 18.374c-5.414 0-10.39-3.008-13.016-7.875C5.61 5.633 10.586 2.625 16 2.625c5.36 0 10.336 3.008 12.96 7.875-2.624 4.867-7.6 7.875-12.96 7.875Z"
      fill="#D65746"
    />
  </svg>
);

export default SvgEye;
