import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetAdvanceInsightsForInquiryUrl } from "axios/commonAPIsUrls";
import AdvanceInsightsAreawise from "views/Common/ViewInsights/Overview/AdvancedInsights/AdvanceInsightsAreawise";
import { viewInsightsStore } from "store";
import { GetAreaColorsForInquiriesUrl } from "axios/commonAPIsUrls";
import { AREA_COLORS } from "constants/defaultValues";
import jwtDecode from "jwt-decode";
import { getColor } from "helpers/common-function";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
  mr_T_B: {
    margin: "20px 0px",
  },
};

const useStyles = makeStyles(styles);
const AdvanceInsights = () => {
  const history = useHistory();
  const classes = useStyles();

  const structureColor = viewInsightsStore((state) => state.structureColor);
  const processColor = viewInsightsStore((state) => state.processColor);
  const peopleColor = viewInsightsStore((state) => state.peopleColor);
  const cultureColor = viewInsightsStore((state) => state.cultureColor);
  const setStructureColor = viewInsightsStore(
    (state) => state.setStructureColor
  );
  const setProcessColor = viewInsightsStore((state) => state.setProcessColor);
  const setPeopleColor = viewInsightsStore((state) => state.setPeopleColor);
  const setCultureColor = viewInsightsStore((state) => state.setCultureColor);

  const { inquiryId } = useParams();
  const token = localStorage.getItem("token");
  const [
    { data: advanceInsights, error, loading },
    ExecuteGetAdvanceInsightsUrl,
  ] = useAxios(GetAdvanceInsightsForInquiryUrl(inquiryId, token));

  // getting dynamic colors for every inquiry
  const [
    { data: areaColors, loading: areaColorsLoading, error: errorInAreaColors },
    executeGetAreaColorsUrl,
  ] = useAxios(GetAreaColorsForInquiriesUrl(inquiryId, token));

  // SETTING DYNAMIC AREA COLORS FOR DIFFERENT INQUIRIES
  React.useEffect(() => {
    if (areaColors) {
      for (let i = 0; i < areaColors.data.length; i++) {
        if (areaColors.data[i].Area === "Structure") {
          const color = areaColors.data[i].Color.toLowerCase();
          setStructureColor(getColor(color));
        } else if (areaColors.data[i].Area === "People") {
          const color = areaColors.data[i].Color.toLowerCase();
          setPeopleColor(getColor(color));
        } else if (areaColors.data[i].Area === "Process") {
          const color = areaColors.data[i].Color.toLowerCase();
          setProcessColor(getColor(color));
        } else if (areaColors.data[i].Area === "Culture") {
          const color = areaColors.data[i].Color.toLowerCase();
          setCultureColor(getColor(color));
        }
      }
    }
  }, [areaColors]);
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }

  const decodedToken = jwtDecode(token);
  let loggedInRole = null;
  if (decodedToken.role.toLowerCase() === "superadmin") {
    loggedInRole = "superAdmin";
  } else if (decodedToken.role.toLowerCase() === "customeradmin") {
    loggedInRole = "customerAdmin";
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={classes.container}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Advance Insights"
              showBackButton
              goBackRoute={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/overview`}
            />
          </CardHeader>
          <CardBody>
            {/* Culture */}
            <div className={classes.mr_T_B}>
              {advanceInsights.CultureArray &&
                advanceInsights.CultureArray.length > 0 && (
                  <AdvanceInsightsAreawise
                    title="Culture"
                    data={advanceInsights.CultureArray}
                    Color={cultureColor}
                  />
                )}
            </div>
            {/* People */}
            <div className={classes.mr_T_B}>
              {advanceInsights.PeopleArray &&
                advanceInsights.PeopleArray.length > 0 && (
                  <AdvanceInsightsAreawise
                    title="People"
                    data={advanceInsights.PeopleArray}
                    Color={peopleColor}
                  />
                )}
            </div>

            {/* Process */}
            <div className={classes.mr_T_B}>
              {advanceInsights.ProcessArray &&
                advanceInsights.ProcessArray.length > 0 && (
                  <AdvanceInsightsAreawise
                    title="Process"
                    data={advanceInsights.ProcessArray}
                    Color={processColor}
                  />
                )}
            </div>
            {/* Structure */}
            <div className={classes.mr_T_B}>
              {advanceInsights.StructureArray &&
                advanceInsights.StructureArray.length > 0 && (
                  <AdvanceInsightsAreawise
                    title="Structure"
                    data={advanceInsights.StructureArray}
                    Color={structureColor}
                  />
                )}
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default AdvanceInsights;
