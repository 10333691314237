import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../../assets/css/atn-modal.css";
import { Modal } from "antd";
import PropTypes from "prop-types";
export function RouterPrompt({
  when,
  onOK,
  onCancel,
  title,
  okText,
  cancelText,
  pathname,
}) {
  const history = useHistory();
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(pathname);
      }
    }
  }, [history, onOK]);

  const handleCancel = useCallback(async () => {
    setShowPrompt(false);
  }, [history, onCancel]);

  return showPrompt ? (
    <Modal
      title={title}
      visible={showPrompt}
      onOk={handleOK}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      // closable={true}
    >
      {` Please save your changes by selecting the "Save as Draft" button to keep
      your progress, or choose "Leave" to discard the changes. Select "Cancel"
      to continue editing.`}
    </Modal>
  ) : null;
}
RouterPrompt.propTypes = {
  when: PropTypes.bool,
  onOK: PropTypes.string,
  onCancel: PropTypes.string,
  title: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  pathname: PropTypes.string,
};
