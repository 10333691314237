/* eslint-disable react/prop-types */
import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import ActivateLogo from "assets/img/activateImages/activate-logo.png";
import styles from "assets/jss/material-dashboard-pro-react/views/Auth/authStyle.js";
import Box from "@material-ui/core/Box";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Icon, Typography } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classNames from "classnames";
import { UserLoginUrl } from "axios/authAPIsUrls";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { resetStores } from "store";
import { authStore } from "store";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router-dom";
import ButtonLoading from "components/Loading/ButtonLoading";
import CustomInput from "components/Inputs/CustomInput";
import { validateMandatoryEmail, validatePassword } from "helpers/validations";
import infoCircle from "assets/img/svgs/info-circle.svg";

// @material-ui/icons
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const useStyles = makeStyles(styles);
// eslint-disable-next-line react/prop-types
const LoginPage = () => {
  const setLoggedInName = authStore((state) => state.setLoggedInName);
  const setFirstLogin = authStore((state) => state.setFirstLogin);
  const setUserToken = authStore((state) => state.setUserToken);
  const history = useHistory();
  // material UI stuff
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const classes = useStyles();
  // states
  const [values, setValues] = React.useState({
    email: "",
    password: "",
  });
  const [validateFields, setValidateFields] = React.useState(false);

  const [{ loading }, ExecuteUserLoginUrl] = useAxios(UserLoginUrl(), {
    manual: true,
  });

  const allFieldsValidated = () => {
    if (validateMandatoryEmail(values.email) !== null) {
      return false;
    }
    if (validatePassword(values.password) !== null) {
      return false;
    }

    return true;
  };
  const loginUserHandler = async () => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      try {
        const res = await ExecuteUserLoginUrl({
          data: {
            username: values.email,
            password: values.password,
          },
        });

        if (res.status === 200) {
          const token = res.data.token;
          const isFirstLogin = res.data.FirstTimeLogin;
          setFirstLogin(isFirstLogin);
          setUserToken(res.data.token);
          localStorage.setItem("isFirstLogin", isFirstLogin);
          const decodedToken = jwtDecode(token);
          const loggedinRole = decodedToken.role;
          localStorage.setItem("token", token);
          localStorage.setItem(
            "loggedInName",
            decodedToken.firstname + " " + decodedToken.lastname
          );
          setLoggedInName(decodedToken.firstname + " " + decodedToken.lastname);
          NotificationManager.success("Logged in Successfully", 5000);
          if (loggedinRole === "SuperAdmin") {
            history.push("/superAdmin/dashboard");
          } else if (loggedinRole === "CustomerAdmin") {
            history.push("/customerAdmin/dashboard");
          } else if (loggedinRole === "CustomerUser") {
            history.push("/customerUser/dashboard");
          }
        }
      } catch (error) {
        console.log("Login API Error", error);
        apiErrorHandler(error, history);
      }
    }
  };

  React.useEffect(() => {
    // CLEARING LOCALSTORAGE IF THE USER DIRECTLY OR MISTAKENLY ACCESS AUTH SCREEN
    localStorage.clear();
    resetStores();
  }, []);

  return (
    <div>
      <GridContainer noDefaultSetting className={classes.container}>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_form}
          >
            {/* image */}
            <Box mb={8} mt={8}>
              <img src={ActivateLogo} alt="activate_logo" height="140" />
            </Box>
            {/* Custom input form */}
            <Box className={classes.width_80}>
              <Box>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    {/* Email Address */}
                    <CustomInput
                      inputType="email"
                      required
                      label="Email Address"
                      leftIcon={<Icon>email</Icon>}
                      value={values.email}
                      onChange={(event) =>
                        setValues({ ...values, email: event.target.value })
                      }
                      errorText={
                        validateFields && validateMandatoryEmail(values.email)
                      }
                    />
                  </GridItem>
                </GridContainer>
              </Box>
              <Box>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    <CustomInput
                      inputType="password"
                      required
                      label="Password"
                      leftIcon={<Icon>lock</Icon>}
                      value={values.password}
                      onChange={(event) =>
                        setValues({ ...values, password: event.target.value })
                      }
                      errorText={
                        validateFields && validatePassword(values.password)
                      }
                    />
                  </GridItem>
                </GridContainer>
              </Box>
            </Box>
            {/* litte content */}
            {/* Commented Forget Password Story */}
            <Box mt={1} mb={1}>
              <Box display="flex">
                <Typography variant="caption" display="block" gutterBottom>
                  <span
                    className={classNames(
                      classes.theme_color,
                      classes.cursor_pointer
                    )}
                    onClick={() => history.push("/auth/forget-password")}
                  >
                    {" "}
                    <span
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      <img src={infoCircle} alt="Box Logo" height="17" />
                    </span>
                    Forgot Password?
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box mt={1} mb={3}>
              <Box
                display="flex"
                // eslint-disable-next-line react/prop-types
              >
                <Typography variant="caption" display="block" gutterBottom>
                  Don&apos;t have an account ?{" "}
                  <span
                    onClick={() => history.push("/auth/signup")}
                    className={classNames(
                      classes.theme_color,
                      classes.cursor_pointer
                    )}
                  >
                    Sign Up
                  </span>
                </Typography>
              </Box>
            </Box>

            <Box p={1} mb={7} display="flex">
              <Button
                round
                disabled={loading}
                className={classes.theme_color_background}
                onClick={loginUserHandler}
              >
                <ExitToAppIcon />
                {loading ? <ButtonLoading /> : "Log In"}
              </Button>
            </Box>
          </Box>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item2}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_content}
            pb={4}
          >
            {/* Content One */}
            <Box p={1}>
              <h1 className={classes.login_form_content1}>
                Welcome to{" "}
                <b>
                  <span className={classes.theme_color}>ACT</span>ivate Advance
                </b>
              </h1>
            </Box>
            {/* Content Two */}
            <Box>
              <h3 className={classes.login_form_content2}>
                Activating Advanced Strategies in Real Time
              </h3>
            </Box>
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LoginPage;
