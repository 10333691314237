import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";

// core components
import TopNavbarLinks from "components/Navbars/TopNavbarLinks";
import Button from "components/CustomButtons/Button.js";

// Activate Logo
import activateLogo from "assets/img/activateImages/activate-logo.png";

import styles from "assets/jss/material-dashboard-pro-react/components/topNavbarStyle.js";

const useStyles = makeStyles(styles);

export default function TopNavbar(props) {
  const classes = useStyles();

  const logoNormal = classes.logoNormal;
  const logoMini = classes.logoMini;
  const logoClasses = classes.logo;
  return (
    <AppBar className={classes.appBar} position="fixed">
      <Toolbar className={classes.container}>
        <div className={classes.flex + " " + classes.initialPadding}>
          <div className={logoClasses}>
            <a href="#" className={logoMini} rel="noreferrer">
              <img src={activateLogo} alt="logo" className={classes.img} />
            </a>
            <a href="#" className={logoNormal} rel="noreferrer"></a>
          </div>
        </div>
        <Hidden smDown implementation="css" className={classes.finalPadding}>
          <TopNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            simple
            size="lg"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

TopNavbar.propTypes = {
  handleDrawerToggle: PropTypes.func,
};
