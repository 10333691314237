import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";

// MUI Core Modules
import { makeStyles } from "@material-ui/core";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import GetRequiredCustomInput from "views/Common/SurveyForms/GetRequiredCustomInput";

const useStyles = makeStyles(styles);

const DynamicSurveyFormAgainstOthers = ({
  data,
  tableTitle,
  count,
  InquiryId,
}) => {
  const classes = useStyles();
  return (
    <>
      <h4 className={classes.tableTitle}>
        {count}
        {". "}
        {tableTitle}
      </h4>
      <GridContainer>
        <h4 className={classes.answerInstructions}>
          Please respond as candidly and specifically as you can to the
          following.
          <br />
          NOTE: If you are an external stakeholder (vendor, customer, etc.), and
          do not have enough information to respond to a specific question,
          please type “NA”
        </h4>
        <GridItem xs={12} md={12} sm={12} noDefaultPadding>
          {data.map((prop, key) => {
            return (
              <GridContainer noDefaultSetting key={key}>
                <h4 className={classes.questionStyle}>
                  {key + 1}. {prop.Description}
                  <br />
                </h4>
                <GridItem xs={12} sm={12} md={12}>
                  <GetRequiredCustomInput
                    inputType={prop.Type ? prop.Type : prop.QuestionType}
                    ratingOptions={prop.RadioButtonOptions}
                    checkboxOptions={
                      prop.Answers === "no checkbox" ? [] : prop.CheckBoxOptions
                    }
                    rowIndex={key}
                    InquiryId={InquiryId}
                    data={data}
                  />
                </GridItem>
              </GridContainer>
            );
          })}
        </GridItem>
      </GridContainer>
    </>
  );
};

export default DynamicSurveyFormAgainstOthers;

DynamicSurveyFormAgainstOthers.propTypes = {
  tableTitle: PropTypes.string,
  data: PropTypes.array,
  count: PropTypes.number,
  InquiryId: PropTypes.number,
};
