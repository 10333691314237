import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import AlertModal from "components/Alerts/AlertModal";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetAllRecommendationsUrl } from "axios/adminAPIsUrls";
import { DeleteRecommendationUrl } from "axios/adminAPIsUrls";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

const RecommendationManagement = () => {
  const history = useHistory();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [recommendationId, setRecommendationId] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const [
    { data: dataRecommendation, error, loading },
    ExecuteGetAllRecommendationsUrl,
  ] = useAxios(GetAllRecommendationsUrl(currentPage, pageSize, token), {
    manual: true,
  });

  const [{ loading: deleteLoading }, ExecuteDeleteRecommendationUrl] = useAxios(
    DeleteRecommendationUrl(recommendationId, token),
    {
      manual: true,
    }
  );

  const deletehandler = async () => {
    try {
      const res = await ExecuteDeleteRecommendationUrl();
      setAlert(!alert);
      NotificationManager.success("Recommendation Deleted", 5000);
      ExecuteGetAllRecommendationsUrl();
    } catch (exception) {
      console.log("Error in Deleting Recommendation", exception);
      apiErrorHandler(exception, history);
    }
  };

  useEffect(() => {
    ExecuteGetAllRecommendationsUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataRecommendation) {
      setData(
        dataRecommendation.data.map((prop, key) => {
          return {
            id: prop.RecommendationId,
            recommendationDetail: prop.RecommendationDetail,
            inquirytype: prop.InquiryTypeName,
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Recommendation"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view/${prop.RecommendationId}`
                    )
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>

                <Button
                  action
                  tooltipText="Edit Recommendation"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/edit/${prop.RecommendationId}`
                    )
                  }
                >
                  <CreateOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Delete Recommendation"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() => toggleDelete(prop.RecommendationId)}
                >
                  <DeleteForeverOutlinedIcon />
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataRecommendation]);

  const toggleDelete = (id) => {
    setRecommendationId(id);
    setAlert(!alert);
  };

  const classes = useStyles();

  const columns = [
    {
      Header: "Recommendation",
      accessor: "recommendationDetail",
      alignment: "center",
    },
    {
      Header: "Inquiry Type",
      accessor: "inquirytype",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }
  if (deleteLoading) {
    return <Loading deleteLoading />;
  }

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletehandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Recommendation Management"
              btnText="Add Recommendation"
              onClick={() => history.push(`${window.location.pathname}/create`)}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataRecommendation && dataRecommendation.currentPage}
              defaultPageSize={
                dataRecommendation && dataRecommendation.pageSize
              }
              totalItems={dataRecommendation && dataRecommendation.totalItem}
              totalPages={dataRecommendation && dataRecommendation.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default RecommendationManagement;
