import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetAllRespondentsUrl } from "axios/commonAPIsUrls";
import jwtDecode from "jwt-decode";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);
const Participants = () => {
  const history = useHistory();
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const { inquiryId } = useParams();

  const token = localStorage.getItem("token");
  const [
    { data: inquiryRespondents, error, loading },
    ExecuteGetAllRespondentsUrl,
  ] = useAxios(GetAllRespondentsUrl(inquiryId, currentPage, pageSize, token), {
    manual: true,
  });

  useEffect(() => {
    ExecuteGetAllRespondentsUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (inquiryRespondents) {
      setData(
        inquiryRespondents.data.map((prop, key) => {
          return {
            id: prop.Id,
            fullName: prop.FirstName + " " + prop.LastName,
            email: `${prop.Email}`,
          };
        })
      );
    }
  }, [inquiryRespondents]);

  const classes = useStyles();

  const columns = [
    {
      Header: "Name",
      accessor: "fullName",
      alignment: "center",
    },
    {
      Header: "Email",
      accessor: "email",
      alignment: "center",
    },
  ];

  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }

  const decodedToken = jwtDecode(token);
  let loggedInRole = null;
  if (decodedToken.role.toLowerCase() === "superadmin") {
    loggedInRole = "superAdmin";
  } else if (decodedToken.role.toLowerCase() === "customeradmin") {
    loggedInRole = "customerAdmin";
  }

  return (
    <GridContainer className={classes.center}>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Participants"
              showBackButton
              goBackRoute={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/overview`}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={inquiryRespondents && inquiryRespondents.currentPage}
              defaultPageSize={
                inquiryRespondents && inquiryRespondents.pageSize
              }
              totalItems={inquiryRespondents && inquiryRespondents.totalItem}
              totalPages={inquiryRespondents && inquiryRespondents.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Participants;
