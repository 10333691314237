import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import InquiryLogActions from "views/CustomerAdmin/Inquirylogs-Management/InquiryLogActions";
import { GetAllInquiriesUrl } from "axios/customerAdminAPIsUrls";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

const InquiryLogs = () => {
  const history = useHistory();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const [
    { data: dataInquiryLog, error, loading },
    ExecuteGetAllInquiriesUrl,
  ] = useAxios(GetAllInquiriesUrl(currentPage, pageSize, token));

  useEffect(() => {
    ExecuteGetAllInquiriesUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataInquiryLog) {
      setData(
        dataInquiryLog.data.map((prop) => {
          return {
            id: prop.Id,
            inquiryType: prop.InquiryTypeName,
            inquiry: prop.Description,
            partcipants: prop.NoOfParticipants,
            documents: prop.Documents,
            advanceInsights: prop.AdvanceInsights,
            completion: prop.Progress + "%",
            active: (
              <CustomSwitchLabel checked={prop.IsActive} noMarginPadding />
            ),
            actions: (
              <InquiryLogActions
                inquiryTypeId={prop.InquiryTypeId}
                inquiryId={prop.Id}
                respondentsAdded={prop.RespondentsAdded}
                progress={prop.Progress}
              />
            ),
          };
        })
      );
    }
  }, [dataInquiryLog]);

  const classes = useStyles();

  const columns = [
    {
      Header: "Inquiry Type",
      accessor: "inquiryType",
      alignment: "center",
    },
    {
      Header: "Inquiry",
      accessor: "inquiry",
      alignment: "center",
    },
    {
      Header: "Partcipants",
      accessor: "partcipants",
      alignment: "center",
    },
    {
      Header: "Documents",
      accessor: "documents",
      alignment: "center",
    },
    {
      Header: "Advance Insights",
      accessor: "advanceInsights",
      alignment: "center",
    },
    {
      Header: "Completion",
      accessor: "completion",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }

  return (
    <GridContainer className={classes.center}>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Active Inquiries"
              btnText="Create Inquiry"
              onClick={() => history.push("/customerAdmin/inquiry-logs/create")}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataInquiryLog && dataInquiryLog.currentPage}
              defaultPageSize={dataInquiryLog && dataInquiryLog.pageSize}
              totalItems={dataInquiryLog && dataInquiryLog.totalItem}
              totalPages={dataInquiryLog && dataInquiryLog.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default InquiryLogs;
