import React from "react";
import useAxios from "axios/httpService";
import { GetAllSurveyFormQuestionsUrl } from "axios/customerUserAPIsUrls";
import jwtDecode from "jwt-decode";
import { surveyFormStore } from "store";
const useSurveyFormQuestions = (inquiryTypeId, inquiryId) => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const [response, setResponse] = React.useState("");
  const currentPage = -1;
  const pageSize = 8;

  // Calling States from Store
  const structureArray = surveyFormStore((state) => state.structureArray);
  const setStructureArray = surveyFormStore((state) => state.setStructureArray);

  const peopleArray = surveyFormStore((state) => state.peopleArray);
  const setPeopleArray = surveyFormStore((state) => state.setPeopleArray);

  const processArray = surveyFormStore((state) => state.processArray);
  const setProcessArray = surveyFormStore((state) => state.setProcessArray);

  const cultureArray = surveyFormStore((state) => state.cultureArray);
  const setCultureArray = surveyFormStore((state) => state.setCultureArray);

  const othersArray = surveyFormStore((state) => state.othersArray);
  const setOthersArray = surveyFormStore((state) => state.setOthersArray);

  const [{ data, loading, error }, executeGetSurveyFormQuestions] = useAxios(
    GetAllSurveyFormQuestionsUrl(
      parseInt(inquiryTypeId),
      parseInt(inquiryId),
      currentPage,
      pageSize,
      token
    ),
    {
      manual: true,
    }
  );

  React.useEffect(async () => {
    let response = await executeGetSurveyFormQuestions();
    setResponse(response);
  }, []);
  React.useEffect(() => {
    if (response) {
      setStructureArray(
        response.data.StructureArray.map((item) => {
          let newItem = item;
          newItem.Answer = item.Answer ? item.Answer : "";
          newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
            ? item.ValueImportanceAnswer
            : "";
          newItem.file = item.file ? item.file : "";
          newItem.InquiryId = inquiryId;
          newItem.CustomerUserId = decodedToken.userid;
          return newItem;
        })
      );

      //  setting required values in peopleArray
      setPeopleArray(
        response.data.PeopleArray.map((item) => {
          let newItem = item;
          newItem.Answer = item.Answer ? item.Answer : "";
          newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
            ? item.ValueImportanceAnswer
            : "";
          newItem.file = item.file ? item.file : "";
          newItem.InquiryId = inquiryId;
          newItem.CustomerUserId = decodedToken.userid;
          return newItem;
        })
      );

      //  setting required values in processArray
      setProcessArray(
        response.data.ProcessArray.map((item) => {
          let newItem = item;
          newItem.Answer = item.Answer ? item.Answer : "";
          newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
            ? item.ValueImportanceAnswer
            : "";
          newItem.file = item.file ? item.file : "";
          newItem.InquiryId = inquiryId;
          newItem.CustomerUserId = decodedToken.userid;
          return newItem;
        })
      );

      //  setting required values in cultureArray
      setCultureArray(
        response.data.CultureArray.map((item) => {
          let newItem = item;
          newItem.Answer = item.Answer ? item.Answer : "";
          newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
            ? item.ValueImportanceAnswer
            : "";
          newItem.file = item.file ? item.file : "";
          newItem.InquiryId = inquiryId;
          newItem.CustomerUserId = decodedToken.userid;
          return newItem;
        })
      );

      //  setting required values in othersArray
      setOthersArray(
        response.data.OthersArray.map((item) => {
          let newItem = item;
          newItem.Type === "Checkbox"
            ? (newItem.Answer = item.Answer ? item.Answer : "no checkbox")
            : (newItem.Answer = item.Answer ? item.Answer : "");
          newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
            ? item.ValueImportanceAnswer
            : "";
          newItem.DownloadLink = item.DownloadLink ? item.DownloadLink : "";
          newItem.DocumentName = item.DocumentName ? item.DocumentName : "";
          newItem.file = "";
          newItem.InquiryId = inquiryId;
          newItem.CheckBoxOptions = item.CheckBoxOptions;
          newItem.CheckBoxAnswers = item.CheckBoxOptionsAnswer;
          newItem.CheckboxArray = item.CheckBoxOptionsAnswer
            ? item.CheckBoxOptionsAnswer
            : [];
          newItem.CustomerUserId = decodedToken.userid;
          return newItem;
        })
      );
    }
  }, [response]);
  if (response) {
    return {
      loading,
      structureArray,
      processArray,
      peopleArray,
      cultureArray,
      othersArray,
      error,
    };
  }
  return { loading };
};

export default useSurveyFormQuestions;
