import * as React from "react";

const SvgEdit = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.414 6.516a.491.491 0 0 0-.71 0L8.632 17.586l-.508 4.723c-.102.609.457 1.168 1.066 1.066l4.723-.508 11.07-11.07a.491.491 0 0 0 0-.711l-4.57-4.57Zm8.227-1.168-2.489-2.489a1.992 1.992 0 0 0-2.793 0l-1.777 1.778a.49.49 0 0 0 0 .71l4.57 4.571a.49.49 0 0 0 .711 0l1.778-1.777a1.992 1.992 0 0 0 0-2.793ZM19.5 19.87V25H3.25V8.75h11.629c.203 0 .355-.05.457-.152l2.031-2.032c.356-.406.102-1.066-.457-1.066H2.437A2.438 2.438 0 0 0 0 7.938v17.875a2.406 2.406 0 0 0 2.438 2.437h17.875a2.438 2.438 0 0 0 2.437-2.438V17.84c0-.559-.66-.813-1.066-.457l-2.032 2.031c-.101.102-.152.254-.152.457Z"
      fill="#D65746"
    />
  </svg>
);

export default SvgEdit;
