import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import React from "react";

export const customerUserMenu = [
  {
    tabName: "Dashboard",
    tabIcon: <StorefrontOutlinedIcon />,
    tabRoute: "/dashboard",
  },
];
