import React from "react";

import { makeStyles } from "@mui/material";
import PropTypes from "prop-types";

// import charts form chart.js
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const HorizontalBarChart = ({ yAxisValues, datasets, options }) => {
  return (
    <div>
      <Bar
        height={500}
        width={500}
        data={{
          labels: yAxisValues,
          datasets: datasets,
        }}
        options={options}
      />
    </div>
  );
};

export default HorizontalBarChart;

HorizontalBarChart.defaultProps = {
  yAxisValues: [],
  datasets: [],
  beginAtZero: true,
  options: {},
};

HorizontalBarChart.propTypes = {
  yAxisValues: PropTypes.array,
  datasets: PropTypes.array,
  beginAtZero: PropTypes.bool,
  options: PropTypes.object,
};
