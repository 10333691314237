import * as React from "react";

const SvgUploadHelpDoc = (props) => (
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5041 38.9896H3.25069V2.99967H16.2534V11.9971C16.256 12.7918 16.5993 13.5533 17.2084 14.1152C17.8174 14.6772 18.6428 14.9939 19.5041 14.9963H29.2562V19.495H32.5069V11.9971C32.5126 11.8001 32.472 11.6042 32.3878 11.423C32.3036 11.2418 32.1779 11.0795 32.0193 10.9474L20.6419 0.450383C20.4988 0.303941 20.3229 0.187883 20.1265 0.110215C19.9301 0.0325465 19.7177 -0.00488237 19.5041 0.000509917H3.25069C2.38934 0.00288334 1.56401 0.319627 0.954946 0.881565C0.345881 1.4435 0.00257247 2.20497 0 2.99967V38.9896C0.00257247 39.7843 0.345881 40.5457 0.954946 41.1077C1.56401 41.6696 2.38934 41.9863 3.25069 41.9887H19.5041V38.9896ZM19.5041 3.5995L28.606 11.9971H19.5041V3.5995Z"
      fill="#B84140"
    />
    <path
      d="M31.4904 36.1098V26.8711M27.7109 30.913L31.4904 26.8711L35.2699 30.913"
      stroke="#B84140"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M40.4996 31.49C40.4996 36.4045 36.3391 40.4879 31.0818 40.4879C25.8245 40.4879 21.6641 36.4045 21.6641 31.49C21.6641 26.5756 25.8245 22.4922 31.0818 22.4922C36.3391 22.4922 40.4996 26.5756 40.4996 31.49Z"
      stroke="#B84140"
      strokeWidth="3"
    />
    <rect
      x="5.5"
      y="21.3711"
      width="16.7969"
      height="2.51953"
      rx="1.25976"
      fill="#B84140"
    />
    <rect
      x="5.5"
      y="28.0859"
      width="9.23872"
      height="2.51965"
      rx="1.25983"
      fill="#B84140"
    />
  </svg>
);

export default SvgUploadHelpDoc;
