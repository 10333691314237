import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import React, { useEffect, useState } from "react";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { GetCustomerAdminsUrl } from "axios/adminAPIsUrls";
import ManageCustomerFields from "views/Common/profileManageCustomers/ManageCustomerFields";

const ViewCustomerAdmin = () => {
  const history = useHistory();
  let { CustomerAdminParamId } = useParams();

  const [fields, setFields] = useState({
    CustomerId: "",
    CustomerName: "",
    Industry: "",
    Address: "",
    State: "",
    City: "",
    Country: "",
    Website: "",
    YearsInBusiness: "",
    Duration: "days",
    ProfitOrNon: "",
    OwnershipStructure: "",
    AnnualRevenue: "",
    AverageFTE: "",
    NumberOfLocations: "",
    HeadquarterAddress: "",
    IsActive: "",
  });

  const token = localStorage.getItem("token");
  const [
    { data: dataCustomer, loading: loadingCustomer, error: errorCustomer },
    ExecuteGetCustomerAdminsUrl,
  ] = useAxios(GetCustomerAdminsUrl(CustomerAdminParamId, token));

  useEffect(() => {
    ExecuteGetCustomerAdminsUrl();
  }, []);

  useEffect(() => {
    if (dataCustomer) {
      setFields({
        CustomerId: dataCustomer.CustomerId,
        CustomerName: dataCustomer.CustomerName,
        Industry: dataCustomer.Industry,
        Address: dataCustomer.Address,
        State: dataCustomer.State,
        City: dataCustomer.City,
        Country: dataCustomer.Country,
        Website: dataCustomer.Website,
        YearsInBusiness: dataCustomer.YearsInBusiness,
        Duration: dataCustomer.Duration,
        ProfitOrNon: dataCustomer.ProfitOrNon,
        OwnershipStructure: dataCustomer.OwnershipStructure,
        AnnualRevenue: dataCustomer.AnnualRevenue,
        AverageFTE: dataCustomer.AverageFTE,
        NumberOfLocations: dataCustomer.NumberOfLocations,
        HeadquarterAddress: dataCustomer.HeadquarterAddress,
        IsActive: dataCustomer.IsActive,
      });
    }
  }, [dataCustomer]);

  if (loadingCustomer) {
    return <Loading loading={loadingCustomer} />;
  }

  if (errorCustomer) {
    apiErrorHandler(errorCustomer, history);
  }

  const prerequisiteValue = {
    title: "View Profile",
    goBackLink: `/superAdmin/manage-customers-profile`,
    disabled: true,
  };

  return (
    <ManageCustomerFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
    />
  );
};

export default ViewCustomerAdmin;
