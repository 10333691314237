import React from "react";
import { useParams } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import monitorActivityGraphStore from "store/monitorActivityGraphStore";

import useAxios from "axios/httpService";
import {
  GetInquiryByIdUrl,
  GetAreaColorsForInquiriesUrl,
} from "axios/commonAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { AREA_COLORS } from "constants/defaultValues";
import MonitorActivityInputs from "./MonitorActivityInputs";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import { getColor } from "helpers/common-function";

const MonitorActivityInsights = () => {
  const { inquiryId } = useParams();
  console.log(inquiryId);
  const token = localStorage.getItem("token");

  // SETTING UP VALUES IN STORE FOR VIEW INSIGHTS
  const setInquiryId = monitorActivityGraphStore((state) => state.setInquiryId);
  const setInquiryName = monitorActivityGraphStore(
    (state) => state.setInquiryName
  );
  const setInquiryTypeName = monitorActivityGraphStore(
    (state) => state.setInquiryTypeName
  );
  const setStructureColor = monitorActivityGraphStore(
    (state) => state.setStructureColor
  );
  const setProcessColor = monitorActivityGraphStore(
    (state) => state.setProcessColor
  );
  const setPeopleColor = monitorActivityGraphStore(
    (state) => state.setPeopleColor
  );
  const setCultureColor = monitorActivityGraphStore(
    (state) => state.setCultureColor
  );

  // getting inquiry detail using inquiry id
  const [{ data, loading, error }, executeGetInquiryByIdUrl] = useAxios(
    GetInquiryByIdUrl(inquiryId, token)
  );

  // getting dynamic colors for every inquiry
  const [
    { data: areaColors, loading: areaColorsLoading, error: errorInAreaColors },
    executeGetAreaColorsUrl,
  ] = useAxios(GetAreaColorsForInquiriesUrl(inquiryId, token));

  React.useEffect(() => {
    if (data) {
      setInquiryTypeName(data.InquiryTypeName);
      setInquiryId(data.Id);
      setInquiryName(data.Description);
    }
  }, []);

  // SETTING DYNAMIC AREA COLORS FOR DIFFERENT INQUIRIES
  React.useEffect(() => {
    if (areaColors) {
      for (let i = 0; i < areaColors.data.length; i++) {
        if (areaColors.data[i].Area === "Structure") {
          const color = areaColors.data[i].Color.toLowerCase();
          setStructureColor(getColor(color));
        } else if (areaColors.data[i].Area === "People") {
          const color = areaColors.data[i].Color.toLowerCase();
          setPeopleColor(getColor(color));
        } else if (areaColors.data[i].Area === "Process") {
          const color = areaColors.data[i].Color.toLowerCase();
          setProcessColor(getColor(color));
        } else if (areaColors.data[i].Area === "Culture") {
          const color = areaColors.data[i].Color.toLowerCase();
          setCultureColor(getColor(color));
        }
      }
    }
  }, [areaColors]);
  if (errorInAreaColors) {
    console.log("Error in Getting Area Colors", error);
    apiErrorHandler(errorInAreaColors, history);
  }

  if (error) {
    console.log("Error in Getting Inquiry By Id", error);
    apiErrorHandler(error, history);
  }

  if (loading || areaColorsLoading) {
    return <Loading loading={loading || areaColorsLoading} />;
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} noDefaultPadding>
        <Card>
          <CardHeader>
            <TableHeader
              title="Inputs Calculation"
              showBackButton
              goBackRoute={`/superAdmin/monitor-activity`}
            />
          </CardHeader>
          <MonitorActivityInputs />
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default MonitorActivityInsights;
