import create from "zustand";

const signupStore = create((set) => ({
  // BELOW ARE THE FIELDS FOR SIGNUP STORY
  fields: {
    step1: {
      orgName: "",
      industryType: "",
      website: "",
      streetAddress: "",
      stateName: "",
      countryName: "",
      cityName: "",
    },
    step2: {
      firstName: "",
      lastName: "",
      email: "",
      designation: "",
      password: "",
      confPassword: "",
    },
  },
  setFields: (fields) => {
    set(() => ({ fields }));
  },

  setFieldsEmpty: () => {
    set(() => ({
      fields: {
        step1: {
          orgName: "",
          industryType: "",
          website: "",
          streetAddress: "",
          stateName: "",
          countryName: "",
          cityName: "",
        },
        step2: {
          firstName: "",
          lastName: "",
          email: "",
          designation: "",
          password: "",
          confPassword: "",
        },
      },
    }));
  },
  validateFields: false,
  setValidateFields: (validateFields) => {
    set(() => ({ validateFields }));
  },

  // BELOW STATES ARE BEING SET DEPENDING ON THE SIGNUP API RESPONSE

  isSignupLoading: false,
  setSignupLoading: (isSignupLoading) => {
    set(() => ({ isSignupLoading }));
  },

  signupError: "",
  setSignupError: (signupError) => {
    set(() => ({ signupError }));
  },

  //  BELOW BOOLEAN WILL WORK WHEN THE STAFF MEMBER TRIES TO SIGNUP, WE NEED TO LOCK ALL ORGANIZATIONAL INFO FIELDS
  areFieldsLocked: false,
  setFieldsLocked: (areFieldsLocked) => {
    set(() => ({ areFieldsLocked }));
  },

  isOrganizationDetailsLoading: false,
  setOrganizationDetailsLoading: (isOrganizationDetailsLoading) => {
    set(() => ({ isOrganizationDetailsLoading }));
  },
}));

export default signupStore;
