/* eslint-disable react/prop-types */
import React from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import { makeStyles } from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";

const styles = {
  ...style,
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyle = makeStyles(styles);

const ManagePathwayFields = ({
  fields,
  setFields,
  prerequisiteValue,
  AreFieldsValidated,
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title={prerequisiteValue.title}
              btnText={prerequisiteValue.btnText}
              showBackButton
              goBackRoute={prerequisiteValue.goBackLink}
              onClick={() => prerequisiteValue.onClick()}
            />
          </CardHeader>
          <CardBody className={classes.ptZero}>
            <FormContainer height>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Inquiry Type"
                    value={fields.inquiryName}
                    disabled
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  {/* leave it empty */}
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Pathway Name"
                    value={fields.name}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        name: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.name)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
              </GridContainer>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ManagePathwayFields;
