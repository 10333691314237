import axios from "axios";
import { makeUseAxios, configure } from "axios-hooks";
import { Config } from "config";

const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: Config.apiGateway.URL,
    timeout: 120000, // 120 seconds

    headers: {
      "Content-Type": "application/json",
    },
  }),
});

const defaultOptions = { useCache: false };

configure({ axios, defaultOptions });

export default useAxios;
