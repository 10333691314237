import React, { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import { makeStyles } from "@material-ui/core";
import GridItem from "components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/findingStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Loading from "components/Loading/Loading";
import TableHeader from "components/Tables/TableHeader";
import useAxios from "axios/httpService";
import { GetFindingsResultUrl } from "axios/commonAPIsUrls";
import FindingResuable from "./FindingResuable";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import { viewInsightsStore } from "store";

const useStyles = makeStyles(styles);
const Findings = () => {
  const classes = useStyles();
  const history = useHistory();

  const structureColor = viewInsightsStore((state) => state.structureColor);
  const processColor = viewInsightsStore((state) => state.processColor);
  const peopleColor = viewInsightsStore((state) => state.peopleColor);
  const cultureColor = viewInsightsStore((state) => state.cultureColor);

  const token = localStorage.getItem("token");

  const inquiryId = viewInsightsStore((state) => state.inquiryId);

  const [{ data, loading, error }, executeGetFindingsResultUrl] = useAxios(
    GetFindingsResultUrl(inquiryId, token)
  );
  useEffect(async () => {
    await executeGetFindingsResultUrl();
  }, []);
  if (loading) {
    return <Loading />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={classes.container}>
        <Card className={classes.shadow}>
          <CardBody>
            <GridContainer className={classes.mr_0_pd_0}>
              <GridItem xs={3} sm={3} md={3}></GridItem>
              <GridItem xs={8} sm={8} md={8} className={classes.mar_L}>
                <h3 className={classes.Title}>Thematic Findings</h3>
              </GridItem>
            </GridContainer>
            {/* Culture */}
            {data.CultureArray && data.CultureArray.length > 0 && (
              <FindingResuable
                title="Culture"
                data={data.CultureArray}
                Color={cultureColor}
              />
            )}

            {/* People */}
            {data.PeopleArray && data.PeopleArray.length > 0 && (
              <FindingResuable
                title="People"
                data={data.PeopleArray}
                Color={peopleColor}
              />
            )}

            {/* Process */}
            {data.ProcessArray && data.ProcessArray.length > 0 && (
              <FindingResuable
                title="Process"
                data={data.ProcessArray}
                Color={processColor}
              />
            )}
            {/* Structure */}
            {data.StructureArray && data.StructureArray.length > 0 && (
              <FindingResuable
                title="Structure"
                data={data.StructureArray}
                Color={structureColor}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Findings;
