import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import UploaderDocPreview from "components/Uploaders/UploaderDocPreview";

const MultipleCustomFileUploader = ({
  file,
  onChange,
  onRemove,
  errorText,
  disabled,
  multiple = false,
}) => {
  let fileInput = React.createRef();

  const handleClick = () => {
    fileInput.current.value = null;
    fileInput.current.click();
  };
  const handleRemove = (name) => {
    onRemove(name);
    //fileInput.current.value = null;
  };

  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={onChange}
        ref={fileInput}
        multiple={multiple}
      />
      {file &&
        file.map((item, id) => {
          return (
            <UploaderDocPreview
              key={id}
              file={item}
              handleRemove={() => handleRemove(item.name)}
            />
          );
        })}
      {errorText && <div className="invalid-feedback">{errorText}</div>}
      <div>
        <span>
          {!disabled ? (
            <>
              <Button
                onClick={handleClick}
                size="sm"
                color="primary"
                disabled={disabled}
              >
                <i className="fa fa-cloud-upload-alt fa-lg" />
                {file.length > 0 ? "Add More" : "Upload"}
              </Button>
            </>
          ) : (
            <Button simple size="sm" color="primary" disabled={disabled}>
              Please click on the picture to download it.
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

export default MultipleCustomFileUploader;

MultipleCustomFileUploader.propTypes = {
  file: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};
