import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";

// MUI core components
import { makeStyles, RadioGroup } from "@material-ui/core";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import { ratingMatrixArray } from "constants/surveyFormConstants";
import CustomRadioButton from "components/RadioButtons/CustomRadioButton";
import { surveyFormStore } from "store";

const useStyles = makeStyles(styles);

const tableHeader = (classes, tableTitle) => (
  <>
    <GridItem xs={6} md={6} sm={6} className={classes.tableRowStyle}>
      <h4 className={classes.tableHeading}>Attribute Performance</h4>
      <GridContainer noDefaultSetting>
        {ratingMatrixArray.map((matrix, key) => {
          return (
            <GridItem xs={2} md={2} sm={2} key={key} noDefaultPadding>
              <p className={classes.tableSubHeading}>
                {matrix.label} = {matrix.value}
              </p>
            </GridItem>
          );
        })}
      </GridContainer>
    </GridItem>
    <GridItem xs={6} md={6} sm={6} className={classes.tableRowStyle}>
      <h4 className={classes.tableHeading}>
        Value/Importance of Attribute to Me
      </h4>
      <GridContainer noDefaultSetting>
        {ratingMatrixArray.map((matrix, key) => {
          return (
            <GridItem xs={2} md={2} sm={2} key={key} noDefaultPadding>
              <p className={classes.tableSubHeading}>
                {matrix.label} = {matrix.value}
              </p>{" "}
            </GridItem>
          );
        })}
      </GridContainer>
    </GridItem>
  </>
);

const TableRow = (props) => {
  const { classes, row, rowIndex, areaType } = props;

  // Calling States from Store
  const structureArray = surveyFormStore((state) => state.structureArray);
  const setStructureArray = surveyFormStore((state) => state.setStructureArray);

  const peopleArray = surveyFormStore((state) => state.peopleArray);
  const setPeopleArray = surveyFormStore((state) => state.setPeopleArray);

  const processArray = surveyFormStore((state) => state.processArray);
  const setProcessArray = surveyFormStore((state) => state.setProcessArray);

  const cultureArray = surveyFormStore((state) => state.cultureArray);
  const setCultureArray = surveyFormStore((state) => state.setCultureArray);

  const disabledFields = surveyFormStore((state) => state.disabledFields);

  const handleChangeRating = (areaType, value, index) => {
    value = isNaN(value) && value.toLowerCase() === "dk" ? "0" : value;
    if (areaType === "Structure") {
      const newArray = [...structureArray];
      newArray[index].Answer = value;
      setStructureArray(newArray);
    } else if (areaType === "People") {
      const newArray = [...peopleArray];
      newArray[index].Answer = value;
      setPeopleArray(newArray);
    } else if (areaType === "Process") {
      const newArray = [...processArray];
      newArray[index].Answer = value;
      setProcessArray(newArray);
    } else if (areaType === "Culture") {
      const newArray = [...cultureArray];
      newArray[index].Answer = value;
      setCultureArray(newArray);
    } else {
      console.log("areaType mismatched in handleChangeRating.");
    }
  };

  const handleChangeValue = (value, index) => {
    value = isNaN(value) && value.toLowerCase() === "dk" ? "0" : value;
    if (areaType === "Structure") {
      const newArray = [...structureArray];
      newArray[index].ValueImportanceAnswer = value;
      setStructureArray(newArray);
    } else if (areaType === "People") {
      const newArray = [...peopleArray];
      newArray[index].ValueImportanceAnswer = value;
      setPeopleArray(newArray);
    } else if (areaType === "Process") {
      const newArray = [...processArray];
      newArray[index].ValueImportanceAnswer = value;
      setProcessArray(newArray);
    } else if (areaType === "Culture") {
      const newArray = [...cultureArray];
      newArray[index].ValueImportanceAnswer = value;
      setCultureArray(newArray);
    } else {
      console.log("areaType mismatched in handleChangeRating.");
    }
  };

  return (
    <GridContainer>
      {/* // left column for area performance */}
      <GridItem xs={6} md={6} sm={6} className={classes.tableRowStyle}>
        <h4 className={classes.questionStyle}>{row.Description}</h4>
        <GridContainer className={classes.LeftratingOptions}>
          {row.RadioButtonOptions &&
            row.RadioButtonOptions.map((option, key) => {
              let checkedAnswer =
                areaType === "Structure"
                  ? structureArray &&
                    structureArray[rowIndex].Answer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : areaType === "Process"
                  ? processArray &&
                    processArray[rowIndex].Answer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : areaType === "People"
                  ? peopleArray &&
                    peopleArray[rowIndex].Answer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : areaType === "Culture"
                  ? cultureArray &&
                    cultureArray[rowIndex].Answer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : null;
              return (
                <RadioGroup key={key} name="customRadio">
                  <GridItem xs={2} md={2} sm={2} noDefaultPadding>
                    <CustomRadioButton
                      disabled={disabledFields}
                      size="small"
                      value={option}
                      checked={checkedAnswer}
                      onChange={(event) =>
                        handleChangeRating(
                          areaType,
                          event.target.value,
                          rowIndex
                        )
                      }
                      label={option.toUpperCase()}
                      customRadioClassname={classes.ratingButton}
                    />
                  </GridItem>
                </RadioGroup>
              );
            })}
        </GridContainer>
      </GridItem>
      {/* // right column for value/importance attribute */}
      <GridItem
        xs={6}
        md={6}
        sm={6}
        className={classes.tableRowStyle + " " + classes.justified}
      >
        <GridContainer className={classes.RightratingOptions}>
          {row.RadioButtonOptions &&
            row.RadioButtonOptions.map((option, key) => {
              let checkedValue =
                areaType === "Structure"
                  ? structureArray &&
                    structureArray[rowIndex].ValueImportanceAnswer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : areaType === "Process"
                  ? processArray &&
                    processArray[rowIndex].ValueImportanceAnswer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : areaType === "People"
                  ? peopleArray &&
                    peopleArray[rowIndex].ValueImportanceAnswer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : areaType === "Culture"
                  ? cultureArray &&
                    cultureArray[rowIndex].ValueImportanceAnswer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  : null;

              return (
                <RadioGroup key={key}>
                  <GridItem xs={2} md={2} sm={2} noDefaultPadding>
                    <CustomRadioButton
                      disabled={disabledFields}
                      size="small"
                      value={option}
                      label={option.toUpperCase()}
                      checked={checkedValue}
                      onChange={(event) =>
                        handleChangeValue(event.target.value, rowIndex)
                      }
                      customRadioClassname={classes.ratingButton}
                    />
                  </GridItem>
                </RadioGroup>
              );
            })}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

// below is the default export function
const DynamicSurveyFormTableAgainstAreas = ({ data, tableTitle, count }) => {
  const classes = useStyles();
  // declaring tableTitle as an areaType
  const areaType = tableTitle;
  return (
    <>
      <h4 className={classes.tableTitle}>
        {count}
        {". "}
        {tableTitle}
      </h4>
      <GridContainer className={classes.tableStyle}>
        {tableHeader(classes, tableTitle)}
        {data.map((row, key) => {
          return (
            <TableRow
              row={row}
              classes={classes}
              key={key}
              rowIndex={key}
              areaType={areaType}
            />
          );
        })}
      </GridContainer>
    </>
  );
};

export default DynamicSurveyFormTableAgainstAreas;

// declaring propTypes
TableRow.propTypes = {
  classes: PropTypes.string,
  row: PropTypes.object,
  rowIndex: PropTypes.number,
  areaType: PropTypes.string,
};

DynamicSurveyFormTableAgainstAreas.propTypes = {
  data: PropTypes.array,
  tableTitle: PropTypes.string,
  count: PropTypes.number,
};
