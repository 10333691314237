/* eslint-disable react/display-name */
import { Icon } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isEmpty } from "helpers/validations";
import PropTypes from "prop-types";
import React from "react";
import CustomInput from "components/Inputs/CustomInput";
import { signupStore } from "store";
import Loading from "components/Loading/Loading";
import { validateWebsiteUrl } from "helpers/validations";

const Step1 = React.forwardRef((props, ref) => {
  const fields = signupStore((state) => state.fields);
  const setFields = signupStore((state) => state.setFields);
  const setFieldsEmpty = signupStore((state) => state.setFieldsEmpty);
  const areFieldsLocked = signupStore((state) => state.areFieldsLocked);

  const isOrganizationDetailsLoading = signupStore(
    (state) => state.isOrganizationDetailsLoading
  );

  const { validateFields } = props;
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));
  React.useEffect(() => {
    setFieldsEmpty();
  }, []);
  const isValidated = () => {
    if (
      fields.step1.orgName.length === 0 ||
      fields.step1.industryType.length === 0 ||
      fields.step1.countryName.length === 0 ||
      fields.step1.cityName.length === 0 ||
      fields.step1.stateName.length === 0 ||
      validateWebsiteUrl(fields.step1.website) !== null
    ) {
      return false;
    }
    return true;
  };

  const handleValueChange = (event, fieldName) => {
    const newFields = { ...fields };
    newFields.step1[`${fieldName}`] = event.target.value;
    setFields(newFields);
  };
  return (
    <GridContainer>
      <GridItem xs={12} md={12} sm={12}>
        {isOrganizationDetailsLoading ? (
          <Loading loading={isOrganizationDetailsLoading} height="fixed" />
        ) : (
          <>
            {/* Organization Name */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              required
              component="form"
              leftIcon={<Icon>domain</Icon>}
              label="Organization Name"
              value={fields.step1.orgName}
              onChange={(event) => handleValueChange(event, "orgName")}
              errorText={validateFields && isEmpty(fields.step1.orgName)}
            />
            {/* INDUSTRY TYPE */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              required
              component="form"
              label="Industry Type"
              leftIcon={<Icon>corporate_fare</Icon>}
              value={fields.step1.industryType}
              onChange={(event) => handleValueChange(event, "industryType")}
              errorText={validateFields && isEmpty(fields.step1.industryType)}
            />
            {/* Website */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              required
              component="form"
              label="Website"
              leftIcon={<Icon>language</Icon>}
              value={fields.step1.website}
              onChange={(event) => handleValueChange(event, "website")}
              errorText={
                validateFields && validateWebsiteUrl(fields.step1.website)
              }
            />
            {/* Street Address */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              component="form"
              label="Street Address"
              leftIcon={<Icon>location_on</Icon>}
              value={fields.step1.streetAddress}
              onChange={(event) => handleValueChange(event, "streetAddress")}
            />
            {/* Country */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              required
              component="form"
              label="Country"
              leftIcon={<Icon>flag</Icon>}
              value={fields.step1.countryName}
              onChange={(event) => handleValueChange(event, "countryName")}
              errorText={validateFields && isEmpty(fields.step1.countryName)}
            />
            {/* State */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              required
              component="form"
              label="State"
              leftIcon={<Icon>public</Icon>}
              value={fields.step1.stateName}
              onChange={(event) => handleValueChange(event, "stateName")}
              errorText={validateFields && isEmpty(fields.step1.stateName)}
            />
            {/* City */}
            <CustomInput
              disabled={areFieldsLocked}
              inputType="text"
              component="form"
              required
              label="City"
              value={fields.step1.cityName}
              leftIcon={<Icon>location_city</Icon>}
              onChange={(event) => handleValueChange(event, "cityName")}
              errorText={validateFields && isEmpty(fields.step1.cityName)}
            />
          </>
        )}
      </GridItem>
    </GridContainer>
  );
});

export default Step1;

Step1.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func,
  validateFields: PropTypes.object,
  setValidateFields: PropTypes.func,
};
