import React from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import classNames from "classnames";

const useStyle = makeStyles(styles);

const FormContainer = (props) => {
  const { children, height } = props;
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem
        xs={12}
        className={
          height
            ? classNames(classes.FormContainer, classes.formFixedHeight)
            : classes.form
        }
      >
        {children}
      </GridItem>
    </GridContainer>
  );
};

export default FormContainer;

FormContainer.propTypes = {
  children: PropTypes.node,
  height: PropTypes.bool,
};
