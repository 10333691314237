import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { GetDiagnosticQuestionUrl } from "axios/adminAPIsUrls";
import DiagnosticsQuestionFields from "views/Common/diagnosticsQuestion/DiagnosticsQuestionFields";
import authStore from "store/authStore";
import jwtDecode from "jwt-decode";

const ViewDiagnosticQuestion = () => {
  const history = useHistory();
  const userToken = authStore((state) => state.userToken);
  let { inquiryParamId, pathwayParamId, diagnosticParamId } = useParams();

  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    Area: "",
    MaxLength: "",
    ValidationRegex: "",
    RadioButtonOptions: [],
    CheckBoxOptions: [],
  });

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteGetDiagnosticQuestionUrl] = useAxios(
    GetDiagnosticQuestionUrl(diagnosticParamId, token)
  );

  useEffect(() => {
    ExecuteGetDiagnosticQuestionUrl();
  }, []);

  useEffect(() => {
    if (data) {
      setFields({
        Title: data.Title,
        Type: data.Type,
        Description: data.Description,
        Area: data.Area,
        MaxLength: data.MaxLength,
        RadioButtonOptions: data.RadioButtonOptions,
        CheckBoxOptions: data.CheckBoxOptions,
        ValidationRegex: data.ValidationRegex,
      });
    }
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  const prerequisiteValue = {
    title: "View Diagnostic Question",
    goBackLink:
      userToken && jwtDecode(userToken).role === "CustomerAdmin"
        ? `/customerAdmin/inquiry-type-management/${inquiryParamId}/pathways/${pathwayParamId}/diagnostic-questions`
        : `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways/${pathwayParamId}/diagnostic-questions`,
    disabled: true,
  };

  return (
    <DiagnosticsQuestionFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
    />
  );
};

export default ViewDiagnosticQuestion;
