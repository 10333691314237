import * as React from "react";

const SvgReviewIntakeQuestionsIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.714 21.797a1.94 1.94 0 1 0 0-3.879 1.94 1.94 0 0 0 0 3.879Z"
      fill="#D65746"
      stroke="#D65746"
      strokeWidth={0.45}
    />
    <path
      d="M14.712 23.061a3.208 3.208 0 0 0 3.204-3.203l-3.204 3.203Zm0 0a3.203 3.203 0 1 1 3.204-3.204l-3.204 3.204Zm.008-8.571h-.014a7.63 7.63 0 0 0-6.868 4.836l-.19.443-.037.089.038.088.19.444a7.63 7.63 0 0 0 6.867 4.835h.014a7.631 7.631 0 0 0 6.867-4.835l.19-.444.037-.088-.037-.09-.19-.442a7.63 7.63 0 0 0-6.867-4.836Z"
      fill="#D65746"
      stroke="#D65746"
      strokeWidth={0.45}
    />
    <path
      d="M6.368 23.286v-.225H2.939V2.94h6.407v4.918a1.944 1.944 0 0 0 1.94 1.939h4.918v3.428h2.164V7.86a1.005 1.005 0 0 0-.327-.765l-5.992-5.993a1.004 1.004 0 0 0-.766-.327H2.714A1.944 1.944 0 0 0 .775 2.714v20.572a1.944 1.944 0 0 0 1.939 1.94h3.654v-1.94ZM11.51 7.632V3.601l4.032 4.031H11.51Z"
      fill="#D65746"
      stroke="#D65746"
      strokeWidth={0.45}
    />
  </svg>
);

export default SvgReviewIntakeQuestionsIcon;
