import React from "react";
import PropTypes from "prop-types";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

const AlertModal = (props) => {
  const classes = useStyles();

  const { alert, setAlert, onDelete, onCancel } = props;

  if (alert) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={3}>
          <SweetAlert
            danger
            title="Are you sure?"
            onConfirm={onDelete}
            onCancel={onCancel}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          >
            Are you sure you want to delete this record?
          </SweetAlert>
        </GridItem>
      </GridContainer>
    );
  }

  return null;
};

export default AlertModal;

AlertModal.propTypes = {
  alert: PropTypes.bool,
  setAlert: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};
