import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import FormContainer from "components/Forms/FormContainer";
import CustomFormSelect from "components/Selects/CustomSelect";
import CustomInput from "components/Inputs/CustomInput";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import { GetRecommendationUrl } from "axios/adminAPIsUrls";

const useStyles = makeStyles(styles);
const ViewRecommendation = () => {
  const history = useHistory();
  const classes = useStyles();
  let { RecommendationsParamId } = useParams();

  const [fields, setFields] = useState({
    InquiryTypeId: "",
    RecommendationDetail: "",
    InquiryTypeName: "",
  });

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteGetRecommendationUrl] = useAxios(
    GetRecommendationUrl(RecommendationsParamId, token)
  );
  useEffect(() => {
    ExecuteGetRecommendationUrl();
  }, []);
  useEffect(() => {
    if (data) {
      setFields({
        InquiryTypeName: data.InquiryTypeName,
        RecommendationDetail: data.RecommendationDetail,
      });
    }
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Recommendations"
              showBackButton
              goBackRoute={`/superAdmin/manage-recommendations`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer height>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  disabled
                  label="Inquiry Type"
                  value={fields.InquiryTypeName}
                  menu={[
                    {
                      id: fields.InquiryTypeName,
                      label: fields.InquiryTypeName,
                    },
                  ]}
                  customClassName={classes.textFieldSelectStyling}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Recommendation"
                  multiline
                  disabled
                  value={fields.RecommendationDetail}
                  customClassName={classes.textFieldSelectStyling}
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewRecommendation;
