import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { GetCustomerAdminsUrl } from "axios/adminAPIsUrls";
import { EditCustomerAdminUrl } from "axios/adminAPIsUrls";
import ManageCustomerFields from "views/Common/profileManageCustomers/ManageCustomerFields";
import { isEmpty } from "helpers/validations";
import { isNumber } from "helpers/validations";

const EditCustomerAdmin = () => {
  const history = useHistory();
  let { CustomerAdminParamId } = useParams();
  // THIS STATE WILL PERFORM VALIDATION ON SUBMIT BUTTON CLICK
  const [areFieldsValidated, setFieldsValidated] = useState(false);
  const [fieldsValidation, setFieldsValidation] = useState({
    CustomerName: false,
    Industry: false,
    Address: false,
    State: false,
    City: false,
    Country: false,
    Website: false,
    YearsInBusiness: false,
    ProfitOrNon: false,
    OwnershipStructure: false,
    AnnualRevenue: false,
    AverageFTE: false,
    NumberOfLocations: false,
    HeadquarterAddress: false,
    IsActive: false,
  });

  const [fields, setFields] = useState({
    CustomerId: "",
    CustomerName: "",
    Industry: "",
    Address: "",
    State: "",
    City: "",
    Country: "",
    Website: "",
    YearsInBusiness: "",
    Duration: "days",
    ProfitOrNon: "",
    OwnershipStructure: "",
    AnnualRevenue: "",
    AverageFTE: "",
    NumberOfLocations: "",
    HeadquarterAddress: "",
    IsActive: "",
  });

  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
  };

  const token = localStorage.getItem("token");
  const [
    { data: dataCustomer, loading: loadingCustomer, error: errorCustomer },
    ExecuteGetCustomerAdminsUrl,
  ] = useAxios(GetCustomerAdminsUrl(CustomerAdminParamId, token));

  useEffect(() => {
    ExecuteGetCustomerAdminsUrl();
  }, []);

  useEffect(() => {
    if (dataCustomer) {
      setFields({
        CustomerId: dataCustomer.CustomerId,
        CustomerName: dataCustomer.CustomerName,
        Industry: dataCustomer.Industry,
        Address: dataCustomer.Address,
        State: dataCustomer.State,
        City: dataCustomer.City,
        Country: dataCustomer.Country,
        Website: dataCustomer.Website,
        YearsInBusiness: dataCustomer.YearsInBusiness,
        Duration: dataCustomer.Duration,
        ProfitOrNon:
          dataCustomer.ProfitOrNon === null ? "" : dataCustomer.ProfitOrNon,
        OwnershipStructure:
          dataCustomer.OwnershipStructure === null
            ? ""
            : dataCustomer.OwnershipStructure,
        AnnualRevenue: dataCustomer.AnnualRevenue,
        AverageFTE: dataCustomer.AverageFTE,
        NumberOfLocations: dataCustomer.NumberOfLocations,
        HeadquarterAddress:
          dataCustomer.HeadquarterAddress === null
            ? ""
            : dataCustomer.HeadquarterAddress,
        IsActive: dataCustomer.IsActive,
      });
    }
  }, [dataCustomer]);

  const [{ loading, error }, ExecuteEditCustomerAdminUrl] = useAxios(
    EditCustomerAdminUrl(CustomerAdminParamId, token),
    {
      manual: true,
    }
  );
  const allFieldsValidated = () => {
    if (isNumber(fields.AnnualRevenue)) {
      return false;
    }
    if (isNumber(fields.AverageFTE)) {
      return false;
    }
    if (isEmpty(fields.City)) {
      return false;
    }
    if (isEmpty(fields.Country)) {
      return false;
    }
    if (isEmpty(fields.CustomerName)) {
      return false;
    }
    if (isEmpty(fields.HeadquarterAddress)) {
      return false;
    }
    if (isEmpty(fields.Industry)) {
      return false;
    }
    if (isNumber(fields.NumberOfLocations)) {
      return false;
    }
    if (isEmpty(fields.OwnershipStructure)) {
      return false;
    }
    if (isEmpty(fields.ProfitOrNon)) {
      return false;
    }
    if (isEmpty(fields.Website)) {
      return false;
    }
    if (isNumber(fields.YearsInBusiness)) {
      return false;
    }
    if (isEmpty(fields.Duration)) {
      return false;
    }

    return true;
  };

  const onUpdateCustomer = async (data = fields) => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      try {
        const res = await ExecuteEditCustomerAdminUrl({
          data,
        });
        NotificationManager.success("Customer Admin Edited", 5000);
        history.push(`/superAdmin/manage-customers-profile`);
      } catch (exception) {
        console.log("Error in Creating New Pathway", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (loading || loadingCustomer) {
    return <Loading loading />;
  }

  if (errorCustomer) {
    apiErrorHandler(errorCustomer, history);
  }

  const prerequisiteValue = {
    title: "Edit Profile",
    goBackLink: `/superAdmin/manage-customers-profile`,
    btnText: "Save", // Btn One
    onClick: function () {
      onUpdateCustomer();
    },
  };

  return (
    <ManageCustomerFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
    />
  );
};

export default EditCustomerAdmin;
