// GET ALL INQUIRIES FOR SUPER ADMIN
export function GetAllInquiriesByAdminUrl(currentPage, pageSize, token) {
  return {
    url: `/Inquiry/Admin?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// GET ALL INQUIRY TYPES
export function GetAllInquiryTypesUrl(currentPage, pageSize, token) {
  return {
    url: `/InquiryType?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// GET INQUIRY BY ID
export function GetInquiryTypeUrl(id, token) {
  return {
    url: `InquiryType/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// create an inquiry type
export function CreateNewInquiryTypeUrl(token) {
  return {
    url: `/InquiryType/CreateInquiryType`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// update an inquiry type
export function EditInquiryTypeUrl(id, token) {
  return {
    url: `/InquiryType/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// DELETE AN INQUIRY TYPE
export function DeleteInquiryTypeUrl(id, token) {
  return {
    url: `/InquiryType/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get All Pathways against an Inquiry Type
export function GetAllPathwaysUrl(inquiryId, pageSize, currentPage, token) {
  return {
    url: `/Pathway/${inquiryId}/AllPathways?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get Pathway by Id
export function PathwayByIdUrl(id, token) {
  return {
    url: `/Pathway/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Update Pathway by Id
export function UpdatePathwayUrl(InquiryTypeId, id, token) {
  return {
    url: `/Pathway/${InquiryTypeId}/Update/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Delete Pathway by Id
export function DeletePathwayUrl(id, token) {
  return {
    url: `/Pathway/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Create Pathways Api
export function CreatePathwaysUrl(inquiryId, token) {
  return {
    url: `/Pathway/${inquiryId}/CreatePathway`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };
}

// Get All Intake-Question Api
export function GetAllIntakeQuestionsUrl(
  inquiryId,
  pageSize,
  currentPage,
  token
) {
  return {
    url: `/IntakeQuestion/${inquiryId}/AllIntakeQuestions?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Create Intake Question Api
export function CreateIntakeQuestionUrl(inquiryId, token) {
  return {
    url: `/IntakeQuestion/${inquiryId}/CreateIntakeQuestion`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };
}
export function UpdateIntakeQuestionUrl(inquiryId, id, token) {
  return {
    url: `/IntakeQuestion/${inquiryId}/Update/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "application/json",
    },
  };
}

export function IntakeQuestionByIdUrl(id, token) {
  return {
    url: `/IntakeQuestion/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Delete Pathway by Id
export function DeleteIntakeQuestionUrl(id, token) {
  return {
    url: `/IntakeQuestion/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get All Diagnostic Api
export function GetAllDiagnosticQuestionsUrl(
  PathwayId,
  pageSize,
  currentPage,
  token
) {
  return {
    url: `/DiagnosticQuestion/${PathwayId}/AllDiagnosticQuestions?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Post Diagnostic Question
export function CreateDiagnosticQuestionUrl(pathwayId, token) {
  return {
    url: `/DiagnosticQuestion/${pathwayId}/CreateDiagnosticQuestion`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Put Diagnostic Question
export function UpdateDiagnosticQuestionUrl(pathwayId, diagnosticId, token) {
  return {
    url: `/DiagnosticQuestion/${pathwayId}/Update/${diagnosticId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Get By Id Diagnostic Api
export function GetDiagnosticQuestionUrl(diagnosticId, token) {
  return {
    url: `/DiagnosticQuestion/${diagnosticId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Delete By Id Diagnostic Api
export function DeleteDiagnosticQuestionUrl(diagnosticId, token) {
  return {
    url: `/DiagnosticQuestion/${diagnosticId}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// GET ALL CUSTOMER ADMINS
export function GetAllCustomerAdminsUrl(currentPage, pageSize, token) {
  return {
    url: `/Customer?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// GET CUSTOMER ADMINS BY CUSTOMER ID
export function GetCustomerAdminsUrl(id, token) {
  return {
    url: `/Customer/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// EDIT CUSTOMER ADMIN BY CUSTOMER ID
export function EditCustomerAdminUrl(id, token) {
  return {
    url: `/Customer/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// DELETE CUSTOMER ADMIN BY CUSTOMER ID
export function DeleteCustomerAdminUrl(id, token) {
  return {
    url: `/Customer/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get All Findings
export function GetAllFindingsUrl(currentPage, pageSize, token) {
  return {
    url: `/Findings/AllFindings?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get Findings By InquiryId
export function GetFindingUrl(id, token) {
  return {
    url: `/Findings/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Add Finding against an Inquiry
export function CreateFindingUrl(token) {
  return {
    url: `/Findings/AddFindings`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Edit Finding against an Inquiry
export function EditFindingUrl(id, token) {
  return {
    url: `/Findings/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Delete Finding against an Inquiry
export function DeleteFindingUrl(id, token) {
  return {
    url: `/Findings/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
//

// Get All Recommendations
export function GetAllRecommendationsUrl(currentPage, pageSize, token) {
  return {
    url: `/Recommendations/AllRecommendations?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get Recommendation By InquiryId
export function GetRecommendationUrl(id, token) {
  return {
    url: `/Recommendations/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Add Recommendation against an Inquiry
export function CreateRecommendationUrl(token) {
  return {
    url: `/Recommendations/AddRecommendations`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Edit Recommendation against an Inquiry
export function EditRecommendationUrl(id, token) {
  return {
    url: `/Recommendations/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Delete Recommendation against an Inquiry
export function DeleteRecommendationUrl(id, token) {
  return {
    url: `/Recommendations/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
//

// Get All RoadMap
export function GetAllRoadMapUrl(currentPage, pageSize, token) {
  return {
    url: `/Roadmap/AllRoadmaps?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get RoadMap By InquiryId
export function GetRoadmapByIdUrl(id, token) {
  return {
    url: `/Roadmap/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Add RoadMap against an Inquiry
export function CreateRoadmapUrl(token) {
  return {
    url: `/Roadmap/AddRoadmap`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Edit RoadMap against an Inquiry
export function EditRoadmapUrl(id, token) {
  return {
    url: `/Roadmap/${id}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// Delete RoadMap against an Inquiry
export function DeleteRoadMapUrl(id, token) {
  return {
    url: `/Roadmap/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
//
// getting all custom questions against all inquiries
export function GetAllCustomQuestions(currentPage, pageSize, token) {
  return {
    url: `/CustomQuestion/AllCustomQuestions?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// accept / reject custom questions
export function ChangeStatusCustomQuestionUrl(CustomerQuestionId, token) {
  return {
    url: `/CustomQuestion/ChangeStatusCustomQuestion/${CustomerQuestionId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting all inquiries against all inquiry types
export function GetAllInquirySuperAdmin(currentPage, pageSize, token) {
  return {
    url: `/Inquiry/Admin?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting all customer user against a customer profile
export function GetAllCustomerUserUrl(
  CustomerId,
  currentPage,
  pageSize,
  token
) {
  return {
    url: `/CustomerUser/GetAll/${CustomerId}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting a customer user against a customer profile ID
export function GetCustomerUserUrl(CustomerId, token) {
  return {
    url: `/CustomerUser/${CustomerId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// deleting a customer user against a customer profile
export function DeleteCustomerUserUrl(id, token) {
  return {
    url: `/CustomerUser/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// updating a customer user against a customer profile
export function EditCustomerUserUrl(CustomerId, token) {
  return {
    url: `/CustomerUser/${CustomerId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Validating Duplicate Filename for ML Excel sheet
export function ValidateExcelFileName(token) {
  return {
    url: "/UploadResultDocument/DocumentValidate",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    },
  };
}

// Uploading Document for ML Excel sheet
export function UploadExcelFileUrl(token, inquiryId) {
  return {
    url: `/UploadResultDocument/UploadDocumentResults/${inquiryId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    },
  };
}

//upload help document multiuploader
export function UploadHelpDocument(token, inquiryId) {
  return {
    url: `/Inquiry/UploadHelpDocument/${inquiryId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    },
  };
}

// Get OpenEnded Answers from survey form
export function GetOpenEndedAnswersUrl(inquiryId, customerUserId, token) {
  return {
    url: `SurveyAnswer/${inquiryId}/ViewOpenEndedSurveyAnswers/${customerUserId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get OpenEnded Answers from survey form
export function GetRespondantsDocuments(
  inquiryId,
  customerUserId,
  currentPage,
  pageSize,
  token
) {
  return {
    url: `/Inquiry/UserDocuments/${inquiryId}/${customerUserId}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

//Geting view Intake response data
// Get OpenEnded Answers from survey form
export function GetIntakeResponse(inquiryId, token) {
  return {
    url: `/Inquiry/GetIntakeAnswers/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// view insights / get input result for portal admin and customer admin both now
export function GetInputResultForPortalAdmin(inquiryId, token) {
  return {
    url: `/UploadResultDocument/InputResultForPortalAdmin/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
