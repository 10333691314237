import { primaryColor } from "assets/jss/material-dashboard-pro-react";

export const BASE_URL_ALPHA = "https://activate-alpha.azurewebsites.net/api";

export const BASE_URL_BETA = "https://activate-beta.azurewebsites.net/api";

export const BASE_URL_MASTER = "https://activate-master.azurewebsites.net/api";

export const BASE_URL_PROD = "https://activate.azurewebsites.net/api";

export const AREA_TYPES = [
  {
    id: "Culture",
    label: "Culture",
  },
  {
    id: "People",
    label: "People",
  },

  {
    id: "Process",
    label: "Process",
  },
  { id: "Structure", label: "Structure" },

  {
    id: "Other",
    label: "Other",
  },
];

export const AREA_COLORS = [
  { red: "#D65746" },
  { green: "#4B9F6D" },
  { blue: "#A7BED0" },
  { yellow: "#FFE600" },
];

export const ROADMAP_COLORS = ["#c96b00", "#e08a28", "#EAA353"];

export const INPUT_TYPES = [
  {
    id: "Textbox",
    label: "Textbox",
  },
  {
    id: "Checkbox",
    label: "Checkbox",
  },
  {
    id: "Rating",
    label: "Rating",
  },
  {
    id: "Document",
    label: "Document",
  },
];

export const INPUT_VALIDATIONS = [
  {
    id: "Alphabet only",
    label: "Alphabet only",
  },
  {
    id: "Number(s) only",
    label: "Number(s) only",
  },
  {
    id: "Alphanumeric only",
    label: "Alphanumeric only",
  },
];

// Duration Array
export const TIME_DURATION = ["years", "months", "days"];

// view insights constants
export const AVERAGE_RATING_COLOR = "#B19C7D";
export const AVERAGE_DIFFERENCE_COLOR = "#D65746";
export const AVERAGE_VALUE_COLOR = "#EAA353";
export const HORIZONTAL_BAR_WIDTH = 20;
export const HORIZONTAL_BAR_BORDER_WIDTH = 1;
