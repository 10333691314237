import React from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";
import { makeStyles } from "@material-ui/core";

const styles = {
  divider: {
    height: 2,
    margin: "0px 15px",
  },
  title: { fontSize: "1.25rem", fontWeight: "600", color: "black" },
};

const StepDivider = ({ color, classes }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
    }}
    className={classes.divider}
  />
);

const useStyles = makeStyles(styles);

const RoadmapStep = ({
  stepNumber,
  stepTitle,
  stepDescription,
  stepDetail,
  handleInput,
  shouldValidateFields,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem>
          <h2 className={classes.title}>Roadmap Step # 0{stepNumber}</h2>
        </GridItem>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              inputType="text"
              required
              label={`Step 0${stepNumber} Title`}
              value={stepTitle}
              disabled={disabled}
              onChange={(event) =>
                handleInput(
                  `Step${stepNumber}_RoadmapTitle`,
                  event.target.value
                )
              }
              errorText={shouldValidateFields && isEmpty(stepTitle)}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              inputType="text"
              required
              disabled={disabled}
              label={`Step 0${stepNumber} Description`}
              value={stepDescription}
              onChange={(event) =>
                handleInput(
                  `Step${stepNumber}_BriefDescription`,
                  event.target.value
                )
              }
              multiline
              errorText={shouldValidateFields && isEmpty(stepDescription)}
              rows={2}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              inputType="text"
              required
              value={stepDetail}
              disabled={disabled}
              label={`Step 0${stepNumber} Detail`}
              onChange={(event) =>
                handleInput(
                  `Step${stepNumber}_RoadmapDetail`,
                  event.target.value
                )
              }
              multiline
              rows={4}
              errorText={shouldValidateFields && isEmpty(stepDetail)}
            />
          </GridItem>
        </GridContainer>
      </GridContainer>
      <StepDivider classes={classes} />
    </>
  );
};

export default RoadmapStep;

RoadmapStep.defaultProps = {
  stepTitle: "Roadmap Title",
  stepDescription: "Roadmap Description",
  stepDetail: "Roadmap Detail",
  stepNumber: 0,
  handleInput: () => alert("This is the Roadmap Step handle Input"),
  shouldValidateFields: false,
  disabled: false,
};

RoadmapStep.propTypes = {
  stepTitle: PropTypes.string,
  stepDescription: PropTypes.string,
  stepDetail: PropTypes.string,
  stepNumber: PropTypes.number,
  handleInput: PropTypes.func,
  shouldValidateFields: PropTypes.bool,
  disabled: PropTypes.bool,
};

StepDivider.defaultProps = {
  color: "#DFDFDF",
};

StepDivider.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};
