import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import SimpleProgressbar from "components/Progressbars/SimpleProgressbar";
import msWordSvg from "assets/img/svgs/ms-word.svg";
import msExcelSvg from "assets/img/svgs/ms-excel.svg";
import PdfSvg from "assets/img/svgs/PDF_file.svg";
import ZipSvg from "assets/img/svgs/Zip_file.svg";
import {
  excelFileFormats,
  docsFileFormats,
  PdfFileFormats,
  ZipFileFormats,
} from "components/Uploaders/fileFormats";
import styles from "assets/jss/material-dashboard-pro-react/components/uploaderDocPreviewStyle";

const useStyles = makeStyles(styles);

const DocumentPreview = ({ fileDownloadLink, fileName }) => {
  const classes = useStyles();

  const [completed, setCompleted] = React.useState(0);

  const [fileIcon, setFileIcon] = React.useState(null);

  React.useEffect(() => {
    if (completed !== 100) {
      setTimeout(() => {
        setCompleted(completed + 50);
      }, 1000);
    }
    // CHANGING FILE ICON DEPENDING ON FILE INPUT
    const fileType = fileName && fileName.split(".")[1];
    if (excelFileFormats.indexOf("." + fileType) >= 0) {
      setFileIcon(msExcelSvg);
    } else if (docsFileFormats.indexOf("." + fileType) >= 0) {
      setFileIcon(msWordSvg);
    } else if (PdfFileFormats.indexOf("." + fileType) >= 0) {
      setFileIcon(PdfSvg);
    } else if (ZipFileFormats.indexOf("." + fileType) >= 0) {
      setFileIcon(ZipSvg);
    } else {
      setFileIcon(fileDownloadLink);
    }
  }, [completed]);
  return (
    <a href={fileDownloadLink} target="_blank" rel="noopener noreferrer">
      <GridItem
        xs={12}
        sm={12}
        md={12}
        noDefaultPadding
        className={classes.root}
      >
        {completed === 100 ? (
          <GridContainer className={classes.filePreviewContainer}>
            <GridItem noDefaultPadding md={2} sm={2} xs={2}>
              <img
                src={fileIcon}
                alt={fileName}
                className={classes.filePreview}
              />
            </GridItem>
            <GridItem noDefaultPadding md={10} sm={10} xs={10}>
              <h5 className={classes.fileName}>{fileName}</h5>
            </GridItem>
          </GridContainer>
        ) : (
          <SimpleProgressbar completed={completed} showPercentage={false} />
        )}
      </GridItem>
    </a>
  );
};

export default DocumentPreview;

DocumentPreview.propTypes = {
  fileName: PropTypes.string,
  fileDownloadLink: PropTypes.string,
};
