import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import React from "react";
import useAxios from "axios/httpService";
import { CreateInquiryUrl } from "axios/customerAdminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import CustomFormSelect from "components/Selects/CustomSelect";
import useInquiryTypes from "hooks/useInquiryTypes";

const useStyles = makeStyles(styles);

const CreateInquiryType = () => {
  const history = useHistory();

  const classes = useStyles();

  const [areFieldsValidated, setValidateFields] = React.useState(false);

  const [fields, setFields] = React.useState({
    InquiryTypeId: "",
    Description: "",
  });

  const token = localStorage.getItem("token");

  const [
    {
      data: createInquiryResponse,
      loading: isCreateInqLoading,
      error: errorInCreateInq,
    },
    ExecuteCreateInquiry,
  ] = useAxios(
    CreateInquiryUrl(token), // change it
    { manual: true }
  );

  const allFieldsValidated = () => {
    if (isEmpty(fields.InquiryTypeId)) {
      return false;
    }
    if (isEmpty(fields.Description)) {
      return false;
    }
    return true;
  };

  const onCreateInquiry = async () => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      try {
        const response = await ExecuteCreateInquiry({ data: fields });
        if (response.status === 200) {
          NotificationManager.success("Created New Inquiry ", 5000);

          history.push(
            `/customerAdmin/inquiry-logs/inquiry-types/${response.data.InquiryTypeId}/inquiry/${response.data.Id}`
          );
        }
      } catch (exception) {
        console.log("Error in Creating New Inquiry ", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  const { loading, inquiryTypes, error } = useInquiryTypes();

  if (loading || isCreateInqLoading) {
    return <Loading loading />;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Add New Inquiries"
              btnText="Next"
              showBackButton
              onClick={() => onCreateInquiry()}
              goBackRoute="/customerAdmin/inquiry-logs"
            />
          </CardHeader>
          <CardBody>
            <FormContainer height>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Inquiry Type"
                  value={fields.InquiryTypeId}
                  menu={inquiryTypes && inquiryTypes}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      InquiryTypeId: parseInt(event.target.value),
                    });
                  }}
                  errorText={
                    areFieldsValidated && isEmpty(fields.InquiryTypeId)
                  }
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Inquiry"
                  multiline
                  value={fields.Description}
                  customClassName={classes.textFieldStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Description: event.target.value,
                    });
                  }}
                  errorText={areFieldsValidated && isEmpty(fields.Description)}
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateInquiryType;
