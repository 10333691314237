import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import { makeStyles, Slide } from "@material-ui/core";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/modalStyle";

const useStyle = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Modal = ({ modalTitle, modalBody, modalActions }) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalBorder,
          }}
          open
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            {modalTitle}
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            {modalBody}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            {modalActions}
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
};

export default Modal;

Modal.propTypes = {
  modalTitle: PropTypes.func,
  modalBody: PropTypes.func,
  modalActions: PropTypes.func,
};
