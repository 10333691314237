import React from "react";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { handleCloseProfile } from "helpers/authHelpers";
import authStore from "store/authStore";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { LogoutRound } from "assets/img/activateImagesComponents";
import activateLogo from "assets/img/activateImages/activate-logo.png";
import PersonOutlineOutlined from "@material-ui/icons/PersonOutlineOutlined";
// importing useHistory from react-router-dom
import { useHistory } from "react-router";
import { PersonRound } from "assets/img/activateImagesComponents";

const useStyles = makeStyles(styles);

export default function CustomerUserSidebar() {
  const loggedInName = authStore((state) => state.loggedInName);
  const classes = useStyles();
  const logoNormal = classes.logoNormal;
  const logoClasses = classes.logo;
  const [openProfile, setOpenProfile] = React.useState(null);
  const setAuthenticated = authStore((state) => state.setAuthenticated);
  const history = useHistory();
  return (
    <MenuList
      style={{ display: "flex", flexDirection: "column", flexWrap: "flex" }}
      role="menu"
    >
      <MenuItem
        style={{
          color: "inherit",
          margin: "10px",
          justifyContent: "space-evenly",
          display: "flex",
          flexWrap: "flex",
        }}
      >
        <div className={logoClasses}>
          <a href="#" rel="noreferrer">
            <img src={activateLogo} alt="logo" width={100} />
          </a>
          <a href="#" className={logoNormal} rel="noreferrer"></a>
        </div>
      </MenuItem>
      <MenuItem
        style={{
          color: "inherit",
          margin: "10px",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexWrap: "flex",
        }}
      >
        <PersonOutlineOutlined />
        <h5 style={{ fontWeight: "600" }}>{loggedInName}</h5>
      </MenuItem>
      <MenuItem
        style={{
          color: "inherit",
          margin: "10px",
          justifyContent: "flex-start",
        }}
        onClick={() => {
          setOpenProfile(null);
          history.push("/customerUser/manage-profile");
        }}
      >
        <span className={classes.menuItem}>
          <PersonRound style={{ fontSize: "30px" }} />
        </span>
        <h4 style={{ fontWeight: "500", marginLeft: "10px" }}>
          Manage Profile
        </h4>
      </MenuItem>
      <MenuItem
        style={{
          color: "inherit",
          margin: "10px",
          justifyContent: "flex-start",
        }}
        onClick={() =>
          handleCloseProfile(history, setOpenProfile, setAuthenticated)
        }
      >
        <span
          style={{
            width: "32px",
          }}
        >
          <LogoutRound style={{ fontSize: "30px" }} />
        </span>
        <h4 style={{ fontWeight: "500", marginLeft: "10px" }}>Log out</h4>
      </MenuItem>
    </MenuList>
  );
}
