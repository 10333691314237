import * as React from "react";
import Slider from "@material-ui//core/Slider";
import { styled } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#bfbfbf" : "#d65746",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#d65746",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 15,
    fontWeight: "normal",
    top: -21,
    backgroundColor: "unset",
    alignItems: "end",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: "8px",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    height: "8px",
    backgroundColor: "grey",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 12,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 0,
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
  },
];

const CustomSlider = ({ defaultvalue }) => {
  return (
    <Box sx={{ width: 320 }}>
      <Box sx={{ m: 2 }} />
      <IOSSlider
        aria-label="ios slider"
        disabled
        defaultValue={defaultvalue}
        marks={marks}
        valueLabelDisplay="on"
        min={0}
        max={5}
      />
    </Box>
  );
};
export default CustomSlider;

CustomSlider.propTypes = {
  defaultvalue: PropTypes.number,
  minvalue: PropTypes.number,
  maxvalue: PropTypes.number,
};
