import React from "react";
import Modal from "components/Modals/Modal";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomFileUploader from "components/Uploaders/CustomFileUploader";
import PropTypes from "prop-types";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/views/fileUploadModalStyle";
import { makeStyles } from "@material-ui/core";
import useAxios from "axios/httpService";
import { ValidateExcelFileName } from "axios/adminAPIsUrls";
import { validateExcelFiles } from "helpers/validations";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "components/common/ReactNotifications";
import ButtonLoading from "components/Loading/ButtonLoading";
import { UploadExcelFileUrl } from "axios/adminAPIsUrls";
import ConfirmationAlert from "./ConfirmationAlert";

const modalTitle = (classes) => (
  <GridItem
    xs={12}
    md={12}
    sm={12}
    noDefaultPadding
    className={classes.modalTitle}
  >
    <h4 className={classes.modalTitleText}>File Upload</h4>
  </GridItem>
);

const modalBody = (
  classes,
  setModalOpen,
  isModalOpen,
  document,
  validationError,
  handleValidateFileInput,
  handleRemoveDocument,
  showAlert,
  setShowAlert
) => {
  if (showAlert) {
    return (
      <ConfirmationAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        setModalOpen={setModalOpen}
      />
    );
  }

  return (
    <GridItem
      xs={12}
      md={12}
      sm={12}
      noDefaultPadding
      className={classes.modalBody}
    >
      <h5 className={classes.modalBodyText}>
        Please upload an excel sheet file. File only in excel formats are
        accepted.
      </h5>

      <CustomFileUploader
        file={document}
        onRemove={(event) => handleRemoveDocument(event)}
        onChange={async (event) => {
          await handleValidateFileInput(event.target.files[0]);
        }}
        errorText={
          (document && validateExcelFiles(document)) || validationError
        }
      />
    </GridItem>
  );
};

const modalActions = (
  classes,
  setModalOpen,
  isUploadBtnDisabled,
  handleUploadDocument,
  loading,
  showAlert,
  setShowAlert
) => (
  <GridItem
    xs={12}
    md={12}
    sm={12}
    noDefaultPadding
    className={classes.modalActions}
  >
    <Button color="primary" size="sm" round onClick={() => setModalOpen(false)}>
      <i className={`fa fa-lg fa-times-circle ${classes.btnIcon}`}></i>
      {showAlert ? "No" : "Cancel"}
    </Button>
    {showAlert ? (
      <Button
        color="primary"
        size="sm"
        round
        disabled={isUploadBtnDisabled}
        onClick={() => setShowAlert(!showAlert)}
      >
        <i className={`fa fa-lg fa-check-circle ${classes.btnIcon}`}></i>
        {"Yes"}
      </Button>
    ) : (
      <Button
        color="primary"
        size="sm"
        round
        disabled={isUploadBtnDisabled}
        onClick={async () => await handleUploadDocument()}
      >
        <i className={`fa fa-lg fa-check-circle ${classes.btnIcon}`}></i>
        {loading ? <ButtonLoading loading={loading} /> : "Done"}
      </Button>
    )}
  </GridItem>
);

const useStyles = makeStyles(styles);

const FileUploadModal = ({
  setModalOpen,
  isModalOpen,
  inquiryId,
  data,
  executeGetAllInquiries,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [fileInput, setFileInput] = React.useState(null);
  const [fileInputError, setFileInputError] = React.useState(null);
  const [isUploadBtnDisabled, setUploadBtnDisabled] = React.useState(null);
  const [showAlert, setShowAlert] = React.useState(true);

  const token = localStorage.getItem("token");

  // Calling an API to Validate Document Name
  const [
    { loading },
    executeValidateExcelFileNameUrl,
  ] = useAxios(ValidateExcelFileName(token), { manual: true });

  // Calling an API to Upload Excel Document
  const [
    { loading: uploadLoading },
    executeUploadExcelFileUrl,
  ] = useAxios(UploadExcelFileUrl(token, inquiryId), { manual: true });

  const handleValidateFileInput = async (file) => {
    setFileInput(file);
    try {
      const data = new FormData();
      data.append("File", file);
      await executeValidateExcelFileNameUrl({ data: data });
      setFileInputError(null);
    } catch (error) {
      console.log("Error Validating ML document", error);
      setFileInputError(error.response.data.errorMessage);
    }
  };

  const handleRemoveDocument = () => {
    setFileInput(null);
    setFileInputError(null);
  };

  const handleUploadDocument = async () => {
    if (!fileInput) {
      setFileInputError("Please upload a document, first.");
    }
    if (fileInput && !fileInputError) {
      setUploadBtnDisabled(true);
      try {
        const data = new FormData();
        data.append("File", fileInput);
        await executeUploadExcelFileUrl({ data: data });
        await executeGetAllInquiries();
        NotificationManager.success("Document Uploaded Successfully.", 5000);
        setModalOpen(false);
      } catch (error) {
        console.log("Error uploading excel file", error);
        apiErrorHandler(error, history);
      }
      setUploadBtnDisabled(false);
    }
  };

  const getInquiryStatus = () => {
    return data.find((item) => item.Id === inquiryId).Progress;
  };

  React.useEffect(() => {
    if (getInquiryStatus() < 90) {
      setShowAlert(true);
    } else if (getInquiryStatus() >= 90) {
      setShowAlert(false);
    }
  }, []);
  return (
    <Modal
      modalTitle={modalTitle(classes)}
      modalBody={modalBody(
        classes,
        setModalOpen,
        isModalOpen,
        fileInput,
        fileInputError,
        handleValidateFileInput,
        handleRemoveDocument,
        showAlert,
        setShowAlert
      )}
      modalActions={modalActions(
        classes,
        setModalOpen,
        isUploadBtnDisabled,
        handleUploadDocument,
        uploadLoading,
        showAlert,
        setShowAlert
      )}
    />
  );
};

export default FileUploadModal;

FileUploadModal.propTypes = {
  setModalOpen: PropTypes.func,
  isModalOpen: PropTypes.bool,
  inquiryId: PropTypes.number,
  executeGetAllInquiries: PropTypes.func,
  data: PropTypes.array,
};
