import * as React from "react";

const SvgLogoutRound = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#D65746" />
    <path
      d="m31.756 20.59-4.374 4.374a.833.833 0 0 1-1.178-1.178l2.951-2.953H19.5a.834.834 0 0 1 0-1.666h9.655l-2.951-2.953a.833.833 0 0 1 1.178-1.178l4.374 4.375a.834.834 0 0 1 0 1.178ZM19.5 28.332h-5.833V11.667H19.5a.833.833 0 0 0 0-1.667h-5.833A1.669 1.669 0 0 0 12 11.667v16.666A1.669 1.669 0 0 0 13.667 30H19.5a.833.833 0 0 0 0-1.667Z"
      fill="#fff"
    />
  </svg>
);

export default SvgLogoutRound;
