import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import { CreateFindingUrl } from "axios/adminAPIsUrls";
import FormContainer from "components/Forms/FormContainer";
import CustomFormSelect from "components/Selects/CustomSelect";
import CustomInput from "components/Inputs/CustomInput";
import useInquiryTypes from "hooks/useInquiryTypes";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "helpers/validations";
import { NotificationManager } from "components/common/ReactNotifications";
import { GetFindingUrl } from "axios/adminAPIsUrls";

const areaConst = [
  {
    id: "Structure",
    label: "Structure",
  },
  {
    id: "Process",
    label: "Process",
  },
  {
    id: "People",
    label: "People",
  },
  {
    id: "Culture",
    label: "Culture",
  },
];

const useStyles = makeStyles(styles);
const ViewFindings = () => {
  const history = useHistory();
  const classes = useStyles();
  let { FindingsParamId } = useParams();

  const [fields, setFields] = useState({
    InquiryTypeName: "",
    Area: "",
    FindingDetail: "",
  });

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteGetFindingUrl] = useAxios(
    GetFindingUrl(FindingsParamId, token)
  );
  useEffect(() => {
    ExecuteGetFindingUrl();
  }, []);
  useEffect(() => {
    if (data) {
      setFields({
        InquiryTypeName: data.InquiryTypeName,
        Area: data.Area,
        FindingDetail: data.FindingDetail,
      });
    }
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Findings"
              showBackButton
              goBackRoute={`/superAdmin/findings`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer height>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  disabled
                  label="Inquiry Type"
                  value={fields.InquiryTypeName}
                  menu={[
                    {
                      id: fields.InquiryTypeName,
                      label: fields.InquiryTypeName,
                    },
                  ]}
                  customClassName={classes.textFieldSelectStyling}
                />
                <CustomFormSelect
                  inputType="text"
                  required
                  disabled
                  label="Area"
                  value={fields.Area}
                  menu={[{ id: fields.Area, label: fields.Area }]}
                  customClassName={classes.textFieldSelectStyling}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Finding Detail"
                  multiline
                  disabled
                  value={fields.FindingDetail}
                  customClassName={classes.textFieldSelectStyling}
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewFindings;
