import React from "react";

// importing material UI icons
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import DirectionsIcon from "@material-ui/icons/Directions";
import DesktopMacOutlinedIcon from "@material-ui/icons/DesktopMacOutlined";

// importing routes from routes
import { routes } from "routes";

export const superAdminMenu = [
  {
    tabName: "Dashboard",
    tabIcon: <StorefrontOutlinedIcon />,
    tabRoute: "/dashboard",
  },
  {
    tabName: "Profiles",
    tabIcon: <PersonOutlineOutlinedIcon />,
    tabRoute: "/manage-customers-profile",
  },
  {
    tabName: "Inquiry Type Management",
    tabIcon: <FindInPageOutlinedIcon />,
    tabRoute: "/inquiry-type-management",
  },
  {
    tabName: "Custom Metrics",
    tabIcon: <TrendingUpOutlinedIcon />,
    tabRoute: "/custom-metrics",
  },
  {
    tabName: "Findings",
    tabIcon: <SearchOutlinedIcon />,
    tabRoute: "/findings",
  },
  {
    tabName: "Recommendations Management",
    tabIcon: <ThumbUpOutlinedIcon />,
    tabRoute: "/manage-recommendations",
  },
  {
    tabName: "Roadmap Management",
    tabIcon: <DirectionsIcon />,
    tabRoute: "/manage-roadmap",
  },
  {
    tabName: "Monitor Activity",
    tabIcon: <DesktopMacOutlinedIcon />,
    tabRoute: "/monitor-activity",
  },
];
