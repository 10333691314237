export const excelFileFormats = [
  ".csv",
  ".xlsm",
  ".xlsb",
  "xlsm",
  "xlsb",
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/msexcel",
  ".xlsx",
];

export const docsFileFormats = [
  ".docs",
  ".docx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  ".doc",
];

export const PdfFileFormats = [".pdf", "application/pdf"];

export const ZipFileFormats = [
  ".zip",
  ".ZIP",
  "application/zip",
  "application/x-zip-compressed",
];
