/* eslint-disable react/prop-types */
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import React from "react";
import { makeStyles } from "@material-ui/core";
import style from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";
import CustomFormSelect from "components/Selects/CustomSelect";
import { isNumber } from "helpers/validations";

const typeConst = [
  {
    id: "Textbox",
    label: "Textbox",
  },
  {
    id: "Checkbox",
    label: "Checkbox",
  },
];

const styles = {
  ...style,
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};
const useStyle = makeStyles(styles);

const IntakeQuestionFields = ({
  fields,
  setFields,
  prerequisiteValue,
  AreFieldsValidated,
}) => {
  const classes = useStyle();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title={prerequisiteValue.title}
              btnText={prerequisiteValue.btnText}
              showBackButton
              goBackRoute={prerequisiteValue.goBackLink}
              onClick={() => prerequisiteValue.onClick()}
            />
          </CardHeader>
          <CardBody className={classes.ptZero}>
            <FormContainer height>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Question Title"
                  value={fields.Title}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Title: event.target.value,
                    });
                  }}
                  errorText={AreFieldsValidated && isEmpty(fields.Title)}
                  disabled={prerequisiteValue.disabled}
                />
              </GridItem>

              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Question Type"
                  value={fields.Type}
                  menu={typeConst}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Type: event.target.value,
                    });
                  }}
                  errorText={AreFieldsValidated && isEmpty(fields.Type)}
                  disabled={prerequisiteValue.disabled}
                />
              </GridItem>

              {fields.Type.toLocaleLowerCase() === "textbox" ? (
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Max. Length"
                    value={fields.MaxLength}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        MaxLength: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isNumber(fields.MaxLength)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
              ) : null}

              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  inputType="text"
                  required
                  label="Question Detail"
                  multiline
                  value={fields.Description}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Description: event.target.value,
                    });
                  }}
                  errorText={AreFieldsValidated && isEmpty(fields.Description)}
                  disabled={prerequisiteValue.disabled}
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default IntakeQuestionFields;
