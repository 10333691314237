import React from "react";
import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import { NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/insightsOverviewStyle";
import { viewInsightsStore } from "store";
import AreaSquare from "components/ViewInsights/AreaSquare";
import jwtDecode from "jwt-decode";

const useStyles = makeStyles(styles);
const renderOverview = (data) => {
  const classes = useStyles();
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [window.location.pathname]);
  return (
    <GridItem xs={12} sm={12} md={12} className={classes.overView}>
      {/* Participants */}
      <GridItem xs={6} sm={6} md={3} className={classes.overView}>
        <NavLink
          to={`${window.location.pathname}/participants`}
          className={classes.overView}
        >
          <h3 className={classes.overviewText}>Participants</h3>
          <h3 className={classes.overviewCount}>({data.NoOfParticipants})</h3>
        </NavLink>
      </GridItem>
      {/* Documents */}
      <GridItem xs={6} sm={6} md={3} className={classes.overView}>
        <NavLink
          to={`${window.location.pathname}/documents`}
          className={classes.overView}
        >
          <h3 className={classes.overviewText}>Tools</h3>
          <h3 className={classes.overviewCount}>({data.Documents})</h3>
        </NavLink>
      </GridItem>

      {/* Advance Insights */}
      <GridItem xs={6} sm={6} md={3} className={classes.overView}>
        <NavLink
          to={`${window.location.pathname}/advance-insights`}
          className={classes.overView}
        >
          <h3 className={classes.overviewText}>Advance Insights</h3>
          <h3 className={classes.overviewCount}>({data.AdvanceInsights})</h3>
        </NavLink>
      </GridItem>
      {/* Completion */}
      <GridItem xs={6} sm={6} md={3} className={classes.overView}>
        <h3 className={classes.overviewText}>Completion</h3>
        <h3 className={classes.overviewCount}>({data.Progress}%)</h3>
      </GridItem>
    </GridItem>
  );
};

const renderAreaShapes = () => {
  const classes = useStyles();
  const history = useHistory();
  const inquiryId = viewInsightsStore((state) => state.inquiryId);
  const structureColor = viewInsightsStore((state) => state.structureColor);
  const processColor = viewInsightsStore((state) => state.processColor);
  const peopleColor = viewInsightsStore((state) => state.peopleColor);
  const cultureColor = viewInsightsStore((state) => state.cultureColor);
  const setCurrentAreaRef = viewInsightsStore(
    (state) => state.setCurrentAreaRef
  );

  const onClick = (value) => {
    setCurrentAreaRef(value);
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    let loggedInRole = "";
    if (decodedToken.role.toLowerCase() === "superadmin") {
      loggedInRole = "superAdmin";
    } else if (decodedToken.role.toLowerCase() === "customeradmin") {
      loggedInRole = "customerAdmin";
    }
    history.push(
      `/${loggedInRole}/dashboard/view-insights/${inquiryId}/inputs`
    );
  };

  return (
    <>
      <GridItem
        xs={12}
        sm={12}
        md={12}
        style={{
          marginTop: "10px",
          width: "90%",
          display: "flex",
          flex: "flexWrap",
          alignItem: "center",
          justifyContent: "space-evenly",
        }}
        id="focus"
      >
        <h4
          style={{
            fontWeight: "500",
            fontSize: "1rem",
            marginBottom: "20px",
          }}
        >
          Green = Strength
        </h4>
        <h4
          style={{
            fontWeight: "500",
            fontSize: "1rem",
            marginBottom: "20px",
          }}
        >
          Blue = Progressing
        </h4>

        <h4
          style={{
            fontWeight: "500",
            fontSize: "1rem",
            marginBottom: "20px",
          }}
        >
          Red = Vulnerability
        </h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} className={classes.areaShapes}>
        <GridItem xs={3} sm={3} md={3} className={classes.areaShapes}>
          <AreaSquare
            onClick={() => onClick("culture")}
            areaName="Culture"
            customClassName={classes.pointer}
            color={cultureColor}
          />
          <span className={classes.mar_A} />
          <div>
            <AreaSquare
              areaName="People"
              onClick={() => onClick("people")}
              customClassName={classes.pointer}
              color={peopleColor}
            />
          </div>
          <span className={classes.mar_A} />
          <div>
            <AreaSquare
              areaName="Process"
              onClick={() => onClick("process")}
              customClassName={classes.pointer}
              color={processColor}
            />
          </div>
          <span className={classes.mar_A} />
          <div>
            <AreaSquare
              areaName="Structure"
              onClick={() => onClick("structure")}
              customClassName={classes.pointer}
              color={structureColor}
            />
          </div>
        </GridItem>
      </GridItem>
    </>
  );
};

const Overview = ({ data }) => {
  const classes = useStyles();
  // This useEffect is redirecting the user to particular area upon clicking in OverViews and Findings
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <GridContainer className={classes.container}>
      {data && renderOverview(data)}
      {data && renderAreaShapes()}
    </GridContainer>
  );
};

export default Overview;

Overview.propTypes = {
  data: PropTypes.object,
};
