import NestedMenubar from "components/Menubar/NestedMenubar";
import { viewInsightsMenu } from "constants/viewInsightsMenu";
import React from "react";
import { viewInsightsStore } from "store";

const InsightsMenubar = () => {
  const inquiryId = viewInsightsStore((state) => state.inquiryId);

  return (
    <NestedMenubar
      color="primary"
      menu={viewInsightsMenu}
      inquiryId={inquiryId}
    />
  );
};

export default InsightsMenubar;
