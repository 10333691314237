const surveyData = async ({
  executeGetSurveyFormQuestions,
  decodedToken,
  inquiryId,
  setUploadedData,
}) => {
  let response = executeGetSurveyFormQuestions;
  let structure = response?.data?.StructureArray.map((item) => {
    let newItem = item;
    newItem.Answer = item.Answer ? item.Answer : "";
    newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
      ? item.ValueImportanceAnswer
      : "";
    newItem.file = item.file ? item.file : "";
    newItem.InquiryId = inquiryId;
    newItem.CustomerUserId = decodedToken.userid;
    return newItem;
  });
  let People = response?.data?.PeopleArray.map((item) => {
    let newItem = item;
    newItem.Answer = item.Answer ? item.Answer : "";
    newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
      ? item.ValueImportanceAnswer
      : "";
    newItem.file = item.file ? item.file : "";
    newItem.InquiryId = inquiryId;
    newItem.CustomerUserId = decodedToken.userid;
    return newItem;
  });
  let Process = response?.data?.ProcessArray.map((item) => {
    let newItem = item;
    newItem.Answer = item.Answer ? item.Answer : "";
    newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
      ? item.ValueImportanceAnswer
      : "";
    newItem.file = item.file ? item.file : "";
    newItem.InquiryId = inquiryId;
    newItem.CustomerUserId = decodedToken.userid;
    return newItem;
  });
  let Culture = response?.data?.CultureArray.map((item) => {
    let newItem = item;
    newItem.Answer = item.Answer ? item.Answer : "";
    newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
      ? item.ValueImportanceAnswer
      : "";
    newItem.file = item.file ? item.file : "";
    newItem.InquiryId = inquiryId;
    newItem.CustomerUserId = decodedToken.userid;
    return newItem;
  });
  let others = response?.data?.OthersArray.map((item) => {
    let newItem = item;
    newItem.Type === "Checkbox"
      ? (newItem.Answer = item.Answer ? item.Answer : "no checkbox")
      : (newItem.Answer = item.Answer ? item.Answer : "");
    newItem.ValueImportanceAnswer = item.ValueImportanceAnswer
      ? item.ValueImportanceAnswer
      : "";
    newItem.DownloadLink = item.DownloadLink ? item.DownloadLink : "";
    newItem.DocumentName = item.DocumentName ? item.DocumentName : "";
    newItem.file = "";
    newItem.InquiryId = inquiryId;
    newItem.CheckBoxOptions = item.CheckBoxOptions;
    newItem.CheckBoxAnswers = item.CheckBoxOptionsAnswer;
    newItem.CheckboxArray = item.CheckBoxOptionsAnswer
      ? item.CheckBoxOptionsAnswer
      : [];
    newItem.CustomerUserId = decodedToken.userid;
    return newItem;
  });
  let data = [...structure, ...People, ...Process, ...Culture, ...others];
  setUploadedData(data);
};
export default surveyData;
