export const ratingMatrixArray = [
  {
    label: 1,
    value: "Very Low",
  },
  {
    label: 2,
    value: "Low",
  },
  {
    label: 3,
    value: "Average",
  },
  {
    label: 4,
    value: "High",
  },
  {
    label: 5,
    value: "Very High",
  },
  {
    label: "DK",
    value: "Don't Know",
  },
];
