// getting all inquiries for customer admin`
export function GetAllInquiriesUrl(currentPage, pageSize, token) {
  return {
    url: `/Inquiry?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Customer Admin Profile Setup API
export function EditCustomerProfileUrl(customerId, userId, token) {
  return {
    url: `/Customer/${customerId}/User/${userId}/CustomerProfileComplete`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export function CreateInquiryUrl(token) {
  const pathwayId = "0";
  // SENDING HARDCODE PATHWAY ID BECAUSE IT IS ALSO HARDCODED AT BACKEND
  // IT WILL BE DECIDED LATER FROM WHERE WE WILL GET PATHWAY ID
  return {
    url: `/Inquiry/CreateInquiry/${pathwayId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// submitting intake answers against an inquiry
export function AddIntakeAnswersUrl(inquiryId, token) {
  return {
    url: `/Inquiry/AddIntakeAnswer/${inquiryId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting all custom questions by user id
export function GetAllCustomQuestionByUserId(
  CustomerAdminId,
  currentPage,
  pageSize,
  token
) {
  return {
    url: `/CustomQuestion/AllCustomQuestionsByUserId/UserId/${CustomerAdminId}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// creating custom question as customer admin
export function CreateCustomQuestionUrl(inquiryId, token) {
  return {
    url: `/CustomQuestion/CreateCustomQuestion/InquiryId/${inquiryId}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting inquiry respondents by inquiry id
export function GetInquiryRespondentsUrl(
  inquiryId,
  currentPage,
  pageSize,
  token
) {
  return {
    url: `/Inquiry/GetRespondents/${inquiryId}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// adding respondents against an inquiry
export function AddInquiryParticipantsUrl(inquiryId, token) {
  return {
    url: `/Inquiry/AddParticipants/${inquiryId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
// getting respondant against the id
export function GetRespondentsbyId(inquiryId, token) {
  return {
    url: `/Inquiry/GetRespondents/${inquiryId}?pageSize=10&currentPage=-1`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting all diagnostic questions against any inquiry type
export function GetAllDiagnosticQuestionsByInqTypeIdUrl(
  inquiryTypeId,
  pageSize,
  currentPage,
  token
) {
  return {
    url: `/DiagnosticQuestion/${inquiryTypeId}/AllDiagnosticQuestionsByInquiryType?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
