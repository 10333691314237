import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import SwapCallsOutlinedIcon from "@material-ui/icons/SwapCallsOutlined";

import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import AlertModal from "components/Alerts/AlertModal";
import { definePathwayStore } from "store";
import {
  GetAllPathwaysUrl,
  GetAllDiagnosticQuestionsUrl,
} from "axios/adminAPIsUrls";
import useAxios from "axios/httpService";
import { GetInquiryTypeUrl } from "axios/adminAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { DeletePathwayUrl } from "axios/adminAPIsUrls";
import Loading from "components/Loading/Loading";
import { DeleteDiagnosticQuestionUrl } from "axios/adminAPIsUrls";
import authStore from "store/authStore";
import jwtDecode from "jwt-decode";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

const DiagnosticQuestion = () => {
  const history = useHistory();
  const userToken = authStore((state) => state.userToken);
  let { inquiryParamId, pathwayParamId } = useParams();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [diagnosticId, setDiagnosticId] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");

  const [
    { data: diagnosticData, error, loading },
    ExecuteGetAllDiagnosticQuestionsUrl,
  ] = useAxios(
    GetAllDiagnosticQuestionsUrl(pathwayParamId, pageSize, currentPage, token),
    { manual: true }
  );

  const [
    { loading: deleteLoading },
    ExecuteDeleteDiagnosticQuestionUrl,
  ] = useAxios(DeleteDiagnosticQuestionUrl(diagnosticId, token), {
    manual: true,
  });

  const deletePathwayhandler = async () => {
    try {
      const res = await ExecuteDeleteDiagnosticQuestionUrl();
      setAlert(!alert);
      NotificationManager.success("Diagnostic Question Deleted", 5000);
      ExecuteGetAllDiagnosticQuestionsUrl();
    } catch (exception) {
      setAlert(!alert);
      console.log("Error in Deleting Diagnostic Question", exception);
      apiErrorHandler(exception, history);
    }
  };

  useEffect(() => {
    ExecuteGetAllDiagnosticQuestionsUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (diagnosticData) {
      setData(
        diagnosticData.data.map((prop, key) => {
          return {
            id: prop.Id,
            questionTitle: prop.Title,
            questionType: prop.Type,
            maxLength: prop.Type === "Textbox" ? prop.MaxLength : "-",
            area: prop.Area,
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                {userToken && jwtDecode(userToken).role === "CustomerAdmin" ? (
                  <>
                    <Button
                      action
                      tooltipText="View Diagnostic Question"
                      justIcon
                      round
                      simple
                      size="sm"
                      color="primary"
                      onClick={() =>
                        history.push(
                          `${window.location.pathname}/view/${prop.Id}`
                        )
                      }
                    >
                      <VisibilityOutlinedIcon />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      action
                      tooltipText="View Diagnostic"
                      justIcon
                      round
                      simple
                      size="sm"
                      color="primary"
                      onClick={() =>
                        history.push(
                          `${window.location.pathname}/view/${prop.Id}`
                        )
                      }
                    >
                      <VisibilityOutlinedIcon />
                    </Button>

                    <Button
                      action
                      tooltipText="Edit Diagnostic"
                      justIcon
                      round
                      simple
                      size="sm"
                      color="primary"
                      onClick={() =>
                        history.push(
                          `${window.location.pathname}/edit/${prop.Id}`
                        )
                      }
                    >
                      <CreateOutlinedIcon />
                    </Button>
                    <Button
                      action
                      tooltipText="Delete Diagnostic"
                      justIcon
                      round
                      simple
                      size="sm"
                      color="primary"
                      onClick={() => toggleDelete(prop.Id)}
                    >
                      <DeleteForeverOutlinedIcon />
                    </Button>
                  </>
                )}
              </div>
            ),
          };
        })
      );
      setLoader(false);
    }
  }, [diagnosticData]);

  const toggleDelete = (id) => {
    setDiagnosticId(id);
    setAlert(!alert);
  };

  const classes = useStyles();

  const columns = [
    {
      Header: "Question Title",
      accessor: "questionTitle",
      alignment: "center",
    },
    {
      Header: "Question Type",
      accessor: "questionType",
      alignment: "center",
    },
    {
      Header: "Max Length",
      accessor: "maxLength",
      alignment: "center",
    },
    {
      Header: "Area",
      accessor: "area",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];

  if (error) {
    apiErrorHandler(error, history);
  }
  if (loading) {
    return <Loading loading />;
  }
  if (deleteLoading) {
    return <Loading loading={deleteLoading} />;
  }

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletePathwayhandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title={
                userToken && jwtDecode(userToken).role === "CustomerAdmin"
                  ? "View Diagnostic Questions"
                  : "Manage Diagnostic Questions"
              }
              btnText={
                userToken && jwtDecode(userToken).role === "CustomerAdmin"
                  ? null
                  : "Add New Questions"
              }
              goBackRoute={
                userToken && jwtDecode(userToken).role === "CustomerAdmin"
                  ? `/customerAdmin/inquiry-type-management/${inquiryParamId}/pathways`
                  : `/superAdmin/inquiry-type-management/${inquiryParamId}/pathways`
              }
              showBackButton
              onClick={() => history.push(`${window.location.pathname}/create`)}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loader}
              currentPage={diagnosticData && diagnosticData.currentPage}
              defaultPageSize={diagnosticData && diagnosticData.pageSize}
              totalItems={diagnosticData && diagnosticData.totalItem}
              totalPages={diagnosticData && diagnosticData.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default DiagnosticQuestion;
