import React from "react";
import useAxios from "axios/httpService";
import { GetAllIntakeQuestionsUrl } from "axios/adminAPIsUrls";

const useIntakeQuestions = (inquiryTypeId) => {
  //   SENDING -1 CURRENT PAGE TO GET ALL INQUIRY TYPES PRESENT IN DATABASE
  const currentPage = -1;
  const token = localStorage.getItem("token");
  const pageSize = 8;

  //   WILL SEND THIS RESPONSE IN RETURN STATEMENT OF OUR CUSTOM HOOK

  const [response, setResponse] = React.useState("");

  const [{ data, loading, error }, executeGetAllIntakeQuestions] = useAxios(
    GetAllIntakeQuestionsUrl(
      parseInt(inquiryTypeId),
      pageSize,
      currentPage,
      token
    ),
    {
      manual: true,
    }
  );

  React.useEffect(async () => {
    setResponse(await executeGetAllIntakeQuestions());
  }, []);

  if (response) {
    const intakeQuestions = [];
    data.data.map((item) => {
      const newValues = {
        intakeQuestionId: item.Id,
        title: item.Title,
        description: item.Description,
        maxLength: item.MaxLength,
        inputType: item.Type,
      };
      intakeQuestions.push(newValues);
    });

    return { loading, intakeQuestions, error };
  }

  return { loading };
};

export default useIntakeQuestions;
