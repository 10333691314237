import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import AlertModal from "components/Alerts/AlertModal";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { GetAllIntakeQuestionsUrl } from "axios/adminAPIsUrls";
import Loading from "components/Loading/Loading";
import { DeleteIntakeQuestionUrl } from "axios/adminAPIsUrls";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

const index = () => {
  const history = useHistory();
  let { inquiryParamId } = useParams();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [pathwayId, setPathwayId] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");

  const [
    { data: allIntakeQuestionsData, error, loading },
    ExecuteGetAllIntakeQuestionsUrl,
  ] = useAxios(
    GetAllIntakeQuestionsUrl(inquiryParamId, pageSize, currentPage, token),
    { manual: true }
  );

  const [
    { loading: deleteLoading },
    ExecuteDeleteIntakeQuestionUrl,
  ] = useAxios(DeleteIntakeQuestionUrl(pathwayId, token), { manual: true });

  const deletePathwayhandler = async () => {
    try {
      const res = await ExecuteDeleteIntakeQuestionUrl();
      setAlert(!alert);
      NotificationManager.success("Intake Question Deleted", 5000);
      ExecuteGetAllIntakeQuestionsUrl();
    } catch (exception) {
      console.log("Error in Deleting Pathway", exception);
      apiErrorHandler(exception, history);
      setAlert(!alert);
    }
  };

  useEffect(() => {
    ExecuteGetAllIntakeQuestionsUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (allIntakeQuestionsData) {
      setData(
        allIntakeQuestionsData.data.map((prop, key) => {
          return {
            id: prop.Id,
            questionTitle: prop.Title,
            questionType: prop.Type,
            maxLength: prop.Type === "Textbox" ? prop.MaxLength : "-",
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Intake Question"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(`${window.location.pathname}/view/${prop.Id}`)
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>

                <Button
                  action
                  tooltipText="Edit Intake Question"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(`${window.location.pathname}/edit/${prop.Id}`)
                  }
                >
                  <CreateOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Delete Intake Question"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() => toggleDelete(prop.Id)}
                >
                  <DeleteForeverOutlinedIcon />
                </Button>
              </div>
            ),
          };
        })
      );
      setLoader(false);
    }
  }, [allIntakeQuestionsData]);

  const toggleDelete = (id) => {
    setPathwayId(id);
    setAlert(!alert);
  };

  const classes = useStyles();

  const columns = [
    {
      Header: "Question Title",
      accessor: "questionTitle",
    },
    {
      Header: "Question Type",
      accessor: "questionType",
      alignment: "center",
    },
    {
      Header: "Max Length",
      accessor: "maxLength",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];

  if (loading) {
    return <Loading loading />;
  }
  if (deleteLoading) {
    return <Loading loading={deleteLoading} />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletePathwayhandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Intake Questions Management:"
              // subTitle="ERP Operational Readiness"
              btnText="Create Question"
              goBackRoute="/superAdmin/inquiry-type-management"
              showBackButton
              onClick={() => history.push(`${window.location.pathname}/create`)}
            />
          </CardHeader>
          <CardBody className={classes.ptZero}>
            <ReactTable
              columns={columns}
              data={data}
              loading={loader}
              currentPage={
                allIntakeQuestionsData && allIntakeQuestionsData.currentPage
              }
              defaultPageSize={
                allIntakeQuestionsData && allIntakeQuestionsData.pageSize
              }
              totalItems={
                allIntakeQuestionsData && allIntakeQuestionsData.totalItem
              }
              totalPages={
                allIntakeQuestionsData && allIntakeQuestionsData.totalPage
              }
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default index;
