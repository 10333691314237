import React from "react";
import { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { useHistory, useParams } from "react-router-dom";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import useAxios from "axios/httpService";
import { GetInputResultForInquiryUrl } from "axios/commonAPIsUrls";
import { GetInputResultForPortalAdmin } from "axios/adminAPIsUrls";
import Loading from "components/Loading/Loading";
import monitorActivityGraphStore from "store/monitorActivityGraphStore";
import InputsResultsGraph from "./MonitorActivityInputsResultsGraph";
import { makeStyles } from "@material-ui/core";
import MonitorActivityInputsResultsGraph from "./MonitorActivityInputsResultsGraph";
import Card from "components/Card/Card";

const styles = {
  inputResults: {
    margin: "20px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

const useStyles = makeStyles(styles);

const MonitorActivityInputs = () => {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");

  const { inquiryId } = useParams();

  // Getting Structure Information from Store
  const structureColor = monitorActivityGraphStore(
    (state) => state.structureColor
  );
  const structureDataset = monitorActivityGraphStore(
    (state) => state.structureDataset
  );
  const setStructureDataset = monitorActivityGraphStore(
    (state) => state.setStructureDataset
  );
  const structureYAxisLabels = monitorActivityGraphStore(
    (state) => state.structureYAxisLabels
  );

  const setStructureYAxisLabels = monitorActivityGraphStore(
    (state) => state.setStructureYAxisLabels
  );

  // Getting Process Information from Store
  const processColor = monitorActivityGraphStore((state) => state.processColor);
  const processDataset = monitorActivityGraphStore(
    (state) => state.processDataset
  );
  const setProcessDataset = monitorActivityGraphStore(
    (state) => state.setProcessDataset
  );
  const processYAxisLabels = monitorActivityGraphStore(
    (state) => state.processYAxisLabels
  );
  const setProcessYAxisLabels = monitorActivityGraphStore(
    (state) => state.setProcessYAxisLabels
  );

  // Getting People Information from Store
  const peopleColor = monitorActivityGraphStore((state) => state.peopleColor);
  const peopleDataset = monitorActivityGraphStore(
    (state) => state.peopleDataset
  );
  const setPeopleDataset = monitorActivityGraphStore(
    (state) => state.setPeopleDataset
  );
  const peopleYAxisLabels = monitorActivityGraphStore(
    (state) => state.peopleYAxisLabels
  );
  const setPeopleYAxisLabels = monitorActivityGraphStore(
    (state) => state.setPeopleYAxisLabels
  );

  // Getting Culture Information from Store
  const cultureColor = monitorActivityGraphStore((state) => state.cultureColor);
  const cultureDataset = monitorActivityGraphStore(
    (state) => state.cultureDataset
  );
  const setCultureDataset = monitorActivityGraphStore(
    (state) => state.setCultureDataset
  );
  const cultureYAxisLabels = monitorActivityGraphStore(
    (state) => state.cultureYAxisLabels
  );
  const setCultureYAxisLabels = monitorActivityGraphStore(
    (state) => state.setCultureYAxisLabels
  );

  // Getting Inputs Results from API
  const [
    { data, loading, error },
    executeGetInputResultForPortalAdmin,
  ] = useAxios(GetInputResultForPortalAdmin(inquiryId, token));

  const getAreaInputsResults = (data, setYAxisLabels, dataset, setDataset) => {
    const newDataset = [...dataset];
    // Removing older values from data array
    newDataset[0].data = [];
    newDataset[1].data = [];
    newDataset[2].data = [];
    const newYAxisLabels = [];
    data &&
      data.map((item) => {
        // setting values in yAxisLabels

        // truncating y axis values upto 25 characters
        /*let truncatedValue = item.Questiondescription.substring(0, 25) + "...";
        newYAxisLabels.push(truncatedValue);
        still have to work down here */

        newYAxisLabels.push(item.Questiondescription);

        // setting values in dataset
        newDataset[0].data.push(item.AverageDifference);
        newDataset[1].data.push(item.AverageRating);
        newDataset[2].data.push(item.AverageValue);
      });
    newDataset[0].data.push(5);
    newDataset[1].data.push(5);
    newDataset[2].data.push(5);

    setDataset(newDataset);
    setYAxisLabels(newYAxisLabels);
  };

  React.useEffect(() => {
    // setting data for structureGraph
    data &&
      getAreaInputsResults(
        data.StructureArray,
        setStructureYAxisLabels,
        structureDataset,
        setStructureDataset
      );

    // setting data for processGraph
    data &&
      getAreaInputsResults(
        data.ProcessArray,
        setProcessYAxisLabels,
        processDataset,
        setProcessDataset
      );

    // setting data for peopleGraph
    data &&
      getAreaInputsResults(
        data.PeopleArray,
        setPeopleYAxisLabels,
        peopleDataset,
        setPeopleDataset
      );

    // setting data for cultureGraph
    data &&
      getAreaInputsResults(
        data.CultureArray,
        setCultureYAxisLabels,
        cultureDataset,
        setCultureDataset
      );
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }

  // error in fetching API response of input details
  if (error) {
    console.log("Error in Getting Input Results", error);
    apiErrorHandler(error, history);
  }

  if (
    data &&
    data.StructureArray.length === 0 &&
    data.CultureArray.length === 0 &&
    data.ProcessArray.length === 0 &&
    data.PeopleArray.length === 0
  ) {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} noDefaultPadding>
          <Card>
            <GridContainer className={classes.centered}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.inputResults}
              >
                <h1>No Data Available Currently</h1>
              </GridItem>
            </GridContainer>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} noDefaultPadding>
        <Card>
          <GridContainer>
            {/* Culture Inputs Results */}
            {data && data.CultureArray && data.CultureArray.length > 0 && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={`${classes.inputResults} culture`}
              >
                <MonitorActivityInputsResultsGraph
                  className={"culture"}
                  areaName="Culture"
                  areaColor={cultureColor}
                  yAxisValues={cultureYAxisLabels}
                  dataset={cultureDataset}
                />
              </GridItem>
            )}
            {/* People Inputs Results */}
            {data && data.PeopleArray && data.PeopleArray.length > 0 && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={`${classes.inputResults} people`}
              >
                <MonitorActivityInputsResultsGraph
                  className={"people"}
                  areaName="People"
                  areaColor={peopleColor}
                  yAxisValues={peopleYAxisLabels}
                  dataset={peopleDataset}
                />
                {/* <div className="People"></div> */}
              </GridItem>
            )}
            {/* Process Inputs Results */}
            {data && data.ProcessArray && data.ProcessArray.length > 0 && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={`${classes.inputResults} process`}
              >
                <MonitorActivityInputsResultsGraph
                  className={"process"}
                  areaName="Process"
                  areaColor={processColor}
                  yAxisValues={processYAxisLabels}
                  dataset={processDataset}
                />
                {/* <div className="Process"></div> */}
              </GridItem>
            )}
            {/* Structure Inputs Results */}
            {data && data.StructureArray && data.StructureArray.length > 0 && (
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={`${classes.inputResults} structure`}
              >
                <MonitorActivityInputsResultsGraph
                  className={"structure"}
                  areaName="Structure"
                  areaColor={structureColor}
                  yAxisValues={structureYAxisLabels}
                  dataset={structureDataset}
                />
                {/* <div className="Structure-page"></div> */}
              </GridItem>
            )}
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default MonitorActivityInputs;
