import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { isEmpty } from "helpers/validations";
import useAxios from "axios/httpService";
import ManageCustomerUserFields from "views/Common/profileManageCustomers/ManageCustomerUserFields";
import { GetCustomerUserUrl } from "axios/adminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { EditCustomerUserUrl } from "axios/adminAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";

const EditCustomerUser = () => {
  const history = useHistory();
  let { customersUserParamId, CustomerUserParamId } = useParams();
  const [fieldValidation, setFieldValidation] = useState(false);
  const [fields, setFields] = useState({
    FirstName: "Testing",
    LastName: "Testing",
    Email: "testing@gmail.com",
    Designation: "Admin",
    IsAdmin: false,
    IsVerified: false,
    Active: false,
  });

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteGetCustomerUserUrl] = useAxios(
    GetCustomerUserUrl(CustomerUserParamId, token),
    {
      manual: true,
    }
  );

  const [{ loading: loadingEdit }, ExecuteEditCustomerUserUrl] = useAxios(
    EditCustomerUserUrl(CustomerUserParamId, token),
    {
      manual: true,
    }
  );

  useEffect(() => {
    ExecuteGetCustomerUserUrl();
  }, []);
  useEffect(() => {
    if (data) {
      setFields({
        FirstName: data.FirstName,
        LastName: data.LastName,
        Email: data.Email,
        Designation: data.Designation,
        IsAdmin: data.IsAdmin,
        Active: data.Active,
        IsVerified: data.IsVerified,
      });
    }
  }, [data]);

  const handleChange = (prop) => (event) => {
    setFields({ ...fields, [prop]: event.target.value });
  };

  const allFieldsValidated = () => {
    if (isEmpty(fields.FirstName)) {
      return false;
    }
    if (isEmpty(fields.LastName)) {
      return false;
    }

    return true;
  };

  const onUpdateProfile = async (data = fields) => {
    setFieldValidation(true);
    if (allFieldsValidated()) {
      try {
        const response = await ExecuteEditCustomerUserUrl({ data });
        NotificationManager.success("Customer User Edited", 5000);
        history.push(
          `/superAdmin/manage-customers-profile/customers-users/${customersUserParamId}`
        );
      } catch (exception) {
        console.log("Error in Updating Customer User", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (error) {
    apiErrorHandler(error, history);
  }
  if (loading || loadingEdit) {
    return <Loading loading />;
  }

  const prerequisiteValue = {
    title: "Edit User",
    goBackLink: `/superAdmin/manage-customers-profile/customers-users/${customersUserParamId}`,
    btnText: "Save", // Btn One
    disabled: false,
    onClick: function () {
      onUpdateProfile();
    },
  };

  return (
    <ManageCustomerUserFields
      fields={fields}
      setFields={setFields}
      AreFieldsValidated={fieldValidation}
      handleChange={handleChange}
      onUpdate={onUpdateProfile}
      prerequisiteValue={prerequisiteValue}
    />
  );
};

export default EditCustomerUser;
