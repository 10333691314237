import * as React from "react";

const SvgPersonRound = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#D65746" />
    <path
      d="M18.94 17.941a4.47 4.47 0 1 0 0-8.941 4.47 4.47 0 0 0 0 8.941ZM19.69 19.081a14.391 14.391 0 0 0-8.137 2.012C10.57 21.674 10 22.769 10 23.92v2.961h10.35a7.782 7.782 0 0 1-.66-7.8Zm11.266 3.33c0-.245-.034-.469-.067-.703l1.274-1.13-1.118-1.933-1.62.548a4.073 4.073 0 0 0-1.207-.704l-.336-1.666h-2.235l-.335 1.666c-.447.167-.85.402-1.207.704l-1.62-.548-1.119 1.934 1.275 1.129c-.034.234-.067.458-.067.704 0 .246.033.47.067.704l-1.274 1.129 1.117 1.933 1.62-.547c.358.301.76.536 1.208.704L25.647 28h2.235l.336-1.665c.447-.168.85-.403 1.207-.704l1.62.547 1.118-1.933-1.274-1.13c.033-.234.067-.457.067-.703Zm-4.191 2.236a2.242 2.242 0 0 1-2.236-2.235 2.242 2.242 0 0 1 2.236-2.236A2.242 2.242 0 0 1 29 22.412a2.242 2.242 0 0 1-2.235 2.235Z"
      fill="#fff"
    />
  </svg>
);

export default SvgPersonRound;
