import React, { useEffect, useState } from "react";
import useAxios from "axios/httpService";
import { useHistory, useParams } from "react-router-dom";
/* eslint-disable react/prop-types */
import { GetInquiryByIdUrl } from "axios/commonAPIsUrls";
import { GetCustomQuestionbyIdUrl } from "axios/commonAPIsUrls";
import Loading from "components/Loading/Loading";
import CustomMetricsFields from "views/Common/CustomMetrics/CustomMetricsFields";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { ChangeStatusCustomQuestionUrl } from "axios/adminAPIsUrls";

const EditCustomMetrics = () => {
  const history = useHistory();
  const { inquiryParamId, CustomParamId } = useParams();
  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    Area: "",
    Inquiry: inquiryParamId,
    MaxLength: 200,
    ValidationRegex: "",
    AddedBy: "constant@gmail.com",
    RadioButtonOptions: [],
    CheckBoxOptions: [],
  });
  const [inquiryMenu, setInquiryMenu] = useState({});

  const token = localStorage.getItem("token");

  const [
    { data, loading: loadingInquiryById, error },
    ExecuteGetInquiryByIdUrl,
  ] = useAxios(GetInquiryByIdUrl(inquiryParamId, token));

  const [
    { data: dataQuestion, loading: loadingQuestion, error: errorQuestion },
    ExecuteGetCustomQuestionbyIdUrl,
  ] = useAxios(GetCustomQuestionbyIdUrl(CustomParamId, token));

  useEffect(() => {
    ExecuteGetInquiryByIdUrl();
    ExecuteGetCustomQuestionbyIdUrl();
  }, []);

  useEffect(() => {
    if (dataQuestion) {
      setFields({
        ...fields,
        Title: dataQuestion.Title,
        Type: dataQuestion.Type,
        Description: dataQuestion.Description,
        Area: dataQuestion.Area,
        MaxLength: dataQuestion.MaxLength,
        ValidationRegex: dataQuestion.ValidationRegex,
        AddedBy: dataQuestion.CreatedBy,
        RadioButtonOptions: dataQuestion.RadioButtonOptions,
        CheckBoxOptions: dataQuestion.CheckBoxOptions,
      });
    }
  }, [dataQuestion]);

  useEffect(() => {
    if (data) {
      setInquiryMenu({
        id: data.Id,
        label: data.Description,
      });
    }
  }, [data]);

  const [{ loading }, ExecuteChangeStatusCustomQuestionUrl] = useAxios(
    ChangeStatusCustomQuestionUrl(CustomParamId, token),
    {
      manual: true,
    }
  );

  const onEditCustom = async (response) => {
    let data = {
      Area: fields.Area,
      Status: response,
    };
    try {
      const res = await ExecuteChangeStatusCustomQuestionUrl({
        data,
      });
      if (response == "Reject") {
        NotificationManager.error("Custom Question", "Rejected", 5000);
      } else if (response == "Approved") {
        NotificationManager.success(`Custom Question Approved`, 5000);
      }
      history.push(`/superAdmin/custom-metrics`);
    } catch (exception) {
      console.log("Error in Updating Custom Question", exception);
      apiErrorHandler(exception, history);
    }
  };

  console.log(fields.Type);
  const prerequisiteValue = {
    title: "Edit Custom Added Question",
    goBackLink: "/superAdmin/custom-metrics",
    btnAccept: "Approve", // Btn One
    btnReject: "Reject", // Btn Two
    disabledArea: fields.Type === "Rating" ? false : true,
    disabled: true,
    Approve: function () {
      onEditCustom("Approved");
    },
    // Btn Two onClick function
    Reject: function () {
      onEditCustom("Reject");
    },
  };
  if (loading || loadingInquiryById || loadingQuestion) {
    return <Loading loading />;
  }

  return (
    <CustomMetricsFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      inquiryMenu={inquiryMenu}
      isedit={true}
    />
  );
};

export default EditCustomMetrics;
