import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import React from "react";
import useAxios from "axios/httpService";
import { GetRoadmapByIdUrl } from "axios/adminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory, useParams } from "react-router-dom";
import Loading from "components/Loading/Loading";
import FormContainer from "components/Forms/FormContainer";
import CustomFormSelect from "components/Selects/CustomSelect";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import RoadmapStep from "./RoadmapStep";

const useStyles = makeStyles(styles);
const EditRoadmap = () => {
  const history = useHistory();
  const classes = useStyles();
  const { roadmapId } = useParams();

  const token = localStorage.getItem("token");

  // Getting Roadmap Details
  const [
    {
      loading: isRoadmapDetailLoading,
      data: roadmapDetail,
      error: errorInRoadmapDetail,
    },
    ExecuteRoadmapDetailUrl,
  ] = useAxios(GetRoadmapByIdUrl(roadmapId, token));

  if (isRoadmapDetailLoading) {
    return <Loading loading />;
  }

  if (errorInRoadmapDetail) {
    console.log("Error in Getting Roadmap Detail", errorInRoadmapDetail);
    apiErrorHandler(errorInRoadmapDetail, history);
  }

  console.log(roadmapDetail && roadmapDetail.InquiryTypeName);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Roadmap"
              showBackButton
              goBackRoute={`/superAdmin/manage-roadmap`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Inquiry Type"
                  value={roadmapDetail && roadmapDetail.InquiryTypeId}
                  menu={[
                    {
                      id: roadmapDetail && roadmapDetail.InquiryTypeId,
                      label: roadmapDetail && roadmapDetail.InquiryTypeName,
                    },
                  ]}
                  customClassName={classes.textFieldSelectStyling}
                  disabled
                />
              </GridItem>
              {/* First Step */}
              <RoadmapStep
                stepNumber={1}
                stepTitle={roadmapDetail && roadmapDetail.Step1_RoadmapTitle}
                stepDetail={roadmapDetail && roadmapDetail.Step1_RoadmapDetail}
                stepDescription={
                  roadmapDetail && roadmapDetail.Step1_BriefDescription
                }
                disabled
              />
              {/* Second Step */}
              <RoadmapStep
                stepNumber={2}
                stepTitle={roadmapDetail && roadmapDetail.Step2_RoadmapTitle}
                stepDetail={roadmapDetail && roadmapDetail.Step2_RoadmapDetail}
                stepDescription={
                  roadmapDetail && roadmapDetail.Step2_BriefDescription
                }
                disabled
              />
              {/* Third Step */}
              <RoadmapStep
                stepNumber={3}
                stepTitle={roadmapDetail && roadmapDetail.Step3_RoadmapTitle}
                stepDetail={roadmapDetail && roadmapDetail.Step3_RoadmapDetail}
                stepDescription={
                  roadmapDetail && roadmapDetail.Step3_BriefDescription
                }
                disabled
              />
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default EditRoadmap;
