import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/components/dashboardCardStyle";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import repeatIcon from "assets/img/svgs/repeat.png";
import advInsightsIcon from "assets/img/svgs/advancedInsights.png";
import { Box } from "@mui/system";
import LocalOffer from "@material-ui/icons/LocalOffer";
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
const useStyles = makeStyles(styles);

const renderCardContent = (
  classes,
  numberOfParticipants,
  numberOfDocuments,
  numberOfAdvancedInsights,
  progressPercentage
) => (
  <>
    {/* FIRST ROW */}
    <GridContainer>
      {/* PARTICIPANTS */}
      <GridItem noDefaultPadding xs={6} md={6} sm={6}>
        <GridContainer className={classes.row}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <i className={classes.iconStyle + ` fa fa-user fa-sm`} />
            <h5 className={classes.text}>Participants</h5>
          </Box>
        </GridContainer>
        <h5 className={classes.count}>{numberOfParticipants}</h5>
      </GridItem>
      {/* ADVANCE INSIGHTS */}
      <GridItem noDefaultPadding xs={6} md={6} sm={6}>
        <GridContainer className={classes.row}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              src={advInsightsIcon}
              alt="advance-insights"
              className={classes.iconImgStyle}
            />
            <h5 className={classes.text}>Advance Insights</h5>
          </Box>
        </GridContainer>
        <h5 className={classes.count}>{numberOfAdvancedInsights}</h5>
      </GridItem>
    </GridContainer>

    {/* SECOND ROW */}
    <GridContainer>
      {/* DOCUMENTS */}
      <GridItem noDefaultPadding xs={6} md={6} sm={6}>
        <GridContainer className={classes.row}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <i className={classes.iconStyle + ` fa fa-file-alt fa-sm`} />
            <h5 className={classes.text}>Tools</h5>
          </Box>
        </GridContainer>
        <h5 className={classes.count}>{numberOfDocuments}</h5>
      </GridItem>
      {/* PROGRESS / COMPLETION */}
      <GridItem noDefaultPadding xs={6} md={6} sm={6}>
        <GridContainer className={classes.row}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              src={repeatIcon}
              alt="repeat-logo"
              className={classes.iconImgStyle}
            />
            <h5 className={classes.text}>Completion</h5>
          </Box>
        </GridContainer>
        <h5 className={classes.count}>{progressPercentage} %</h5>
      </GridItem>
    </GridContainer>
  </>
);

const DashboardCard = ({
  inquiryName,
  inquiryTypeName,
  numberOfParticipants,
  numberOfDocuments,
  numberOfAdvancedInsights,
  progressPercentage,
  onClick,
}) => {
  const classes = useStyles();

  const truncateString = (value, length) =>
    value && value.length > length ? value.substring(0, length) + "..." : value;

  const [showFullTitle, setShowFullTitle] = React.useState(false);

  const [showFullTag, setShowFullTag] = React.useState(false);

  return (
    <Card className={classes.root}>
      <CardHeader className={classes.header}>
        <GridContainer className={classes.row}>
          <GridItem noDefaultPadding xs={6} md={6} sm={6}>
            {showFullTitle && inquiryName.length > 15 ? (
              <div className="invalid-feedback-text d-block">{inquiryName}</div>
            ) : null}
            <a
              onMouseEnter={() => setShowFullTitle(true)}
              onMouseLeave={() => setShowFullTitle(false)}
            >
              {truncateString(inquiryName, 15)}
            </a>
          </GridItem>
          <GridItem noDefaultPadding xs={6} md={6} sm={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {showFullTag && inquiryTypeName.length > 10 ? (
                <div className="invalid-feedback-text-right d-block ">
                  {inquiryTypeName}
                </div>
              ) : null}
              <Box className={classes.tag}>
                <LocalOffer classes={{ root: classes.tagIcon }} />
                <span>
                  <a
                    onMouseEnter={() => setShowFullTag(true)}
                    onMouseLeave={() => setShowFullTag(false)}
                  >
                    {truncateString(inquiryTypeName, 10)}
                  </a>
                </span>
              </Box>
            </Box>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        {renderCardContent(
          classes,
          numberOfParticipants,
          numberOfDocuments,
          numberOfAdvancedInsights,
          progressPercentage
        )}
      </CardBody>
      <CardFooter className={classes.footer}>
        <Button
          color="primary"
          round
          className={classes.footerButton}
          size="md"
          disabled={progressPercentage !== 100}
          onClick={onClick}
        >
          <i className={classes.iconsButtons + " fa fa-chart-line fa-lg"} />
          View Results
        </Button>
      </CardFooter>
    </Card>
  );
};

export default DashboardCard;

DashboardCard.propTypes = {
  inquiryName: PropTypes.string,
  inquiryTypeName: PropTypes.string,
  numberOfAdvancedInsights: PropTypes.number,
  numberOfDocuments: PropTypes.number,
  numberOfParticipants: PropTypes.number,
  progressPercentage: PropTypes.number,
  onClick: PropTypes.func,
};
