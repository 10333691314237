/* eslint-disable no-constant-condition */
import React from "react";
import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/findingStyle";
import GridItem from "components/Grid/GridItem";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import AreaSquare from "components/ViewInsights/AreaSquare";

const useStyles = makeStyles(styles);

const AdvanceInsightsAreawise = ({ title, data, Color }) => {
  const classes = useStyles();

  return (
    <GridContainer className={classes.cont}>
      <GridItem xs={12} sm={12} md={3}>
        <AreaSquare areaName={title} color={Color} />
      </GridItem>
      <GridItem xs={12} sm={12} md={9}>
        {data &&
          data.map((item, key) => {
            return (
              <ul key={key} className={classes.list}>
                <li xs={12} sm={12} md={12} className={classes.listItem}>
                  {item.AdvanceInsights}
                </li>
              </ul>
            );
          })}
      </GridItem>
    </GridContainer>
  );
};

export default AdvanceInsightsAreawise;

AdvanceInsightsAreawise.propTypes = {
  title: PropTypes.string,
  Color: PropTypes.string,
  data: PropTypes.array,
};
