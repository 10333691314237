import React, { useState } from "react";
import Modal from "components/Modals/Modal";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomFileUploader from "components/Uploaders/CustomFileUploader";
import PropTypes from "prop-types";

// import styles
import styles from "assets/jss/material-dashboard-pro-react/views/fileUploadModalStyle";
import { makeStyles } from "@material-ui/core";
import useAxios from "axios/httpService";
import { ValidateExcelFileName } from "axios/adminAPIsUrls";
import { validateExcelFiles } from "helpers/validations";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "components/common/ReactNotifications";
import ButtonLoading from "components/Loading/ButtonLoading";
import { UploadExcelFileUrl, UploadHelpDocument } from "axios/adminAPIsUrls";
import ConfirmationAlert from "./ConfirmationAlert";
import MultipleCustomFileUploader from "components/Uploaders/MultipleCustomFileUploader";

const modalTitle = (classes) => (
  <GridItem
    xs={12}
    md={12}
    sm={12}
    noDefaultPadding
    className={classes.modalTitle}
  >
    <h4 className={classes.modalTitleText}>File Upload</h4>
  </GridItem>
);

const modalBody = (
  classes,
  list,
  setList,
  setMultiModalOpen,
  isMultiModalOpen,
  document,
  validationError,
  handleValidateFileInput,
  handleRemoveDocument,
  showAlert,
  setShowAlert
) => {
  if (showAlert) {
    return (
      <ConfirmationAlert
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        setModalOpen={setMultiModalOpen}
      />
    );
  }

  return (
    <>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        noDefaultPadding
        className={classes.modalBody}
      >
        <h5 className={classes.modalBodyText} style={{ padding: "10px 90px" }}>
          Please upload Help Document(s) here
        </h5>
      </GridItem>
      <GridItem
        xs={12}
        md={12}
        sm={12}
        noDefaultPadding
        className={classes.modalBody}
        style={{ maxHeight: "400px", overflowY: "auto" }}
      >
        <MultipleCustomFileUploader
          file={document}
          multiple
          onRemove={(event) => handleRemoveDocument(event)}
          onChange={(event) => {
            handleValidateFileInput(event.target.files);
          }}
          errorText={validationError}
        />
      </GridItem>
    </>
  );
};

const modalActions = (
  classes,
  setMultiModalOpen,
  isUploadBtnDisabled,
  handleUploadDocument,
  loading,
  showAlert,
  setShowAlert
) => (
  <GridItem
    xs={12}
    md={12}
    sm={12}
    noDefaultPadding
    className={classes.modalActions}
  >
    <Button
      color="primary"
      size="sm"
      round
      onClick={() => setMultiModalOpen(false)}
    >
      <i className={`fa fa-lg fa-times-circle ${classes.btnIcon}`}></i>
      {showAlert ? "No" : "Cancel"}
    </Button>
    {showAlert ? (
      <Button
        color="primary"
        size="sm"
        round
        disabled={isUploadBtnDisabled}
        onClick={() => setShowAlert(!showAlert)}
      >
        <i className={`fa fa-lg fa-check-circle ${classes.btnIcon}`}></i>
        {"Yes"}
      </Button>
    ) : (
      <Button
        color="primary"
        size="sm"
        round
        disabled={isUploadBtnDisabled}
        onClick={async () => await handleUploadDocument()}
      >
        <i className={`fa fa-lg fa-check-circle ${classes.btnIcon}`}></i>
        {loading ? <ButtonLoading loading={loading} /> : "Done"}
      </Button>
    )}
  </GridItem>
);
const useStyles = makeStyles(styles);

const MultiFileUploadModal = ({
  setMultiModalOpen,
  isMultiModalOpen,
  inquiryId,
  data,
  executeGetAllInquiries,
}) => {
  const [list, setList] = useState([0]);
  const classes = useStyles();
  const history = useHistory();

  const [fileInput, setFileInput] = React.useState([]);
  const [fileInputError, setFileInputError] = React.useState(null);
  const [isUploadBtnDisabled, setUploadBtnDisabled] = React.useState(null);
  const [showAlert, setShowAlert] = React.useState(true);

  const token = localStorage.getItem("token");
  const [
    { loading: uploadLoading },
    executeUploadHelpDocument,
  ] = useAxios(UploadHelpDocument(token, inquiryId), { manual: true });

  const handleValidateFileInput = (file, id) => {
    setFileInputError(null);
    //to check if the array we are making is empty or already contains file and its second iteration
    //to check if the user is attaching the single file or multifile through ctrl select
    if (fileInput.length == 0 && file.length == 1) {
      setFileInput([file[0]]);
    } else if (fileInput.length == 0 && file.length > 1) {
      let tempArray = [];
      for (let i = 0; i < file.length; i++) {
        tempArray.push(file[i]);
      }
      setFileInput(tempArray);
    } else if (fileInput.length > 0 && file.length == 1) {
      let Ifduplicate = fileInput.filter((oldfile) => {
        return oldfile.name == file[0].name;
      });
      if (Ifduplicate.length > 0) {
        NotificationManager.error(
          "Duplicate File",
          `${Ifduplicate[0].name} Already Uploaded`,
          5000
        );
      } else {
        setFileInput([...fileInput, file[0]]);
      }
    } else if (fileInput.length > 0 && file.length > 1) {
      let tempArray = [];
      for (let i = 0; i < file.length; i++) {
        let Ifduplicate = fileInput.filter((oldfile) => {
          return oldfile.name == file[i].name;
        });
        if (Ifduplicate.length > 0) {
          NotificationManager.error(
            "Duplicate File",
            `${Ifduplicate[0].name} Already Uploaded`,
            5000
          );
        } else {
          tempArray.push(file[i]);
        }
      }
      //tempArrayUnique is merging the array fileInput and new multiselectedfiles and altering down the previously selected files
      let tempArrayUnique = fileInput.concat(tempArray);
      setFileInput(tempArrayUnique);
    }
  };

  const handleRemoveDocument = (name) => {
    let updatedFile = fileInput.filter((file) => {
      return file.name != name;
    });
    setFileInput(updatedFile);
    setFileInputError(null);
  };

  const handleUploadDocument = async () => {
    if (fileInput.length == 0) {
      setFileInputError("Please upload a document, first.");
    }
    if (fileInput.length > 0 && !fileInputError) {
      setUploadBtnDisabled(true);
      try {
        const data = new FormData();
        for (let item of fileInput) {
          data.append("Files", item);
        }
        console.log(data.entries());
        await executeUploadHelpDocument({ data: data });
        await executeGetAllInquiries();
        NotificationManager.success("Document Uploaded Successfully.", 5000);
        setMultiModalOpen(false);
      } catch (error) {
        console.log("Error uploading excel file", error.response);
        apiErrorHandler(error, history);
      }
      setUploadBtnDisabled(false);
    }
  };
  const getInquiryStatus = () => {
    return data.find((item) => item.Id === inquiryId).Progress;
  };

  React.useEffect(() => {
    //alert is kept false in both condition and in else as it should not show alert at all
    if (getInquiryStatus() < 90) {
      setShowAlert(false);
    } else if (getInquiryStatus() == 90) {
      setShowAlert(false);
    } else {
      setShowAlert(false);
    }
  }, []);
  return (
    <Modal
      modalTitle={modalTitle(classes)}
      modalBody={modalBody(
        classes,
        list,
        setList,
        setMultiModalOpen,
        isMultiModalOpen,
        fileInput,
        fileInputError,
        handleValidateFileInput,
        handleRemoveDocument,
        showAlert,
        setShowAlert
      )}
      modalActions={modalActions(
        classes,
        setMultiModalOpen,
        isUploadBtnDisabled,
        handleUploadDocument,
        uploadLoading,
        showAlert,
        setShowAlert
      )}
    />
  );
};

export default MultiFileUploadModal;

MultiFileUploadModal.propTypes = {
  setMultiModalOpen: PropTypes.func,
  isMultiModalOpen: PropTypes.bool,
  inquiryId: PropTypes.number,
  executeGetAllInquiries: PropTypes.func,
  data: PropTypes.array,
};
