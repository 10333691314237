/* eslint-disable no-constant-condition */
/* eslint-disable react/display-name */
import { makeStyles } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import PropTypes from "prop-types";
import Loading from "components/Loading/Loading";
import { signupStore } from "store";
const styles = {
  centered: {
    textAlign: "center",
    marginTop: "100px",
  },
  success: {
    fontWeight: "400",
  },
  errorTitle: {
    color: "red",
  },
  errorDetail: {
    fontWeight: "200",
    fontSize: "1rem !important",
    color: "red",
  },
};
const useStyles = makeStyles(styles);
const Step3 = React.forwardRef((props, ref) => {
  const classes = useStyles();

  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));
  const isValidated = () => {
    return true;
  };

  const isSignupLoading = signupStore((state) => state.isSignupLoading);

  const signupError = signupStore((state) => state.signupError);

  if (isSignupLoading) {
    return <Loading loading={isSignupLoading} height="fixed" />;
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={classes.centered}>
        <h2 className={signupError ? classes.errorTitle : classes.success}>
          {signupError
            ? signupError.response.data.title
            : "Thank You For Signing Up!"}
        </h2>
        {signupError &&
          signupError.response.data.errorMessage.map((error, key) => {
            return (
              <h4 className={classes.errorDetail} key={key}>
                {error}
              </h4>
            );
          })}
      </GridItem>
    </GridContainer>
  );
});

export default Step3;

Step3.propTypes = {
  fields: PropTypes.object,
  setFields: PropTypes.func,
  fieldsValidations: PropTypes.object,
  setValidateFields: PropTypes.func,
};
