import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import styles from "assets/jss/material-dashboard-pro-react/components/navPillsStyle.js";

import { getActiveTabIndex } from "helpers/routesHelpers";
import jwtDecode from "jwt-decode";
import authStore from "store/authStore";
import surveyFormStore from "store/surveyFormStore";

const useStyles = makeStyles(styles);

const Menubar = (props) => {
  const classes = useStyles();
  const { color, menu } = props;
  let history = useHistory();
  const [layout, setLayout] = React.useState("");

  function a11yProps(index) {
    return {
      id: `scrollable-on-tab-${index}`,
      "aria-controls": `scrollable-on-tabpanel-${index}`,
    };
  }
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
  });

  const isAuthenticated = authStore((state) => state.isAuthenticated);
  const setIsPrompt = surveyFormStore((state) => state.setIsPrompt);
  const isPrompt = surveyFormStore((state) => state.isPrompt);
  const userToken = authStore((state) => state.userToken);
  const [path, setPath] = useState("");
  React.useEffect(() => {
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      if (decodedToken.role === "SuperAdmin") {
        setLayout("/superAdmin");
      } else if (decodedToken.role === "CustomerAdmin") {
        setLayout("/customerAdmin");
      } else if (decodedToken.role === "CustomerUser") {
        setLayout("/customerUser");
      }
    }
  }, [isAuthenticated, userToken]);
  useEffect(() => {
    if (path) {
      history.push(path);
    }
  }, [path]);
  return (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.primaryIndicator,
        scroller: classes.scroller,
      }}
      value={getActiveTabIndex()}
      variant="scrollable"
      scrollButtons="on"
    >
      {menu.map((tab, key) => {
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.pillsWithIcons]: tab.tabIcon !== undefined,
        });
        return (
          <Tab
            classes={{
              root: pillsClasses,
              selected: classes[color],
            }}
            label={
              <div className={classes.label}>
                <div className={classes.tabIcon}>{tab.tabIcon}</div>
                <div className={classes.tabName}>{tab.tabName}</div>
              </div>
            }
            {...a11yProps(key)}
            key={key}
            component={Link}
            onClick={() => {
              setIsPrompt(false);
              setPath(layout + tab.tabRoute);
              history.push(layout + tab.tabRoute);
            }}
            // to={layout + tab.tabRoute}
          />
        );
      })}
    </Tabs>
  );
};

export default Menubar;

Menubar.propTypes = {
  color: Proptypes.string,
  menu: Proptypes.array,
};
