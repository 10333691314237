import React from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import style from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import CustomInput from "components/Inputs/CustomInput";
import CustomFormSelect from "components/Selects/CustomSelect";
import { makeStyles } from "@material-ui/core";
import { isEmpty, isNumber } from "helpers/validations";
import PropTypes from "prop-types";
import {
  AREA_TYPES,
  INPUT_TYPES,
  INPUT_VALIDATIONS,
} from "constants/defaultValues";
import InputOptions from "components/Inputs/InputOptions";

const styles = {
  ...style,
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};
const useStyle = makeStyles(styles);

const DiagnosticsQuestionFields = ({
  fields,
  setFields,
  prerequisiteValue,
  AreFieldsValidated,
}) => {
  const classes = useStyle();

  // this useEffect is cleaning the input options if the user accidentally selects checkbox or radio
  React.useEffect(() => {
    // we do not need to run this useEffect for view questions
    // that is why, disabled property will tell us that if this component is being used for view or add
    // if disabled is true, then this component will not run
    if (!prerequisiteValue.disabled) {
      if (
        fields.Type.toLowerCase() === "rating" ||
        fields.Type.toLowerCase() === "checkbox"
      ) {
        const newFields = { ...fields };
        newFields.CheckBoxOptions = [];
        newFields.RadioButtonOptions = [];
        setFields(newFields);
      }
    }
  }, [fields.Type]);

  // this useEffect will select Rating input field if the user selects any area other than "Others"
  // client wants rating to be input field for every area field except for 'Others'
  // the input field will be locked when the input type is rating so that the user can only enter rating for any Areas other than "Others"
  React.useEffect(() => {
    if (fields.Area && fields.Area !== "Other") {
      setFields({ ...fields, Type: "Rating" });
    }
  }, [fields.Area]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title={prerequisiteValue.title}
              btnText={prerequisiteValue.btnText}
              showBackButton
              goBackRoute={prerequisiteValue.goBackLink}
              onClick={() => prerequisiteValue.onClick()}
            />
          </CardHeader>
          <CardBody>
            <FormContainer height>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomFormSelect
                    inputType="text"
                    required
                    label="Area"
                    value={fields.Area}
                    menu={AREA_TYPES}
                    customClassName={classes.textFieldSelectStyling}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        Area: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.Area)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    inputType="text"
                    required
                    label="Question Title"
                    value={fields.Title}
                    onChange={(event) => {
                      setFields({
                        ...fields,
                        Title: event.target.value,
                      });
                    }}
                    errorText={AreFieldsValidated && isEmpty(fields.Title)}
                    disabled={prerequisiteValue.disabled}
                  />
                </GridItem>
                {fields.Type.toLocaleLowerCase() === "textbox" ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomFormSelect
                      inputType="text"
                      required
                      label="Question Type"
                      value={fields.Type}
                      menu={INPUT_TYPES}
                      disabled={
                        (fields.Area && fields.Area !== "Other") ||
                        prerequisiteValue.disabled
                      }
                      customClassName={classes.textFieldSelectStyling}
                      onChange={(event) => {
                        setFields({
                          ...fields,
                          Type: event.target.value,
                        });
                      }}
                      errorText={AreFieldsValidated && isEmpty(fields.Type)}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="Question Detail"
                      multiline
                      value={fields.Description}
                      onChange={(event) => {
                        setFields({
                          ...fields,
                          Description: event.target.value,
                        });
                      }}
                      errorText={
                        AreFieldsValidated && isEmpty(fields.Description)
                      }
                      disabled={prerequisiteValue.disabled}
                    />
                  </GridItem>
                )}
                {fields.Type.toLocaleLowerCase() === "textbox" ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomFormSelect
                      inputType="text"
                      required
                      label="Validation Regex"
                      value={fields.ValidationRegex}
                      menu={INPUT_VALIDATIONS}
                      customClassName={classes.textFieldSelectStyling}
                      onChange={(event) => {
                        setFields({
                          ...fields,
                          ValidationRegex: event.target.value,
                        });
                      }}
                      errorText={
                        AreFieldsValidated && isEmpty(fields.ValidationRegex)
                      }
                      disabled={prerequisiteValue.disabled}
                    />
                  </GridItem>
                ) : null}
                {fields.Type.toLocaleLowerCase() === "textbox" ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="Question Detail"
                      multiline
                      value={fields.Description}
                      onChange={(event) => {
                        setFields({
                          ...fields,
                          Description: event.target.value,
                        });
                      }}
                      errorText={
                        AreFieldsValidated && isEmpty(fields.Description)
                      }
                      disabled={prerequisiteValue.disabled}
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomFormSelect
                      inputType="text"
                      required
                      label="Question Type"
                      value={fields.Type}
                      menu={INPUT_TYPES}
                      disabled={
                        (fields.Area && fields.Area !== "Other") ||
                        prerequisiteValue.disabled
                      }
                      customClassName={classes.textFieldSelectStyling}
                      onChange={(event) => {
                        setFields({
                          ...fields,
                          Type: event.target.value,
                        });
                      }}
                      errorText={AreFieldsValidated && isEmpty(fields.Type)}
                    />
                  </GridItem>
                )}
                {fields.Type.toLocaleLowerCase() === "textbox" ? (
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="Max. Length"
                      value={fields.MaxLength}
                      onChange={(event) => {
                        setFields({
                          ...fields,
                          MaxLength: event.target.value,
                        });
                      }}
                      errorText={
                        AreFieldsValidated && isNumber(fields.MaxLength)
                      }
                      disabled={prerequisiteValue.disabled}
                    />
                  </GridItem>
                ) : null}
                {fields.Type.toLowerCase() === "rating" ||
                fields.Type.toLowerCase() === "checkbox" ? (
                  <GridItem xs={12} sm={12} md={12}>
                    <InputOptions
                      fields={fields}
                      setFields={setFields}
                      options={
                        fields.Type.toLowerCase() === "rating"
                          ? fields.RadioButtonOptions
                          : fields.CheckBoxOptions
                      }
                      disabled={prerequisiteValue.disabled}
                      shouldValidateFields={AreFieldsValidated}
                      errorText={
                        AreFieldsValidated &&
                        isEmpty(
                          fields.Type.toLowerCase() === "rating"
                            ? fields.RadioButtonOptions
                            : fields.CheckBoxOptions
                        )
                      }
                    />
                  </GridItem>
                ) : null}
              </GridContainer>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default DiagnosticsQuestionFields;

DiagnosticsQuestionFields.propTypes = {
  fields: PropTypes.array,
  setFields: PropTypes.func,
  prerequisiteValue: PropTypes.object,
  AreFieldsValidated: PropTypes.bool,
};
