/* eslint-disable no-constant-condition */
import React, { useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/ScenariosStyle";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import useAxios from "axios/httpService";
import { GetRecommendationsResultUrl } from "axios/commonAPIsUrls";
import Loading from "components/Loading/Loading";
import ScenariosResuable from "./ScenariosReusable";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router-dom";
import { viewInsightsStore } from "store";

const useStyles = makeStyles(styles);
const Scenarios = () => {
  const inquiryId = viewInsightsStore((state) => state.inquiryId);
  const classes = useStyles();

  const history = useHistory();

  const token = localStorage.getItem("token");

  const [
    { data, loading, error },
    executeGetRecommendationsResultUrl,
  ] = useAxios(GetRecommendationsResultUrl(inquiryId, token));
  const getRecommendation = async () => {
    await executeGetRecommendationsResultUrl();
  };
  useEffect(() => {
    getRecommendation();
  }, []);
  if (loading) {
    return <Loading />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} className={classes.container}>
        <Card className={classes.shadow}>
          <CardHeader
            style={{
              display: "flex",
              justifyContent: "center",
              alignItem: "center",
            }}
          >
            <h5 className={classes.title} style={{ width: "60%" }}>
              Keep your team’s performance on track in real time by adjusting
              priority levels as your circumstances change. To take advantage of
              this feature, contact your Account Manager.
            </h5>
          </CardHeader>
          <CardBody>
            {data?.data && data?.data.length > 0 && (
              <ScenariosResuable
                data={data.data}
                //Color={"#D65746"}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Scenarios;
