import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import { GetAllCustomerUserUrl } from "axios/adminAPIsUrls";
import { useParams } from "react-router-dom";
import { DeleteCustomerUserUrl } from "axios/adminAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";
import AlertModal from "components/Alerts/AlertModal";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
  longtooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-25px",

    "&::before": {
      left: "60%",
      marginLeft: "-19px",
      //top: "41px",
    },
  },
};

const useStyles = makeStyles(styles);

const CustomerUser = () => {
  const history = useHistory();
  let { customersUserParamId } = useParams();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [customerUserId, setCustomerUserId] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const [
    { data: dataCustomer, error, loading },
    ExecuteGetAllCustomerUserUrl,
  ] = useAxios(
    GetAllCustomerUserUrl(customersUserParamId, currentPage, pageSize, token),
    { manual: true }
  );

  const [{ loading: deleteLoading }, ExecuteDeleteCustomerUserUrl] = useAxios(
    DeleteCustomerUserUrl(customerUserId, token),
    {
      manual: true,
    }
  );

  const deletePathwayhandler = async () => {
    try {
      const res = await ExecuteDeleteCustomerUserUrl();
      setAlert(!alert);
      NotificationManager.success("Customer User Deleted", 5000);
      ExecuteGetAllCustomerUserUrl();
    } catch (exception) {
      console.log("Error in Deleting Customer User", exception);
      apiErrorHandler(exception, history);
    }
  };

  useEffect(() => {
    ExecuteGetAllCustomerUserUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataCustomer) {
      setData(
        dataCustomer.data.map((prop, key) => {
          return {
            id: prop.Id,
            firstName: prop.FirstName,
            lastName: prop.LastName,
            email: prop.Email,
            jobTitle: prop.Designation,
            admin: <CustomSwitchLabel checked={prop.IsAdmin} noMarginPadding />,
            active: <CustomSwitchLabel checked={prop.Active} noMarginPadding />,
            verify: (
              <CustomSwitchLabel checked={prop.IsVerified} noMarginPadding />
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Profile"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(`${window.location.pathname}/view/${prop.Id}`)
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>

                <Button
                  action
                  tooltipText="Edit Profile"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(`${window.location.pathname}/edit/${prop.Id}`)
                  }
                >
                  <CreateOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Delete Profile"
                  tooltipClassName={classes.longtooltip}
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() => toggleDelete(prop.Id)}
                >
                  <DeleteForeverOutlinedIcon />
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataCustomer]);

  const toggleDelete = (id) => {
    setCustomerUserId(id);
    setAlert(!alert);
  };

  const classes = useStyles();

  const columns = [
    {
      Header: "First Name",
      accessor: "firstName",
      alignment: "center",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      alignment: "center",
    },
    {
      Header: "Email Address",
      accessor: "email",
      alignment: "center",
      textLength: 15,
    },
    {
      Header: "Job Title",
      accessor: "jobTitle",
      alignment: "center",
    },
    {
      Header: "Admin",
      accessor: "admin",
      alignment: "center",
    },
    {
      Header: "Active",
      accessor: "active",
      alignment: "center",
    },
    {
      Header: "Verify",
      accessor: "verify",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading || deleteLoading) {
    return <Loading loading />;
  }

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletePathwayhandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="User Management"
              showBackButton
              goBackRoute="/superAdmin/manage-customers-profile"
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataCustomer && dataCustomer.currentPage}
              defaultPageSize={dataCustomer && dataCustomer.pageSize}
              totalItems={dataCustomer && dataCustomer.totalItem}
              totalPages={dataCustomer && dataCustomer.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CustomerUser;
