import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import Button from "components/CustomButtons/Button";
import FormContainer from "components/Forms/FormContainer";
import { makeStyles } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import surveyFormStore from "store/surveyFormStore";
import useSurveyFormQuestions from "hooks/SurveyForms/useSurveyFormQuestions";
import useAxios from "axios/httpService";
import { AddSurveyFormAnswersUrl } from "axios/commonAPIsUrls";
import { useHistory, useParams } from "react-router";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { resetSurveyFormStore } from "store";
import DynamicSurveyFormTableAgainstAreas from "views/Common/SurveyForms/DynamicSurveyFormTableAgainstAreas";
import DynamicSurveyFormAgainstOthers from "views/Common/SurveyForms/DynamicSurveyFormAgainstOthers";
import jwtDecode from "jwt-decode";
import { validateExcelFiles } from "helpers/validations";
import { Box } from "@mui/material";
import { Icon } from "@iconify/react";
import useSurveyFormDetails from "hooks/SurveyForms/useSurveyFormDetails";
import { RouterPrompt } from "components/ReactPrompt/ReactPrompt";
import surveyData from "./surveyData";
import { GetAllSurveyFormQuestionsUrl } from "axios/customerUserAPIsUrls";
const useStyles = makeStyles(styles);

const EditSurveyForm = () => {
  const history = useHistory();

  const classes = useStyles();

  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const { inquiryTypeId, inquiryId } = useParams();
  const [pathname, setPathname] = useState("");
  const [newData, setNewData] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  // // GETTING SURVEY FORM QUESTIONS FROM CUSTOM HOOK
  const { loading, error } = useSurveyFormQuestions(inquiryTypeId, inquiryId);
  const [
    { data, loading: surveyloading, error: surveyErrpr },
    executeGetSurveyFormQuestions,
  ] = useAxios(
    GetAllSurveyFormQuestionsUrl(
      parseInt(inquiryTypeId),
      parseInt(inquiryId),
      -1,
      8,
      token
    ),
    {
      manual: true,
    }
  );
  React.useEffect(() => {
    resetSurveyFormStore();
    return () => {
      resetSurveyFormStore();
    };
  }, []);

  React.useEffect(() => {
    if (decodedToken.role === "CustomerUser") {
      setPathname("/customerUser/dashboard");
    } else if (decodedToken.role === "CustomerAdmin") {
      setPathname("/customerAdmin/survey-forms");
    }
  }, [decodedToken]);

  React.useEffect(async () => {
    await surveyData({
      executeGetSurveyFormQuestions: await executeGetSurveyFormQuestions(),
      decodedToken: decodedToken,
      inquiryId: inquiryId,
      setUploadedData,
    });
  }, []);
  // posting survey form answers to the backend
  const [{ loading: isAddLoading }, executeAddSurveyFormAnswers] = useAxios(
    AddSurveyFormAnswersUrl(token),
    {
      manual: true,
    }
  );
  const structureArray = surveyFormStore((state) => state.structureArray);
  const peopleArray = surveyFormStore((state) => state.peopleArray);
  const processArray = surveyFormStore((state) => state.processArray);
  const cultureArray = surveyFormStore((state) => state.cultureArray);
  const othersArray = surveyFormStore((state) => state.othersArray);
  const setIsPrompt = surveyFormStore((state) => state.setIsPrompt);
  const isPrompt = surveyFormStore((state) => state.isPrompt);
  let count = 0;

  const isDocNameDuplicated = surveyFormStore(
    (state) => state.isDocNameDuplicated
  );
  const ifExcelFormat = surveyFormStore((state) => state.ifExcelFormat);

  const handleSubmitSurveyForm = async (status) => {
    const surveyFormAnswers = [];
    let counter = 0;
    let names = [];
    for (let i = 0; i < othersArray.length; i++) {
      if (othersArray[i].Type == "Document") {
        counter++;
        names.push(othersArray[i].file.name);
      }
    }
    const toFindDuplicates = (arry) =>
      arry.filter((item, index) => arry.indexOf(item) !== index);
    const duplicateElements = toFindDuplicates(names);
    if (duplicateElements.length > 0 && duplicateElements[0] != undefined) {
      NotificationManager.error(
        "Error:",
        `File(${duplicateElements}) is attached twice`,
        7000
      );
    } else {
      structureArray.map((item) => surveyFormAnswers.push(item));
      peopleArray.map((item) => surveyFormAnswers.push(item));
      processArray.map((item) => surveyFormAnswers.push(item));
      cultureArray.map((item) => surveyFormAnswers.push(item));
      othersArray.map((item) => surveyFormAnswers.push(item));
      try {
        setIsPrompt(false);
        const data = new FormData();
        surveyFormAnswers.forEach((item, index) => {
          // Due to backend requirement, we are sending value as 0 for Dont Know selection in ratings
          // changing answers
          if (typeof item.Answer === "string") {
            if (isNaN(item.Answer) && item.Answer.toLowerCase() === "dk") {
              item.Answer = "0";
            }
          }
          // changing valueImportanceAnswer
          if (typeof item.ValueImportanceAnswer === "string") {
            if (
              isNaN(item.ValueImportanceAnswer) &&
              item.ValueImportanceAnswer.toLowerCase() === "dk"
            ) {
              item.ValueImportanceAnswer = "0";
            }
          }
          // if the answer is a document, then append 'document' in its answer
          item.Type === "Document" && (item.file || item.DownloadLink) ? (
            <>
              {data.append(`SurveyAnswerDtos[${index}].Answer`, "Document")}
              {data.append(
                `SurveyAnswerDtos[${index}].${
                  item.file ? "file" : "DocumentUrl"
                }`,
                item.file
                  ? item.file
                  : item.DownloadLink
                  ? item.DownloadLink
                  : ""
              )}
            </>
          ) : item.Type === "Document" && !item.file ? (
            <>
              {data.append(`SurveyAnswerDtos[${index}].Answer`, "No Document")}
              {data.append(
                `SurveyAnswerDtos[${index}].file`,
                item.file ? item.file : ""
              )}
            </>
          ) : (
            data.append(`SurveyAnswerDtos[${index}].Answer`, item.Answer)
          );
          data.append(`SurveyAnswerDtos[${index}].DiagnosticId`, item.Id);
          data.append(`SurveyAnswerDtos[${index}].InquiryId`, item.InquiryId);
          data.append(
            `SurveyAnswerDtos[${index}].CustomerUserId`,
            item.CustomerUserId
          );
          data.append(
            `SurveyAnswerDtos[${index}].IsCustomQuestion`,
            item.IsCustomQuestion
          );
          // if type is rating, only then append value answer in it
          item.Type === "Rating" &&
            data.append(
              `SurveyAnswerDtos[${index}].ValueAnswer`,
              item.ValueImportanceAnswer
            );

          item.CheckboxArray &&
            item.CheckboxArray.forEach((value, key) => {
              data.append(
                `SurveyAnswerDtos[${index}].CheckBoxOptions[${key}]`,
                value
              );
            });
        });
        data.append("Status", status);
        await executeAddSurveyFormAnswers({ data: data });
        NotificationManager.success(
          `${
            status === "saveasdraft"
              ? "Survey Form Saved As Draft Successfully."
              : "Survey Form Submitted Successfully."
          }`,
          5000
        );
        if (decodedToken.role === "CustomerUser") {
          history.push("/customerUser/dashboard");
        } else if (decodedToken.role === "CustomerAdmin") {
          history.push("/customerAdmin/survey-forms");
        }
      } catch (error) {
        setIsPrompt(true);
        console.log("error", error);
        apiErrorHandler(error, history);
      }
    }
  };
  React.useEffect(() => {
    if (ifExcelFormat === true) {
      NotificationManager.error(
        "Error:",
        `Invalid File Format,only Excel Format is allowed.`,
        5000
      );
    }
    return () => {
      // if canceled
      isDocNameDuplicated;
      ifExcelFormat;
    };
  }, [isDocNameDuplicated, ifExcelFormat]);

  if (error) {
    console.log("Error in Getting Survey Questions", error);
    apiErrorHandler(error, history);
  }
  const goBackRoute = () => {
    if (decodedToken.role === "CustomerUser") {
      return "/customerUser/dashboard";
    } else if (decodedToken.role === "CustomerAdmin") {
      return "/customerAdmin/survey-forms";
    }
  };
  useEffect(() => {
    const Answers = [];
    structureArray?.length > 0 &&
      structureArray.map((item) => Answers.push(item));
    peopleArray?.length > 0 && peopleArray.map((item) => Answers.push(item));
    processArray?.length > 0 && processArray.map((item) => Answers.push(item));
    cultureArray?.length > 0 && cultureArray.map((item) => Answers.push(item));
    othersArray?.length > 0 && othersArray.map((item) => Answers.push(item));
    setNewData(Answers);
    Answers?.length &&
      Answers.forEach((item, index) => {
        if (
          (item.Answer !== "checkbox" &&
            item.Answer !== "document" &&
            item.Answer) ||
          item.ValueImportanceAnswer ||
          (item.Type === "Document" &&
            (typeof item.file === "object" || item.DownloadLink)) ||
          (item.CheckboxArray?.length > 0 && item.CheckboxArray[1] !== "")
        ) {
          setIsPrompt(true);
        }
      });
  }, [
    structureArray,
    peopleArray,
    processArray,
    cultureArray,
    othersArray,
    uploadedData,
  ]);
  useEffect(() => {
    let compare = JSON.stringify(newData) !== JSON.stringify(uploadedData);
    setIsChanged(compare);
  }, [newData, uploadedData]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Complete Survey Form"
              showBackButton
              goBackRoute={goBackRoute()}
            />
            <h4 className={classes.parah4}>
              Welcome to{" "}
              <strong className={classes.parastrong}>ACTivate Advance</strong>,
              where custom organization performance roadmaps come to life in
              real time. As you complete the questions in this confidential
              portal, you can be assured that your candid responses will be a
              key to unlocking the strategic metrics that empower teams and
              remove barriers to excellence. Below you will find a number of
              questions relating to the performance of your team/organization.
              (Note: If you are an external stakeholder, please refer to the
              instructions provided by the administrator before completing this
              survey). For rating questions, please indicate to what extent you
              believe that the team/organization establishes/practices the
              following attributes. Although you may see evidence at times to
              support multiple answers, select the option you feel most closely
              reflects overall functioning. In addition, please indicate the
              level of value or importance you assign to each attribute. If you
              do not know the answer, please mark DK. For open-ended questions,
              please respond as completely and specifically as you can. The
              final portion of the survey will ask you to upload several
              documents. If you have questions about this portal, please ask
              your administrator for guidance. Technical support is available at{" "}
              <Link
                to="#"
                className={classes.parastrong}
                onClick={(e) => {
                  window.location = "mailto:noele@activate-technologies.com";
                  e.preventDefault();
                }}
              >
                info@activate-technologies.com
              </Link>
            </h4>
            <h4 className={classes.parah4}>
              <strong>IMPORTANT:</strong>
              <i>
                {" "}
                Please do not select the{" "}
                <strong className={classes.parastrong}>
                  &#34;Back&#34;
                </strong>{" "}
                button on your browser as you complete this survey, as this will
                delete your data. When you have completed the survey, please
                click{" "}
                <strong className={classes.parastrong}>&#34;Submit&#34;</strong>
                . If you cannot provide all your responses and documents in one
                session, please click{" "}
                <strong className={classes.parastrong}>
                  &#34;Save as Draft&#34;
                </strong>
                . This will allow you to save your responses and log back in
                later to complete the survey. After you log back in, you can
                make changes to any of your saved responses or change which
                documents you would like to upload; however, once you click{" "}
                <strong className={classes.parastrong}>&#34;Submit&#34;</strong>
                , responses and documents cannot be changed.
              </i>
            </h4>
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={12} sm={12} md={12}>
                {loading || isAddLoading ? (
                  <Loading loading={loading || isAddLoading} height="fixed" />
                ) : (
                  <>
                    {/* Culture */}
                    {cultureArray && cultureArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={cultureArray}
                        tableTitle={`Culture`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* People */}
                    {peopleArray && peopleArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={peopleArray}
                        tableTitle={`People`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* Process */}
                    {processArray && processArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={processArray}
                        tableTitle={`Process`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* Structure */}
                    {structureArray && structureArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={structureArray}
                        tableTitle={`Structure`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* OTHERS */}
                    {othersArray && othersArray?.length > 0 && (
                      <DynamicSurveyFormAgainstOthers
                        data={othersArray}
                        tableTitle={`Other`}
                        count={(count = count + 1)}
                        InquiryId={inquiryId}
                      />
                    )}
                    <div className={classes.surveyFormEnd}>
                      <h4 className={classes.answerInstructions}>
                        You have reached the end of this survey. Please click
                        <span className={classes.primaryText}> Submit </span>
                        button below.
                      </h4>
                      <Box
                        display={"flex"}
                        flexDirection="row"
                        sx={{ width: "330px", justifyContent: "space-between" }}
                      >
                        <Button
                          size="lg"
                          color="primary"
                          round
                          disabled={isDocNameDuplicated || ifExcelFormat}
                          onClick={() => {
                            handleSubmitSurveyForm("saveasdraft");
                          }}
                        >
                          <Icon icon="ri:draft-fill" />
                          Save as Draft
                        </Button>
                        <Button
                          size="lg"
                          color="primary"
                          round
                          disabled={isDocNameDuplicated || ifExcelFormat}
                          onClick={() => {
                            handleSubmitSurveyForm("completed");
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <i
                            className={`fa fa-sm fa-paper-plane ${classes.mr5}`}
                          />
                          Submit&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Button>
                      </Box>
                    </div>
                  </>
                )}
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
      {isPrompt && isChanged && (
        <RouterPrompt
          when={true}
          title="Leave this page"
          cancelText="Cancel"
          okText="Leave"
          onOK={() => true}
          onCancel={() => false}
          pathname={pathname}
        />
      )}
    </GridContainer>
  );
};

export default EditSurveyForm;
