import useAxios from "axios/httpService";
import { GetAllCustomerUserUrl } from "axios/adminAPIsUrls";

// THIS HOOK IS GETTING ALL STAFF USERS AGAINST AN ORGANIZATION

const useStaffUsers = (organizationId) => {
  const token = localStorage.getItem("token");
  const pageSize = 8;
  const currentPage = -1;
  const [{ data, loading, error }, executeGetStaffUsersUrl] = useAxios(
    GetAllCustomerUserUrl(organizationId, currentPage, pageSize, token)
  );

  if (data) {
    return { loading, error, customerUsers: data.data };
  }

  return { loading, error };
};

export default useStaffUsers;
