import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import CustomTable from "views/Common/CustomMetrics/CustomTable";
import { GetAllCustomQuestionByUserId } from "axios/customerAdminAPIsUrls";
import jwtDecode from "jwt-decode";
import { DeleteCustomUrl } from "axios/commonAPIsUrls";

const CustomMetricsCustomer = () => {
  const history = useHistory();
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [customQuestionId, setCustomQuestionId] = useState("");
  const [alert, setAlert] = useState(false);
  const token = localStorage.getItem("token");
  let userId;
  if (token) {
    const decodedToken = jwtDecode(token);
    userId = decodedToken.userid;
  }
  const [
    { data: dataCustomerAdmin, error, loading },
    ExecuteGetAllCustomQuestionByUserId,
  ] = useAxios(
    GetAllCustomQuestionByUserId(userId, currentPage, pageSize, token),
    { manual: true }
  );
  const [{ loading: deleteLoading }, ExecuteDeleteCustomUrl] = useAxios(
    DeleteCustomUrl(customQuestionId, token),
    {
      manual: true,
    }
  );
  const deletehandler = async () => {
    try {
      const res = await ExecuteDeleteCustomUrl();
      setAlert(!alert);
      NotificationManager.success("Custom Question Deleted", 5000);
      ExecuteGetAllCustomQuestionByUserId();
    } catch (exception) {
      console.log("Error in Deleting Custom Question", exception);
      setAlert(false);
      apiErrorHandler(exception, history);
    }
  };

  useEffect(() => {
    ExecuteGetAllCustomQuestionByUserId(currentPage);
  }, [currentPage]);

  if (loading || deleteLoading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <CustomTable
      dataAPi={dataCustomerAdmin}
      loading={loading}
      alert={alert}
      setCurrentPage={setCurrentPage}
      setAlert={setAlert}
      deletehandler={deletehandler}
      setId={setCustomQuestionId}
      showDeleteBtn
    />
  );
};

export default CustomMetricsCustomer;
