import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import PropTypes from "prop-types";

const useStyles = makeStyles(styles);

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomSelect = ({
  required,
  isError,
  label,
  errorText,
  labelWidth,
  value,
  onChange,
  menu,
  customClassName = " ",
  disabled = false,
}) => {
  const classes = useStyles();

  const [cursorPosition, setCursorPosition] = React.useState(0);

  const [classNames, setClassNames] = React.useState("");

  React.useEffect(() => {
    if (disabled) {
      setClassNames(classes.selectInput + " " + classes.disabledSelect);
    } else {
      setClassNames(classes.selectInput);
    }
  }, [disabled]);

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl + " " + customClassName}
    >
      {errorText && (
        <div
          className="invalid-feedback"
          style={{ left: `${cursorPosition}%` }}
        >
          {errorText}
        </div>
      )}
      <InputLabel
        required={required}
        classes={{
          asterisk: classes.required,
          root: classes.inputLabel,
        }}
        className={classes.inputLabel}
      >
        {label}
      </InputLabel>
      <Select
        className={classNames}
        classes={{
          outlined: classNames,
          root: classNames,
          selected: classNames,
        }}
        disabled={disabled}
        labelWidth={labelWidth ? labelWidth : label.length * 8}
        variant="outlined"
        MenuProps={{
          ...MenuProps,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          getContentAnchorEl: null,
        }}
        value={value}
        onChange={onChange}
      >
        {menu &&
          menu.map((option, key) => {
            return (
              <MenuItem
                key={key}
                value={option.id}
                classes={{ root: classes.menuItem, selected: classes.menuItem }}
              >
                {option.label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  required: PropTypes.bool,
  isError: PropTypes.bool,
  label: PropTypes.string,
  errorText: PropTypes.string,
  labelWidth: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  leftIcon: PropTypes.func,
  inputType: PropTypes.string,
  rightIcon: PropTypes.func,
  customClassName: PropTypes.className,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  menu: PropTypes.array,
  disabled: PropTypes.bool,
};
