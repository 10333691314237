/* eslint-disable react/prop-types */
import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import styles from "assets/jss/material-dashboard-pro-react/views/profileSetup";
import { Box, makeStyles } from "@material-ui/core";
import { validatePassword } from "helpers/validations";
import { validateConfPassword, isEmpty } from "helpers/validations";
import CustomInput from "components/Inputs/CustomInput";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ChangePassword = ({
  setIsModelOpen,
  isModelOpen,
  fields,
  setFields,
  AreFieldsValidated,
  changePasswordAPi,
  setValidateFields,
}) => {
  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalBorder,
          }}
          open={isModelOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader + " " + classes.flex}
          >
            <Box mx={18}>
              <h4 className={classes.modalTitle + " " + classes.bold}>
                Change Password
              </h4>
            </Box>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            <CustomInput
              inputType="password"
              required
              label="Current Password"
              value={fields.CurrentPassword}
              onChange={(event) => {
                setFields({
                  ...fields,
                  CurrentPassword: event.target.value,
                });
              }}
              errorText={AreFieldsValidated && isEmpty(fields.CurrentPassword)}
            />
            <CustomInput
              inputType="password"
              required
              label="New Password"
              value={fields.NewPassword}
              onChange={(event) => {
                setFields({
                  ...fields,
                  NewPassword: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated && validatePassword(fields.NewPassword)
              }
            />
            <CustomInput
              inputType="password"
              required
              label="Confirm Password"
              value={fields.ConfirmNewPassword}
              onChange={(event) => {
                setFields({
                  ...fields,
                  ConfirmNewPassword: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated &&
                validateConfPassword(
                  fields.NewPassword,
                  fields.ConfirmNewPassword
                )
              }
            />
            <h6>
              <span
                className={classes.logout}
                onClick={() => {
                  setValidateFields(false);
                  setFields({
                    CurrentPassword: "",
                    NewPassword: "",
                    ConfirmNewPassword: "",
                  });
                  setIsModelOpen(false);
                }}
              >
                Cancel
              </span>
            </h6>
          </DialogContent>
          <DialogActions className={classes.footer}>
            <Button
              color="primary"
              round
              className={classes.done}
              onClick={() => {
                changePasswordAPi();
              }}
            >
              {"Change Password"}
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
};

export default ChangePassword;
