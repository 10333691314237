/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import AlertModal from "components/Alerts/AlertModal";
import PersonIcon from "@material-ui/icons/Person";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import { useHistory } from "react-router";
import SurveyFormStatus from "components/SurveyForms/SurveyFormStatus";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

// eslint-disable-next-line react/prop-types
const CustomTable = ({
  dataAPi,
  loading,
  setCurrentPage,
  alert,
  setAlert,
  deletehandler,
  setId,
  showDeleteBtn,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const columns = [
    {
      Header: "Question Title",
      accessor: "questionTitle",
      alignment: "center",
    },
    {
      Header: "Added By",
      accessor: "addedBy",
      textLength: 15,
      alignment: "center",
    },
    {
      Header: "Inquiry",
      accessor: "inquiry",
      alignment: "center",
      textLength: 15,
    },
    {
      Header: "Question Type",
      accessor: "questionType",
      alignment: "center",
    },
    {
      Header: "Max Length",
      accessor: "maxLength",
      alignment: "center",
    },
    {
      Header: "Status",
      accessor: "status",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];

  useEffect(() => {
    if (dataAPi) {
      setData(
        dataAPi.data.map((prop, key) => {
          return {
            id: prop.CustomQuestionId,
            questionTitle: prop.Title,
            addedBy: prop.CreatedBy,
            inquiry: prop.Description,
            questionType: prop.Type,
            maxLength: prop.Type === "Textbox" ? prop.MaxLength : "-",
            status: (
              <div className="actions-center">
                <SurveyFormStatus
                  status={prop.Status == "Reject" ? "Rejected" : prop.Status}
                />
              </div>
            ),
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Custom"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/inquiry/${prop.InquiryId}/view/${prop.CustomQuestionId}`
                    )
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>

                <Button
                  action
                  tooltipText="Edit Custom"
                  justIcon
                  round
                  disabled={prop.Status.toLowerCase() === "approved"}
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/inquiry/${prop.InquiryId}/edit/${prop.CustomQuestionId}`
                    )
                  }
                >
                  <CreateOutlinedIcon />
                </Button>
                {showDeleteBtn ? (
                  <Button
                    action
                    tooltipText="Delete"
                    justIcon
                    round
                    simple
                    size="sm"
                    color="primary"
                    onClick={() => toggleDelete(prop.CustomQuestionId)}
                  >
                    <DeleteForeverOutlinedIcon />
                  </Button>
                ) : null}
              </div>
            ),
          };
        })
      );
    }
  }, [dataAPi]);

  const toggleDelete = (id) => {
    setId(id);
    setAlert(!alert);
  };

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletehandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader title="Custom Added Question" />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataAPi && dataAPi.currentPage}
              defaultPageSize={dataAPi && dataAPi.pageSize}
              totalItems={dataAPi && dataAPi.totalItem}
              totalPages={dataAPi && dataAPi.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CustomTable;
