// common APIs urls are those endpoints that can be accessed in different app layouts

// view survey form details endpoint (question + answer)
export function GetSurveyFormDetailsUrl(inquiryId, customerUserId, token) {
  return {
    url: `/SurveyAnswer/${inquiryId}/ViewSurveyAnswers/${customerUserId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// add survey form answers endpoint
export function AddSurveyFormAnswersUrl(token) {
  return {
    url: `/SurveyAnswer/AddSurveyAnswers`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      ContentType:
        "multipart/form-data; boundary=<calculted when request is sent>",
    },
  };
}

// validate duplicate document name in survey form
export function ValidateDocumentNameUrl(token) {
  return {
    url: "/SurveyAnswer/DocumentValidate",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting user profile for customer user and customer admin endpoint
export function GetProfileDetails(id, token) {
  return {
    url: `/CustomerUser/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// updating user profiles for customer user and customer admin endpoint
export function EditProfileDetailsUrl(id, token) {
  return {
    url: `/CustomerUser/${id}`,
    method: "Put",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting custom question by id
export function GetCustomQuestionbyIdUrl(CustomerQuestionId, token) {
  return {
    url: `/CustomQuestion/${CustomerQuestionId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// updating custom question by id
export function EditCustomQuestionUrl(CustomQuestionId, token) {
  return {
    url: `/CustomQuestion/${CustomQuestionId}`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// deleting custom question by id
export function DeleteCustomUrl(id, token) {
  return {
    url: `/CustomQuestion/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting inquiries by inquiry type id
export function GetInquiryByIdUrl(id, token) {
  return {
    url: `/Inquiry/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// changing password by user ID
export function ChangePasswordUrl(id, token) {
  return {
    url: `/auth/changepassword/${id}`,
    method: "Put",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// view insights / get findings result
export function GetFindingsResultUrl(inquiryId, token) {
  return {
    url: `/UploadResultDocument/FindingsResult/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

//view insights / get recomendation results(scenerios)
export function GetRecommendationsResultUrl(inquiryId, token) {
  return {
    url: `/UploadResultDocument/RecommendationResult/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get Area Colors from Different Inquiries
export function GetAreaColorsForInquiriesUrl(inquiryId, token) {
  return {
    url: `/UploadResultDocument/AreaColorResult/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get All Documents against an Inquiry
export function GetAllDocumentForInquiryUrl(
  inquiryId,
  currentPage,
  pageSize,
  token
) {
  return {
    url: `/UploadResultDocument/InquiryDocuments/${inquiryId}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// Get All Adv Insights against an Inquiry
export function GetAdvanceInsightsForInquiryUrl(inquiryId, token) {
  return {
    url: `/UploadResultDocument/AdvanceInsightsResult/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting all respondents against an inquiry ID
export function GetAllRespondentsUrl(InquiryId, currentPage, pageSize, token) {
  return {
    url: `/Inquiry/GetRespondents/${InquiryId}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// view insights / get input result
export function GetInputResultForInquiryUrl(inquiryId, token) {
  return {
    url: `/UploadResultDocument/InputResult/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export function GetRoadmapForInquiryUrl(inquiryId, token) {
  return {
    url: `/UploadResultDocument/RoadmapResult/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// ExportPDF
export function GetExportPdfUrl(inquiryId, token) {
  return {
    url: `/PDFReport/ExportData/${inquiryId}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
