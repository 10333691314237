import React from "react";
import PropTypes from "prop-types";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const SimpleProgressbar = ({ completed, showPercentage }) => {
  const containerStyles = {
    height: 15,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: "50px 0px",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed ? completed : 0}%`,
    backgroundColor: primaryColor[0],
    transition: "width 1s ease-in-out",
    borderRadius: "inherit",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {showPercentage && (
          <span style={labelStyles}>{completed ? completed : 0} %</span>
        )}
      </div>
    </div>
  );
};

export default SimpleProgressbar;

SimpleProgressbar.propTypes = {
  completed: PropTypes.number,
  showPercentage: PropTypes.bool,
};
