import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/intakeQuestionsResponse";
import { makeStyles } from "@material-ui/core";
import useIntakeQuestions from "hooks/useIntakeQuestions";
import CustomInputGrow from "components/Inputs/CustomInputGrow";
import CustomInputLabel from "components/Inputs/CustomInputLabel";
import Loading from "components/Loading/Loading";
import CustomCheckbox from "components/Checkboxes/CustomCheckbox";
import SimpleProgressbar from "components/Progressbars/SimpleProgressbar";
import { isEmpty } from "helpers/validations";
import DiagnosticsRecommendations from "./DiagnosticsRecommendations";
import TableHeader from "components/Tables/TableHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { useParams, useHistory } from "react-router";

const useStyles = makeStyles(styles);

const IntakeQuestionsResponse = () => {
  const classes = useStyles();

  const history = useHistory();

  const { inquiryId, inquiryTypeId } = useParams();

  const [areFieldsValidated, setValidateFields] = React.useState(false);

  const [fields, setFields] = React.useState("");

  const [answers, setAnswers] = React.useState([]);

  const [fieldIndex, setFieldIndex] = React.useState(0);

  const [markedAsChecked, setMarkedAsChecked] = React.useState(true);

  const [readyToSubmit, setReadyToSubmit] = React.useState(false);

  const { loading, intakeQuestions, error } = useIntakeQuestions(inquiryTypeId);

  const [progressPercentage, setProgressPercentage] = React.useState("");

  const getCheckbox = (key) => {
    return (
      <CustomCheckbox
        customClassName={classes.customPadding}
        checked={
          !answers[key]
            ? handleValueChange(key, "no")
            : answers[key].Answer === "yes"
            ? true
            : false
        }
        onChange={(event) => {
          let value = "";
          if (event.target.checked) {
            value = "yes";
          } else {
            value = "no";
          }
          handleValueChange(key, value);
        }}
      />
    );
  };

  const getTextbox = (key, maxLength) => {
    return (
      <CustomInputGrow
        label="Please answer your question"
        required
        maxLength={maxLength}
        multiline
        value={answers[key] && answers[key].Answer}
        onChange={(event) => handleValueChange(key, event.target.value)}
        errorText={
          areFieldsValidated && isEmpty(answers[key] && answers[key].Answer)
        }
      />
    );
  };

  // BELOW FUNCTION WILL EXECUTE WHEN INPUT VALUES ARE CHANGED
  const handleValueChange = (index, value) => {
    const newAnswer = [...answers];
    newAnswer[index] = {
      ...newAnswer[index],
      IntakeId: fields[index].intakeQuestionId,
      Answer: value,
    };
    setAnswers(newAnswer);
  };
  // BELOW FUNCTION WILL EXECUTE WHEN USER CLICKS ON CHECKBOX TO PROCEED THE FORM
  const handleCheckedMark = (key, event) => {
    if (!answers[key] || (answers[key] && answers[key].Answer === "")) {
      setValidateFields(true);
    } else {
      setValidateFields(false);
      const newValues = [...fields];
      newValues[key].isQuestionAnswered = event.target.checked;
      setFields(newValues);
      if (event.target.checked) {
        setMarkedAsChecked(true);
        setFieldIndex(key + 1);
      } else {
        setFields("");
        setMarkedAsChecked(false);
        setFieldIndex(key);
      }
    }
  };

  React.useEffect(() => {
    if (!error) {
      if (intakeQuestions && intakeQuestions.length > 0) {
        // below if condition is fulfilling happy flow of form
        if (markedAsChecked && fieldIndex < intakeQuestions.length) {
          const newValue = {
            intakeQuestionId: intakeQuestions[fieldIndex].intakeQuestionId,
            title: intakeQuestions[fieldIndex].title,
            description: intakeQuestions[fieldIndex].description,
            maxLength: intakeQuestions[fieldIndex].maxLength,
            inputType: intakeQuestions[fieldIndex].inputType,
            isQuestionAnswered: false,
          };
          setFields((prevState) => [...prevState, newValue]);
        } else if (!markedAsChecked) {
          // below code is fulfilling exceptional flow
          let index = 0;
          while (index <= fieldIndex) {
            const newValues = {
              intakeQuestionId: intakeQuestions[index].intakeQuestionId,
              title: intakeQuestions[index].title,
              description: intakeQuestions[index].description,
              maxLength: intakeQuestions[index].maxLength,
              inputType: intakeQuestions[index].inputType,
              isQuestionAnswered: index < fieldIndex ? true : false,
            };
            setFields((prevState) => [...prevState, newValues]);
            index++;
          }
        }

        // HANDLING THE VALUE OF PROGRESSBAR BELOW
        const numberOfQuestions = intakeQuestions.length;
        setProgressPercentage(parseInt((100 / numberOfQuestions) * fieldIndex));
      } else if (intakeQuestions && intakeQuestions.length === 0) {
        // setFields(["Not found"]);
      }
    }
  }, [error, fieldIndex]);

  if (readyToSubmit) {
    return (
      <DiagnosticsRecommendations
        readyToSubmit={readyToSubmit}
        setReadyToSubmit={setReadyToSubmit}
        inquiryId={inquiryId}
        answers={answers}
      />
    );
  }

  if (!readyToSubmit)
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <TableHeader title="Add New Inquiries" />
            </CardHeader>
          </Card>
          <Dialog
            classes={{
              root: classes.center + " " + classes.modalRoot,
              paper:
                classes.modal +
                " " +
                classes.modalBorder +
                " " +
                classes.modalSize,
            }}
            open
            keepMounted
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
          >
            <DialogTitle
              id="notice-modal-slide-title"
              disableTypography
              className={classes.modalHeader + " " + classes.flex}
            >
              <h4 className={classes.modalTitle + " " + classes.bold}>
                Intake Questions
              </h4>
            </DialogTitle>
            <DialogContent
              id="notice-modal-slide-description"
              className={classes.modalBody}
            >
              {loading && <Loading loading height="none" />}
              {intakeQuestions && fields.length === 0 ? (
                <div>
                  <p>No Intake Question Found. </p>
                  <p>
                    If you want to go back to inquiry logs,
                    <Button
                      className={classes.closeButton}
                      simple
                      size="sm"
                      color="primary"
                      tooltipText="Go back to Inquiry Logs"
                    >
                      <h4
                        className={classes.closeBtnText}
                        onClick={() =>
                          history.push("/customerAdmin/inquiry-logs")
                        }
                      >
                        Click here.
                      </h4>
                    </Button>
                  </p>
                </div>
              ) : null}
              {fields &&
                fields.map((question, key) => {
                  return (
                    <GridItem
                      key={question.intakeQuestionId}
                      xs={12}
                      md={12}
                      sm={12}
                    >
                      <div className={classes.row}>
                        <GridItem xs={1} md={1} sm={1} noDefaultPadding>
                          <CustomInputLabel label={`${key + 1}.`} bold />
                        </GridItem>
                        <GridItem xs={9} md={9} sm={9} noDefaultPadding>
                          <CustomInputLabel label={question.description} bold />
                        </GridItem>
                        <GridItem xs={2} md={2} sm={2} noDefaultPadding>
                          <CustomCheckbox
                            customClassName={classes.rightAligned}
                            checked={question.isQuestionAnswered}
                            onChange={(event) => {
                              handleCheckedMark(key, event);
                            }}
                          />
                        </GridItem>
                      </div>
                      <GridItem
                        xs={9}
                        md={9}
                        sm={9}
                        noDefaultPadding
                        className={classes.customGridStyle}
                      >
                        {key === fieldIndex
                          ? question.inputType.toLowerCase() === "textbox"
                            ? getTextbox(key, question.maxLength)
                            : getCheckbox(key)
                          : null}
                      </GridItem>
                    </GridItem>
                  );
                })}

              {/* SHOWING SIMPLE PROGRESSBAR HERE */}
              <GridItem xs={12} md={12} sm={12}>
                <SimpleProgressbar
                  completed={progressPercentage}
                  showPercentage
                />
              </GridItem>
            </DialogContent>
            <DialogActions className={classes.footer}>
              {intakeQuestions && intakeQuestions.length === fieldIndex ? (
                <Button
                  color="primary"
                  round
                  disabled={intakeQuestions && fields.length === 0}
                  className={classes.submit}
                  onClick={() => setReadyToSubmit(true)}
                >
                  Submit
                </Button>
              ) : null}
            </DialogActions>
          </Dialog>
        </GridItem>
      </GridContainer>
    );
};

export default IntakeQuestionsResponse;
