// getting all staff inquiries by email IDs
export function GetAllInquiriesStaffUrl(email, currentPage, pageSize, token) {
  return {
    url: `/Inquiry/Staff/${email}?pageSize=${pageSize}&currentPage=${currentPage}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

// getting organization details of the customer user
export function GetOrganizationDetailsUrl(organizationName) {
  return {
    url: `/Customer/CustomerByName/${organizationName}`,
    method: "GET",
  };
}

// getting survey form details (only questions)
export function GetAllSurveyFormQuestionsUrl(
  inquiryTypeId,
  inquiryId,
  currentPage,
  pageSize,
  token
) {
  return {
    url: `/SurveyAnswer/${inquiryTypeId}/${inquiryId}/SurveyFormQuestions?currentPage=${currentPage}&pageSize=${pageSize}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}
