import * as React from "react";

const SvgGroup = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 23 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.758 8.389a1.045 1.045 0 0 1-1.046 1.02c-1.045 0-1.045-1.047-1.045-1.047V8.35c0-.062.004-.125.011-.187a3.097 3.097 0 0 1 .586-1.487c.632-.855 1.77-1.48 3.603-1.449 1.324.024 2.5.579 3.235 1.492.749.93 1.001 2.189.505 3.428-.505 1.261-1.646 1.763-2.303 2.05l-.07.032c-.39.172-.575.26-.687.35l-.001.002v1.009a1.046 1.046 0 1 1-2.091.001v-1.045c0-.73.351-1.25.785-1.6.348-.278.787-.47 1.095-.607l.053-.024c.756-.333 1.115-.54 1.278-.946a1.283 1.283 0 0 0-.192-1.338c-.31-.384-.877-.7-1.643-.713-1.304-.022-1.736.398-1.886.603-.1.135-.164.293-.187.46v.008ZM11.5 18.818a1.394 1.394 0 1 0 0-2.788 1.394 1.394 0 0 0 0 2.788Z"
      fill="#D65746"
    />
    <path
      d="M0 3.485A3.485 3.485 0 0 1 3.485 0h16.03A3.485 3.485 0 0 1 23 3.485v19.863a1.045 1.045 0 0 1-1.046 1.046H2.091a1.394 1.394 0 0 0 1.394 1.394h18.47a1.045 1.045 0 1 1 0 2.09H3.485A3.485 3.485 0 0 1 0 24.395V3.484Zm2.09 0v18.818h18.82V3.485a1.394 1.394 0 0 0-1.395-1.394H3.485A1.394 1.394 0 0 0 2.09 3.485Z"
      fill="#D65746"
    />
  </svg>
);

export default SvgGroup;
