import React, { useEffect, useState } from "react";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import TableHeader from "components/Tables/TableHeader";
import AlertModal from "components/Alerts/AlertModal";
import InquiryTypesActions from "./InquiryTypesActions";

import useAxios from "axios/httpService";
import { GetAllInquiryTypesUrl } from "axios/adminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router-dom";
import { DeleteInquiryTypeUrl } from "axios/adminAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";
import Loading from "components/Loading/Loading";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import authStore from "store/authStore";
import jwtDecode from "jwt-decode";

const InquiryTypes = () => {
  const [alert, setAlert] = React.useState(false);
  const userToken = authStore((state) => state.userToken);
  const [recordId, setRecordId] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const toggleDelete = (id) => {
    setRecordId(id);
    setAlert(!alert);
  };

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;

  const token = localStorage.getItem("token");

  const [{ data, loading, error }, executeGetAllInquiryTypes] = useAxios(
    GetAllInquiryTypesUrl(currentPage, pageSize, token),
    {
      manual: true,
    }
  );

  useEffect(() => {
    executeGetAllInquiryTypes(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (data) {
      setDataTable(
        data.data.map((row, key) => {
          return {
            id: row.Id,
            inqType: row.InqType,
            details: row.Description,
            isActive: (
              <CustomSwitchLabel checked={row.IsActive} noMarginPadding />
            ),
            actions: (
              <InquiryTypesActions
                setAlert={setAlert}
                alert={alert}
                recordName={row.InqType}
                recordId={row.Id}
                toggleDelete={toggleDelete}
              />
            ),
          };
        })
      );
    }
  }, [data]);

  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  // DELETING AN INQUIRY TYPE

  const [
    { loading: deleteLoading },
    ExecuteDeleteInquiry,
  ] = useAxios(DeleteInquiryTypeUrl(recordId, token), { manual: true });

  const onDeleteInquiry = async () => {
    try {
      const response = await ExecuteDeleteInquiry();
      if (response.status === 200) {
        setAlert(!alert);
        NotificationManager.success("Inquiry Type Deleted", 5000);
        executeGetAllInquiryTypes();
      }
    } catch (exception) {
      setAlert(!alert);
      console.log("Error in Deleting  Inquiry Type", exception);
      apiErrorHandler(exception, history);
    }
  };

  const columns = [
    {
      Header: "Inquiry Type",
      accessor: "inqType",
      alignment: "left",
    },
    {
      Header: "Details",
      accessor: "details",
      alignment: "left",
    },
    {
      Header: "Active",
      accessor: "isActive",
      alignment: "center",
    },
    {
      Header: <div>Actions</div>,
      accessor: "actions",
      alignment: "center",
    },
  ];

  if (loading) {
    return <Loading loading />;
  }

  return (
    <GridContainer>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => onDeleteInquiry()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Inquiry Type Management"
              btnText={
                userToken && jwtDecode(userToken).role === "CustomerAdmin"
                  ? null
                  : "Create New Inquiry Type"
              }
              onClick={() => history.push(`${window.location.pathname}/create`)}
            />
          </CardHeader>
          <CardBody>
            {dataTable && (
              <ReactTable
                columns={columns}
                data={dataTable}
                loading={loading}
                currentPage={data && data.currentPage}
                defaultPageSize={data && data.pageSize}
                totalPages={data && data.totalPage}
                totalItems={data && data.totalItem}
                setCurrentPage={setCurrentPage}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default InquiryTypes;
