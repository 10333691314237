import { makeStyles } from "@material-ui/core";
import React from "react";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/Inputs/CustomInput";
import CustomFileUploader from "components/Uploaders/CustomFileUploader";
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import PropTypes from "prop-types";
import surveyFormStore from "store/surveyFormStore";
import DocumentPreview from "components/Uploaders/DocumentPreview";
import useAxios from "axios/httpService";
import { ValidateDocumentNameUrl } from "axios/commonAPIsUrls";
import CustomRadioButton from "components/RadioButtons/CustomRadioButton";
import CustomCheckboxLabel from "components/Checkboxes/CustomCheckboxLabel";
import { validateExcelFiles, validateFileFormat } from "helpers/validations";
import { EmptyFile } from "helpers/validations";
import { id } from "date-fns/locale";
import { NotificationManager } from "components/common/ReactNotifications";

const useStyles = makeStyles(styles);

const GetRequiredCustomInput = ({
  inputType,
  ratingOptions,
  checkboxOptions,
  rowIndex,
  InquiryId,
  data,
}) => {
  const classes = useStyles();
  const [inputValidator, setInputValidator] = React.useState([]);
  const othersArray = surveyFormStore((state) => state.othersArray);
  const setOthersArray = surveyFormStore((state) => state.setOthersArray);

  const disabledFields = surveyFormStore((state) => state.disabledFields);

  const isDocNameDuplicated = surveyFormStore(
    (state) => state.isDocNameDuplicated
  );
  const setDocNameDuplication = surveyFormStore(
    (state) => state.setDocNameDuplication
  );
  const isDocNameDuplicatedObject = surveyFormStore(
    (state) => state.isDocNameDuplicatedObject
  );
  const setDocNameDuplicationObject = surveyFormStore(
    (state) => state.setDocNameDuplicationObject
  );
  const setDocNameTrue = surveyFormStore((state) => state.setDocNameTrue);
  const ifExcelFormat = surveyFormStore((state) => state.ifExcelFormat);

  const setNotExcelFormat = surveyFormStore((state) => state.setNotExcelFormat);
  const checker = (result, id) => {
    if (!result) {
      // console.log("false");
      if (!isDocNameDuplicatedObject.includes(id)) {
        setDocNameDuplicationObject(id);
      }
    } else if (result) {
      // console.log("true");
      if (isDocNameDuplicatedObject.includes(id)) {
        setDocNameTrue(id);
      }
    }
  };
  // console.log(inputValidator);
  const handleInputValue = (value, index) => {
    value = isNaN(value) && value.toLowerCase() === "dk" ? "0" : value;
    const newArray = [...othersArray];
    newArray[index].Answer = value;
    setOthersArray(newArray);
  };

  const handleInputCheckbox = (checked, value, index) => {
    if (checked === true) {
      othersArray[index].Answer = "checkbox";
    } else {
      othersArray[index].Answer = "no checkbox";
    }
    const newArray = [...othersArray];
    newArray[index].CheckboxArray = othersArray[index]?.CheckboxArray
      ? [...othersArray[index]?.CheckboxArray]
      : [];
    if (checked) {
      newArray[index]?.CheckboxArray.push(value);
    } else {
      const valueIndex = newArray[index]?.CheckboxArray?.indexOf(value);
      if (valueIndex > -1) {
        newArray[index]?.CheckboxArray.splice(valueIndex, 1);
      }
    }
    setOthersArray(newArray);
  };

  const handleInputDocument = (event, index, actionType) => {
    const newArray = [...othersArray];
    if (actionType === "upload") {
      newArray[index].Answer = "document";
      newArray[index].file = event.target.files[0] || "";
    } else if (actionType === "remove") {
      newArray[index].Answer = "";
      newArray[index].file = "";
      newArray[index].DownloadLink = "";
    }
    setOthersArray(newArray);
  };

  const token = localStorage.getItem("token");
  React.useEffect(() => {
    setDocNameDuplication(isDocNameDuplicatedObject.length > 0);
  }, [isDocNameDuplicatedObject]);

  // Validating the document name from backend because it must be unique
  const [, executeValidateDocName] = useAxios(ValidateDocumentNameUrl(token), {
    manual: true,
  });
  const validateDocumentName = async (file, id) => {
    const DiagnosticId = data.filter((item) => item.Answer === "document");
    try {
      await executeValidateDocName({
        data: {
          DocumentName: file.name,
          InquiryId: parseInt(InquiryId),
          DiagnosticId: DiagnosticId[0]?.Id,
        },
      });
      checker(true, id);
    } catch (error) {
      if (error !== undefined) {
        NotificationManager.error(
          "Error:",
          `${file.name}  ${error?.response?.data?.errorMessage[0]}`,
          7000
        );

        checker(false, id);
      }
    }
  };
  const ExcelFormatChecker = async (value) => {
    // console.log("validate", value);
    if (value !== null) {
      //setNotExcelFile(true);
      const temp = true;
      setNotExcelFormat(temp);
    } else {
      //setNotExcelFile(false);
      const temp = false;
      setNotExcelFormat(temp);
    }
  };

  const disabled = false;
  if (inputType && inputType.toLowerCase() === "textbox") {
    return (
      <div className={classes.formField}>
        <GridItem xs={8} md={8} sm={8} noDefaultPadding>
          <CustomInput
            label="Required Field"
            value={othersArray[rowIndex].Answer}
            required
            MaxLength={othersArray[rowIndex].MaxLength}
            disabled={disabledFields}
            multiline
            onChange={(event) => {
              const reg = new RegExp(/^\d+$/);
              const validateNumber = reg.test(event.target.value);
              const letters = new RegExp(/^([^0-9]*)$/); //we have changed the regex that in letter it will not allow numbers only
              const validateLetters = letters.test(event.target.value);
              const alphanumeric = new RegExp(
                /^[a-zA-Z0-9_]+(?:\W+[a-zA-Z0-9_]+)*\W*$/
              ); // eslint-disable-line
              const validateAlphanum = alphanumeric.test(event.target.value);
              if (
                (othersArray[rowIndex].ValidationRegex === "Number(s) only" &&
                  validateNumber) ||
                (othersArray[rowIndex].ValidationRegex === "Alphabet only" &&
                  validateLetters) ||
                (othersArray[rowIndex].ValidationRegex ===
                  "Alphanumeric only" &&
                  validateAlphanum) ||
                event.target.value === ""
              ) {
                handleInputValue(event.target.value, rowIndex);
              }
            }}
            rows={3}
            customInput={classes.customInput}
            // errorText={
            //   areFieldsValidated[validationIndex] &&
            //   isEmpty(surveyFormAnswers[targetIndex].Answer)
            // }
          />
        </GridItem>
      </div>
    );
  } else if (inputType && inputType.toLowerCase() === "checkbox") {
    return (
      <div
        className={classes.formField}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0px",
          padding: "0px",
        }}
      >
        {checkboxOptions &&
          checkboxOptions?.map((option, key) => {
            let checked = null;
            if (
              disabledFields &&
              othersArray[rowIndex]?.CheckBoxAnswers !== null
            ) {
              checked = othersArray[rowIndex]?.CheckBoxAnswers?.find(
                (x) => x === option
              );
            } else {
              checked =
                othersArray && othersArray[rowIndex]?.CheckboxArray?.length
                  ? othersArray[rowIndex]?.CheckboxArray?.includes(option)
                  : othersArray[rowIndex]?.CheckBoxAnswers?.find(
                      (x) => x === option
                    );
            }
            return (
              <GridItem xs={2} md={2} sm={2} key={key} noDefaultPadding>
                <CustomCheckboxLabel
                  label={option}
                  checked={checked}
                  value={option}
                  disabled={disabledFields}
                  onChange={(event) =>
                    handleInputCheckbox(
                      event.target.checked,
                      event.target.value,
                      rowIndex
                    )
                  }
                  customClassname={classes.customCheckbox}
                />
              </GridItem>
            );
          })}
      </div>
    );
  } else if (inputType && inputType.toLowerCase() === "rating") {
    return (
      <div
        className={classes.formField}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0px",
          padding: "0px",
        }}
      >
        {ratingOptions &&
          ratingOptions.map((option, key) => {
            return (
              <GridItem xs={2} md={2} sm={2} key={key}>
                <CustomRadioButton
                  label={option}
                  value={option}
                  disabled={disabledFields}
                  checked={
                    othersArray &&
                    othersArray[rowIndex].Answer ===
                      (option === "DK" ||
                      option === "dk" ||
                      option === "Dk" ||
                      option === "dK"
                        ? "0"
                        : option)
                  }
                  onChange={(event) =>
                    handleInputValue(event.target.value, rowIndex)
                  }
                />
              </GridItem>
            );
          })}
      </div>
    );
  } else if (inputType && inputType.toLowerCase() === "document") {
    return (
      <div className={classes.formField}>
        {disabledFields ? (
          <GridContainer>
            <GridItem noDefaultPadding xs={4} md={4} sm={4}>
              <DocumentPreview
                fileName={othersArray[rowIndex].DocumentName}
                fileDownloadLink={othersArray[rowIndex].DownloadLink}
              />
            </GridItem>
          </GridContainer>
        ) : (
          <CustomFileUploader
            file={
              othersArray[rowIndex].file
                ? othersArray[rowIndex].file
                : othersArray[rowIndex].DownloadLink
            }
            fileName={
              othersArray[rowIndex].DocumentName
                ? othersArray[rowIndex].DocumentName
                : null
            }
            id={rowIndex}
            onChange={async (event) => {
              handleInputDocument(event, rowIndex, "upload");
              await validateDocumentName(othersArray[rowIndex].file, rowIndex);
              // console.log(othersArray[rowIndex].file.type);
              const excelFileStatus = await validateFileFormat(
                othersArray[rowIndex].file
              );
              //console.log(excelFileStatus);
              await ExcelFormatChecker(excelFileStatus);
            }}
            onRemove={async (event) => {
              handleInputDocument(event, rowIndex, "remove");
              // await validateDocumentName(othersArray[rowIndex].file);
              const excelFileStatus = await EmptyFile();
              await ExcelFormatChecker(excelFileStatus);
              setDocNameTrue(rowIndex);
            }}
            // errorText={
            //   othersArray[rowIndex].Answer &&
            //   isDocNameDuplicated &&
            //   "Document Name already exists."
            // }
          />
        )}
      </div>
    );
  }
  return (
    <div>
      <h1>Required Component Not Found.</h1>
    </div>
  );
};

export default GetRequiredCustomInput;

GetRequiredCustomInput.propTypes = {
  inputType: PropTypes.string,
  ratingOptions: PropTypes.array,
  checkboxOptions: PropTypes.array,
  rowIndex: PropTypes.number,
  InquiryId: PropTypes.number,
  data: PropTypes.array,
};
