import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import React, { useEffect } from "react";
import useAxios from "axios/httpService";
import { GetInquiryTypeUrl } from "axios/adminAPIsUrls";
import Loading from "components/Loading/Loading";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router-dom";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import CustomInput from "components/Inputs/CustomInput";

const ViewInquiryType = () => {
  const history = useHistory();
  const splitUrl = window.location.pathname.split("/");
  const recordId = splitUrl[splitUrl.length - 1];

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteGetInquiryTypeDetails] = useAxios(
    GetInquiryTypeUrl(recordId && recordId, token)
  );

  useEffect(() => {
    ExecuteGetInquiryTypeDetails();
  }, []);

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Inquiry Type"
              showBackButton
              goBackRoute="/superAdmin/inquiry-type-management"
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={6} sm={6} md={6}>
                {/* FOR INQUIRY TYPES */}
                <CustomInput
                  inputType="text"
                  disabled
                  label="Inquiry Type"
                  value={data && data.InqType}
                  required
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CustomInput
                  disabled
                  inputType="text"
                  label="Details"
                  value={data && data.Description}
                  multiline
                  rows={5}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomSwitchLabel
                  insideForm
                  disabled
                  checked={data && data.IsActive}
                  label="Active"
                />
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewInquiryType;
