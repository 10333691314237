import React from "react";
// core components
import Button from "components/CustomButtons/Button.js";
import { PropTypes } from "prop-types";

// importing SVGs

import RecreateInquirySvg from "assets/img/svgsComponents/Edit.js";
import EyeSvg from "assets/img/svgsComponents/Eye.js";
import AddCustomQuestionsSvg from "assets/img/svgsComponents/Group.js";
import ManageParticipantsSvg from "assets/img/svgsComponents/Vector.js";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core";
import jwtDecode from "jwt-decode";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

const styles = {
  ViewRespondants: {
    //right: "3%",
    width: "10%",
    marginTop: "-2.5rem",

    "&::before": {
      left: "60%",
      marginLeft: "-44px",
      top: "41px",
    },
  },
  longtooltip: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-23px",

    "&::before": {
      left: "60%",
      marginLeft: "-36px",
      //top: "41px",
    },
  },
  ViewRespondantsToolTip: {
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.48rem",
    marginInlineStart: "-70px",
    "&::before": {
      left: "136%",
      marginLeft: "-70px",
      //top: "41px",
    },
  },

  CreateCustomQuestion: {
    //right: "3%",
    width: "fit-content !important",
    zIndex: "5",
    marginTop: "-1.25rem",
    marginInlineStart: "-17px",

    "&::before": {
      left: "60%",
      marginLeft: "-64px",
      //top: "41px",
    },
  },
};

const useStyles = makeStyles(styles);

const InquiryLogActions = (props) => {
  const { inquiryTypeId, inquiryId, respondentsAdded, progress } = props;
  const classes = useStyles();
  const history = useHistory();

  const recreateInquiry = () => {
    history.push(
      `/customerAdmin/inquiry-logs/recreate/inquiry/${inquiryId}`
      //`/customerAdmin/inquiry-logs/inquiry-types/${inquiryTypeId}/inquiry/${inquiryId}`
    );
  };

  const manageRespondents = () => [
    history.push(`/customerAdmin/inquiry-logs/manage-respondents/${inquiryId}`),
  ];

  const redirectToViewInsights = () => {
    const token = localStorage.getItem("token");
    const decodedToken = jwtDecode(token);
    if (decodedToken.role === "SuperAdmin") {
      history.push(`/superAdmin/dashboard/view-insights/${inquiryId}/overview`);
    } else if (decodedToken.role === "CustomerAdmin") {
      history.push(
        `/customerAdmin/dashboard/view-insights/${inquiryId}/overview`
      );
    }
  };
  return (
    // we've added some custom button actions
    <div className="actions-center">
      <Button
        justIcon
        round
        simple
        size="sm"
        color="primary"
        action
        tooltipText="Manage Respondents"
        onClick={() => manageRespondents()}
      >
        <div style={{ fontSize: "30px" }}>
          <ManageParticipantsSvg />
        </div>
      </Button>
      <Button
        action
        disabled={respondentsAdded}
        tooltipText="Create Custom Question"
        tooltipClassName={classes.CreateCustomQuestion}
        justIcon
        round
        simple
        size="sm"
        color="primary"
        onClick={() =>
          history.push(
            `/customerAdmin/inquiry-logs/custom-metrics/inquiry/${inquiryId}/create`
          )
        }
      >
        <div style={{ fontSize: "30px" }}>
          <AddCustomQuestionsSvg />
        </div>
      </Button>
      <Button
        justIcon
        round
        simple
        size="sm"
        color="primary"
        action
        disabled={progress !== 100}
        tooltipText="View Results"
        onClick={redirectToViewInsights}
      >
        <div style={{ fontSize: "30px" }}>
          <EyeSvg />
        </div>
      </Button>
      <Button
        justIcon
        round
        simple
        size="sm"
        color="primary"
        action
        tooltipText="Recreate"
        onClick={() => recreateInquiry()}
      >
        <div style={{ fontSize: "30px" }}>
          <RecreateInquirySvg />
        </div>
      </Button>
      <Button
        action
        tooltipText="View Respondents"
        tooltipClassName={classes.ViewRespondantsToolTip}
        justIcon
        round
        simple
        size="sm"
        color="primary"
        onClick={() =>
          history.push(
            `${window.location.pathname}/view-respondents/${inquiryId}`
          )
        }
      >
        <VisibilityOutlinedIcon />
      </Button>
    </div>
  );
};

export default InquiryLogActions;

InquiryLogActions.propTypes = {
  inquiryTypeId: PropTypes.number,
  inquiryId: PropTypes.number,
  respondentsAdded: PropTypes.bool,
  progress: PropTypes.number,
};
