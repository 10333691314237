import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import Slide from "@material-ui/core/Slide";
import styles from "assets/jss/material-dashboard-pro-react/views/profileSetup";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import useAxios from "axios/httpService";
import { EditCustomerProfileUrl } from "axios/customerAdminAPIsUrls";
import jwtDecode from "jwt-decode";
import { NotificationManager } from "components/common/ReactNotifications";
import authStore from "store/authStore";
import ButtonLoading from "components/Loading/ButtonLoading";
import {
  convertIntoNumber,
  isEmpty,
  validateMandatoryNumber,
} from "helpers/validations";

import CustomInput from "components/Inputs/CustomInput";
import CustomInputDropdown from "components/Inputs/CustomInputDropdown";

// import constants
import { TIME_DURATION } from "constants/defaultValues";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ProfileSetup = () => {
  const classes = useStyles();
  const [fields, setFields] = React.useState({
    yearsInBusiness: "",
    profitOrNonprofit: "",
    ownershipStructure: "",
    annualRevenue: "",
    averageFTE: "",
    numberOfLocations: "",
    headquarterAddress: "",
    duration: "days",
  });

  const [AreFieldsValidated, setValidateFields] = React.useState(false);

  const [isModalOpen, setModalOpen] = React.useState(true);

  const setFirstLogin = authStore((state) => state.setFirstLogin);

  const history = useHistory();

  const token = localStorage.getItem("token");
  let customerId = "";
  let userId = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    customerId = decodedToken.customerid;
    userId = decodedToken.userid;
  }

  const [{ loading }, executeEditCustomerProfile] = useAxios(
    EditCustomerProfileUrl(customerId, userId, token),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    // TEMPORARILY VALIDATING EACH AND EVERY FIELD, LATER WE WILL IMPLEMENT YUP
    if (isEmpty(fields.yearsInBusiness)) {
      return false;
    }
    if (isEmpty(fields.duration)) {
      return false;
    }
    if (isEmpty(fields.profitOrNonprofit)) {
      return false;
    }
    if (isEmpty(fields.ownershipStructure)) {
      return false;
    }
    if (validateMandatoryNumber(fields.annualRevenue)) {
      return false;
    }
    if (validateMandatoryNumber(fields.averageFTE)) {
      return false;
    }
    if (validateMandatoryNumber(fields.numberOfLocations)) {
      return false;
    }
    if (isEmpty(fields.headquarterAddress)) {
      return false;
    }

    return true;
  };

  const onClickDone = async () => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      const data = {
        YearsInBusiness: convertIntoNumber(fields.yearsInBusiness),
        duration: fields.duration,
        ProfitOrNOn: fields.profitOrNonprofit,
        OwnerShipStructure: fields.ownershipStructure,
        AnnualRevenue: convertIntoNumber(fields.annualRevenue),
        AverageFTE: convertIntoNumber(fields.averageFTE),
        NumberOfLocations: convertIntoNumber(fields.numberOfLocations),
        HeadquarterAddress: fields.headquarterAddress,
        IsActive: true,
        // hardcoding the value of isActive due to backend
      };
      try {
        console.log(data);
        const response = await executeEditCustomerProfile({ data: data });
        console.log(response.status);
        if (response.status === 200) {
          NotificationManager.success(
            "Profile Setup Completed Successfully.",
            5000
          );
          setModalOpen(false);
          localStorage.setItem("isFirstLogin", false);
          setFirstLogin(false);
        }
      } catch (exception) {
        console.log("Error in Profile Setup", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  const handleYearsDropdown = (value) => {
    setFields({
      ...fields,
      duration: value,
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal + " " + classes.modalBorder,
          }}
          open={isModalOpen}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader + " " + classes.flex}
          >
            <h4 className={classes.modalTitle + " " + classes.bold}>
              Please answer these questions for company account setup
            </h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
            {/* YEARS IN BUSINESS */}
            <CustomInputDropdown
              inputType="number"
              minLength={0}
              required
              label="How Long in business"
              value={fields.yearsInBusiness}
              onChange={(event) => {
                var numbers = new RegExp(/^[0-9]+$/);
                if (
                  event.target.value != null &&
                  numbers.test(event.target.value)
                ) {
                  setFields({
                    ...fields,
                    yearsInBusiness: event.target.value,
                  });
                }
              }}
              dropdownMenu={TIME_DURATION}
              dropdownValue={fields.duration}
              handleDropdown={(event) =>
                handleYearsDropdown(event.target.value)
              }
              errorText={AreFieldsValidated && isEmpty(fields.yearsInBusiness)}
            />

            {/* FOR PROFIT OR NON PROFIT */}
            <CustomInput
              inputType="text"
              required
              label="For profit or non-profit"
              value={fields.profitOrNonprofit}
              onChange={(event) => {
                setFields({
                  ...fields,
                  profitOrNonprofit: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated && isEmpty(fields.profitOrNonprofit)
              }
            />
            {/* OWNERHSSHIP STRUCTURE */}
            <CustomInput
              required
              inputType="text"
              label="Ownership structure"
              value={fields.ownershipStructure}
              onChange={(event) => {
                setFields({
                  ...fields,
                  ownershipStructure: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated && isEmpty(fields.ownershipStructure)
              }
            />

            {/* ANNUAL REVENUE */}
            <CustomInput
              required
              inputType="number"
              label="Annual Revenue"
              value={fields.annualRevenue}
              minLength={0}
              leftIcon={"$"}
              onChange={(event) => {
                var numbers = new RegExp(/^[0-9]+$/);
                if (
                  event.target.value != null &&
                  numbers.test(event.target.value)
                ) {
                  setFields({
                    ...fields,
                    annualRevenue: event.target.value,
                  });
                }
              }}
              errorText={
                AreFieldsValidated &&
                validateMandatoryNumber(fields.annualRevenue)
              }
            />

            {/* AVERAGE FTE's OVER PAST THREE YEARS */}
            <CustomInput
              required
              label="Average FTE's over past three years"
              value={fields.averageFTE}
              inputType="text"
              onChange={(event) => {
                setFields({
                  ...fields,
                  averageFTE: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated && validateMandatoryNumber(fields.averageFTE)
              }
            />

            {/* NUMBER OF FACILITIES */}
            <CustomInput
              required
              label="Number of facilities/locations"
              value={fields.numberOfLocations}
              inputType="text"
              onChange={(event) => {
                setFields({
                  ...fields,
                  numberOfLocations: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated &&
                validateMandatoryNumber(fields.numberOfLocations)
              }
            />
            {/* HEADQUARTERS LOCATION */}
            <CustomInput
              required
              label="Headquarters' location"
              value={fields.headquarterAddress}
              inputType="text"
              onChange={() => {
                setFields({
                  ...fields,
                  headquarterAddress: event.target.value,
                });
              }}
              errorText={
                AreFieldsValidated && isEmpty(fields.headquarterAddress)
              }
            />

            {/* LOG OUT BUTTON */}
            <h6
              className={classes.logout}
              onClick={() => history.push("/auth/login")}
            >
              <i
                className={classes.iconsButtons + " fa fa-sign-out-alt fa-lg"}
              />
              Log Out
            </h6>
          </DialogContent>
          <DialogActions className={classes.footer}>
            <Button
              color="primary"
              round
              disabled={loading}
              className={classes.done}
              onClick={() => onClickDone()}
            >
              <i className={classes.iconsButtons + " fa fa-check fa-lg"} />
              {loading ? <ButtonLoading /> : "Done"}
            </Button>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
};

export default ProfileSetup;
