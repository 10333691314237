import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { IntakeQuestionByIdUrl } from "axios/adminAPIsUrls";
import IntakeQuestionFields from "views/Common/intakeQuestion/IntakeQuestionFields";

const styles = {
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyle = makeStyles(styles);

const ViewIntakeQuestion = () => {
  const classes = useStyle();
  const history = useHistory();
  let { inquiryParamId, intakeQuestionId } = useParams();

  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    MaxLength: 200,
  });

  const token = localStorage.getItem("token");
  const [{ data, loading, error }, ExecuteIntakeQuestionByIdUrl] = useAxios(
    IntakeQuestionByIdUrl(intakeQuestionId, token)
  );

  useEffect(() => {
    ExecuteIntakeQuestionByIdUrl();
  }, []);

  useEffect(() => {
    if (data) {
      setFields({
        Title: data.Title,
        Type: data.Type,
        Description: data.Description,
        MaxLength: data.MaxLength,
      });
    }
  }, [data]);

  if (loading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  const prerequisiteValue = {
    title: "View Intake Questions",
    goBackLink: `/superAdmin/inquiry-type-management/${inquiryParamId}/intake-questions`,
    disabled: true,
  };

  return (
    <IntakeQuestionFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
    />
  );
};

export default ViewIntakeQuestion;
