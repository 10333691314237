import React from "react";
import { useState } from "react";
import useAxios from "axios/httpService";
import { GetAllInquiryTypesUrl } from "axios/adminAPIsUrls";

const useInquiryTypes = () => {
  //   SENDING -1 CURRENT PAGE TO GET ALL INQUIRY TYPES PRESENT IN DATABASE
  const currentPage = -1;
  const token = localStorage.getItem("token");
  const pageSize = 8;
  const [inquirytype, setInquiryType] = useState([]);

  //   WILL SEND THIS RESPONSE IN RETURN STATEMENT OF OUR CUSTOM HOOK

  const [{ data, loading, error }, executeGetAllInquiryTypes] = useAxios(
    GetAllInquiryTypesUrl(currentPage, pageSize, token),
    {
      manual: false,
    }
  );

  if (data) {
    const inquiryTypes = [];
    data.data.map((item) => {
      const newValues = {
        id: item.Id,
        label: item.InqType,
        value: item.InqType,
      };
      inquiryTypes.push(newValues);
    });
    const res = inquiryTypes.sort((CurrentValue, nextValue) => {
      if (CurrentValue.label.toLowerCase() == nextValue.label) return 0;
      if (CurrentValue.label.toLowerCase() === "other") return 1;
      if (nextValue.label.toLowerCase() === "other") return -1;
      if (CurrentValue.label.toLowerCase() < nextValue.label.toLowerCase()) {
        return -1;
      } else if (
        CurrentValue.label.toLowerCase() > nextValue.label.toLowerCase()
      ) {
        return 1;
      }
      return 0;
    });

    return { loading, inquiryTypes, error };
  }

  return { loading };
};

export default useInquiryTypes;
