import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import React, { useState } from "react";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import useAxios from "axios/httpService";
import { CreateNewInquiryTypeUrl } from "axios/adminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";
import CustomInput from "components/Inputs/CustomInput";
import { isEmpty } from "helpers/validations";

const CreateInquiryType = () => {
  const history = useHistory();

  // THIS STATE WILL PERFORM VALIDATION ON SUBMIT BUTTON CLICK
  const [areFieldsValidated, setValidateFields] = useState(false);

  const [fields, setFields] = useState({
    InqType: "",
    Description: "",
    IsActive: false,
  });

  const token = localStorage.getItem("token");
  const [{ loading }, ExecuteCreateInquiry] = useAxios(
    CreateNewInquiryTypeUrl(token),
    { manual: true }
  );

  const onCreateInquiry = async () => {
    if (fields.InqType) {
      try {
        const response = await ExecuteCreateInquiry({ data: fields });
        if (response.status === 200) {
          NotificationManager.success("New Inquiry Type Created", 5000);
          history.push("/superAdmin/inquiry-type-management");
        }
      } catch (exception) {
        console.log("Error in Creating New Inquiry Type", exception);
        apiErrorHandler(exception, history);
      }
    } else {
      setValidateFields(true);
    }
  };

  if (loading) {
    return <Loading loading />;
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Create New Inquiry Type"
              btnText="Create"
              showBackButton
              onClick={() => onCreateInquiry()}
              goBackRoute="/superAdmin/inquiry-type-management"
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={6} sm={6} md={6}>
                {/* FOR INQUIRY TYPE */}
                <CustomInput
                  inputType="text"
                  required
                  label="Inquiry Type"
                  value={fields.InqType}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      InqType: event.target.value,
                    });
                  }}
                  errorText={areFieldsValidated && isEmpty(fields.InqType)}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                {/* FOR DETAILS */}
                <CustomInput
                  inputType="text"
                  label="Details"
                  value={fields.Description}
                  multiline
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      Description: event.target.value,
                    });
                  }}
                />
              </GridItem>
              <CustomSwitchLabel
                insideForm
                checked={fields.IsActive}
                onChange={(event) =>
                  setFields({ ...fields, IsActive: event.target.checked })
                }
                label="Active"
              />
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateInquiryType;
