import { CircularProgressbar as Progressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React from "react";
import styles from "assets/jss/material-dashboard-pro-react/components/circularProgressbar.js";
import { makeStyles } from "@material-ui/core";
import { PropTypes } from "prop-types";

const useStyles = makeStyles(styles);

const CircularProgressbar = (props) => {
  const { strokeWidth, value, text } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Progressbar
        strokeWidth={strokeWidth}
        value={value}
        text={text}
        className={classes.root}
      />
    </div>
  );
};

export default CircularProgressbar;

CircularProgressbar.propTypes = {
  strokeWidth: PropTypes.number,
  value: PropTypes.number,
  text: PropTypes.string,
};
