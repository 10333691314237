import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/intakeQuestionsResponse";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import useAxios from "axios/httpService";
import { AddIntakeAnswersUrl } from "axios/customerAdminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router";
import ButtonLoading from "components/Loading/ButtonLoading";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";

const useStyles = makeStyles(styles);

const DiagnosticsRecommendations = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { readyToSubmit, setReadyToSubmit, inquiryId, answers } = props;
  const token = localStorage.getItem("token");
  const [{ loading }, executeAddIntakeAnswers] = useAxios(
    AddIntakeAnswersUrl(parseInt(inquiryId), token),
    { manual: true }
  );

  const sendIntakeAnswers = async () => {
    const data = { IntakeAnswersDtos: answers };
    try {
      const response = await executeAddIntakeAnswers({
        data: data,
      });
      if (response.status === 200) {
        history.push(
          `/customerAdmin/inquiry-logs/progress-meter/${response.data.Id}`
        );
      }
    } catch (exception) {
      apiErrorHandler(exception, history);
    }
    setReadyToSubmit(undefined);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <TableHeader title="Add New Inquiries" />
          </CardHeader>
        </Card>
        <Dialog
          classes={{
            root: classes.center + " " + classes.modalRoot,
            paper:
              classes.modal +
              " " +
              classes.modalBorder +
              " " +
              classes.modalSize,
          }}
          open
          keepMounted
          aria-labelledby="notice-modal-slide-title"
          aria-describedby="notice-modal-slide-description"
        >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader + " " + classes.flex}
          >
            <h4 className={classes.modalTitle + " " + classes.bold}>
              Diagnostic Recommendation
            </h4>
          </DialogTitle>
          <DialogContent>
            <h4 className={classes.semiBold}>
              Based on the results of your intake data, ACTivate Advance
              recommends a WellCheck Diagnostic.
            </h4>
          </DialogContent>
          <DialogActions className={classes.footer}>
            <GridItem
              xs={12}
              md={12}
              sm={12}
              noDefaultPadding
              className={classes.submit}
            >
              <Button
                color="primary"
                round
                className={classes.noTextTransform}
                onClick={() => sendIntakeAnswers()}
              >
                {loading ? <ButtonLoading /> : "Yes"}
              </Button>

              <Button
                color="primary"
                round
                className={classes.noTextTransform}
                onClick={() => setReadyToSubmit(!readyToSubmit)}
              >
                No
              </Button>
            </GridItem>
          </DialogActions>
        </Dialog>
      </GridItem>
    </GridContainer>
  );
};

export default DiagnosticsRecommendations;

DiagnosticsRecommendations.propTypes = {
  readyToSubmit: PropTypes.bool,
  setReadyToSubmit: PropTypes.func,
  inquiryId: PropTypes.string,
  answers: PropTypes.array,
};
