import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import UploaderDocPreview from "components/Uploaders/UploaderDocPreview";

const CustomFileUploader = ({
  file,
  onChange,
  onRemove,
  errorText,
  disabled,
  fileName,
}) => {
  let fileInput = React.createRef();

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    onRemove();
    fileInput.current.value = null;
  };

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={onChange} ref={fileInput} />
      {file && (
        <UploaderDocPreview
          file={file}
          handleRemove={handleRemove}
          fileName={fileName}
        />
      )}
      {errorText && <div className="invalid-feedback">{errorText}</div>}
      <div>
        <span>
          {!disabled ? (
            <>
              <Button
                onClick={handleClick}
                size="sm"
                color="primary"
                disabled={disabled}
              >
                <i className="fa fa-cloud-upload-alt fa-lg" />
                {file ? "Change" : "Upload"}
              </Button>
              {file && (
                <Button
                  size="sm"
                  color="primary"
                  onClick={handleRemove}
                  disabled={disabled}
                >
                  <i className="fa fa-times fa-lg" />
                  Remove
                </Button>
              )}
            </>
          ) : (
            <Button simple size="sm" color="primary" disabled={disabled}>
              Please click on the picture to download it.
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

export default CustomFileUploader;

CustomFileUploader.propTypes = {
  file: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  fileName: PropTypes.string,
};
