import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import styles from "assets/jss/material-dashboard-pro-react/views/progressmeterStyle";
import { makeStyles } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import CircularProgressbar from "components/Progressbars/CircularProgressbar";
import { useHistory, useParams } from "react-router";
import Loading from "components/Loading/Loading";
import useAxios from "axios/httpService";
import { GetInquiryByIdUrl } from "axios/commonAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";

const useStyles = makeStyles(styles);

const IntakeAnswersProgressMeter = () => {
  const classes = useStyles();
  const history = useHistory();

  const { inquiryId } = useParams();

  const token = localStorage.getItem("token");

  const [{ data, loading, error }, executeGetInquiryByIdUrl] = useAxios(
    GetInquiryByIdUrl(inquiryId, token)
  );

  if (loading) {
    return <Loading loading={loading} />;
  }

  if (error) {
    apiErrorHandler(history, error);
    console.log("error in GetInquiryByIdUrl", error.response);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <TableHeader title="Progress Meter" />
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} md={12} className={classes.row}>
              <GridItem xs={4} sm={4} md={4}>
                <Card className={classes.card}>
                  <h3 className={classes.leftCardContent}>
                    {data && data.Description}
                  </h3>
                </Card>
              </GridItem>

              <GridItem xs={4} sm={4} md={4}>
                <Card className={classes.card}>
                  <h4 className={classes.rightCardTitle}>WellCheck Pathway</h4>
                  <h2>
                    <CircularProgressbar
                      strokeWidth={8}
                      value={10}
                      text={"10%"}
                    />
                  </h2>
                  <h5 className={classes.rightCardMsg}>
                    Inquiry Created Successfully
                  </h5>
                  <div className={classes.rightCardButton}>
                    <Button
                      color="primary"
                      round
                      className={classes.noTextTransform}
                      onClick={() =>
                        history.push("/customerAdmin/inquiry-logs")
                      }
                    >
                      Redirect to Inquiry Logs
                    </Button>
                  </div>
                </Card>
              </GridItem>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default IntakeAnswersProgressMeter;

IntakeAnswersProgressMeter.propTypes = {};
