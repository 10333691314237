import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import SimpleProgressbar from "components/Progressbars/SimpleProgressbar";
import Button from "components/CustomButtons/Button";

import styles from "assets/jss/material-dashboard-pro-react/components/uploaderDocPreviewStyle";
import msWordSvg from "assets/img/svgs/ms-word.svg";
import msExcelSvg from "assets/img/svgs/ms-excel.svg";
import PdfSvg from "assets/img/svgs/PDF_file.svg";
import ZipSvg from "assets/img/svgs/Zip_file.svg";
import {
  excelFileFormats,
  docsFileFormats,
  PdfFileFormats,
  ZipFileFormats,
} from "components/Uploaders/fileFormats";
const useStyles = makeStyles(styles);

const UploaderDocPreview = ({ file, handleRemove, fileName }) => {
  const classes = useStyles();

  const [completed, setCompleted] = React.useState(0);

  const [fileIcon, setfileIcon] = React.useState("");

  React.useEffect(() => {
    if (completed !== 100) {
      setTimeout(() => {
        setCompleted(completed + 50);
      }, 1000);
    }
    // CHANGING FILE ICON DEPENDING ON FILE INPUT
    if (file?.type) {
      if (excelFileFormats.indexOf(file.type) > 0) {
        setfileIcon(msExcelSvg);
      } else if (docsFileFormats.indexOf(file.type) > 0) {
        setfileIcon(msWordSvg);
      } else if (PdfFileFormats.indexOf(file.type) > 0) {
        setfileIcon(PdfSvg);
      } else if (ZipFileFormats.indexOf(file.type) > 0) {
        setfileIcon(ZipSvg);
      } else {
        setfileIcon(file && URL.createObjectURL(file));
      }
    } else {
      const fileType = fileName && fileName.split(".")[1];
      if (excelFileFormats.indexOf("." + fileType) >= 0) {
        setfileIcon(msExcelSvg);
      } else if (docsFileFormats.indexOf("." + fileType) >= 0) {
        setfileIcon(msWordSvg);
      } else if (PdfFileFormats.indexOf("." + fileType) >= 0) {
        setfileIcon(PdfSvg);
      } else if (ZipFileFormats.indexOf("." + fileType) >= 0) {
        setfileIcon(ZipSvg);
      } else {
        setfileIcon(file);
      }
    }
  }, [completed, file]);

  //console.log(excelFileFormats.indexof(file.type));
  //console.log(file.type);
  return (
    <GridItem xs={12} sm={12} md={12} noDefaultPadding className={classes.root}>
      {completed === 100 ? (
        <GridContainer className={classes.container}>
          <GridItem noDefaultPadding md={2} sm={2} xs={2}>
            <img src={fileIcon} alt="..." className={classes.fileStyles} />
          </GridItem>
          <GridItem noDefaultPadding md={8} sm={8} xs={8}>
            <h5 className={classes.fileName}>{file.name || fileName}</h5>
          </GridItem>
          <GridItem noDefaultPadding md={2} sm={2} xs={2}>
            <Button simple justIcon onClick={handleRemove}>
              <i className={`fa fa-trash-alt fa-lg ` + classes.deleteIcon} />
            </Button>
          </GridItem>
        </GridContainer>
      ) : (
        <SimpleProgressbar completed={completed} showPercentage={false} />
      )}
    </GridItem>
  );
};

export default UploaderDocPreview;

UploaderDocPreview.propTypes = {
  file: PropTypes.object,
  handleRemove: PropTypes.func,
  fileName: PropTypes.string,
};
