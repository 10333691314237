import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import authCoverImage from "assets/img/activateImages/authCoverImg.png";
import activateLogo from "assets/img/activateImages/activate-logo.png";

import styles from "assets/jss/material-dashboard-pro-react/views/Auth/signupStyle";
import { makeStyles } from "@material-ui/core";
import Wizard from "components/Wizard/Wizard";
import Step1 from "./signupWizardSteps/Step1";
import Step2 from "./signupWizardSteps/Step2";
import Step3 from "./signupWizardSteps/Step3";
import useAxios from "axios/httpService";
import { CustomerSignupUrl } from "axios/authAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { NotificationManager } from "components/common/ReactNotifications";
import { useHistory, useParams } from "react-router-dom";
import useOrganizationDetails from "hooks/useOrganizationDetails";
import { doubleCaesarDecryption } from "helpers/signupDecryption";
import { signupStore } from "store";

const useStyle = makeStyles(styles);

const SignupPage = () => {
  const classes = useStyle(styles);

  const history = useHistory();

  const { encryptedOrgName, encryptedEmail } = useParams();

  const setFieldsLocked = signupStore((state) => state.setFieldsLocked);

  const fields = signupStore((state) => state.fields);
  const setFields = signupStore((state) => state.setFields);
  const setFieldsEmpty = signupStore((state) => state.setFieldsEmpty);
  const setSignupLoading = signupStore((state) => state.setSignupLoading);
  const setSignupError = signupStore((state) => state.setSignupError);
  const setOrganizationDetailsLoading = signupStore(
    (state) => state.setOrganizationDetailsLoading
  );

  const [validateFields, setValidateFields] = React.useState(false);

  const orgName = encryptedOrgName && doubleCaesarDecryption(encryptedOrgName);

  const staffEmail = encryptedEmail && doubleCaesarDecryption(encryptedEmail);

  const {
    loading: isOrgDetailsLoading,
    organizationDetails,
    error: errorInOrgDetails,
  } = useOrganizationDetails(orgName);

  React.useEffect(() => {
    setOrganizationDetailsLoading(isOrgDetailsLoading);
    if (organizationDetails) {
      const newFields = { ...fields };
      for (const key in organizationDetails) {
        newFields.step1[`${key}`] = organizationDetails[key];
      }
      newFields.step2.email = staffEmail;
      setFields(newFields);
      setFieldsLocked(true);
    }
  }, [errorInOrgDetails, isOrgDetailsLoading]);

  // MAKING AN API CALL FOR SIGN UP
  const [{ loading, error }, executeSignup] = useAxios(CustomerSignupUrl(), {
    manual: true,
  });

  React.useEffect(() => {
    setSignupLoading(loading);
    setSignupError(error);
  }, [error, loading]);

  const redirectLogin = () => {
    history.push("/auth/login");
  };
  const onSignup = async () => {
    try {
      const data = {
        CustomerName: fields.step1.orgName,
        Industry: fields.step1.industryType,
        Address: fields.step1.streetAddress,
        Country: fields.step1.countryName,
        State: fields.step1.stateName,
        City: fields.step1.cityName,
        Website: fields.step1.website,
        // IF THE USER IS ALREADY ENROLLED AGAINST ANY ORGANIZATION, THEN THE ADMIN BOOL WILL BE FALSE
        isAdmin: organizationDetails ? false : true,
        FirstName: fields.step2.firstName,
        LastName: fields.step2.lastName,
        Email: fields.step2.email,
        Password: fields.step2.password,
        ConfirmPassword: fields.step2.confPassword,
        Designation: fields.step2.designation,
      };

      // SENDING CUSTOMER ID IF THE STAFF TRIES TO SIGNUP (BACKEND REQUIREMENT)
      if (organizationDetails) {
        data.CustomerId = organizationDetails.customerId;
      }
      const response = await executeSignup({ data: data });
      if (response.status === 200) {
        NotificationManager.success("Signed Up in Successfully", 5000);
      }
    } catch (exception) {
      console.log("Error Signing Up", exception);
      apiErrorHandler(exception, history);
    }
  };

  if (errorInOrgDetails) {
    apiErrorHandler(errorInOrgDetails, history);
  }

  return (
    <GridContainer className={classes.fullSize}>
      <GridItem xs={12} sm={12} md={6} className={classes.container}>
        <div className={classes.form}>
          <div className={classes.logo}>
            <img src={activateLogo} alt="activate_logo" height="140" />
          </div>
          <Wizard
            validate
            validateFields={validateFields}
            setValidateFields={setValidateFields}
            steps={[
              {
                stepName: "Organizational Details",
                stepComponent: Step1,
                stepId: "step1",
              },
              {
                stepName: "Personal Information",
                stepComponent: Step2,
                stepId: "step2",
              },
              {
                stepName: "Complete",
                stepComponent: Step3,
                stepId: "step3",
              },
            ]}
            finishButtonText="Login"
            signupButtonClick={() => onSignup()}
            finishButtonClick={() => redirectLogin()}
            goBackButtonClick={() => history.push("/auth/login")}
          />
        </div>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={6}
        className={classes.removePadding + " " + classes.coverImage}
      >
        <h1 className={classes.imgTitle}>
          Welcome to{" "}
          <b>
            <span className={classes.primary}>ACT</span>ivate Advance
          </b>
        </h1>
        <h3 className={classes.imgText}>
          Activating Advanced Strategies in Real Time
        </h3>
      </GridItem>
    </GridContainer>
  );
};

export default SignupPage;
