import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import AlertModal from "components/Alerts/AlertModal";
import useAxios from "axios/httpService";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { GetAllRoadMapUrl } from "axios/adminAPIsUrls";
import { DeleteRoadMapUrl } from "axios/adminAPIsUrls";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

const RoadMap_Management = () => {
  const history = useHistory();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [roadmapId, setRoadmapId] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const [
    { data: dataRoadmap, error, loading },
    ExecuteGetAllRoadMapUrl,
  ] = useAxios(GetAllRoadMapUrl(currentPage, pageSize, token), {
    manual: true,
  });

  const [{ loading: deleteLoading }, ExecuteDeleteRoadMapUrl] = useAxios(
    DeleteRoadMapUrl(roadmapId, token),
    {
      manual: true,
    }
  );

  const deletehandler = async () => {
    try {
      const res = await ExecuteDeleteRoadMapUrl();
      setAlert(!alert);
      NotificationManager.success("Roadmap Deleted", 5000);
      ExecuteGetAllRoadMapUrl();
    } catch (exception) {
      console.log("Error in Deleting Roadmap", exception);
      apiErrorHandler(exception, history);
    }
  };

  useEffect(() => {
    ExecuteGetAllRoadMapUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataRoadmap) {
      setData(
        dataRoadmap.data.map((prop, key) => {
          return {
            id: prop.RoadmapId,
            Step1_RoadmapTitle: prop.Step1_RoadmapTitle,
            Step2_RoadmapTitle: prop.Step2_RoadmapTitle,
            Step3_RoadmapTitle: prop.Step3_RoadmapTitle,
            InquiryTypeName: prop.InquiryTypeName,
            //inquirytype: prop.InquiryTypeName,
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Roadmap"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view/${prop.RoadmapId}` //
                    )
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>

                <Button
                  action
                  tooltipText="Edit Roadmap"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/edit/${prop.RoadmapId}`
                    )
                  }
                >
                  <CreateOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Delete Roadmap"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() => toggleDelete(prop.RoadmapId)}
                >
                  <DeleteForeverOutlinedIcon />
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataRoadmap]);

  const toggleDelete = (id) => {
    setRoadmapId(id);
    setAlert(!alert);
  };

  const classes = useStyles();

  const columns = [
    {
      Header: "INQUIRY TYPE",
      accessor: "InquiryTypeName",
      alignment: "center",
    },
    {
      Header: "Roadmap Step1",
      accessor: "Step1_RoadmapTitle",
      alignment: "center",
    },
    {
      Header: "Roadmap Step2",
      accessor: "Step2_RoadmapTitle",
      alignment: "center",
    },
    {
      Header: "Roadmap Step3",
      accessor: "Step3_RoadmapTitle",
      alignment: "center",
    },
    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }
  if (deleteLoading) {
    return <Loading deleteLoading />;
  }

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletehandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Roadmap Management"
              btnText="Add Roadmap"
              onClick={() => history.push(`${window.location.pathname}/create`)}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataRoadmap && dataRoadmap.currentPage}
              defaultPageSize={dataRoadmap && dataRoadmap.pageSize}
              totalItems={dataRoadmap && dataRoadmap.totalItem}
              totalPages={dataRoadmap && dataRoadmap.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default RoadMap_Management;
