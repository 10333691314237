/* eslint-disable no-constant-condition */
import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import styles from "assets/jss/material-dashboard-pro-react/views/ScenariosStyle";
import GridItem from "components/Grid/GridItem";

import PropTypes from "prop-types";
import CustomSlider from "./CustomSlider";

const useStyles = makeStyles(styles);

const ScenariosResuable = ({ data }) => {
  const classes = useStyles();

  return (
    <GridContainer className={classes.cont}>
      {data &&
        data.map((item, key) => {
          return (
            <GridContainer key={key} className={classes.cont}>
              <GridItem xs={12} sm={12} md={4} className={classes.mar_L}>
                <ul>
                  <li xs={12} sm={12} md={12} className={classes.li}>
                    {item.RecommendationDetails}
                  </li>
                </ul>
              </GridItem>
              <GridItem xs={12} sm={12} md={7}>
                <CustomSlider defaultvalue={item.LevelOfFocus} />
              </GridItem>
            </GridContainer>
          );
        })}
    </GridContainer>
  );
};

export default ScenariosResuable;

ScenariosResuable.propTypes = {
  data: PropTypes.array,
};
