import React from "react";
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem";
import AreaSquare from "components/ViewInsights/AreaSquare";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import { grayColor } from "assets/jss/material-dashboard-pro-react";

const InputsResultsGraph = ({ areaName, dataset, yAxisValues, areaColor }) => {
  const graphOptions = {
    indexAxis: "y",
    layout: {
      autoPadding: "10px",
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          display: false,
        },
      },
      x: {
        ticks: {
          max: 50,
          suggestedMax: 50,
        },
      },
    },
  };
  return (
    <>
      <GridItem xs={12} md={2} sm={12}>
        <AreaSquare areaName={areaName} color={areaColor} />
      </GridItem>
      <GridItem xs={12} md={10} sm={12}>
        <HorizontalBarChart
          datasets={dataset}
          yAxisValues={yAxisValues}
          height={200}
          width={200}
          options={graphOptions}
        />
      </GridItem>
    </>
  );
};

export default InputsResultsGraph;

InputsResultsGraph.defaultProps = {
  areaName: "Default Area Name",
  dataset: [
    {
      id: 1,
      backgroundColor: primaryColor[0],
      label: "First Dataset",
      borderColor: primaryColor[0],
      borderWidth: 1,
      data: [1, 2, 3, 4, 5],
    },
    {
      id: 2,
      backgroundColor: grayColor[0],
      label: "Second Dataset",
      borderColor: grayColor[0],
      borderWidth: 1,
      data: [1, 2, 3, 4, 5],
    },
  ],
  yAxisValues: ["value 01", "value 02", "value 03", "value 04", "value 05"],
  areaColor: primaryColor[0],
};

InputsResultsGraph.propTypes = {
  areaName: PropTypes.string,
  dataset: PropTypes.array,
  yAxisValues: PropTypes.array,
  areaColor: PropTypes.string,
};
