import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import { whiteColor } from "assets/jss/material-dashboard-pro-react";
import { viewInsightsStore } from "store";
import { viewInsightsMenu } from "constants/viewInsightsMenu";
import { makeStyles } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import { useHistory } from "react-router-dom";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import PrintIcon from "@material-ui/icons/Print";
import jwtDecode from "jwt-decode";
import useAxios from "axios/httpService";
import { GetExportPdfUrl } from "axios/commonAPIsUrls";
import { useEffect } from "react";

const styles = {
  breadcrumb: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "black",
  },
  atag: {
    display: "flex",
    flexDirection: "row",
    color: "#555555",
  },
  h4tag: {
    display: "inline",
    marginLeft: "10px",
    marginRight: "24px",
    fontWeight: "500",
  },
  svgmargin: { marginTop: "13px" },
  Export: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "auto",
    cursor: "pointer",
  },
  inquiryName: {
    fontSize: "1.5rem",
    fontWeight: "500",
    color: "black !important",
  },
  inquiryTypeName: {
    fontSize: "0.85rem",
    fontWeight: "500",
    color: whiteColor,
    backgroundColor: primaryColor[0],
    padding: "5px",
    marginLeft: "5px",
    borderRadius: "10px",
  },
  activeTabName: {
    fontSize: "1.25rem",
    fontWeight: "600",
    color: primaryColor[0],
  },
};

const useStyles = makeStyles(styles);

const ViewInsightsBreadcrumb = () => {
  const classes = useStyles();
  const history = useHistory();
  const inquiryName = viewInsightsStore((state) => state.inquiryName);
  const inquiryTypeName = viewInsightsStore((state) => state.inquiryTypeName);
  const inquiryId = viewInsightsStore((state) => state.inquiryId);
  const token = localStorage.getItem("token");
  const [{ data, loading, error, executeGetExportData }] = useAxios(
    GetExportPdfUrl(inquiryId, token)
  );
  useEffect(async () => {
    await executeGetExportData();
  }, []);
  const [exportpdf, setExportpdf] = React.useState("");
  React.useEffect(() => {
    if (data) {
      setExportpdf(data.DocPath);
    }
  }, [data]);
  // Get Active Tab Index
  const getActiveTabName = () => {
    const value = viewInsightsMenu.find((obj) => {
      const tabRoute = obj.tabRoute.split("/");
      const currentTab = tabRoute[tabRoute.length - 1];
      const requestedPath = window.location.pathname.split("/");
      const activeTab = requestedPath[requestedPath.length - 1];
      return currentTab === activeTab;
    });
    return value.tabName;
  };

  const [activeTabName, setActiveTabName] = React.useState(null);

  React.useEffect(() => {
    setActiveTabName(getActiveTabName());
  }, []);

  const decodedToken = jwtDecode(token);
  let loggedInRole = null;
  if (decodedToken.role === "SuperAdmin") {
    loggedInRole = "superAdmin";
  } else if (decodedToken.role === "CustomerAdmin") {
    loggedInRole = "customerAdmin";
  }

  const [showFullTitle, setShowFullTitle] = React.useState(false);

  const truncateString = (value, length) =>
    value && value.length > length ? value.substring(0, length) + "..." : value;

  return (
    <GridContainer>
      <GridItem className={classes.breadcrumb}>
        <Button
          color="white"
          aria-label="Back"
          round
          size="sm"
          className={classes.backButton}
          onClick={() => history.push(`/${loggedInRole}/dashboard`)}
          justIcon
        >
          <ArrowBackOutlinedIcon className={classes.buttonLink} />
        </Button>
        <span style={{ marginRight: "5px" }} />
        <h2 className={classes.inquiryName}>
          {showFullTitle && inquiryName.length > 25 ? (
            <div className={`invalid-insight-feedback  d-block`}>
              {inquiryName}
            </div>
          ) : null}
          <a
            onMouseEnter={() => setShowFullTitle(true)}
            onMouseLeave={() => setShowFullTitle(false)}
            className={classes.inquiryName}
          >
            {truncateString(inquiryName, 25)}
          </a>
          <span className={classes.inquiryTypeName}>{inquiryTypeName}</span>
          <span
            style={{
              margin: "0px 10px 0px 20px",
              color: "black",
            }}
          >
            {">"}
          </span>
        </h2>
        <h3 className={classes.activeTabName}>{activeTabName}</h3>
      </GridItem>

      {decodedToken.role === "CustomerAdmin" ? null : (
        <GridItem className={classes.Export}>
          <a
            href={exportpdf}
            className={classes.atag}
            target="_blank"
            rel="noreferrer"
          >
            <PrintIcon fontSize="small" className={classes.svgmargin} />
            <h4 className={classes.h4tag}> Export Data</h4>
          </a>
        </GridItem>
      )}
    </GridContainer>
  );
};

export default ViewInsightsBreadcrumb;
