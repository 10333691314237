import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import classNames from "classnames";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import { appRoutes } from "routes";
import styles from "assets/jss/material-dashboard-pro-react/components/navPillsStyle.js";

import jwtDecode from "jwt-decode";
import authStore from "store/authStore";
import { viewInsightsMenu } from "constants/viewInsightsMenu";

const useStyles = makeStyles(styles);

const NestedMenubar = (props) => {
  const classes = useStyles();
  const { color, menu, inquiryId } = props;

  const [layout, setLayout] = React.useState("");

  // Below, we are extracting only view insights routes from the whole routes stack memory
  const viewInsightsMenubarRoutes = appRoutes().filter((route) => {
    return route.path.indexOf("view-insights") > 0;
  });

  function a11yProps(index) {
    return {
      id: `scrollable-on-tab-${index}`,
      "aria-controls": `scrollable-on-tabpanel-${index}`,
    };
  }
  const flexContainerClasses = classNames({
    [classes.flexContainer]: true,
  });

  const isAuthenticated = authStore((state) => state.isAuthenticated);
  const userToken = authStore((state) => state.userToken);
  React.useEffect(() => {
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      if (decodedToken.role === "SuperAdmin") {
        setLayout("/superAdmin");
      } else if (decodedToken.role === "CustomerAdmin") {
        setLayout("/customerAdmin");
      }
    }
  }, [isAuthenticated, userToken]);

  // Get Active Tab Index
  const getActiveTabIndex = () => {
    const value = viewInsightsMenu.find((obj) => {
      const tabRoute = obj.tabRoute.split("/");
      const currentTab = tabRoute[tabRoute.length - 1];

      const requestedPath = window.location.pathname.split("/");
      const activeTab = requestedPath[requestedPath.length - 1];
      return currentTab === activeTab;
    });
    return viewInsightsMenu.indexOf(value);
  };

  return (
    <Tabs
      classes={{
        root: classes.root,
        fixed: classes.fixed,
        flexContainer: flexContainerClasses,
        indicator: classes.primaryIndicator,
        scroller: classes.scroller,
      }}
      value={getActiveTabIndex()}
      variant="scrollable"
      scrollButtons="on"
    >
      {menu.map((tab, key) => {
        const pillsClasses = classNames({
          [classes.pills]: true,
          [classes.pillsWithIcons]: tab.tabIcon !== undefined,
        });

        let currentTab = tab.tabRoute.split("/");
        currentTab = currentTab[currentTab.length - 1];

        return (
          <Tab
            classes={{
              root: pillsClasses,
              selected: classes[color],
            }}
            label={
              <div className={classes.label}>
                <div className={classes.tabName}>{tab.tabName}</div>
              </div>
            }
            {...a11yProps(key)}
            key={key}
            component={Link}
            to={layout + `/dashboard/view-insights/${inquiryId}/${currentTab}`}
          />
        );
      })}
    </Tabs>
  );
};

export default NestedMenubar;

NestedMenubar.propTypes = {
  menu: PropTypes.array,
  color: PropTypes.string,
  inquiryId: PropTypes.string,
};
