import React from "react";
import useAxios from "axios/httpService";
import { GetSurveyFormDetailsUrl } from "axios/commonAPIsUrls";
import jwtDecode from "jwt-decode";
import { surveyFormStore } from "store";

const useSurveyFormDetails = (inquiryId, customerUserId) => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const [response, setResponse] = React.useState("");

  // Calling States from Store
  const structureArray = surveyFormStore((state) => state.structureArray);
  const setStructureArray = surveyFormStore((state) => state.setStructureArray);

  const peopleArray = surveyFormStore((state) => state.peopleArray);
  const setPeopleArray = surveyFormStore((state) => state.setPeopleArray);

  const processArray = surveyFormStore((state) => state.processArray);
  const setProcessArray = surveyFormStore((state) => state.setProcessArray);

  const cultureArray = surveyFormStore((state) => state.cultureArray);
  const setCultureArray = surveyFormStore((state) => state.setCultureArray);

  const othersArray = surveyFormStore((state) => state.othersArray);
  const setOthersArray = surveyFormStore((state) => state.setOthersArray);

  const [{ loading, error }, executeGetSurveyFormDetails] = useAxios(
    GetSurveyFormDetailsUrl(parseInt(inquiryId), customerUserId, token),
    {
      manual: true,
    }
  );

  React.useEffect(async () => {
    setResponse(await executeGetSurveyFormDetails());
  }, []);
  React.useEffect(() => {
    if (response) {
      //  setting required values in structureArray
      setStructureArray(
        response.data.StructureArray.map((item) => {
          let newItem = { ...item };
          newItem.Description = item.Question;
          newItem.RadioButtonOptions = item.Options;
          newItem.CheckboxArray = item.Options;
          return newItem;
        })
      );

      //  setting required values in peopleArray
      setPeopleArray(
        response.data.PeopleArray.map((item) => {
          let newItem = { ...item };
          newItem.Description = item.Question;
          newItem.RadioButtonOptions = item.Options;
          newItem.CheckboxArray = item.Options;
          return newItem;
        })
      );

      //  setting required values in processArray
      setProcessArray(
        response.data.ProcessArray.map((item) => {
          let newItem = { ...item };
          newItem.RadioButtonOptions = item.Options;
          newItem.Description = item.Question;
          newItem.CheckboxArray = item.Options;
          return newItem;
        })
      );

      //  setting required values in cultureArray
      setCultureArray(
        response.data.CultureArray.map((item) => {
          let newItem = { ...item };
          newItem.Description = item.Question;
          newItem.RadioButtonOptions = item.Options;
          newItem.CheckboxArray = item.Options;
          return newItem;
        })
      );

      //  setting required values in othersArray
      setOthersArray(
        response.data.OthersArray.map((item) => {
          let newItem = { ...item };
          newItem.Description = item.Question;
          if (item.QuestionType.toLowerCase() === "rating") {
            newItem.RadioButtonOptions = item.Options;
          } else if (item.QuestionType.toLowerCase() === "checkbox") {
            newItem.CheckBoxOptions = item.Options;
          }
          newItem.CheckBoxAnswers = item.CheckBoxOptions;
          return newItem;
        })
      );
    }
  }, [response]);

  if (response) {
    return {
      loading,
      structureArray,
      cultureArray,
      peopleArray,
      processArray,
      othersArray,
      error,
    };
  }

  return { loading, error };
};

export default useSurveyFormDetails;
