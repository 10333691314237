import * as React from "react";

const SvgViewDocumentIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M44.286 53.143a4.429 4.429 0 1 0 0-8.857 4.429 4.429 0 0 0 0 8.857Z"
      fill="#D65746"
    />
    <path
      d="M61.506 47.56a19.132 19.132 0 0 0-17.22-12.131 19.131 19.131 0 0 0-17.22 12.132l-.495 1.153.494 1.154A19.131 19.131 0 0 0 44.285 62a19.13 19.13 0 0 0 17.221-12.132L62 48.714l-.494-1.153Zm-17.22 10.011a8.858 8.858 0 1 1 8.857-8.857 8.868 8.868 0 0 1-8.857 8.857ZM11.07 33.214h11.072v4.429H11.07v-4.429Zm0-11.071h26.572v4.428H11.07v-4.428Zm0-11.072h26.572V15.5H11.07v-4.429Z"
      fill="#D65746"
    />
    <path
      d="M44.286 0H4.429A4.442 4.442 0 0 0 0 4.429V57.57A4.442 4.442 0 0 0 4.429 62h17.714v-4.429H4.429V4.43h39.857v24.357h4.428V4.429A4.442 4.442 0 0 0 44.286 0Z"
      fill="#D65746"
    />
  </svg>
);

export default SvgViewDocumentIcon;
