import React from "react";
import { Link } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TableHeader from "components/Tables/TableHeader";
import FormContainer from "components/Forms/FormContainer";
import { makeStyles } from "@material-ui/core";
import Loading from "components/Loading/Loading";
import styles from "assets/jss/material-dashboard-pro-react/components/surveyFormStyle";
import { useParams, useHistory } from "react-router";
import useSurveyFormDetails from "hooks/SurveyForms/useSurveyFormDetails";
import { resetSurveyFormStore } from "store";
import jwtDecode from "jwt-decode";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import DynamicSurveyFormTableAgainstAreas from "views/Common/SurveyForms/DynamicSurveyFormTableAgainstAreas";
import DynamicSurveyFormAgainstOthers from "views/Common/SurveyForms/DynamicSurveyFormAgainstOthers";
import { surveyFormStore } from "store";
const useStyles = makeStyles(styles);

const ViewSurveyForm = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);

  const classes = useStyles();

  const history = useHistory();

  const { inquiryId, customerUserId } = useParams();

  let count = 0;

  const { loading, error } = useSurveyFormDetails(inquiryId, customerUserId);

  const structureArray = surveyFormStore((state) => state.structureArray);
  const peopleArray = surveyFormStore((state) => state.peopleArray);
  const processArray = surveyFormStore((state) => state.processArray);
  const cultureArray = surveyFormStore((state) => state.cultureArray);
  const othersArray = surveyFormStore((state) => state.othersArray);
  const disabledFields = surveyFormStore((state) => state.disabledFields);
  const setDisabledFields = surveyFormStore((state) => state.setDisabledFields);
  // this useEffect is resetting the surveyFormStore to its initial state
  React.useEffect(() => {
    resetSurveyFormStore();
    setDisabledFields(true);
  }, []);

  if (error) {
    console.log("Error in Getting Survey Form Response", error);
    apiErrorHandler(error, history);
  }

  const goBackRoute = () => {
    if (decodedToken.role === "SuperAdmin") {
      return `/superAdmin/monitor-activity/view-respondents/${inquiryId}`;
    } else if (decodedToken.role === "CustomerUser") {
      return "/customerUser/dashboard";
    } else if (decodedToken.role === "CustomerAdmin") {
      return "/customerAdmin/survey-forms";
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="View Survey Form"
              showBackButton
              goBackRoute={goBackRoute()}
            />
            <h4 className={classes.parah4}>
              Welcome to{" "}
              <strong className={classes.parastrong}>ACTivate Advance</strong>,
              where custom organization performance roadmaps come to life in
              real time. As you complete the questions in this confidential
              portal, you can be assured that your candid responses will be a
              key to unlocking the strategic metrics that empower teams and
              remove barriers to excellence. Below you will find a number of
              questions relating to the performance of your team/organization.
              (Note: If you are an external stakeholder, please refer to the
              instructions provided by the administrator before completing this
              survey). For rating questions, please indicate to what extent you
              believe that the team/organization establishes/practices the
              following attributes. Although you may see evidence at times to
              support multiple answers, select the option you feel most closely
              reflects overall functioning. In addition, please indicate the
              level of value or importance you assign to each attribute. If you
              do not know the answer, please mark DK. For open-ended questions,
              please respond as completely and specifically as you can. The
              final portion of the survey will ask you to upload several
              documents. If you have questions about this portal, please ask
              your administrator for guidance. Technical support is available at{" "}
              <Link
                to="#"
                className={classes.parastrong}
                onClick={(e) => {
                  window.location = "mailto:noele@activate-technologies.com";
                  e.preventDefault();
                }}
              >
                info@activate-technologies.com
              </Link>
            </h4>
            <h4 className={classes.parah4}>
              <strong>IMPORTANT:</strong>
              <i>
                {" "}
                Please do not select the{" "}
                <strong className={classes.parastrong}>
                  &#34;Back&#34;
                </strong>{" "}
                button on your browser as you complete this survey, as this will
                delete your data. When you have completed the survey, please
                click{" "}
                <strong className={classes.parastrong}>&#34;Submit&#34;</strong>
                . If you cannot provide all your responses and documents in one
                session, please click{" "}
                <strong className={classes.parastrong}>
                  &#34;Save as Draft&#34;
                </strong>
                . This will allow you to save your responses and log back in
                later to complete the survey. After you log back in, you can
                make changes to any of your saved responses or change which
                documents you would like to upload; however, once you click{" "}
                <strong className={classes.parastrong}>&#34;Submit&#34;</strong>
                , responses and documents cannot be changed.
              </i>
            </h4>
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={12} sm={12} md={12}>
                {loading ? (
                  <Loading loading={loading} height="fixed" />
                ) : (
                  <>
                    {/* Culture */}
                    {cultureArray && cultureArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={cultureArray}
                        tableTitle={`Culture`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* People */}
                    {peopleArray && peopleArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={peopleArray}
                        tableTitle={`People`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* Process */}
                    {processArray && processArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={processArray}
                        tableTitle={`Process`}
                        count={(count = count + 1)}
                      />
                    )}

                    {/* Structure */}
                    {structureArray && structureArray?.length > 0 && (
                      <DynamicSurveyFormTableAgainstAreas
                        data={structureArray}
                        tableTitle={`Structure`}
                        count={(count = count + 1)}
                      />
                    )}
                    {/* OTHERS */}
                    {othersArray && othersArray?.length > 0 && (
                      <DynamicSurveyFormAgainstOthers
                        data={othersArray}
                        tableTitle={`Other`}
                        count={(count = count + 1)}
                      />
                    )}

                    <div className={classes.surveyFormEnd}>
                      <h4 className={classes.answerInstructions}>
                        You have reached the end of this survey.{" "}
                        {!disabledFields && (
                          <>
                            Please click
                            <span className={classes.primaryText}>
                              {" "}
                              Submit{" "}
                            </span>
                            button below.
                          </>
                        )}
                      </h4>
                    </div>
                  </>
                )}
              </GridItem>
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewSurveyForm;
