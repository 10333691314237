import React, { useEffect } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";

import ViewInsightsBreadcrumb from "components/Breadcrumbs/ViewInsightsBreadcrumb";
import Overview from "views/Common/ViewInsights/Overview/index";
import InsightsMenubar from "components/Navbars/InsightsMenubar";
import Findings from "views/Common/ViewInsights/Findings";
import Scenarios from "views/Common/ViewInsights/Scenarios";
import Inputs from "views/Common/ViewInsights/Inputs";
import Roadmap from "views/Common/ViewInsights/Roadmap";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import { viewInsightsStore } from "store";
import useAxios from "axios/httpService";
import {
  GetInquiryByIdUrl,
  GetAreaColorsForInquiriesUrl,
} from "axios/commonAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import { AREA_COLORS } from "constants/defaultValues";
import jwtDecode from "jwt-decode";
import { getColor } from "helpers/common-function";

const ViewInsights = () => {
  const { inquiryId } = useParams();

  const token = localStorage.getItem("token");

  // SETTING UP VALUES IN STORE FOR VIEW INSIGHTS
  const setInquiryId = viewInsightsStore((state) => state.setInquiryId);
  const setInquiryName = viewInsightsStore((state) => state.setInquiryName);
  const setInquiryTypeName = viewInsightsStore(
    (state) => state.setInquiryTypeName
  );
  const setStructureColor = viewInsightsStore(
    (state) => state.setStructureColor
  );
  const setProcessColor = viewInsightsStore((state) => state.setProcessColor);
  const setPeopleColor = viewInsightsStore((state) => state.setPeopleColor);
  const setCultureColor = viewInsightsStore((state) => state.setCultureColor);

  // getting inquiry detail using inquiry id
  const [{ data, loading, error }, executeGetInquiryByIdUrl] = useAxios(
    GetInquiryByIdUrl(inquiryId, token)
  );

  // getting dynamic colors for every inquiry
  const [
    { data: areaColors, loading: areaColorsLoading, error: errorInAreaColors },
    executeGetAreaColorsUrl,
  ] = useAxios(GetAreaColorsForInquiriesUrl(inquiryId, token));

  useEffect(async () => {
    await executeGetInquiryByIdUrl();
    await executeGetAreaColorsUrl();
  }, []);
  React.useEffect(() => {
    if (data) {
      setInquiryTypeName(data.InquiryTypeName);
      setInquiryId(data.Id);
      setInquiryName(data.Description);
    }
  }, [data]);

  // SETTING DYNAMIC AREA COLORS FOR DIFFERENT INQUIRIES
  React.useEffect(() => {
    if (areaColors) {
      for (let i = 0; i < areaColors.data.length; i++) {
        if (areaColors.data[i].Area === "Structure") {
          const color = areaColors.data[i].Color.toLowerCase();
          setStructureColor(getColor(color));
        } else if (areaColors.data[i].Area === "People") {
          const color = areaColors.data[i].Color.toLowerCase();
          setPeopleColor(getColor(color));
        } else if (areaColors.data[i].Area === "Process") {
          const color = areaColors.data[i].Color.toLowerCase();
          setProcessColor(getColor(color));
        } else if (areaColors.data[i].Area === "Culture") {
          const color = areaColors.data[i].Color.toLowerCase();
          setCultureColor(getColor(color));
        }
      }
    }
  }, [areaColors]);
  if (errorInAreaColors) {
    console.log("Error in Getting Area Colors", error);
    apiErrorHandler(errorInAreaColors, history);
  }

  if (error) {
    console.log("Error in Getting Inquiry By Id", error);
    apiErrorHandler(error, history);
  }

  if (loading || areaColorsLoading) {
    return <Loading loading={loading || areaColorsLoading} />;
  }

  const decodedToken = jwtDecode(token);
  let loggedInRole = null;
  if (decodedToken.role.toLowerCase() === "superadmin") {
    loggedInRole = "superAdmin";
  } else if (decodedToken.role.toLowerCase() === "customeradmin") {
    loggedInRole = "customerAdmin";
  }
  console.log(data);
  console.log(areaColors);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} noDefaultPadding>
        <Card>
          <ViewInsightsBreadcrumb />
          <InsightsMenubar />
          <Switch>
            <Route
              exact
              path={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/overview`}
              render={(props) => <Overview {...props} data={data} />}
            />
            <Route
              exact
              path={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/findings`}
              render={(props) => <Findings {...props} />}
            />
            <Route
              exact
              path={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/scenarios`}
              render={(props) => <Scenarios {...props} />}
            />
            <Route
              exact
              path={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/inputs`}
              render={(props) => <Inputs {...props} />}
            />
            <Route
              exact
              path={`/${loggedInRole}/dashboard/view-insights/${inquiryId}/roadmap`}
              render={(props) => <Roadmap {...props} />}
            />
            ;
            <Redirect to="/auth/page-not-found" />{" "}
          </Switch>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ViewInsights;
