import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import SwapCallsOutlinedIcon from "@material-ui/icons/SwapCallsOutlined";

import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/Tables/ReactTable.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import TableHeader from "components/Tables/TableHeader";
import { useHistory, useParams } from "react-router-dom";
import AlertModal from "components/Alerts/AlertModal";
import { definePathwayStore } from "store";
import { GetAllPathwaysUrl } from "axios/adminAPIsUrls";
import PersonIcon from "@material-ui/icons/Person";
import useAxios from "axios/httpService";
import { GetInquiryTypeUrl } from "axios/adminAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { DeletePathwayUrl } from "axios/adminAPIsUrls";
import Loading from "components/Loading/Loading";
import { GetAllCustomerAdminsUrl } from "axios/adminAPIsUrls";
import { DeleteCustomerAdminUrl } from "axios/adminAPIsUrls";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import { GetAllFindingsUrl } from "axios/adminAPIsUrls";
import { DeleteFindingUrl } from "axios/adminAPIsUrls";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  mtZero: {
    marginTop: "0px",
  },
  ptZero: {
    paddingTop: "0px !important",
  },
};

const useStyles = makeStyles(styles);

const Findings = () => {
  const history = useHistory();

  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [findindId, setFindindId] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("token");
  const [
    { data: dataFindings, error, loading },
    ExecuteGetAllFindingsUrl,
  ] = useAxios(GetAllFindingsUrl(currentPage, pageSize, token), {
    manual: true,
  });

  const [{ loading: deleteLoading }, ExecuteDeleteFindingUrl] = useAxios(
    DeleteFindingUrl(findindId, token),
    {
      manual: true,
    }
  );

  const deletehandler = async () => {
    try {
      const res = await ExecuteDeleteFindingUrl();
      setAlert(!alert);
      NotificationManager.success("Findings Deleted", 5000);
      ExecuteGetAllFindingsUrl();
    } catch (exception) {
      console.log("Error in Deleting Findings", exception);
      apiErrorHandler(exception, history);
    }
  };

  useEffect(() => {
    ExecuteGetAllFindingsUrl(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (dataFindings) {
      setData(
        dataFindings.data.map((prop, key) => {
          return {
            id: prop.FindingId,
            findingsDetail: prop.FindingDetail,
            area: prop.Area,
            actions: (
              // we've added some custom button actions
              <div className="actions-center">
                <Button
                  action
                  tooltipText="View Finding"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/view/${prop.FindingId}`
                    )
                  }
                >
                  <VisibilityOutlinedIcon />
                </Button>

                <Button
                  action
                  tooltipText="Edit Finding"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `${window.location.pathname}/edit/${prop.FindingId}`
                    )
                  }
                >
                  <CreateOutlinedIcon />
                </Button>
                <Button
                  action
                  tooltipText="Delete Finding"
                  justIcon
                  round
                  simple
                  size="sm"
                  color="primary"
                  onClick={() => toggleDelete(prop.FindingId)}
                >
                  <DeleteForeverOutlinedIcon />
                </Button>
              </div>
            ),
          };
        })
      );
    }
  }, [dataFindings]);

  const toggleDelete = (id) => {
    setFindindId(id);
    setAlert(!alert);
  };

  const classes = useStyles();

  const columns = [
    {
      Header: "Findings Details",
      accessor: "findingsDetail",
      alignment: "center",
    },
    {
      Header: "Area",
      accessor: "area",
      alignment: "center",
    },

    {
      Header: (
        <div
          style={{
            textAlign: "center",
          }}
        >
          Actions
        </div>
      ),
      accessor: "actions",
      alignment: "center",
    },
  ];
  // ERROR HANDLING
  if (error) {
    apiErrorHandler(error, history);
  }

  if (loading) {
    return <Loading loading />;
  }
  if (deleteLoading) {
    return <Loading deleteLoading />;
  }

  return (
    <GridContainer className={classes.center}>
      <AlertModal
        alert={alert}
        setAlert={setAlert}
        onDelete={() => deletehandler()}
        onCancel={() => setAlert(!alert)}
      />
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Findings Management"
              btnText="Add Findings"
              onClick={() => history.push(`${window.location.pathname}/create`)}
            />
          </CardHeader>
          <CardBody>
            <ReactTable
              columns={columns}
              data={data}
              loading={loading}
              currentPage={dataFindings && dataFindings.currentPage}
              defaultPageSize={dataFindings && dataFindings.pageSize}
              totalItems={dataFindings && dataFindings.totalItem}
              totalPages={dataFindings && dataFindings.totalPage}
              setCurrentPage={setCurrentPage}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default Findings;
