import create from "zustand";
import { AVERAGE_RATING_COLOR } from "constants/defaultValues";
import { HORIZONTAL_BAR_BORDER_WIDTH } from "constants/defaultValues";
import { HORIZONTAL_BAR_WIDTH } from "constants/defaultValues";
import { AVERAGE_VALUE_COLOR } from "constants/defaultValues";

const viewInsightsStore = create((set) => ({
  inquiryId: null,
  setInquiryId: (inquiryId) => set(() => ({ inquiryId })),

  inquiryName: null,
  setInquiryName: (inquiryName) => set(() => ({ inquiryName })),

  inquiryTypeName: null,
  setInquiryTypeName: (inquiryTypeName) => set(() => ({ inquiryTypeName })),

  structureColor: "",
  setStructureColor: (structureColor) => set(() => ({ structureColor })),

  peopleColor: "",
  setPeopleColor: (peopleColor) => set(() => ({ peopleColor })),

  processColor: "",
  setProcessColor: (processColor) => set(() => ({ processColor })),

  cultureColor: "",
  setCultureColor: (cultureColor) => set(() => ({ cultureColor })),

  // inputs graphs result

  // Structure
  structureYAxisLabels: [],
  setStructureYAxisLabels: (structureYAxisLabels) =>
    set(() => ({ structureYAxisLabels })),

  structureDataset: [
    {
      label: "Average Rating",
      backgroundColor: AVERAGE_RATING_COLOR,
      borderColor: AVERAGE_RATING_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
    {
      label: "Average Value",
      backgroundColor: AVERAGE_VALUE_COLOR,
      borderColor: AVERAGE_VALUE_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
  ],
  setStructureDataset: (structureDataset) => set(() => ({ structureDataset })),

  // Storing Input Current Area Name as Reference
  currentAreaRef: "culture",
  setCurrentAreaRef: (currentAreaRef) => set(() => ({ currentAreaRef })),

  // Process
  processYAxisLabels: [],
  setProcessYAxisLabels: (processYAxisLabels) =>
    set(() => ({ processYAxisLabels })),

  processDataset: [
    {
      label: "Average Rating",
      backgroundColor: AVERAGE_RATING_COLOR,
      borderColor: AVERAGE_RATING_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
    {
      label: "Average Value",
      backgroundColor: AVERAGE_VALUE_COLOR,
      borderColor: AVERAGE_VALUE_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
  ],
  setProcessDataset: (processDataset) => set(() => ({ processDataset })),

  // People
  peopleYAxisLabels: [],
  setPeopleYAxisLabels: (peopleYAxisLabels) =>
    set(() => ({ peopleYAxisLabels })),

  peopleDataset: [
    {
      label: "Average Rating",
      backgroundColor: AVERAGE_RATING_COLOR,
      borderColor: AVERAGE_RATING_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
    {
      label: "Average Value",
      backgroundColor: AVERAGE_VALUE_COLOR,
      borderColor: AVERAGE_VALUE_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
  ],
  setPeopleDataset: (peopleDataset) => set(() => ({ peopleDataset })),

  // Culture
  cultureYAxisLabels: [],
  setCultureYAxisLabels: (cultureYAxisLabels) =>
    set(() => ({ cultureYAxisLabels })),

  cultureDataset: [
    {
      label: "Average Rating",
      backgroundColor: AVERAGE_RATING_COLOR,
      borderColor: AVERAGE_RATING_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
    {
      label: "Average Value",
      backgroundColor: AVERAGE_VALUE_COLOR,
      borderColor: AVERAGE_VALUE_COLOR,
      borderWidth: HORIZONTAL_BAR_BORDER_WIDTH,
      barThickness: HORIZONTAL_BAR_WIDTH,
      data: [],
    },
  ],
  setCultureDataset: (cultureDataset) => set(() => ({ cultureDataset })),
}));

export default viewInsightsStore;
