/* eslint-disable react/prop-types */
import useAxios from "axios/httpService";
import { GetInquiryByIdUrl } from "axios/commonAPIsUrls";
import { GetCustomQuestionbyIdUrl } from "axios/commonAPIsUrls";
import Loading from "components/Loading/Loading";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CustomMetricsFields from "views/Common/CustomMetrics/CustomMetricsFields";

const ViewCustomMetrics = () => {
  const history = useHistory();
  const { inquiryParamId, CustomParamId } = useParams();
  const [areFieldsValidated, setFieldsValidated] = useState(false);
  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    Area: "",
    Inquiry: inquiryParamId,
    MaxLength: 200,
    ValidationRegex: "",
    AddedBy: "",
    RadioButtonOptions: [],
    CheckBoxOptions: [],
  });
  const [inquiryMenu, setInquiryMenu] = useState({});

  const token = localStorage.getItem("token");
  let email = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    email = decodedToken.email;
  }
  const [
    { data, loading: loadingInquiryById, error },
    ExecuteGetInquiryByIdUrl,
  ] = useAxios(GetInquiryByIdUrl(inquiryParamId, token));

  const [
    { data: dataQuestion, loading: loadingQuestion, error: errorQuestion },
    ExecuteGetCustomQuestionbyIdUrl,
  ] = useAxios(GetCustomQuestionbyIdUrl(CustomParamId, token));

  useEffect(() => {
    ExecuteGetInquiryByIdUrl();
    ExecuteGetCustomQuestionbyIdUrl();
  }, []);

  useEffect(() => {
    if (dataQuestion) {
      setFields({
        ...fields,
        Title: dataQuestion.Title,
        Type: dataQuestion.Type,
        Description: dataQuestion.Description,
        Area: dataQuestion.Area,
        MaxLength: dataQuestion.MaxLength,
        ValidationRegex: dataQuestion.ValidationRegex,
        RadioButtonOptions: dataQuestion.RadioButtonOptions,
        CheckBoxOptions: dataQuestion.CheckBoxOptions,
      });
    }
  }, [dataQuestion]);

  useEffect(() => {
    if (data) {
      setInquiryMenu({
        id: data.Id,
        label: data.Description,
      });
      setFields({
        ...fields,
        AddedBy: email,
      });
    }
  }, [data]);

  const prerequisiteValue = {
    title: "View Custom Added Question",
    goBackLink: "/customerAdmin/custom-metrics",
    disabledArea: true,
    disabled: true,
  };
  if (loadingInquiryById || loadingQuestion) {
    return <Loading loading />;
  }

  return (
    <CustomMetricsFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
      inquiryMenu={inquiryMenu}
    />
  );
};

export default ViewCustomMetrics;
