import React from "react";

// importing material UI icons
import StorefrontOutlinedIcon from "@material-ui/icons/StorefrontOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EventNoteIcon from "@material-ui/icons/EventNote";

export const customerAdminMenu = [
  {
    tabName: "Dashboard",
    tabIcon: <StorefrontOutlinedIcon />,
    tabRoute: "/dashboard",
  },
  {
    tabName: "Inquiry Log",
    tabIcon: <FindInPageOutlinedIcon />,
    tabRoute: "/inquiry-logs",
  },
  {
    tabName: "Custom Metrics",
    tabIcon: <TrendingUpOutlinedIcon />,
    tabRoute: "/custom-metrics",
  },
  {
    tabName: "Survey Forms",
    tabIcon: <EventNoteIcon />,
    tabRoute: "/survey-forms",
  },
  {
    tabName: "Inquiry Type Management",
    tabIcon: <FindInPageOutlinedIcon />,
    tabRoute: "/inquiry-type-management",
  },
  // {
  //   tabName: "Reports",
  //   tabIcon: <DescriptionOutlinedIcon />,
  //   tabRoute: "/reports",
  // },
];
