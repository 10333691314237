// login endpoint
export function UserLoginUrl() {
  return { url: `/auth/login`, method: "POST" };
}

// forgot password endpoint
export function UserForgotPasswordUrl() {
  return { url: `/auth/forgotpassword`, method: "POST" };
}

// verify token endpoint
export function UserVerifyTokenUrl() {
  return { url: `/auth/verifyforgotpasswordtoken`, method: "POST" };
}

// reset password endpoint
export function UserResetPasswordUrl(UserId) {
  return { url: `/auth/resetpassword/${UserId}`, method: "PUT" };
}

// signup endpoint
export function CustomerSignupUrl() {
  return {
    url: `/Customer/CustomerAdminsignup`,
    method: "POST",
  };
}
