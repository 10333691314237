import * as React from "react";

const SvgSend = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.695.291a1 1 0 0 0-1.05-.23l-22 8a1 1 0 0 0 0 1.87l8.59 3.43 6.34-6.36 1.41 1.41-6.37 6.37 3.44 8.59a1 1 0 0 0 .93.63 1 1 0 0 0 .92-.66l8-22a1 1 0 0 0-.21-1.05Z"
      fill="#fff"
    />
  </svg>
);

export default SvgSend;
