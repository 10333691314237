import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import InquiryTypes from "./Inquiry-Types";

const useStyles = makeStyles(styles);

const InquiryManagement = () => {
  const classes = useStyles();
  return <InquiryTypes />;
};

export default InquiryManagement;
