import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/Auth/authStyle.js";
// import activate logo
import ActivateLogo from "assets/img/activateImages/activate-logo.png";
import MailIcon from "@material-ui/icons/Mail";

import Box from "@material-ui/core/Box";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Icon, makeStyles, Typography } from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import classNames from "classnames";
import { authStore } from "store";
import { UserForgotPasswordUrl } from "axios/authAPIsUrls";
import useAxios from "axios/httpService";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import ButtonLoading from "components/Loading/ButtonLoading";
import CustomInput from "components/Inputs/CustomInput";
import SendSvg from "assets/img/svgsComponents/Send";
import { validateMandatoryEmail } from "helpers/validations";

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);
const useStyles = makeStyles(styles);
// eslint-disable-next-line react/prop-types
const ForgetPasswordPage = ({ history }) => {
  const classes = useStyles();
  const setEmail = authStore((state) => state.setEmail);
  const setUserId = authStore((state) => state.setUserId);

  const [validateFields, setValidateFields] = React.useState(false);
  const [values, setValues] = React.useState({
    Email: "",
  });
  const [isEmailValid, setIsEmailValid] = React.useState(false);

  // checkingLoader
  const [checkingLoader, setCheckingLoader] = React.useState(false);

  const [{ loading }, ExecuteUserForgotPasswordUrl] = useAxios(
    UserForgotPasswordUrl(),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (validateMandatoryEmail(values.Email) !== null) {
      return false;
    }
    return true;
  };
  // setCheckingLoader(!checkingLoader);
  const forgetPasswordHandler = async () => {
    setValidateFields(true);
    if (allFieldsValidated()) {
      setEmail(values.Email);
      try {
        setCheckingLoader(true);
        const res = await ExecuteUserForgotPasswordUrl({ data: values });
        setUserId(res.data.Id);
        // eslint-disable-next-line react/prop-types
        history.push("/auth/verify-token");
      } catch (error) {
        console.log("API Error", error);
        apiErrorHandler(error, history);
      } finally {
        setCheckingLoader(false);
      }
    }
  };

  return (
    <div>
      <GridContainer noDefaultSetting className={classes.container}>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_form}
          >
            {/* image */}
            <Box mb={8} mt={8}>
              <img src={ActivateLogo} alt="activate_logo" height="140" />
            </Box>
            <Box p={1}>
              <Typography variant="caption" display="block" gutterBottom>
                Please enter your email address to receive a verification code.
              </Typography>
            </Box>
            {/* Custom input form */}
            <Box className={classes.width_80}>
              <Box mt={2}>
                <GridContainer alignItems="flex-end">
                  <GridItem xs={12} md={12} sm={12}>
                    {/* Email Address */}
                    <CustomInput
                      inputType="email"
                      required
                      label="Email Address"
                      leftIcon={<Icon>email</Icon>}
                      value={values.Email}
                      onChange={(event) =>
                        setValues({ Email: event.target.value })
                      }
                      errorText={
                        validateFields && validateMandatoryEmail(values.Email)
                      }
                    />
                  </GridItem>
                </GridContainer>
              </Box>
            </Box>
            {/* litte content */}

            <Box mb={3}>
              <Box display="flex">
                <Typography variant="caption" display="block" gutterBottom>
                  Already have an account ?{" "}
                  <span
                    className={classNames(
                      classes.theme_color,
                      classes.cursor_pointer
                    )}
                    // eslint-disable-next-line react/prop-types
                    onClick={() => history.push("/auth/login")}
                  >
                    {" "}
                    Login
                  </span>
                </Typography>
              </Box>
            </Box>
            {/* button */}
            <Box p={1} mt={4} mb={10} display="flex">
              <Button
                round
                disabled={checkingLoader}
                className={classes.theme_color_background}
                onClick={forgetPasswordHandler}
              >
                <Box display="inline">
                  <SendSvg height="15" />
                </Box>
                {loading ? (
                  <ButtonLoading />
                ) : (
                  <b className={classes.ButtonText}>Send</b>
                )}
              </Button>
            </Box>
          </Box>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={6}
          noDefaultPadding
          className={classes.item2}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className={classes.login_content}
            pb={4}
          >
            {/* Content One */}
            <Box p={1}>
              <h1 className={classes.login_form_content1}>
                Welcome to{" "}
                <b>
                  <span className={classes.theme_color}>ACT</span>ivate Advance
                </b>
              </h1>
            </Box>
            {/* Content Two */}
            <Box>
              <h3 className={classes.login_form_content2}>
                Activating Advanced Strategies in Real Time
              </h3>
            </Box>
          </Box>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default ForgetPasswordPage;
