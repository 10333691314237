import React from "react";
import GridContainer from "components/Grid/GridContainer";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    textTransform: "uppercase",
    minWidth: "200px",
    height: "200px",
    marginTop: "60px",
  },
  areaTitle: { fontSize: "1.25rem", fontWeight: "500", color: "white" },
  DefaultareaTitle: {
    fontSize: "1.25rem",
    fontWeight: "500",
    color: "#000000",
  },
};

const useStyles = makeStyles(styles);

const AreaSquare = ({ areaName, color, onClick, customClassName }) => {
  const classes = useStyles();
  return (
    <GridContainer
      className={classes.container + " " + customClassName}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {color ? (
        <h3 className={classes.areaTitle}>{areaName}</h3>
      ) : (
        <h3 className={classes.DefaultareaTitle}>{areaName}</h3>
      )}
    </GridContainer>
  );
};

export default AreaSquare;

AreaSquare.defaultProps = {
  areaName: "Area Title Here",
  color: "black",
};

AreaSquare.propTypes = {
  areaName: PropTypes.string,
  color: PropTypes.string,
  customClassName: PropTypes.string,
  onClick: PropTypes.func,
};
