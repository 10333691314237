import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import React, { useState } from "react";
import useAxios from "axios/httpService";
import { CreateRoadmapUrl } from "axios/adminAPIsUrls";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { useHistory } from "react-router-dom";
import Loading from "components/Loading/Loading";
import FormContainer from "components/Forms/FormContainer";
import CustomFormSelect from "components/Selects/CustomSelect";
import useInquiryTypes from "hooks/useInquiryTypes";
import styles from "assets/jss/material-dashboard-pro-react/components/formStyle (temp)";
import { makeStyles } from "@material-ui/core";
import { isEmpty } from "helpers/validations";
import { NotificationManager } from "components/common/ReactNotifications";
import RoadmapStep from "./RoadmapStep";

const useStyles = makeStyles(styles);
const CreateRoadmap = () => {
  const history = useHistory();
  const classes = useStyles();

  const [areFieldsValidated, setValidateFields] = useState(false);
  const [fields, setFields] = useState({
    InquiryTypeId: "",
    Step1_RoadmapTitle: "",
    Step2_RoadmapTitle: "",
    Step3_RoadmapTitle: "",
    Step1_BriefDescription: "",
    Step2_BriefDescription: "",
    Step3_BriefDescription: "",
    Step1_RoadmapDetail: "",
    Step2_RoadmapDetail: "",
    Step3_RoadmapDetail: "",
    InquiryTypeName: "",
  });

  const token = localStorage.getItem("token");
  const { loading, inquiryTypes, error } = useInquiryTypes();

  const [
    { loading: loadingCreateRoadmap, error: errorInCreateRoadmap },
    ExecuteCreateRoadmapUrl,
  ] = useAxios(CreateRoadmapUrl(token), { manual: true });

  const allFieldsValidated = () => {
    if (isEmpty(fields.Step1_RoadmapDetail)) {
      return false;
    }
    if (isEmpty(fields.Step2_RoadmapDetail)) {
      return false;
    }
    if (isEmpty(fields.Step3_RoadmapDetail)) {
      return false;
    }
    if (isEmpty(fields.Step1_BriefDescription)) {
      return false;
    }
    if (isEmpty(fields.Step2_BriefDescription)) {
      return false;
    }
    if (isEmpty(fields.Step3_BriefDescription)) {
      return false;
    }
    if (isEmpty(fields.Step1_RoadmapTitle)) {
      return false;
    }
    if (isEmpty(fields.Step2_RoadmapTitle)) {
      return false;
    }
    if (isEmpty(fields.Step3_RoadmapTitle)) {
      return false;
    }
    if (isEmpty(fields.InquiryTypeId)) {
      return false;
    }
    return true;
  };

  const onCreate = async (data = fields) => {
    setValidateFields(true);

    if (allFieldsValidated()) {
      try {
        const response = await ExecuteCreateRoadmapUrl({ data });
        if (response.status === 200) {
          NotificationManager.success("Created New Roadmap", 5000);
          history.push("/superAdmin/manage-roadmap");
        }
      } catch (exception) {
        console.log("Error in Creating New Roadmap ", exception);
        apiErrorHandler(exception, history);
      }
    }
  };

  if (loading || loadingCreateRoadmap) {
    return <Loading loading />;
  }

  if (error) {
    console.log("Error in Getting Inquiry Types", error);
    apiErrorHandler(error, history);
  }

  if (errorInCreateRoadmap) {
    console.log("Error in Creating Roadmap", errorInCreateRoadmap);
    apiErrorHandler(errorInCreateRoadmap, history);
  }

  const handleInput = (type, value) => {
    setFields({
      ...fields,
      [type]: value,
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader>
            <TableHeader
              title="Add Roadmap"
              btnText="Save"
              onClick={() => onCreate()}
              showBackButton
              goBackRoute={`/superAdmin/manage-roadmap`}
            />
          </CardHeader>
          <CardBody>
            <FormContainer>
              <GridItem xs={6} sm={6} md={6}>
                <CustomFormSelect
                  inputType="text"
                  required
                  label="Inquiry Type"
                  value={fields.InquiryTypeId}
                  menu={inquiryTypes && inquiryTypes}
                  customClassName={classes.textFieldSelectStyling}
                  onChange={(event) => {
                    setFields({
                      ...fields,
                      InquiryTypeId: parseInt(event.target.value),
                    });
                  }}
                  errorText={
                    areFieldsValidated && isEmpty(fields.InquiryTypeId)
                  }
                />
              </GridItem>
              {/* First Step */}
              <RoadmapStep
                stepNumber={1}
                stepTitle={fields.Step1_RoadmapTitle}
                stepDetail={fields.Step1_RoadmapDetail}
                stepDescription={fields.Step1_BriefDescription}
                handleInput={handleInput}
                shouldValidateFields={areFieldsValidated}
              />
              {/* Second Step */}
              <RoadmapStep
                stepNumber={2}
                stepTitle={fields.Step2_RoadmapTitle}
                stepDetail={fields.Step2_RoadmapDetail}
                stepDescription={fields.Step2_BriefDescription}
                handleInput={handleInput}
                shouldValidateFields={areFieldsValidated}
              />
              {/* Third Step */}
              <RoadmapStep
                stepNumber={3}
                stepTitle={fields.Step3_RoadmapTitle}
                stepDetail={fields.Step3_RoadmapDetail}
                stepDescription={fields.Step3_BriefDescription}
                handleInput={handleInput}
                shouldValidateFields={areFieldsValidated}
              />
            </FormContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default CreateRoadmap;
