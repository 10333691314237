import * as React from "react";

const SvgVector = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 31 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.127 21.412v-.994c0-.52.25-1.01.64-1.24a17.31 17.31 0 0 1 8.74-2.355c.046 0 .078 0 .124.016.157-1.07.47-2.095.923-3.028-.344-.031-.688-.046-1.048-.046a20.467 20.467 0 0 0-10.333 2.783C.797 17.344 0 18.842 0 20.418v4.053h14.476a10.814 10.814 0 0 1-1.516-3.06H3.127Zm9.38-9.177c3.454 0 6.252-2.737 6.252-6.117S15.961 0 12.506 0 6.253 2.738 6.253 6.118c0 3.38 2.798 6.117 6.253 6.117Zm0-9.176c1.719 0 3.126 1.376 3.126 3.059 0 1.682-1.407 3.058-3.127 3.058-1.72 0-3.126-1.376-3.126-3.058 0-1.683 1.407-3.06 3.126-3.06Zm16.805 15.294c0-.337-.047-.642-.094-.964L31 15.845l-1.563-2.646-2.267.75a5.73 5.73 0 0 0-1.688-.964l-.47-2.28h-3.126l-.469 2.28a5.73 5.73 0 0 0-1.688.963l-2.267-.75-1.563 2.647 1.782 1.544c-.047.322-.094.628-.094.964 0 .336.047.642.094.963l-1.782 1.545 1.563 2.646 2.267-.75c.5.414 1.063.735 1.688.964L21.886 26h3.127l.469-2.279a5.729 5.729 0 0 0 1.688-.963l2.267.75L31 20.86l-1.782-1.544c.047-.322.094-.628.094-.964Zm-5.863 3.059c-1.72 0-3.126-1.377-3.126-3.06 0-1.681 1.407-3.058 3.126-3.058 1.72 0 3.127 1.377 3.127 3.059 0 1.682-1.407 3.059-3.127 3.059Z"
      fill="#D65746"
    />
  </svg>
);

export default SvgVector;
