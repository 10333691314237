import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useAxios from "axios/httpService";
import CustomTable from "views/Common/CustomMetrics/CustomTable";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import Loading from "components/Loading/Loading";
import jwtDecode from "jwt-decode";
import { GetAllCustomQuestions } from "axios/adminAPIsUrls";

const CustomMetricsAdmin = () => {
  const history = useHistory();
  const pageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [alert, setAlert] = useState(false);
  const token = localStorage.getItem("token");
  let userId;
  if (token) {
    const decodedToken = jwtDecode(token);
    userId = decodedToken.userid;
  }
  const [
    { data, error, loading },
    ExecuteGetAllCustomQuestionByUserId,
  ] = useAxios(GetAllCustomQuestions(currentPage, pageSize, token), {
    manual: true,
  });

  useEffect(() => {
    ExecuteGetAllCustomQuestionByUserId(currentPage);
  }, [currentPage]);

  if (loading) {
    return <Loading loading />;
  }
  if (error) {
    apiErrorHandler(error, history);
  }

  return (
    <CustomTable
      dataAPi={data}
      loading={loading}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default CustomMetricsAdmin;
