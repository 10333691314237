const { AREA_COLORS } = require("constants/defaultValues");

export const getColor = (color) => {
  let newColor =
    color === "red"
      ? AREA_COLORS[0].red
      : color === "blue"
      ? AREA_COLORS[2].blue
      : color === "yellow"
      ? AREA_COLORS[3].yellow
      : color === "green"
      ? AREA_COLORS[1].green
      : color;
  return newColor;
};
