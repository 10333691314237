/* eslint-disable react/prop-types */
import useAxios from "axios/httpService";
import { GetInquiryByIdUrl } from "axios/commonAPIsUrls";
import { CreateCustomQuestionUrl } from "axios/customerAdminAPIsUrls";
import { NotificationManager } from "components/common/ReactNotifications";
import Loading from "components/Loading/Loading";
import { apiErrorHandler } from "helpers/apiErrorHandler";
import { isNumber } from "helpers/validations";
import { isEmpty } from "helpers/validations";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CustomMetricsFields from "views/Common/CustomMetrics/CustomMetricsFields";

const CreateCustomMetrics = () => {
  const history = useHistory();
  const { inquiryParamId } = useParams();
  const [areFieldsValidated, setFieldsValidated] = useState(false);
  const [fields, setFields] = useState({
    Title: "",
    Type: "",
    Description: "",
    Area: "",
    Inquiry: inquiryParamId,
    MaxLength: 200,
    ValidationRegex: "",
    AddedBy: "constant@gmail.com",
    RadioButtonOptions: [],
    CheckBoxOptions: [],
  });
  const [inquiryMenu, setInquiryMenu] = useState({});

  const token = localStorage.getItem("token");
  let email = "";
  if (token) {
    const decodedToken = jwtDecode(token);
    email = decodedToken.email;
  }
  const [
    { data, loading: loadingInquiryById, error },
    ExecuteGetInquiryByIdUrl,
  ] = useAxios(GetInquiryByIdUrl(inquiryParamId, token));
  useEffect(() => {
    ExecuteGetInquiryByIdUrl();
  }, []);
  useEffect(() => {
    if (data) {
      setInquiryMenu({
        id: data.Id,
        label: data.Description,
      });
      setFields({
        ...fields,
        AddedBy: email,
      });
    }
  }, [data]);

  const [{ loading }, ExecuteCreateCustomQuestionUrl] = useAxios(
    CreateCustomQuestionUrl(inquiryParamId, token),
    {
      manual: true,
    }
  );

  const allFieldsValidated = () => {
    if (isEmpty(fields.Area)) {
      return false;
    }
    if (isEmpty(fields.Type)) {
      return false;
    }
    if (isEmpty(fields.Title)) {
      return false;
    }
    if (isEmpty(fields.Description)) {
      return false;
    }
    if (fields.Type === "Textbox") {
      if (isNumber(fields.MaxLength)) {
        return false;
      }
      if (isEmpty(fields.ValidationRegex)) {
        return false;
      }
    }
    if (fields.Type.toLowerCase() === "rating") {
      if (
        isEmpty(fields.RadioButtonOptions) ||
        fields.RadioButtonOptions.indexOf("") > -1
      ) {
        return false;
      }
    }
    if (fields.Type.toLowerCase() === "checkbox") {
      if (
        isEmpty(fields.CheckBoxOptions) ||
        fields.CheckBoxOptions.indexOf("") > -1
      ) {
        return false;
      }
    }

    return true;
  };

  const onUpdateCustom = async (data = fields) => {
    setFieldsValidated(true);
    if (allFieldsValidated()) {
      if (fields.Type !== "Textbox") {
        delete data.MaxLength;
        delete data.ValidationRegex;
      }
      if (fields.Type !== "Rating") {
        data.RadioButtonOptions = [];
      }
      if (fields.Type !== "Checkbox") {
        data.CheckBoxOptions = [];
      }
      const value = data.RadioButtonOptions.filter((element) => {
        return element === "d";
      });
      if (fields.Type == "Rating" && value.length > 0) {
        NotificationManager.error(
          "Failure",
          "Remove Invalid rating option d to proceed",
          5000
        );
        setFieldsValidated(false);
      } else {
        delete data.AddedBy;
        delete data.Inquiry;
        try {
          const res = await ExecuteCreateCustomQuestionUrl({
            data,
          });
          NotificationManager.success("Custom Question Created", 5000);
          history.push(`/customerAdmin/custom-metrics`);
        } catch (exception) {
          console.log("Error in Creating Custom Question", exception);
          apiErrorHandler(exception, history);
        }
      }
    }
  };

  const prerequisiteValue = {
    title: "Add Custom Question",
    goBackLink: "/customerAdmin/inquiry-logs",
    btnAccept: "Save", // Btn One
    disabledArea: false,
    Approve: function () {
      onUpdateCustom();
    },
  };
  if (loading || loadingInquiryById) {
    return <Loading loading />;
  }

  return (
    <CustomMetricsFields
      fields={fields}
      setFields={setFields}
      prerequisiteValue={prerequisiteValue}
      AreFieldsValidated={areFieldsValidated}
      inquiryMenu={inquiryMenu}
    />
  );
};

export default CreateCustomMetrics;
