export const viewInsightsMenu = [
  {
    tabName: "Overview",
    tabRoute: `/dashboard/view-insights/:inquiryId/overview`,
  },
  {
    tabName: "Findings",
    tabRoute: `/dashboard/view-insights/:inquiryId/findings`,
  },
  {
    tabName: "Scenarios",
    tabRoute: `/dashboard/view-insights/:inquiryId/scenarios`,
  },
  {
    tabName: "Inputs",
    tabRoute: `/dashboard/view-insights/:inquiryId/inputs`,
  },
  {
    tabName: "Roadmap",
    tabRoute: `/dashboard/view-insights/:inquiryId/roadmap`,
  },
];
