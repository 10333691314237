/* eslint-disable react/prop-types */
import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomSwitchLabel from "components/Checkboxes/CustomSwitchLabel";
import FormContainer from "components/Forms/FormContainer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import TableHeader from "components/Tables/TableHeader";
import CustomInput from "components/Inputs/CustomInput";
import ChangePassword from "views/Common/Manage-Profile/ChangePassword";
import { isEmpty } from "helpers/validations";

const styles = {
  themeColorPointer: {
    color: "#D65746",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const useStyles = makeStyles(styles);

const ManageProfile = ({
  fields,
  handleChange,
  handleChangeCheck,
  onUpdate,
  AreFieldsValidated,
  isModelOpen,
  setIsModelOpen,
  fieldsPass,
  setFieldsPass,
  AreFieldsValidatedPass,
  setValidateFieldsPass,
  changePasswordAPi,
}) => {
  const classes = useStyles();
  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <TableHeader
                title="Manage Profile"
                btnText="Save"
                showBackButton
                onClick={() => onUpdate()}
                goBackRoute={`/customerAdmin/dashboard`}
              />
            </CardHeader>
            <CardBody>
              <FormContainer height>
                <GridContainer style={{ marginTop: "50px" }}>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="First Name"
                      value={fields.FirstName}
                      onChange={handleChange("FirstName")}
                      errorText={
                        AreFieldsValidated && isEmpty(fields.FirstName)
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      label="Last Name"
                      value={fields.LastName}
                      onChange={handleChange("LastName")}
                      errorText={AreFieldsValidated && isEmpty(fields.LastName)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      required
                      disabled
                      label="Email Address"
                      value={fields.Email}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      inputType="text"
                      label="Job Title"
                      value={fields.Designation}
                      onChange={handleChange("Designation")}
                    />
                  </GridItem>

                  <GridItem xs={12}>
                    <Typography variant="body1" gutterBottom>
                      <span
                        onClick={() => setIsModelOpen(true)}
                        className={classes.themeColorPointer}
                      >
                        Change Password
                      </span>
                    </Typography>
                  </GridItem>

                  <GridItem xs={12} noDefaultPadding>
                    <CustomSwitchLabel
                      checked={fields.IsAdmin}
                      disabled
                      onChange={handleChangeCheck("IsAdmin")}
                      themeColorLabel
                      label="Admin"
                    />
                  </GridItem>
                  <GridItem xs={12} noDefaultPadding>
                    <CustomSwitchLabel
                      checked={fields.Active}
                      onChange={handleChangeCheck("Active")}
                      themeColorLabel
                      label="Active"
                    />
                  </GridItem>
                </GridContainer>
              </FormContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {isModelOpen ? (
        <ChangePassword
          setIsModelOpen={setIsModelOpen}
          isModelOpen={isModelOpen}
          fields={fieldsPass}
          setFields={setFieldsPass}
          AreFieldsValidated={AreFieldsValidatedPass}
          setValidateFields={setValidateFieldsPass}
          changePasswordAPi={changePasswordAPi}
        />
      ) : null}
    </>
  );
};

export default ManageProfile;
